unit libace;

interface

{$mode objfpc}
{$modeswitch externalclass}

uses
  Web, JS, Classes, Types;

type
  TAcePoint = class external name 'Object' (TJSObject)
  private
    FColumn: NativeInt external name 'column';
    FRow: NativeInt external name 'row';
  public
    property Column: NativeInt read FColumn write FColumn;
    property Row: NativeInt read FRow write FRow;
  end;

  TAceRange = class external name 'Range' (TJSObject)
  private
    FEnd: TAcePoint external name 'end';
    FStart: TAcePoint external name 'start';
  public
    constructor new(aStartRow, aStartColumn, aEndRow, aEndColumn: NativeInt); overload; //TO BE TESTED
    procedure setStart(aRow: NativeInt; aColumn: NativeInt);
    procedure setEnd(aRow: NativeInt; aColumn: NativeInt);
    function fromPoints(aStartPoint: TAcePoint; aEndPoint: TAcePoint): TAceRange;
    property &end: TAcePoint read FEnd;
    property start: TAcePoint read FStart;
  end;

  TAceCompletion = class external name 'Object' (TJSObject)
  private
    FValue: string external name 'value';
    FScore: Integer external name 'score';
    FMeta: string external name 'meta';
    FName: string external name 'name';
    FCaption: string external name 'caption';
  public
    property value: string read FValue write FValue;
    property score: Integer read FScore write FScore;
    property meta: string read FMeta write FMeta;
    property name: string read FName write FName;
    property caption: string read FCaption write FCaption;
  end;

  TAceCompletions = Array of TAceCompletion;
  TAceEditSession = class;
  TAceEditor = class;

  TAceCompletersCallback = reference to procedure(aObject: TJSObject; aCompletions: TAceCompletions);

  TAceCompleter = class external name 'Object' (TJSObject)
  public
    procedure getCompletions(aEditor: TAceEditor; aSession: TAceEditSession; aPos: TAcePoint; aPrefix: string; aCallback: TAceCompletersCallback); overload;
  end;

  TAceCompletersArray = array of TAceCompleter;

  TAceVirtualRenderer = class external name 'VirtualRenderer' (TJSObject)
  public
    procedure setOption(aName: string; aValue: JSValue);
    procedure setShowGutter(aShow: Boolean);
    function getShowGutter: Boolean;
  end;

  TAceDocument = class external name 'Document' (TJSObject)
  public
    procedure insertFullLines(aRow: Integer; aLines: TJSStringDynArray);
    procedure removeNewLine(aRow: NativeInt);
    procedure setNewLineMode(aMode: string);
    procedure setValue(aText: string);
    function getAllLines: TJSStringDynArray;
    function getLength: NativeInt;
    function getLine(aRow: NativeInt): string;
    function getLines(aFirstRow: NativeInt; aLastRow: NativeInt): TJSStringDynArray;
    function getValue: string;
    function indexToPosition(aIntex: NativeInt): TAcePoint;
    function insert(aPosition: TAcePoint; aText: string): TAcePoint;
    function insertMergedLines(aPosition: TAcePoint; aLines: TJSStringDynArray): TAcePoint;
    function positionToIndex(aPosition: TAcePoint): NativeInt;
    function remove(aRange: TAceRange): TAcePoint;
    function removeInLine(aRow: NativeInt; aStartColumn: NativeInt; aEndColumn: NativeInt): TAcePoint;
    function removeFullLines(aFirstRow: NativeInt; aLastRow: NativeInt): TJSStringDynArray;
    function replace(aRange: TAceRange; aText: string): TAcePoint;
  end;

  TAceSearchOptions = class external name 'Object' (TJSObject)
  private
    FBackwards: Boolean external name 'backwards';
    FCaseSensitive: Boolean external name 'caseSensitive';
    FNeedle: string external name 'needle';
    FPreventScroll: Boolean external name 'preventScroll';
    FRange: TAceRange external name 'range';
    FRegExp: TJSRegexp external name 'regExp';
    FSkipCurrent: Boolean external name 'skipCurrent';
    FStart: TAceRange external name 'start';
    FWholeWord: string external name 'wholeWord';
    FWrap: Boolean external name 'wrap';
  public
    property backwards: Boolean read FBackWards write FBackWards;
    property caseSensitive: Boolean read FCaseSensitive write FCaseSensitive;
    property needle: string read FNeedle write FNeedle;
    property preventScroll: Boolean read FPreventScroll write FPreventScroll;
    property range: TAceRange read FRange write FRange;
    property regExp: TJSRegexp read FRegExp write FRegExp;
    property skipCurrent: Boolean read FSkipCurrent write FSkipCurrent;
    property start: TAceRange read FStart write FStart;
    property wholeWord: string read FWholeWord write FWholeWord;
    property wrap: Boolean read FWrap write FWrap;
  end;

  TAceEditSession = class external name 'EditSession' (TJSObject)
  public
    procedure setMode(aMode: string);
    procedure setOption(aName: string; aValue: JSValue);
    procedure setTabSize(aSize: NativeInt);
    procedure setUseWrapMode(aUseWrapMode: Boolean);
    procedure setWrapLimitRange(); overload;
    procedure setWrapLimitRange(aMin: NativeInt; aMax: NativeInt); overload;
    function getDocument: TAceDocument;
    function getTabSize: NativeInt;
    function getTabString: string;
    function getUseWrapMode: Boolean;
  end;

  TAceSelection = class external name 'Selection' (TJSObject)
  public
    procedure clearSelection;
    //TODO: cursor moving procedures if needed
    procedure moveTo(aRow: NativeInt; aColumn: NativeInt);
    procedure moveToPosition(aPosition: TAcePoint);
    procedure selectAll;
    procedure selectAWord;
    //TODO: selection procedures if needed
    procedure setSelectionRange(aRange: TAceRange; aReverse: Boolean);
    function getCursor: TAcePoint;
    function getRange: TAceRange;
    function getSelectionLead: TAcePoint;
    function isEmpty: Boolean;
    function isMultiLine: Boolean;
  end;

  TAceEditor = class external name 'Editor' (TJSObject)
  private
    FCompleters: TAceCompletersArray external name 'completers';
    FContainer: TJSHTMLElement external name 'container';
    FRenderer: TAceVirtualRenderer external name 'renderer';
    FSelection: TAceSelection external name 'selection';
    FSession: TAceEditSession external name 'session';
  public
    constructor new(aVirtualRenderer : TAceVirtualRenderer; aEditSession : TAceEditSession); overload;
    procedure centerSelection;
    procedure clearSelection;
    procedure copyLinesDown;
    procedure copyLinesUp;
    procedure find(aNeedle: string); overload;
    procedure find(aNeedle: string; aOptions: TAceSearchOptions; aAnimate: Boolean); overload;
    procedure findNext;
    procedure findPrevious;
    procedure focus;
    procedure gotoLine(aLineNumber: NativeInt; aColumn: NativeInt; aAnimate: Boolean);
    procedure gotoPageDown;
    procedure gotoPageUp;
    procedure indent;
    procedure moveCursorTo(aRow: NativeInt; aColumn: NativeInt); //does not deselect
    //TODO: navigation stuffs if needed later
    procedure redo;
    procedure removeSelectionMaker(aOrientedRange: TAceRange);
    procedure resize;
    //TODO: removal stuff if needed
    procedure selectAll;
    procedure setDisplayIndentGuides(aDisplay: Boolean);
    procedure setFadeFoldWidgets(aFade: Boolean);
    procedure setFontSize(aSize: string);
    procedure setHighlightActiveLine(aShouldHighlight: Boolean);
    procedure setHScrollBarAlwaysVisible(aAlwaysVisible: Boolean);
    procedure setOption(aName: string; aValue: JSValue);
    procedure setPrintMarginColumn(aShowMargin: NativeInt);
    procedure setReadOnly(aReadOnly: Boolean);
    procedure setShowFoldWidgets(aShow: Boolean);
    procedure setShowInvisibles(aShowInvisibles: Boolean);
    procedure setShowPrintMargin(aShowMargin: Boolean);
    procedure setTheme(aTheme: string);
    procedure setValue(aText: string);
    procedure setVScrollBarAlwaysVisible(aAlwaysVisible: Boolean);
    procedure toggleCommentLines;
    procedure undo;
    function addSelectionMarker(aOrientedRange: TAceRange): TAceRange;
    function execCommand(aCommand: string): Boolean;
    function findAll: NativeInt; overload;
    function findAll(aNeedle: string): NativeInt; overload;
    function getCursorPosition: TAcePoint;
    function getCursorPositionScreen: TAcePoint;
    function getDisplayIndentGuides: Boolean;
    function getFadeFoldWidgets: Boolean;
    function getFontSize: string;
    function getHighlightActiveLine: Boolean;
    function getHScrollBarAlwaysVisible: Boolean;
    function getKeyboardHandler: string;
    function getPrintMarginColumn: NativeInt;
    function getReadOnly: Boolean;
    function getSelectedText: string;
    function getShowFoldWidgets: Boolean;
    function getShowInvisibles: Boolean;
    function getShowPrintMargin: Boolean;
    function getTheme: string;
    function getValue: string;
    function getVScrollBarAlwaysVisible: Boolean;
    function replace(aReplacement: string): NativeInt; overload;
    function replace(aReplacement: string; aOptions: TAceSearchOptions): NativeInt; overload;
    function replaceAll(aReplacement: string): NativeInt; overload;
    function replaceAll(aReplacement: string; aOptions: TAceSearchOptions): NativeInt; overload;
    property completers: TAceCompletersArray read FCompleters write FCompleters;
    property container: TJSHTMLElement read FContainer write FContainer;
    property renderer: TAceVirtualRenderer read FRenderer write FRenderer;
    property selection: TAceSelection read FSelection write FSelection;
    property session: TAceEditSession read FSession write FSession;
  end;

  TAce = class external name 'ace' (TJSObject)
  private
    FRange: TAceRange external name 'Range';
  public
    function edit(aElement: string): TAceEditor;
    function edit(aElement: TJSElement): TAceEditor;
    function require(aModuleName: string): TJSObject;
    property Range: TAceRange read FRange write FRange;
  end;

var
  ace : TAce; external name 'ace';

const
  PascalKeywords: array[0..87] of string = ('absolute', 'abstract', 'all', 'and', 'and_then', 'array', 'as', 'asm', 'attribute', 'begin',
    'bindable', 'case', 'class', 'const', 'constructor', 'destructor', 'div', 'do', 'else', 'end',
    'except', 'export', 'exports', 'external', 'far', 'file', 'finalization', 'finally', 'for',
    'forward', 'function', 'goto', 'if', 'implementation', 'import', 'in', 'inherited', 'initialization',
    'interface', 'interrupt', 'is', 'label', 'library', 'mod', 'module', 'name', 'near', 'nil', 'not',
    'object', 'of', 'only', 'operator', 'or', 'or_else', 'otherwise', 'packed', 'pow', 'private', 'procedure', 'program',
    'property', 'protected', 'public', 'published', 'qualified', 'record', 'repeat', 'resident',
    'restricted', 'segment', 'set', 'shl', 'shr', 'then', 'to', 'try', 'type', 'unit', 'until',
    'uses', 'value', 'var', 'view', 'virtual', 'while', 'with', 'xor');

implementation

end.
