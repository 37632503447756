{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2024                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCTreeViewData;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

{$IFNDEF LCLLIB}
{$IFNDEF WEBLIB}
{$HINTS OFF}
{$IF COMPILERVERSION > 22}
{$DEFINE USEOWNEDCOLLECTION}
{$IFEND}
{$HINTS ON}
{$ENDIF}
{$ENDIF}

{$IFDEF LCLLIB}
{$DEFINE USEOWNEDCOLLECTION}
{$ENDIF}

interface

uses
  Classes, WEBLib.TMSFNCTypes, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.TMSFNCBitmapContainer, WEBLib.TMSFNCTreeViewBase
  {$IFNDEF LCLLIB}
  {$IFNDEF WEBLIB}
  ,Generics.Collections, Types
  {$ENDIF}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,Contnrs
  {$ENDIF}
  ;

resourcestring
  sTMSFNCTreeViewGroup = 'Group';
  sTMSFNCTreeViewColumn = 'Column';

const
  {$IFDEF FMXMOBILE}
  MAXLEVELDEPTH = 100;
  MAXNODECOUNT = 1000000;
  {$ELSE}
  MAXLEVELDEPTH = 100;
  MAXNODECOUNT = 2000000;
  {$ENDIF}

type
  TTMSFNCTreeViewCacheItem = class;
  TTMSFNCTreeViewData = class;
  TTMSFNCTreeViewGroup = class;
  TTMSFNCTreeViewColumn = class;

  TTMSFNCTreeViewNode = class;

  TTMSFNCTreeViewNodeScrollPosition = (tvnspTop, tvnspBottom, tvnspMiddle);

  TTMSFNCTreeViewVirtualNodeRemoveData = record
    RowIndex: Integer;
    Count: Integer;
    ParentNode: Integer;
  end;

  TTMSFNCArrayTRectF = array of TRectF;
  TTMSFNCArrayBoolean = array of Boolean;

  TTMSFNCTreeViewVirtualNode = class
  private
    FTreeView: TTMSFNCTreeViewData;
    FLevel: Integer;
    FAddedToVisibleList: Boolean;
    FChildren: Integer;
    FParentNode: Integer;
    FExpanded: Boolean;
    FTotalChildren: Integer;
    FIndex: Integer;
    FRow: Integer;
    FHeight: Double;
    FCalculated: Boolean;
    FCache: TTMSFNCTreeViewCacheItem;
    FTextRects: TTMSFNCArrayTRectF;
    FBitmapRects: TTMSFNCArrayTRectF;
    FExpandRects: TTMSFNCArrayTRectF;
    FCheckRects: TTMSFNCArrayTRectF;
    FCheckStates: TTMSFNCArrayBoolean;
    FNode: TTMSFNCTreeViewNode;
    FExtended: Boolean;
    FExtraRects: TTMSFNCArrayTRectF;
    FTitleRects: TTMSFNCArrayTRectF;
    FTitleHeight: Double;
    FTitleExtraRects: TTMSFNCArrayTRectF;
    FDataPointer: Pointer;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    function GetText(AColumn: Integer): String;
    function GetTitle(AColumn: Integer): String;
    function GetTitleExpanded(AColumn: Integer): Boolean;
  protected
    property Cache: TTMSFNCTreeViewCacheItem read FCache;
  public
    function GetParent: TTMSFNCTreeViewVirtualNode; virtual;
    function GetChildCount: Integer; virtual;
    function GetPrevious: TTMSFNCTreeViewVirtualNode; virtual;
    function GetNext: TTMSFNCTreeViewVirtualNode; virtual;
    function GetPreviousChild(ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode; virtual;
    function GetNextChild(ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode; virtual;
    function GetNextSibling: TTMSFNCTreeViewVirtualNode; virtual;
    function GetPreviousSibling: TTMSFNCTreeViewVirtualNode; virtual;
    function GetFirstChild: TTMSFNCTreeViewVirtualNode; virtual;
    function GetLastChild: TTMSFNCTreeViewVirtualNode; virtual;
    procedure RemoveChildren; virtual;
    procedure Expand(ARecurse: Boolean = False); virtual;
    procedure Collapse(ARecurse: Boolean = False); virtual;

    property Children: Integer read FChildren;
    property TotalChildren: Integer read FTotalChildren;
    property Expanded: Boolean read FExpanded;
    property Extended: Boolean read FExtended;
    property Level: Integer read FLevel;
    property Row: Integer read FRow;
    property Calculated: Boolean read FCalculated;
    property Height: Double read FHeight;
    property TitleHeight: Double read FTitleHeight;
    property ParentNode: Integer read FParentNode;
    property Index: Integer read FIndex;
    property ExtraRects: TTMSFNCArrayTRectF read FExtraRects;
    property TextRects: TTMSFNCArrayTRectF read FTextRects;
    property TitleRects: TTMSFNCArrayTRectF read FTitleRects;
    property TitleExtraRects: TTMSFNCArrayTRectF read FTitleExtraRects;
    property BitmapRects: TTMSFNCArrayTRectF read FBitmapRects;
    property CheckRects: TTMSFNCArrayTRectF read FCheckRects;
    property ExpandRects: TTMSFNCArrayTRectF read FExpandRects;
    property CheckStates: TTMSFNCArrayBoolean read FCheckStates;
    property Node: TTMSFNCTreeViewNode read FNode;
    property Text[AColumn: Integer]: String read GetText;
    property Title[AColumn: Integer]: String read GetTitle;
    property TitleExpanded[AColumn: Integer]: Boolean read GetTitleExpanded;
    property Visible: Boolean read FAddedToVisibleList;

    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;

    procedure CopyFrom(Source: TTMSFNCTreeViewVirtualNode); virtual;
    constructor Create(ATreeView: TTMSFNCTreeViewData);
    destructor Destroy; override;
  end;

  TTMSFNCTreeViewCacheItemKind = (ikNode, ikColumnTop, ikColumnBottom, ikGroupTop, ikGroupBottom);

  TTMSFNCTreeViewCacheItem = class
  private
    FRect: TRectF;
    FDrawRect: TRectF;
    FKind: TTMSFNCTreeViewCacheItemKind;
    FGroup: Integer;
    FColumn: Integer;
    FNode: TTMSFNCTreeViewVirtualNode;
    FRow: Integer;
    FCol: Integer;
    FStartColumn: Integer;
    FEndColumn: Integer;
  public
    class function CreateNode(ARect: TRectF; ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewCacheItem;
    class function CreateColumnTop(ARect: TRectF; AColumn: Integer): TTMSFNCTreeViewCacheItem;
    class function CreateGroupTop(ARect: TRectF; AGroup: Integer; AStartColumn, AEndColumn: Integer): TTMSFNCTreeViewCacheItem;
    class function CreateColumnBottom(ARect: TRectF; AColumn: Integer): TTMSFNCTreeViewCacheItem;
    class function CreateGroupBottom(ARect: TRectF; AGroup: Integer; AStartColumn, AEndColumn: Integer): TTMSFNCTreeViewCacheItem;

    property Rect: TRectF read FRect write FRect;
    property DrawRect: TRectF read FDrawRect write FDrawRect;
    property Kind: TTMSFNCTreeViewCacheItemKind read FKind write FKind;
    property Node: TTMSFNCTreeViewVirtualNode read FNode write FNode;
    property Group: Integer read FGroup write FGroup;
    property StartColumn: Integer read FStartColumn write FStartColumn;
    property EndColumn: Integer read FEndColumn write FEndColumn;
    property Column: Integer read FColumn write FColumn;
    property Col: Integer read FCol write FCol;
    property Row: Integer read FRow write FRow;
    destructor Destroy; override;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCTreeViewCacheItemList = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCTreeViewCacheItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTreeViewCacheItem);
  public
    property Items[Index: Integer]: TTMSFNCTreeViewCacheItem read GetItem write SetItem; default;
  end;
  TTMSFNCTreeViewIntegerList = class(TList)
  private
    function GetItem(Index: Integer): Integer;
    procedure SetItem(Index: Integer; const Value: Integer);
  public
    property Items[Index: Integer]: Integer read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCTreeViewCacheItemList = class(TList<TTMSFNCTreeViewCacheItem>);
  TTMSFNCTreeViewIntegerList = class(TList<Integer>);
  {$ENDIF}
  TTMSFNCTreeViewVirtualNodeArray = array of TTMSFNCTreeViewVirtualNode;

  TTMSFNCTreeViewColumnEditorType = (tcetEdit, tcetComboBox, tcetMemo, tcetNone);

  TTMSFNCTreeViewNodesSortMode = (nsmAscending, nsmDescending);
  TTMSFNCTreeViewNodesSortKind = (nskNone, nskAscending, nskDescending);
  TTMSFNCTreeViewColumnSorting = (tcsNone, tcsNormal, tcsRecursive, tcsNormalCaseSensitive, tcsRecursiveCaseSensitive);

  TTMSFNCTreeViewColumnFiltering = class(TPersistent)
  private
    FColumn: TTMSFNCTreeViewColumn;
    FEnabled: Boolean;
    FMultiColumn: boolean;
    FDropDownHeight: integer;
    FDropDownWidth: integer;
    FButtonSize: Single;
    procedure SetEnabled(const Value: Boolean);
    function IsButtonSizeStored: Boolean;
    procedure SetButtonSize(const Value: Single);
  public
    constructor Create(AColumn: TTMSFNCTreeViewColumn);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property Enabled: Boolean read FEnabled write SetEnabled default False;
    property DropDownWidth: integer read FDropDownWidth write FDropDownWidth default 100;
    property DropDownHeight: integer read FDropDownHeight write FDropDownHeight default 120;
    property MultiColumn: boolean read FMultiColumn write FMultiColumn default false;
    property ButtonSize: Single read FButtonSize write SetButtonSize stored IsButtonSizeStored nodefault;
  end;

  TTMSFNCTreeViewFilterOperation = (tfoSHORT, tfoNONE, tfoAND, tfoXOR, tfoOR);

  TTMSFNCTreeViewFilterData = class(TCollectionItem)
  private
    FColumn: SmallInt;
    FCondition: string;
    FCaseSensitive: Boolean;
    FSuffix: string;
    FPrefix: string;
    FOperation: TTMSFNCTreeViewFilterOperation;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
  published
    property Column: smallint read FColumn write FColumn;
    property Condition:string read FCondition write FCondition;
    property CaseSensitive: Boolean read FCaseSensitive write FCaseSensitive default True;
    property Prefix: string read FPrefix write FPrefix;
    property Suffix: string read FSuffix write FSuffix;
    property Operation: TTMSFNCTreeViewFilterOperation read FOperation write FOperation;
  end;

  {$IFNDEF USEOWNEDCOLLECTION}
  TTMSFNCTreeViewFilter = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFDEF USEOWNEDCOLLECTION}
  TTMSFNCTreeViewFilter = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCTreeViewFilterData>)
  {$ENDIF}
  private
    FOwner: TTMSFNCTreeViewData;
    function GetItem(Index: Integer): TTMSFNCTreeViewFilterData;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTreeViewFilterData);
    function GetColFilter(Col: Integer): TTMSFNCTreeViewFilterData;
  public
    constructor Create(AOwner: TTMSFNCTreeViewData);
    function Add: TTMSFNCTreeViewFilterData;
    function Insert(index: Integer): TTMSFNCTreeViewFilterData;
    property Items[Index: Integer]: TTMSFNCTreeViewFilterData read GetItem write SetItem; default;
    property ColumnFilter[Col: Integer]: TTMSFNCTreeViewFilterData read GetColFilter;
    function HasFilter(Col: integer): Boolean;
    procedure RemoveColumnFilter(Col: integer);
  end;

  TTMSFNCTreeViewColumn = class(TCollectionItem)
  private
    FSortKind: TTMSFNCTreeViewNodesSortKind;
    FTag: NativeInt;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FTreeView: TTMSFNCTreeViewData;
    FText: String;
    FName: String;
    FDBKey: String;
    FDataBoolean: Boolean;
    FWordWrapping: Boolean;
    FVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FTrimming: TTMSFNCGraphicsTextTrimming;
    FHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FWidth: Double;
    FVisible: Boolean;
    FUseDefaultAppearance: Boolean;
    FBottomFill: TTMSFNCGraphicsFill;
    FBottomStroke: TTMSFNCGraphicsStroke;
    FTopFill: TTMSFNCGraphicsFill;
    FTopStroke: TTMSFNCGraphicsStroke;
    FTopFont: TTMSFNCGraphicsFont;
    FBottomFont: TTMSFNCGraphicsFont;
    FFont: TTMSFNCGraphicsFont;
    FFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FEditorType: TTMSFNCTreeViewColumnEditorType;
    FDisabledFontColor: TTMSFNCGraphicsColor;
    FSelectedFontColor: TTMSFNCGraphicsColor;
    FEditorItems: TStringList;
    FCustomEditor: Boolean;
    FSorting: TTMSFNCTreeViewColumnSorting;
    FSortIndex: Integer;
    FFiltering: TTMSFNCTreeViewColumnFiltering;
    FDataPointer: Pointer;
    FTitleTrimming: TTMSFNCGraphicsTextTrimming;
    FTitleHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FTitleWordWrapping: Boolean;
    FTitleVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FDisabledTitleFontColor: TTMSFNCGraphicsColor;
    FSelectedTitleFontColor: TTMSFNCGraphicsColor;
    FTitleFont: TTMSFNCGraphicsFont;
    FExpanded: Boolean;
    FExpandable: Boolean;
    FExpandingButtonSize: Single;
    FHTMLTemplate: string;
    FMinimumWidth: Double;
    procedure SetText(const Value: String);
    procedure SetName(const Value: String);
    procedure SetHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTrimming(const Value: TTMSFNCGraphicsTextTrimming);
    procedure SetVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetWordWrapping(const Value: Boolean);
    procedure SetWidth(const Value: Double);
    procedure SetVisible(const Value: Boolean);
    procedure SetUseDefaultAppearance(const Value: Boolean);
    procedure SetBottomFill(const Value: TTMSFNCGraphicsFill);
    procedure SetBottomStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetTopFill(const Value: TTMSFNCGraphicsFill);
    procedure SetTopStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetBottomFont(const Value: TTMSFNCGraphicsFont);
    procedure SetTopFont(const Value: TTMSFNCGraphicsFont);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetEditorType(const Value: TTMSFNCTreeViewColumnEditorType);
    procedure SetDisabledFontColor(const Value: TTMSFNCGraphicsColor);
    procedure SetSelectedFontColor(const Value: TTMSFNCGraphicsColor);
    procedure SetEditorItems(const Value: TStringList);
    procedure SetSorting(const Value: TTMSFNCTreeViewColumnSorting);
    procedure SetSortKind(const Value: TTMSFNCTreeViewNodesSortKind);
    procedure SetSortIndex(const Value: Integer);
    procedure SetFiltering(const Value: TTMSFNCTreeViewColumnFiltering);
    procedure SetTitleHorizontalTextAlign(
      const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTitleTrimming(const Value: TTMSFNCGraphicsTextTrimming);
    procedure SetTitleVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetTitleWordWrapping(const Value: Boolean);
    procedure SetDisabledTitleFontColor(const Value: TTMSFNCGraphicsColor);
    procedure SetSelectedTitleFontColor(const Value: TTMSFNCGraphicsColor);
    procedure SetTitleFont(const Value: TTMSFNCGraphicsFont);
    procedure SetExpanded(const Value: Boolean);
    procedure SetExpandable(const Value: Boolean);
    function IsExpandingButtonSizeStored: Boolean;
    procedure SetExpandingButtonSize(const Value: Single);
    procedure SetHTMLTemplate(const Value: string);
    procedure SetMinimumWidth(const Value: Double);
  protected
    procedure UpdateColumn;
    procedure Changed(Sender: TObject);
    function GetText: String; virtual;
    function GetColumnText: String; virtual;
    function GetDisplayName: string; override;
    property SortIndex: Integer read FSortIndex write SetSortIndex default -1;
  public
    function TreeView: TTMSFNCTreeViewData;
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    procedure UpdateWidth(AWidth: Double); virtual;
    procedure Sort(ARecurse: Boolean = False; ACaseSensitive: Boolean = True; ASortingMode: TTMSFNCTreeViewNodesSortMode = nsmAscending); virtual;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property DBKey: String read FDBKey write FDBKey;
    property SortKind: TTMSFNCTreeViewNodesSortKind read FSortKind write SetSortKind default nskNone;
    property TitleHorizontalTextAlign: TTMSFNCGraphicsTextAlign read FTitleHorizontalTextAlign write SetTitleHorizontalTextAlign default gtaLeading;
    property TitleVerticalTextAlign: TTMSFNCGraphicsTextAlign read FTitleVerticalTextAlign write SetTitleVerticalTextAlign default gtaCenter;
    property TitleWordWrapping: Boolean read FTitleWordWrapping write SetTitleWordWrapping default False;
    property TitleTrimming: TTMSFNCGraphicsTextTrimming read FTitleTrimming write SetTitleTrimming default gttNone;
    property TitleFont: TTMSFNCGraphicsFont read FTitleFont write SetTitleFont;
    property SelectedTitleFontColor: TTMSFNCGraphicsColor read FSelectedTitleFontColor write SetSelectedTitleFontColor default gcWhite;
    property DisabledTitleFontColor: TTMSFNCGraphicsColor read FDisabledTitleFontColor write SetDisabledTitleFontColor default gcSilver;
    property Expandable: Boolean read FExpandable write SetExpandable default False;
    property Expanded: Boolean read FExpanded write SetExpanded default True;
    property ExpandingButtonSize: Single read FExpandingButtonSize write SetExpandingButtonSize stored IsExpandingButtonSizeStored nodefault;
  published
    property EditorType: TTMSFNCTreeViewColumnEditorType read FEditorType write SetEditorType default tcetNone;
    property EditorItems: TStringList read FEditorItems write SetEditorItems;
    property CustomEditor: Boolean read FCustomEditor write FCustomEditor default False;

    property Name: String read FName write SetName;
    property Text: String read FText write SetText;
    property Tag: NativeInt read FTag write FTag default 0;
    property HorizontalTextAlign: TTMSFNCGraphicsTextAlign read FHorizontalTextAlign write SetHorizontalTextAlign default gtaLeading;
    property VerticalTextAlign: TTMSFNCGraphicsTextAlign read FVerticalTextAlign write SetVerticalTextAlign default gtaCenter;
    property WordWrapping: Boolean read FWordWrapping write SetWordWrapping default False;
    property Trimming: TTMSFNCGraphicsTextTrimming read FTrimming write SetTrimming default gttNone;
    property MinimumWidth: Double read FMinimumWidth write SetMinimumWidth;
    property Width: Double read FWidth write SetWidth;
    property Visible: Boolean read FVisible write SetVisible default True;
    property UseDefaultAppearance: Boolean read FUseDefaultAppearance write SetUseDefaultAppearance default True;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property SelectedFontColor: TTMSFNCGraphicsColor read FSelectedFontColor write SetSelectedFontColor default gcWhite;
    property DisabledFontColor: TTMSFNCGraphicsColor read FDisabledFontColor write SetDisabledFontColor default gcSilver;

    property TopFill: TTMSFNCGraphicsFill read FTopFill write SetTopFill;
    property TopStroke: TTMSFNCGraphicsStroke read FTopStroke write SetTopStroke;
    property TopFont: TTMSFNCGraphicsFont read FTopFont write SetTopFont;

    property BottomFill: TTMSFNCGraphicsFill read FBottomFill write SetBottomFill;
    property BottomStroke: TTMSFNCGraphicsStroke read FBottomStroke write SetBottomStroke;
    property BottomFont: TTMSFNCGraphicsFont read FBottomFont write SetBottomFont;
    property Sorting: TTMSFNCTreeViewColumnSorting read FSorting write SetSorting default tcsNone;
    property Filtering: TTMSFNCTreeViewColumnFiltering read FFiltering write SetFiltering;
    property HTMLTemplate: string read FHTMLTemplate write SetHTMLTemplate;
  end;

  {$IFNDEF USEOWNEDCOLLECTION}
  TTMSFNCTreeViewColumns = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFDEF USEOWNEDCOLLECTION}
  TTMSFNCTreeViewColumns = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCTreeViewColumn>)
  {$ENDIF}
  private
    FTreeView: TTMSFNCTreeViewData;
    function GetItem(Index: Integer): TTMSFNCTreeViewColumn;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTreeViewColumn);
  protected
    function GetItemClass: TCollectionItemClass; virtual;
  public
    function TreeView: TTMSFNCTreeViewData;
    constructor Create(ATreeView: TTMSFNCTreeViewData);
    function Add: TTMSFNCTreeViewColumn; virtual;
    function Insert(Index: Integer): TTMSFNCTreeViewColumn;
    property Items[Index: Integer]: TTMSFNCTreeViewColumn read GetItem write SetItem; default;
  end;

  TTMSFNCTreeViewNodes = class;
  TTMSFNCTreeViewNodeCheckType = (tvntNone, tvntCheckBox, tvntRadioButton);

  TTMSFNCTreeViewNodeValue = class(TCollectionItem)
  private
    FTag: NativeInt;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FTreeView: TTMSFNCTreeViewData;
    FNode: TTMSFNCTreeViewNode;
    FText: String;
    FDBKey: String;
    FDataBoolean: Boolean;
    FCheckType: TTMSFNCTreeViewNodeCheckType;
    FChecked: Boolean;
    FCollapsedIcon: TTMSFNCBitmap;
    FCollapsedIconLarge: TTMSFNCBitmap;
    FExpandedIcon: TTMSFNCBitmap;
    FExpandedIconLarge: TTMSFNCBitmap;
    FCollapsedIconName: String;
    FCollapsedIconLargeName: String;
    FExpandedIconName: String;
    FExpandedIconLargeName: String;
    FDataPointer: Pointer;
    FTitle: String;
    FTitleExpanded: Boolean;
    FHTMLTemplateItems: TStringList;
    procedure SetText(const Value: String);
    procedure UpdateNodeValue;
    procedure SetChecked(const Value: Boolean);
    procedure SetCheckType(const Value: TTMSFNCTreeViewNodeCheckType);
    procedure SetCollapsedIconLarge(const Value: TTMSFNCBitmap);
    procedure SetCollapsedIcon(const Value: TTMSFNCBitmap);
    procedure SetExpandedIconLarge(const Value: TTMSFNCBitmap);
    procedure SetExpandedIcon(const Value: TTMSFNCBitmap);
    procedure SetCollapsedIconLargeName(const Value: String);
    procedure SetCollapsedIconName(const Value: String);
    procedure SetExpandedIconLargeName(const Value: String);
    procedure SetExpandedIconName(const Value: String);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    procedure SetTitle(const Value: String);
    procedure SetTitleExpanded(const Value: Boolean);
    procedure SetHTMLTemplateItems(const Value: TStringList);
  protected
    procedure BitmapChanged(Sender: TObject);
    procedure TemplateItemsChanged(Sender: TObject);
    property Title: String read FTitle write SetTitle;
    property TitleExpanded: Boolean read FTitleExpanded write SetTitleExpanded default True;
  public
    function TreeView: TTMSFNCTreeViewData;
    function Node: TTMSFNCTreeViewNode;
    function GetText: string;
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    procedure AssignData(Source: TPersistent); virtual;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property DBKey: String read FDBKey write FDBKey;
  published
    property Tag: NativeInt read FTag write FTag default 0;
    property Text: String read FText write SetText;
    property CollapsedIconName: String read FCollapsedIconName write SetCollapsedIconName;
    property CollapsedIconLargeName: String read FCollapsedIconLargeName write SetCollapsedIconLargeName;
    property ExpandedIconName: String read FExpandedIconName write SetExpandedIconName;
    property ExpandedIconLargeName: String read FExpandedIconLargeName write SetExpandedIconLargeName;

    property CollapsedIcon: TTMSFNCBitmap read FCollapsedIcon write SetCollapsedIcon;
    property CollapsedIconLarge: TTMSFNCBitmap read FCollapsedIconLarge write SetCollapsedIconLarge;
    property ExpandedIcon: TTMSFNCBitmap read FExpandedIcon write SetExpandedIcon;
    property ExpandedIconLarge: TTMSFNCBitmap read FExpandedIconLarge write SetExpandedIconLarge;
    property Checked: Boolean read FChecked write SetChecked default False;
    property CheckType: TTMSFNCTreeViewNodeCheckType read FCheckType write SetCheckType default tvntNone;
    property HTMLTemplateItems: TStringList read FHTMLTemplateItems write SetHTMLTemplateItems;
  end;

  {$IFNDEF USEOWNEDCOLLECTION}
  TTMSFNCTreeViewNodeValues = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFDEF USEOWNEDCOLLECTION}
  TTMSFNCTreeViewNodeValues = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCTreeViewNodeValue>)
  {$ENDIF}
  private
    FTreeView: TTMSFNCTreeViewData;
    FNode: TTMSFNCTreeViewNode;
    function GetItem(Index: Integer): TTMSFNCTreeViewNodeValue;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTreeViewNodeValue);
  protected
    function GetItemClass: TCollectionItemClass; virtual;
  public
    function TreeView: TTMSFNCTreeViewData;
    function Node: TTMSFNCTreeViewNode;
    procedure UpdateChecked(AIndex: Integer; AValue: Boolean); virtual;
    constructor Create(ATreeView: TTMSFNCTreeViewData; ANode: TTMSFNCTreeViewNode);
    function Add: TTMSFNCTreeViewNodeValue;
    function Insert(Index: Integer): TTMSFNCTreeViewNodeValue;
    property Items[Index: Integer]: TTMSFNCTreeViewNodeValue read GetItem write SetItem; default;
  end;

  TTMSFNCTreeViewNodeTextValues = array of string;
  TTMSFNCTreeViewNodeCheckStates = array of Boolean;
  TTMSFNCTreeViewNodeCheckTypes = array of TTMSFNCTreeViewNodeCheckType;
  TTMSFNCTreeViewNodeIcons = array of TTMSFNCBitmap;
  TTMSFNCTreeViewNodeIconNames = array of string;

  TTMSFNCTreeViewNode = class(TCollectionItem)
  private
    FTag: NativeInt;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FTreeView: TTMSFNCTreeViewData;
    FValues: TTMSFNCTreeViewNodeValues;
    FDBKey: String;
    FDataBoolean: Boolean;
    FNodes: TTMSFNCTreeViewNodes;
    FVirtualNode: TTMSFNCTreeViewVirtualNode;
    FExpanded: Boolean;
    FEnabled: Boolean;
    FExtended: Boolean;
    FDataPointer: Pointer;
    procedure SetValues(const Value: TTMSFNCTreeViewNodeValues);
    procedure SetNodes(const Value: TTMSFNCTreeViewNodes);
    procedure SetExpanded(const Value: Boolean);
    procedure SetEnabled(const Value: Boolean);
    procedure SetExtended(const Value: Boolean);
    function GetChecked(AColumn: integer): Boolean;
    procedure SetChecked(AColumn: integer; const Value: Boolean);
    function GetCheckType(AColumn: Integer): TTMSFNCTreeViewNodeCheckType;
    function GetCollapsedIcon(AColumn: Integer;
      ALarge: Boolean): TTMSFNCBitmap;
    function GetCollapsedIconName(AColumn: Integer;
      ALarge: Boolean): String;
    function GetExpandedIcon(AColumn: Integer;
      ALarge: Boolean): TTMSFNCBitmap;
    function GetExpandedIconName(AColumn: Integer;
      ALarge: Boolean): String;
    function GetText(AColumn: Integer): String;
    procedure SetCheckType(AColumn: Integer;
      const Value: TTMSFNCTreeViewNodeCheckType);
    procedure SetCollapsedIcon(AColumn: Integer; ALarge: Boolean;
      const Value: TTMSFNCBitmap);
    procedure SetCollapsedIconName(AColumn: Integer; ALarge: Boolean;
      const Value: String);
    procedure SetExpandedIcon(AColumn: Integer; ALarge: Boolean;
      const Value: TTMSFNCBitmap);
    procedure SetExpandedIconName(AColumn: Integer; ALarge: Boolean;
      const Value: String);
    procedure SetText(AColumn: Integer; const Value: String);
    function GetStrippedHTMLText(AColumn: Integer): String;
  protected
    procedure UpdateNode;
    function GetValueForColumn(AColumn: Integer): TTMSFNCTreeViewNodeValue; virtual;
    function CreateNodes: TTMSFNCTreeViewNodes; virtual;
    function CreateNodeValues: TTMSFNCTreeViewNodeValues; virtual;
    procedure SetIndex(Value: Integer); override;
    procedure ValuesChanged(Sender: TObject);
  public
    function TreeView: TTMSFNCTreeViewData;
    function GetParent: TTMSFNCTreeViewNode; virtual;
    function GetChildCount: Integer; virtual;
    function GetPrevious: TTMSFNCTreeViewNode; virtual;
    function GetNext: TTMSFNCTreeViewNode; virtual;
    function GetPreviousChild(ANode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode; virtual;
    function GetNextChild(ANode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode; virtual;
    function GetNextSibling: TTMSFNCTreeViewNode; virtual;
    function GetPreviousSibling: TTMSFNCTreeViewNode; virtual;
    function GetFirstChild: TTMSFNCTreeViewNode; virtual;
    function GetLastChild: TTMSFNCTreeViewNode; virtual;
    function SaveToString(ATextOnly: Boolean = True): String; virtual;
    procedure LoadFromString(AString: String); virtual;
    procedure RemoveChildren; virtual;
    procedure Expand(ARecurse: Boolean = False); virtual;
    procedure Collapse(ARecurse: Boolean = False); virtual;
    procedure MoveTo(ADestination: TTMSFNCTreeViewNode; AIndex: Integer = -1); virtual;
    procedure CopyTo(ADestination: TTMSFNCTreeViewNode; AIndex: Integer = -1); virtual;
    procedure SetTextValues(ATextValues: TTMSFNCTreeViewNodeTextValues); virtual;
    procedure SetCheckStates(ACheckStates: TTMSFNCTreeViewNodeCheckStates); virtual;
    procedure SetCheckTypes(ACheckTypes: TTMSFNCTreeViewNodeCheckTypes); virtual;
    procedure SetCollapsedIcons(AIcons: TTMSFNCTreeViewNodeIcons; ALarge: Boolean = False); virtual;
    procedure SetExpandedIcons(AIcons: TTMSFNCTreeViewNodeIcons; ALarge: Boolean = False); virtual;
    procedure SetCollapsedIconNames(AIconNames: TTMSFNCTreeViewNodeIconNames; ALarge: Boolean = False); virtual;
    procedure SetExpandedIconNames(AIconNames: TTMSFNCTreeViewNodeIconNames; ALarge: Boolean = False); virtual;
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    procedure AssignData(Source: TPersistent); virtual;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property DBKey: String read FDBKey write FDBKey;
    property Checked[AColumn: Integer]: Boolean read GetChecked write SetChecked;
    property Text[AColumn: Integer]: String read GetText write SetText;
    property StrippedHTMLText[AColumn: Integer]: String read GetStrippedHTMLText;
    property CheckTypes[AColumn: Integer]: TTMSFNCTreeViewNodeCheckType read GetCheckType write SetCheckType;
    property ExpandedIcons[AColumn: Integer; ALarge: Boolean]: TTMSFNCBitmap read GetExpandedIcon write SetExpandedIcon;
    property CollapsedIcons[AColumn: Integer; ALarge: Boolean]: TTMSFNCBitmap read GetCollapsedIcon write SetCollapsedIcon;
    property ExpandedIconNames[AColumn: Integer; ALarge: Boolean]: String read GetExpandedIconName write SetExpandedIconName;
    property CollapsedIconNames[AColumn: Integer; ALarge: Boolean]: String read GetCollapsedIconName write SetCollapsedIconName;
    property VirtualNode: TTMSFNCTreeViewVirtualNode read FVirtualNode;
  published
    property Values: TTMSFNCTreeViewNodeValues read FValues write SetValues;
    property Expanded: Boolean read FExpanded write SetExpanded default False;
    property Enabled: Boolean read FEnabled write SetEnabled default True;
    property Extended: Boolean read FExtended write SetExtended default False;
    property Tag: NativeInt read FTag write FTag;
    property Nodes: TTMSFNCTreeViewNodes read FNodes write SetNodes;
  end;

  {$IFNDEF USEOWNEDCOLLECTION}
  TTMSFNCTreeViewNodes = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFDEF USEOWNEDCOLLECTION}
  TTMSFNCTreeViewNodes = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCTreeViewNode>)
  {$ENDIF}
  private
    FTreeView: TTMSFNCTreeViewData;
    FNode: TTMSFNCTreeViewNode;
    function GetItem(Index: Integer): TTMSFNCTreeViewNode;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTreeViewNode);
  protected
    function GetItemClass: TCollectionItemClass; virtual;
    function Compare(ANode1, ANode2: TTMSFNCTreeViewNode; AColumn: Integer = 0; ACaseSensitive: Boolean = True; ASortingMode: TTMSFNCTreeViewNodesSortMode = nsmAscending): Integer; virtual;
    procedure QuickSort(L, R: Integer; AColumn: Integer = 0; ACaseSensitive: Boolean = True; ASortingMode: TTMSFNCTreeViewNodesSortMode = nsmAscending); virtual;
  public
    function TreeView: TTMSFNCTreeViewData;
    function Node: TTMSFNCTreeViewNode;
    constructor Create(ATreeView: TTMSFNCTreeViewData; ANode: TTMSFNCTreeViewNode);
    destructor Destroy; override;
    function Add: TTMSFNCTreeViewNode;
    function Insert(Index: Integer): TTMSFNCTreeViewNode;
    property Items[Index: Integer]: TTMSFNCTreeViewNode read GetItem write SetItem; default;
    procedure Sort(AColumn: Integer = 0; ARecurse: Boolean = False; ACaseSensitive: Boolean = True; ASortingMode: TTMSFNCTreeViewNodesSortMode = nsmAscending; AClearNodeList: Boolean = True);
  end;

  TTMSFNCTreeViewGroup = class(TCollectionItem)
  private
    FTag: NativeInt;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FTreeView: TTMSFNCTreeViewData;
    FText: String;
    FStartColumn: Integer;
    FEndColumn: Integer;
    FName: String;
    FDBKey: String;
    FDataBoolean: Boolean;
    FBottomFill: TTMSFNCGraphicsFill;
    FBottomStroke: TTMSFNCGraphicsStroke;
    FTopFill: TTMSFNCGraphicsFill;
    FTopStroke: TTMSFNCGraphicsStroke;
    FTopFont: TTMSFNCGraphicsFont;
    FBottomFont: TTMSFNCGraphicsFont;
    FUseDefaultAppearance: Boolean;
    FDataPointer: Pointer;
    procedure SetText(const Value: String);
    procedure SetStartColumn(const Value: Integer);
    procedure SetEndColumn(const Value: Integer);
    procedure SetName(const Value: String);
    procedure SetBottomFill(const Value: TTMSFNCGraphicsFill);
    procedure SetBottomStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetTopFill(const Value: TTMSFNCGraphicsFill);
    procedure SetTopStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetBottomFont(const Value: TTMSFNCGraphicsFont);
    procedure SetTopFont(const Value: TTMSFNCGraphicsFont);
    procedure SetUseDefaultAppearance(const Value: Boolean);
  protected
    procedure UpdateGroup;
    procedure Changed(Sender: TObject);
  public
    function TreeView: TTMSFNCTreeViewData;
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
    property DBKey: String read FDBKey write FDBKey;
    function GetText: String; virtual;
  published
    property Name: String read FName write SetName;
    property Text: String read FText write SetText;
    property StartColumn: Integer read FStartColumn write SetStartColumn default 0;
    property EndColumn: Integer read FEndColumn write SetEndColumn default 0;
    property Tag: NativeInt read FTag write FTag default 0;
    property UseDefaultAppearance: Boolean read FUseDefaultAppearance write SetUseDefaultAppearance default True;

    property TopFill: TTMSFNCGraphicsFill read FTopFill write SetTopFill;
    property TopStroke: TTMSFNCGraphicsStroke read FTopStroke write SetTopStroke;
    property TopFont: TTMSFNCGraphicsFont read FTopFont write SetTopFont;

    property BottomFill: TTMSFNCGraphicsFill read FBottomFill write SetBottomFill;
    property BottomStroke: TTMSFNCGraphicsStroke read FBottomStroke write SetBottomStroke;
    property BottomFont: TTMSFNCGraphicsFont read FBottomFont write SetBottomFont;
  end;

  {$IFNDEF USEOWNEDCOLLECTION}
  TTMSFNCTreeViewGroups = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFDEF USEOWNEDCOLLECTION}
  TTMSFNCTreeViewGroups = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCTreeViewGroup>)
  {$ENDIF}
  private
    FTreeView: TTMSFNCTreeViewData;
    function GetItem(Index: Integer): TTMSFNCTreeViewGroup;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTreeViewGroup);
  protected
    function GetItemClass: TCollectionItemClass; virtual;
  public
    function TreeView: TTMSFNCTreeViewData;
    constructor Create(ATreeView: TTMSFNCTreeViewData);
    function Add: TTMSFNCTreeViewGroup;
    function Insert(Index: Integer): TTMSFNCTreeViewGroup;
    property Items[Index: Integer]: TTMSFNCTreeViewGroup read GetItem write SetItem; default;
  end;

  TTMSFNCTreeViewNodeTextMode = (tntmDrawing, tntmEditing);
  {$IFDEF WEBLIB}
  TTMSFNCTreeViewNodeStructure = class(TObjectList)
  private
    function GetItem(Index: Integer): TTMSFNCTreeViewVirtualNode;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTreeViewVirtualNode);
  public
    property Items[Index: Integer]: TTMSFNCTreeViewVirtualNode read GetItem write SetItem; default;
  end;
  TTMSFNCTreeViewVisibleNodes = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCTreeViewVirtualNode;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTreeViewVirtualNode);
  public
    property Items[Index: Integer]: TTMSFNCTreeViewVirtualNode read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCTreeViewNodeStructure = class(TObjectList<TTMSFNCTreeViewVirtualNode>);
  TTMSFNCTreeViewVisibleNodes = class(TList<TTMSFNCTreeViewVirtualNode>);
  {$ENDIF}

  TTMSFNCTreeViewDisplayGroup = record
    StartColumn: Integer;
    EndColumn: Integer;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCTreeViewDisplayGroup) b : boolean;
    {$ENDIF}
  end;

  {$IFDEF WEBLIB}
  TTMSFNCTreeViewDisplayGroups = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCTreeViewDisplayGroup;
    procedure SetItem(Index: Integer; const Value: TTMSFNCTreeViewDisplayGroup);
  public
    property Items[Index: Integer]: TTMSFNCTreeViewDisplayGroup read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCTreeViewDisplayGroups = class(TList<TTMSFNCTreeViewDisplayGroup>);
  {$ENDIF}

  {$IFDEF FNCLIB}
  TTMSFNCTreeViewData = class(TTMSFNCTreeViewBase, ITMSFNCBitmapContainer)
  {$ENDIF}
  {$IFNDEF FNCLIB}
  TTMSFNCTreeViewData = class(TTMSFNCTreeViewBase)
  {$ENDIF}
  private
    FFilterApplied: Boolean;
    FBlockUpdate: Boolean;
    FNodeStructure: TTMSFNCTreeViewNodeStructure;
    FVisibleNodes: TTMSFNCTreeViewVisibleNodes;
    FColumns: TTMSFNCTreeViewColumns;
    FGroups: TTMSFNCTreeViewGroups;
    FNodes: TTMSFNCTreeViewNodes;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FDisplayGroups: TTMSFNCTreeViewDisplayGroups;
    FFilter: TTMSFNCTreeViewFilter;
    FSortMode: TTMSFNCTreeViewNodesSortMode;
    procedure SetColumns(const Value: TTMSFNCTreeViewColumns);
    procedure SetGroups(const Value: TTMSFNCTreeViewGroups);
    procedure SetNodes(const Value: TTMSFNCTreeViewNodes);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
  protected
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    function CreateColumns: TTMSFNCTreeViewColumns; virtual;
    function CreateGroups: TTMSFNCTreeViewGroups; virtual;
    function CreateNodes: TTMSFNCTreeViewNodes; virtual;
    function GetInsertPosition(ANode: TTMSFNCTreeViewVirtualNode; ARoot: Boolean; AInsert: Boolean): Integer; virtual;
    function GetNodeFromNodeStructure(AIndex: Integer): TTMSFNCTreeViewVirtualNode; virtual;
    function MatchFilter(ANode: TTMSFNCTreeViewVirtualNode): Boolean; virtual;
    procedure BuildNodeList; virtual;
    procedure UpdateColumns; override;
    procedure BuildInternalNodeList(ANode: TTMSFNCTreeViewVirtualNode; AID: String; AParentNode: Integer; ANodeIndex: Integer; ALevel: Integer; AParentExpanded: Boolean; var ATotalChildren: Integer);
    procedure GetNodeForNodeData(ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure DoGetNumberOfNodes(ANode: TTMSFNCTreeViewVirtualNode; var ANumberOfNodes: Integer); virtual;
    procedure DoGetColumnTrimming({%H-}AColumn: Integer; {%H-}AKind: TTMSFNCTreeViewCacheItemKind; var {%H-}ATrimming: TTMSFNCGraphicsTextTrimming); virtual;
    procedure DoGetColumnHorizontalTextAlign({%H-}AColumn: Integer; {%H-}AKind: TTMSFNCTreeViewCacheItemKind; var {%H-}AHorizontalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoGetColumnVerticalTextAlign({%H-}AColumn: Integer; {%H-}AKind: TTMSFNCTreeViewCacheItemKind; var {%H-}AVerticalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoGetColumnWordWrapping({%H-}AColumn: Integer; {%H-}AKind: TTMSFNCTreeViewCacheItemKind; var {%H-}AWordWrapping: Boolean); virtual;
    procedure DoGetNodeSides({%H-}ANode: TTMSFNCTreeViewVirtualNode; var {%H-}ASides: TTMSFNCGraphicsSides); virtual;
    procedure DoGetNodeText({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; {%H-}AMode: TTMSFNCTreeViewNodeTextMode; var {%H-}AText: String); virtual;
    procedure DoGetNodeData(ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure DoGetNodeTitle({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; {%H-}AMode: TTMSFNCTreeViewNodeTextMode; var {%H-}ATitle: String); virtual;
    procedure DoGetNodeTitleExpanded({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}AExpanded: Boolean); virtual;
    procedure DoGetNodeTrimming({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}ATrimming: TTMSFNCGraphicsTextTrimming); virtual;
    procedure DoGetNodeHorizontalTextAlign({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}AHorizontalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoGetNodeVerticalTextAlign({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}AVerticalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoGetNodeWordWrapping({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}AWordWrapping: Boolean); virtual;
    procedure DoGetNodeTitleTrimming({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}ATrimming: TTMSFNCGraphicsTextTrimming); virtual;
    procedure DoGetNodeTitleHorizontalTextAlign({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}AHorizontalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoGetNodeTitleVerticalTextAlign({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}AVerticalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoGetNodeTitleWordWrapping({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}AWordWrapping: Boolean); virtual;
    procedure DoGetNodeExtraSize({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}AExtraSize: Single); virtual;
    procedure DoGetNodeTitleExtraSize({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}ATitleExtraSize: Single); virtual;
    procedure DoGetNodeCheckType({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}ACheckType: TTMSFNCTreeViewNodeCheckType); virtual;
    procedure DoGetNodeIcon({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; {%H-}ALarge: Boolean; var {%H-}AIcon: TTMSFNCBitmap); virtual;
    procedure DoGetNodeIconSize({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; {%H-}ALarge: Boolean; {%H-}AIcon: TTMSFNCBitmap; var {%H-}AIconWidth: Double; var {%H-}AIconHeight: Double); virtual;
    procedure DoGetNodeSelectedColor({%H-}ANode: TTMSFNCTreeViewVirtualNode; var {%H-}AColor: TTMSFNCGraphicsColor); virtual;
    procedure DoGetNodeDisabledColor({%H-}ANode: TTMSFNCTreeViewVirtualNode; var {%H-}AColor: TTMSFNCGraphicsColor); virtual;
    procedure DoGetNodeColor({%H-}ANode: TTMSFNCTreeViewVirtualNode; var {%H-}AColor: TTMSFNCGraphicsColor); virtual;
    procedure DoGetNodeSelectedTextColor({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}ATextColor: TTMSFNCGraphicsColor); virtual;
    procedure DoGetNodeDisabledTextColor({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}ATextColor: TTMSFNCGraphicsColor); virtual;
    procedure DoGetNodeSelectedTitleColor({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}ATitleColor: TTMSFNCGraphicsColor); virtual;
    procedure DoGetNodeDisabledTitleColor({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}ATitleColor: TTMSFNCGraphicsColor); virtual;
    procedure DoGetNodeTextColor({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}ATextColor: TTMSFNCGraphicsColor); virtual;
    procedure DoGetNodeTitleColor({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}ATitleColor: TTMSFNCGraphicsColor); virtual;
    procedure DoIsNodeExpanded({%H-}ANode: TTMSFNCTreeViewVirtualNode; var {%H-}AExpanded: Boolean); virtual;
    procedure DoIsNodeExtended({%H-}ANode: TTMSFNCTreeViewVirtualNode; var {%H-}AExtended: Boolean); virtual;
    procedure DoIsNodeChecked({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; var {%H-}AChecked: Boolean); virtual;
    procedure DoIsNodeVisible({%H-}ANode: TTMSFNCTreeViewVirtualNode; var {%H-}AVisible: Boolean); virtual;
    procedure DoIsNodeEnabled({%H-}ANode: TTMSFNCTreeViewVirtualNode; var {%H-}AEnabled: Boolean); virtual;
    procedure DoNodeCompare({%H-}ANode1, {%H-}ANode2: TTMSFNCTreeViewNode; {%H-}AColumn: Integer; var {%H-}ACompareResult: Integer); virtual;
    procedure DoGetHTMLTemplateValue(ANodeValue: TTMSFNCTreeViewNodeValue; AName: string; var AValue: string); virtual;
    procedure DoGetHTMLTemplate(ANodeValue: TTMSFNCTreeViewNodeValue; AColumnIndex: Integer; var AHTMLTemplate: string); virtual;
    procedure ToggleCheckNodeInternal(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ARecurse: Boolean = False); virtual;
    procedure CheckNodeInternal(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ARecurse: Boolean = False); virtual;
    procedure UpdateCheckState({%H-}ANode: TTMSFNCTreeViewVirtualNode; {%H-}AColumn: Integer; {%H-}AChecked: Boolean); virtual;
    procedure UnCheckNodeInternal(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ARecurse: Boolean = False); virtual;
    procedure ExpandNodeInternal(ANode: TTMSFNCTreeViewVirtualNode; ARecurse: Boolean = False); virtual;
    procedure CollapseNodeInternal(ANode: TTMSFNCTreeViewVirtualNode; ARecurse: Boolean = False); virtual;
    function RemoveNodeInternal(ANode: TTMSFNCTreeViewVirtualNode; AUpdateNodes: Boolean = True): TTMSFNCTreeViewVirtualNodeRemoveData; virtual;
    procedure ClearFocusedNode(ANode: TTMSFNCTreeViewVirtualNode); virtual; abstract;
    procedure UpdateNodesInternal(ANodeRemoveData: TTMSFNCTreeViewVirtualNodeRemoveData); virtual;
    procedure InsertNodeInternal(AParentNode: TTMSFNCTreeViewVirtualNode; AIndex, AInsertIndex: Integer; ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure UpdateVisibleNodes(ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure ToggleNodeInternal(ANode: TTMSFNCTreeViewVirtualNode; ARecurse: Boolean = False); virtual;
    procedure InsertItemInternal(AItem: TTMSFNCTreeViewNode); virtual;
    function IsVariableNodeHeight: Boolean; virtual; abstract;
    function RemoveItemInternal(AItem: TTMSFNCTreeViewNode; AUpdate: Boolean = True): TTMSFNCTreeViewVirtualNodeRemoveData; virtual;
    function GetNodesSpacing(ANode: TTMSFNCTreeViewVirtualNode): Single; virtual;
    function GetVisibleNodeForIndex(AIndex: Integer): TTMSFNCTreeViewVirtualNode; virtual;
    procedure ToggleNode(ANode: TTMSFNCTreeViewNode); virtual;
    procedure UpdateNodeCalculated(ANode: TTMSFNCTreeViewVirtualNode; ACalculated: Boolean); virtual;
    procedure UpdateNodeHeight(ANode: TTMSFNCTreeViewVirtualNode; AHeight: Double); virtual;
    procedure UpdateNodeTitleHeight(ANode: TTMSFNCTreeViewVirtualNode; AHeight: Double); virtual;
    procedure UpdateNodeCheckStates(ANode: TTMSFNCTreeViewVirtualNode; ACheckStates: TTMSFNCArrayBoolean); virtual;
    procedure UpdateNodeBitmapRects(ANode: TTMSFNCTreeViewVirtualNode; ABitmapRects: TTMSFNCArrayTRectF); virtual;
    procedure UpdateNodeCheckRects(ANode: TTMSFNCTreeViewVirtualNode; ACheckRects: TTMSFNCArrayTRectF); virtual;
    procedure UpdateNodeExpandRects(ANode: TTMSFNCTreeViewVirtualNode; AExpandRects: TTMSFNCArrayTRectF); virtual;
    procedure UpdateNodeTextRects(ANode: TTMSFNCTreeViewVirtualNode; ATextRects: TTMSFNCArrayTRectF); virtual;
    procedure UpdateNodeTitleRects(ANode: TTMSFNCTreeViewVirtualNode; ATitleRects: TTMSFNCArrayTRectF); virtual;
    procedure UpdateNodeExtraRects(ANode: TTMSFNCTreeViewVirtualNode; AExtraRects: TTMSFNCArrayTRectF); virtual;
    procedure UpdateNodeTitleExtraRects(ANode: TTMSFNCTreeViewVirtualNode; ATitleExtraRects: TTMSFNCArrayTRectF); virtual;
    procedure UpdateNodeCacheReference(ANode: TTMSFNCTreeViewVirtualNode; ACache: TTMSFNCTreeViewCacheItem); virtual;
    procedure UpdateNodeExpanded(ANode: TTMSFNCTreeViewVirtualNode; AExpanded: Boolean); virtual;
    procedure UpdateNodeExtended(ANode: TTMSFNCTreeViewVirtualNode; AExtended: Boolean); virtual;
    procedure UpdateNodeTotalChildren(ANode: TTMSFNCTreeViewVirtualNode; ATotalChildren: Integer); virtual;
    procedure UpdateNodeChildren(ANode: TTMSFNCTreeViewVirtualNode; AChildren: Integer); virtual;
    procedure UpdateNodeIndex(ANode: TTMSFNCTreeViewVirtualNode; AIndex: Integer); virtual;
    procedure UpdateNodeRow(ANode: TTMSFNCTreeViewVirtualNode; ARow: Integer); virtual;
    procedure UpdateNodeLevel(ANode: TTMSFNCTreeViewVirtualNode; ALevel: Integer); virtual;
    procedure UpdateNodeParentNode(ANode: TTMSFNCTreeViewVirtualNode; AParentNode: Integer); virtual;
    procedure UpdateNode(ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure CustomizeScrollPosition({%H-}ANode: TTMSFNCTreeViewVirtualNode; var {%H-}APosition, {%H-}ATopPosition: Double); virtual;
    function HTMLReplace(AValue: string; ANodeValue: TTMSFNCTreeViewNodeValue): string;
    function GetHTMLTemplate(AColumnIndex: Integer): string; virtual;
    property Columns: TTMSFNCTreeViewColumns read FColumns write SetColumns;
    property Groups: TTMSFNCTreeViewGroups read FGroups write SetGroups;
    property Nodes: TTMSFNCTreeViewNodes read FNodes write SetNodes;
    property DisplayGroups: TTMSFNCTreeViewDisplayGroups read FDisplayGroups;
    property BlockUpdate: Boolean read FBlockUpdate write FBlockUpdate;
    property NodeStructure: TTMSFNCTreeViewNodeStructure read FNodeStructure;
    property VisibleNodes: TTMSFNCTreeViewVisibleNodes read FVisibleNodes;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    function IsColumnVisible(ACol: Integer): Boolean; override;
    function FindColumnByName(AName: String): TTMSFNCTreeViewColumn; virtual;
    function FindGroupByName(AName: String): TTMSFNCTreeViewGroup; virtual;
    function FindColumnIndexByName(AName: String): Integer; virtual;
    function FindGroupIndexByName(AName: String): Integer; virtual;

    procedure BeforeExport; override;
    procedure AfterExport; override;
    procedure ClearColumns; virtual;
    procedure ClearNodes; virtual;
    procedure ClearNodeList; virtual;
    procedure RemoveNodeFromSelection(ANode: TTMSFNCTreeViewVirtualNode); virtual; abstract;
    procedure AddNodeToSelection(ANode: TTMSFNCTreeViewVirtualNode); virtual; abstract;
    procedure RemoveVirtualNode(ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure RemoveNode(ANode: TTMSFNCTreeViewNode); virtual;
    procedure ExpandNode(ANode: TTMSFNCTreeViewNode; ARecurse: Boolean = False); virtual;
    procedure MoveNode(ANode: TTMSFNCTreeViewNode; ADestinationNode: TTMSFNCTreeViewNode; AIndex: Integer = -1); virtual;
    procedure CopyNode(ANode: TTMSFNCTreeViewNode; ADestinationNode: TTMSFNCTreeViewNode; AIndex: Integer = -1); virtual;
    procedure ExpandAll; virtual;
    procedure CollapseAll; virtual;
    procedure ExpandAllVirtual; virtual;
    procedure CollapseAllVirtual; virtual;
    procedure ApplyFilter; virtual;
    procedure RemoveFilter; virtual;
    procedure RemoveFilters; virtual;
    procedure CollapseNode(ANode: TTMSFNCTreeViewNode; ARecurse: Boolean = False); virtual;
    procedure ToggleVirtualNode(ANode: TTMSFNCTreeViewVirtualNode; ARecurse: Boolean = False); virtual;
    procedure ExpandVirtualNode(ANode: TTMSFNCTreeViewVirtualNode; ARecurse: Boolean = False); virtual;
    procedure CollapseVirtualNode(ANode: TTMSFNCTreeViewVirtualNode; ARecurse: Boolean = False); virtual;
    procedure CheckNode(ANode: TTMSFNCTreeViewNode; AColumn: Integer; ARecurse: Boolean = False); virtual;
    procedure UnCheckNode(ANode: TTMSFNCTreeViewNode; AColumn: Integer; ARecurse: Boolean = False); virtual;
    procedure ToggleCheckNode(ANode: TTMSFNCTreeViewNode; AColumn: Integer; ARecurse: Boolean = False); virtual;

    procedure CheckVirtualNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ARecurse: Boolean = False); virtual;
    procedure UnCheckVirtualNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ARecurse: Boolean = False); virtual;
    procedure ToggleCheckVirtualNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ARecurse: Boolean = False); virtual;

    procedure SetNodeTextValues(ANode: TTMSFNCTreeViewNode; ATextValues: TTMSFNCTreeViewNodeTextValues); virtual;
    procedure SetNodeCheckStates(ANode: TTMSFNCTreeViewNode; ACheckStates: TTMSFNCTreeViewNodeCheckStates); virtual;
    procedure SetNodeCheckTypes(ANode: TTMSFNCTreeViewNode; ACheckTypes: TTMSFNCTreeViewNodeCheckTypes); virtual;
    procedure SetNodeCollapsedIcons(ANode: TTMSFNCTreeViewNode; AIcons: TTMSFNCTreeViewNodeIcons; ALarge: Boolean = False); virtual;
    procedure SetNodeExpandedIcons(ANode: TTMSFNCTreeViewNode; AIcons: TTMSFNCTreeViewNodeIcons; ALarge: Boolean = False); virtual;
    procedure SetNodeCollapsedIconNames(ANode: TTMSFNCTreeViewNode; AIconNames: TTMSFNCTreeViewNodeIconNames; ALarge: Boolean = False); virtual;
    procedure SetNodeExpandedIconNames(ANode: TTMSFNCTreeViewNode; AIconNames: TTMSFNCTreeViewNodeIconNames; ALarge: Boolean = False); virtual;

    procedure RemoveVirtualNodeChildren(ANode: TTMSFNCTreeViewVirtualNode); virtual;
    procedure RemoveNodeChildren(ANode: TTMSFNCTreeViewNode); virtual;
    procedure ScrollToNode(ANode: TTMSFNCTreeViewNode; AScrollIfNotVisible: Boolean = False; AScrollPosition: TTMSFNCTreeViewNodeScrollPosition = tvnspTop; AForceScroll: Boolean = False); virtual;
    procedure ScrollToVirtualNode(ANode: TTMSFNCTreeViewVirtualNode; AScrollIfNotVisible: Boolean = False; AScrollPosition: TTMSFNCTreeViewNodeScrollPosition = tvnspTop; AForceScroll: Boolean = False); virtual;
    procedure ScrollToVirtualNodeInternal(ANode: TTMSFNCTreeViewVirtualNode; AScrollIfNotVisible: Boolean = False; AScrollPosition: TTMSFNCTreeViewNodeScrollPosition = tvnspTop; AForceScroll: Boolean = False); virtual;
    procedure ScrollToVirtualNodeRow(ARow: Integer; AScrollIfNotVisible: Boolean = False; AScrollPosition: TTMSFNCTreeViewNodeScrollPosition = tvnspTop; AForceScroll: Boolean = False); virtual;
    procedure ScrollToTop;
    procedure ScrollToBottom;

    function GetNodeForRow(ARow: Integer): TTMSFNCTreeViewVirtualNode; virtual;
    function AddNode(AParentNode: TTMSFNCTreeViewNode = nil): TTMSFNCTreeViewNode; virtual;
    function AddVirtualNode(AParentNode: TTMSFNCTreeViewVirtualNode = nil): TTMSFNCTreeViewVirtualNode; virtual;
    function GetParentVirtualNode(ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode; virtual;
    function GetParentNode(ANode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode; virtual;
    function GetVirtualNodeChildCount(ANode: TTMSFNCTreeViewVirtualNode): Integer; virtual;
    function GetTotalVirtualNodeCount: Integer; virtual;
    function GetTotalNodeCount: Integer; virtual;
    function GetNodeText(ANode: TTMSFNCTreeViewNode; AColumn: Integer): string; virtual;
    function GetVirtualNodeText(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer): string; virtual;
    function GetNodeTitle(ANode: TTMSFNCTreeViewNode; AColumn: Integer): string; virtual;
    function GetVirtualNodeTitle(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer): string; virtual;
    function GetVirtualNodeTitleExpanded(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer): Boolean; virtual;
    function FindVirtualNodeByRow(ARow: Integer): TTMSFNCTreeViewVirtualNode; virtual;
    function FindNodeByRow(ARow: Integer): TTMSFNCTreeViewNode; virtual;
    function FindVirtualNodeByDBKey(ADBKey: String): TTMSFNCTreeViewVirtualNode; virtual;
    function FindNodeByDBKey(ADBKey: String): TTMSFNCTreeViewNode; virtual;
    function FindVirtualNodeByTextAndColumn(AText: string; AColumn: Integer; ARecurse: Boolean = False; ACaseSensitive: Boolean = True): TTMSFNCTreeViewVirtualNode; virtual;
    function FindNodeByTextAndColumn(AText: string; AColumn: Integer; ARecurse: Boolean = False; ACaseSensitive: Boolean = True): TTMSFNCTreeViewNode; virtual;
    function GetNodeChildCount(ANode: TTMSFNCTreeViewNode): Integer; virtual;
    function GetPreviousNode(ANode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode; virtual;
    function GetNextNode(ANode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode; virtual;
    function GetPreviousChildNode(ANode: TTMSFNCTreeViewNode; AStartNode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode; virtual;
    function GetNextChildNode(ANode: TTMSFNCTreeViewNode; AStartNode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode; virtual;
    function GetNextSiblingNode(ANode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode; virtual;
    function GetPreviousSiblingNode(ANode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode; virtual;
    function GetFirstChildNode(ANode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode; virtual;
    function GetLastChildNode(ANode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode; virtual;

    function GetPreviousVirtualNode(ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode; virtual;
    function GetNextVirtualNode(ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode; virtual;
    function GetPreviousChildVirtualNode({%H-}ANode: TTMSFNCTreeViewVirtualNode; AStartNode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode; virtual;
    function GetNextChildVirtualNode({%H-}ANode: TTMSFNCTreeViewVirtualNode; AStartNode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode; virtual;
    function GetNextSiblingVirtualNode({%H-}ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode; virtual;
    function GetPreviousSiblingVirtualNode({%H-}ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode; virtual;
    function GetFirstChildVirtualNode({%H-}ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode; virtual;
    function GetLastChildVirtualNode({%H-}ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode; virtual;

    function GetFirstRootNode: TTMSFNCTreeViewNode; virtual;
    function GetLastRootNode: TTMSFNCTreeViewNode; virtual;
    function GetLastNode: TTMSFNCTreeViewNode; virtual;
    function GetFirstRootVirtualNode: TTMSFNCTreeViewVirtualNode; virtual;
    function GetLastRootVirtualNode: TTMSFNCTreeViewVirtualNode; virtual;
    function GetLastVirtualNode: TTMSFNCTreeViewVirtualNode; virtual;
    function GetRootNodeByIndex(AIndex: Integer): TTMSFNCTreeViewNode; virtual;
    function GetRootVirtualNodeByIndex(AIndex: Integer): TTMSFNCTreeViewVirtualNode; virtual;

    function InsertNode(AIndex: Integer; AParentNode: TTMSFNCTreeViewNode = nil): TTMSFNCTreeViewNode; virtual;
    function InsertVirtualNode(AIndex: Integer; AParentNode: TTMSFNCTreeViewVirtualNode = nil): TTMSFNCTreeViewVirtualNode; virtual;

    property Filter: TTMSFNCTreeViewFilter read FFilter;
    property SortMode: TTMSFNCTreeViewNodesSortMode read FSortMode;
  end;

function TranslateTextEx(AText: String): String;

implementation

uses
  SysUtils, Math, WEBLib.TMSFNCUtils, WEBLib.Graphics
  {$IFDEF FMXLIB}
  , FMX.Types
  {$ENDIF}
  {$IFDEF VCLLIB}
  ,AnsiStrings
  {$ENDIF}
  ;

function TranslateTextEx(AText: String): String;
begin
  {$IFDEF FMXLIB}
  Result := TranslateText(AText);
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  Result := AText;
  {$ENDIF}
end;

{$IFDEF LCLLIB}
class operator TTMSFNCTreeViewDisplayGroup.=(z1, z2: TTMSFNCTreeViewDisplayGroup)b: boolean;
begin
  Result := z1 = z2;
end;
{$ENDIF}

type
  {$HINTS OFF}
  TShadowedCollection = class(TPersistent)
  private
    {%H-}FItemClass: TCollectionItemClass;
    {$IFDEF LCLWEBLIB}
    FItems: TFPList;
    {$ENDIF}
    {$IFNDEF LCLWEBLIB}
    FItems: TList<TCollectionItem>;
    {$ENDIF}
  end;
  {$HINTS ON}

{ TTMSFNCTreeViewData }

procedure TTMSFNCTreeViewData.RemoveVirtualNode(ANode: TTMSFNCTreeViewVirtualNode);
begin
  RemoveNodeInternal(ANode);
end;          

procedure TTMSFNCTreeViewData.ToggleCheckNode(ANode: TTMSFNCTreeViewNode; AColumn: Integer; ARecurse: Boolean = False);
begin
  if Assigned(ANode) then
    ToggleCheckNodeInternal(ANode.VirtualNode, AColumn, ARecurse);
end;

procedure TTMSFNCTreeViewData.CheckNode(ANode: TTMSFNCTreeViewNode; AColumn: Integer; ARecurse: Boolean = False);
begin
  if Assigned(ANode) then
    CheckNodeInternal(ANode.VirtualNode, AColumn, ARecurse);
end;

procedure TTMSFNCTreeViewData.UnCheckNode(ANode: TTMSFNCTreeViewNode; AColumn: Integer; ARecurse: Boolean = False);
begin
  if Assigned(ANode) then
    UnCheckNodeInternal(ANode.VirtualNode, AColumn, ARecurse);
end;

procedure TTMSFNCTreeViewData.ToggleCheckVirtualNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ARecurse: Boolean = False);
begin
  ToggleCheckNodeInternal(ANode, AColumn, ARecurse);
end;

procedure TTMSFNCTreeViewData.CheckVirtualNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ARecurse: Boolean = False);
begin
  CheckNodeInternal(ANode, AColumn, ARecurse);
end;

procedure TTMSFNCTreeViewData.UnCheckVirtualNode(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ARecurse: Boolean = False);
begin
  UnCheckNodeInternal(ANode, AColumn, ARecurse);
end;

procedure TTMSFNCTreeViewData.CollapseAll;
  procedure CollapseNodes(n: TTMSFNCTreeViewNodes);
  var
    I: Integer;
  begin
    for I := 0 to n.Count - 1 do
    begin
      n[I].FExpanded := False;
      CollapseNodes(n[I].Nodes);
    end;
  end;
begin
  CollapseNodes(Nodes);
  CollapseAllVirtual;
end;

procedure TTMSFNCTreeViewData.CollapseAllVirtual;
var
  I: Integer;
  v: TTMSFNCTreeViewVirtualNode;
begin
  VisibleNodes.Clear;
  TotalRowHeight := 1;
  RowCount := 0;
  for I := 0 to NodeStructure.Count - 1 do
  begin
    v := GetNodeFromNodeStructure(I);
    if Assigned(v) then
    begin
      UpdateNodeExpanded(v, False);
      if v.ParentNode = -1 then
      begin
        VisibleNodes.Add(v);
        TotalRowHeight := TotalRowHeight + v.Height;
        RowCount := RowCount + 1;
      end;
    end;
  end;

  ResetNodes;
  UpdateTreeView;
  Invalidate;
end;

procedure TTMSFNCTreeViewData.CollapseNode(ANode: TTMSFNCTreeViewNode; ARecurse: Boolean = False);
begin
  if Assigned(ANode) then
    CollapseVirtualNode(ANode.VirtualNode, ARecurse);
end;

procedure TTMSFNCTreeViewData.ToggleNode(ANode: TTMSFNCTreeViewNode);
begin
  if Assigned(ANode) then
    ToggleVirtualNode(ANode.VirtualNode);
end;

procedure TTMSFNCTreeViewData.ExpandAll;
  procedure ExpandNodes(n: TTMSFNCTreeViewNodes);
  var
    I: Integer;
  begin
    for I := 0 to n.Count - 1 do
    begin
      n[I].FExpanded := True;
      ExpandNodes(n[I].Nodes);
    end;
  end;
begin
  ExpandNodes(Nodes);
  ExpandAllVirtual;
end;

procedure TTMSFNCTreeViewData.ExpandAllVirtual;
var
  I: Integer;
  v: TTMSFNCTreeViewVirtualNode;
begin
  VisibleNodes.Clear;
  TotalRowHeight := 1;
  RowCount := 0;
  for I := 0 to NodeStructure.Count - 1 do
  begin
    v := GetNodeFromNodeStructure(I);
    if Assigned(v) and (((MatchFilter(v) or v.FAddedToVisibleList) and FFilterApplied) or not FFilterApplied) then
    begin
      UpdateNodeExpanded(v, True);
      VisibleNodes.Add(v);
      TotalRowHeight := TotalRowHeight + v.Height;
      RowCount := RowCount + 1;
    end;
  end;

  ResetNodes;
  UpdateTreeView;
  Invalidate;
end;

procedure TTMSFNCTreeViewData.ExpandNode(ANode: TTMSFNCTreeViewNode; ARecurse: Boolean = False);
begin
  if Assigned(ANode) then
    ExpandVirtualNode(ANode.VirtualNode, ARecurse);
end;

procedure TTMSFNCTreeViewData.CollapseVirtualNode(ANode: TTMSFNCTreeViewVirtualNode; ARecurse: Boolean = False);
begin
  CollapseNodeInternal(ANode, ARecurse);
end;

procedure TTMSFNCTreeViewData.CopyNode(ANode,
  ADestinationNode: TTMSFNCTreeViewNode; AIndex: Integer);
var
  n, nw: TTMSFNCTreeViewNode;
begin
  if ADestinationNode <> ANode then
  begin
    BeginUpdate;
    n := TTMSFNCTreeViewNode.Create(nil);
    n.Assign(ANode);

    if Assigned(ADestinationNode) then
      nw := ADestinationNode.Nodes.Add
    else
      nw := Nodes.Add;

    nw.Assign(n);
    n.Free;

    if Assigned(ADestinationNode) then
    begin
      if (AIndex >= 0) and (AIndex <= ADestinationNode.Nodes.Count - 1) then
        nw.Index := AIndex;
    end
    else
    begin
      if (AIndex >= 0) and (AIndex <= Nodes.Count - 1) then
        nw.Index := AIndex;
    end;

    EndUpdate;
  end;
end;

procedure TTMSFNCTreeViewData.ToggleVirtualNode(ANode: TTMSFNCTreeViewVirtualNode; ARecurse: Boolean = False);
begin
  ToggleNodeInternal(ANode, ARecurse);
end;

procedure TTMSFNCTreeViewData.ExpandVirtualNode(ANode: TTMSFNCTreeViewVirtualNode; ARecurse: Boolean = False);
begin
  ExpandNodeInternal(ANode, ARecurse);
end;

procedure TTMSFNCTreeViewData.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCTreeViewData then
  begin
    FColumns.Assign((Source as TTMSFNCTreeViewData).Columns);
    FGroups.Assign((Source as TTMSFNCTreeViewData).Groups);
    FNodes.Assign((Source as TTMSFNCTreeViewData).Nodes);
  end;
end;

procedure TTMSFNCTreeViewData.ClearColumns;
begin
  BeginUpdate;
  Columns.Clear;
  EndUpdate;
end;

procedure TTMSFNCTreeViewData.ClearNodeList;
begin
  BeginUpdate;
  NodeListBuild := False;
  ClearFocusedNode(nil);
  EndUpdate;
end;

procedure TTMSFNCTreeViewData.ClearNodes;
begin
  BeginUpdate;
  NodeListBuild := False;
  Nodes.Clear;
  ClearFocusedNode(nil);
  EndUpdate;
end;

constructor TTMSFNCTreeViewData.Create(AOwner: TComponent);
begin
  inherited;
  FFilter := TTMSFNCTreeViewFilter.Create(Self);
  FNodeStructure := TTMSFNCTreeViewNodeStructure.Create;
  FVisibleNodes := TTMSFNCTreeViewVisibleNodes.Create;
  FDisplayGroups := TTMSFNCTreeViewDisplayGroups.Create;

  FNodes := CreateNodes;
  FColumns := CreateColumns;
  FGroups := CreateGroups;
end;

function TTMSFNCTreeViewData.CreateGroups: TTMSFNCTreeViewGroups;
begin
  Result := TTMSFNCTreeViewGroups.Create(Self);
end;

function TTMSFNCTreeViewData.GetNodeFromNodeStructure(AIndex: Integer): TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if (AIndex >= 0) and (AIndex <= NodeStructure.Count - 1) then
    Result := NodeStructure[AIndex];
end;

function TTMSFNCTreeViewData.GetNodesSpacing(ANode: TTMSFNCTreeViewVirtualNode): Single;
begin
  Result := 0;
end;

function TTMSFNCTreeViewData.GetNodeText(ANode: TTMSFNCTreeViewNode;
  AColumn: Integer): string;
begin
  Result := GetVirtualNodeText(ANode.VirtualNode, AColumn);
end;

function TTMSFNCTreeViewData.GetNodeTitle(ANode: TTMSFNCTreeViewNode;
  AColumn: Integer): string;
begin
  Result := GetVirtualNodeTitle(ANode.VirtualNode, AColumn);
end;

function TTMSFNCTreeViewData.GetParentNode(
  ANode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode;
var
  v: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(ANode) then
  begin
    v := GetParentVirtualNode(ANode.VirtualNode);
    if Assigned(v) then
      Result := v.Node;
  end;
end;

function TTMSFNCTreeViewData.GetPreviousChildNode(
  ANode: TTMSFNCTreeViewNode; AStartNode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode;
var
  v: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(ANode) and Assigned(AStartNode) then
  begin
    v := GetPreviousChildVirtualNode(ANode.VirtualNode, AStartNode.VirtualNode);
    if Assigned(v) then
      Result := v.Node;
  end;
end;

function TTMSFNCTreeViewData.GetPreviousChildVirtualNode(
  ANode: TTMSFNCTreeViewVirtualNode; AStartNode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode;
begin
  Result := GetPreviousSiblingVirtualNode(AStartNode);
end;

function TTMSFNCTreeViewData.GetPreviousNode(
  ANode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode;
var
  v: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(ANode) then
  begin
    v := GetPreviousVirtualNode(ANode.VirtualNode);
    if Assigned(v) then
      Result := v.Node;
  end;
end;

function TTMSFNCTreeViewData.GetPreviousSiblingNode(
  ANode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode;
var
  v: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(ANode) then
  begin
    v := GetPreviousSiblingVirtualNode(ANode.VirtualNode);
    if Assigned(v) then
      Result := v.Node;
  end;
end;

function TTMSFNCTreeViewData.GetPreviousSiblingVirtualNode(
  ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode;
var
  res: TTMSFNCTreeViewVirtualNode;
begin
  res := ANode;
  repeat
    res := GetPreviousVirtualNode(res);
    Result := res;
  until (Result = nil) or (Assigned(Result) and (Result.ParentNode = ANode.ParentNode));
end;

function TTMSFNCTreeViewData.GetPreviousVirtualNode(
  ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(ANode) then
    Result := GetNodeFromNodeStructure(ANode.Row - 1);
end;

function TTMSFNCTreeViewData.GetRootNodeByIndex(
  AIndex: Integer): TTMSFNCTreeViewNode;
var
  n: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  n := GetRootVirtualNodeByIndex(AIndex);
  if Assigned(n) then
    Result := n.Node;
end;

function TTMSFNCTreeViewData.GetRootVirtualNodeByIndex(
  AIndex: Integer): TTMSFNCTreeViewVirtualNode;
var
  I: Integer;
begin
  Result := nil;
  for I := 0 to NodeStructure.Count - 1 do
  begin
    if NodeStructure[I].Index = AIndex then
    begin
      Result := NodeStructure[I];
      Break;
    end;
  end;
end;

function TTMSFNCTreeViewData.GetTotalNodeCount: Integer;
begin
  Result := GetTotalVirtualNodeCount;
end;

function TTMSFNCTreeViewData.GetTotalVirtualNodeCount: Integer;
var
  n: TTMSFNCTreeViewVirtualNode;
begin
  Result := 0;
  n := GetFirstRootVirtualNode;
  while Assigned(n) do
  begin
    Result := Result + n.TotalChildren + 1;
    n := n.GetNextSibling;
  end;
end;

procedure TTMSFNCTreeViewData.InsertItemInternal(AItem: TTMSFNCTreeViewNode);
var
  pn: TTMSFNCTreeViewNode;
begin
  if (csDestroying in ComponentState) or not Assigned(AItem) or not NodeListBuild then
    Exit;

  pn := TTMSFNCTreeViewNodes(AItem.Collection).Node;
  if Assigned(pn) then
    InsertVirtualNode(AItem.Index, pn.VirtualNode)
  else
    InsertVirtualNode(AItem.Index);
end;

procedure TTMSFNCTreeViewData.RemoveFilter;
begin
  FFilterApplied := False;
  ClearNodeList;
end;

procedure TTMSFNCTreeViewData.RemoveFilters;
begin
  Filter.Clear;
  RemoveFilter;
end;

function TTMSFNCTreeViewData.RemoveItemInternal(AItem: TTMSFNCTreeViewNode; AUpdate: Boolean = True): TTMSFNCTreeViewVirtualNodeRemoveData;
begin
  Result.RowIndex := -1;
  Result.Count := 0;
  Result.ParentNode := -1;

  if (csDestroying in ComponentState) or not Assigned(AItem) or not NodeListBuild then
    Exit;

  Result := RemoveNodeInternal(AItem.VirtualNode, AUpdate);
end;

procedure TTMSFNCTreeViewData.InsertNodeInternal(AParentNode: TTMSFNCTreeViewVirtualNode; AIndex, AInsertIndex: Integer; ANode: TTMSFNCTreeViewVirtualNode);
var
  I: Integer;
  pi: Integer;
  pv, rs: TTMSFNCTreeViewVirtualNode;
  cnt: Integer;
  vi: Boolean;
  chk: Boolean;
  chkst: TTMSFNCArrayBoolean;
  ins: Integer;
  exp, ext: Boolean;
  K: Integer;
begin
  if not NodeListBuild or (csDestroying in ComponentState) then
    Exit;

  if not Assigned(ANode) then
  begin
    ANode.Free;
    Exit;
  end;

  cnt := NodeStructure.Count;
  if Assigned(AParentNode) then
  begin
    pi := AParentNode.Row;
    if (pi >= 0) and (pi <= NodeStructure.Count - 1) then
    begin
      pv := GetNodeFromNodeStructure(pi);
      while Assigned(pv) do
      begin
        UpdateNodeTotalChildren(pv, pv.TotalChildren + 1);
        if pv.Row = AParentNode.Row then
          UpdateNodeChildren(pv, pv.Children + 1);

        pi := pv.ParentNode;
        pv := GetNodeFromNodeStructure(pi);
      end;
    end;
  end;

  UpdateNodeIndex(ANode, AIndex);
  UpdateNodeRow(ANode, AInsertIndex);

  if Assigned(AParentNode) then
  begin
    UpdateNodeParentNode(ANode, AParentNode.Row);
    UpdateNodeLevel(ANode, AParentNode.Level + 1);
  end
  else
  begin
    UpdateNodeParentNode(ANode, -1);
    UpdateNodeLevel(ANode, 0);
  end;

  UpdateNodeHeight(ANode, DefaultRowHeight);

  GetNodeForNodeData(ANode);

  exp := False;
  DoIsNodeExpanded(ANode, exp);
  UpdateNodeExpanded(ANode, exp);

  ext := False;
  DoIsNodeExtended(ANode, ext);
  UpdateNodeExtended(ANode, ext);

  SetLength(chkst, 0);
  for I := 0 to ColumnCount - 1 do
  begin
    chk := False;
    DoIsNodeChecked(ANode, I, chk);
    SetLength(chkst, Length(chkst) + 1);
    chkst[Length(chkst) - 1] := chk;
  end;

  UpdateNodeCheckStates(ANode, chkst);

  if (ANode.Row >= 0) and (ANode.Row < NodeStructure.Count) then
    NodeStructure.Insert(ANode.Row, ANode)
  else
    NodeStructure.Add(ANode);

  vi := True;
  if Assigned(AParentNode) then
    DoIsNodeVisible(AParentNode, vi);

  cnt := cnt - NodeStructure.Count;

  for I := ANode.Row + 1 to NodeStructure.Count - 1 do
  begin
    rs := GetNodeFromNodeStructure(I);
    if Assigned(rs) then
    begin
      UpdateNodeRow(rs, rs.Row - cnt);

      if rs.ParentNode > ANode.ParentNode then
        UpdateNodeParentNode(rs, rs.ParentNode - cnt);

      if rs.ParentNode = ANode.ParentNode then
        UpdateNodeIndex(rs, rs.Index + 1);

      GetNodeForNodeData(rs);

      exp := False;
      DoIsNodeExpanded(rs, exp);
      UpdateNodeExpanded(rs, exp);

      ext := False;
      DoIsNodeExtended(rs, ext);
      UpdateNodeExtended(rs, ext);

      SetLength(chkst, 0);
      for K := 0 to ColumnCount - 1 do
      begin
        chk := False;
        DoIsNodeChecked(RS, K, chk);
        SetLength(chkst, Length(chkst) + 1);
        chkst[Length(chkst) - 1] := chk;
      end;

      UpdateNodeCheckStates(rs, chkst);
    end;
  end;

  if not Assigned(AParentNode) or (Assigned(AParentNode) and AParentNode.Expanded and vi) then
  begin
    if not Assigned(AParentNode) then
    begin
      RowCount := RowCount + 1;
      TotalRowHeight := TotalRowHeight + DefaultRowHeight;
      ins := GetInsertPosition(ANode, True, True);
      if (ins >= 0) and (ins < VisibleNodes.Count) then
        VisibleNodes.Insert(ins, ANode)
      else
        VisibleNodes.Add(ANode);
    end
    else
    begin
      UpdateNodeExpanded(AParentNode, False);
      UpdateVisibleNodes(AParentNode);
      UpdateNodeExpanded(AParentNode, True);
      UpdateVisibleNodes(AParentNode);
    end;
  end;

  ResetNodes(False);
  UpdateTreeView;
  Invalidate;
end;

function TTMSFNCTreeViewData.CreateNodes: TTMSFNCTreeViewNodes;
begin
  Result := TTMSFNCTreeViewNodes.Create(Self, nil);
end;

procedure TTMSFNCTreeViewData.CustomizeScrollPosition(
  ANode: TTMSFNCTreeViewVirtualNode; var APosition, ATopPosition: Double);
begin

end;

function TTMSFNCTreeViewData.CreateColumns: TTMSFNCTreeViewColumns;
begin
  Result := TTMSFNCTreeViewColumns.Create(Self);
end;

procedure TTMSFNCTreeViewData.RemoveNodeChildren(ANode: TTMSFNCTreeViewNode);
begin
  if Assigned(ANode) then
    ANode.Nodes.Clear;
end;

procedure TTMSFNCTreeViewData.RemoveVirtualNodeChildren(ANode: TTMSFNCTreeViewVirtualNode);
var
  I: Integer;
  s: TTMSFNCTreeViewVirtualNode;
begin
  if Assigned(ANode) then
  begin
    for I := ANode.TotalChildren - 1 downto 0 do
    begin
      s := GetNodeFromNodeStructure(ANode.Row + I);
      if Assigned(s) then
        RemoveVirtualNode(s);    
    end;
  end;
end;

destructor TTMSFNCTreeViewData.Destroy;
begin
  FFilter.Free;
  FDisplayGroups.Free;
  FNodeStructure.Free;
  FVisibleNodes.Free;
  FNodes.Free;
  FGroups.Free;
  FColumns.Free;
  inherited;
end;

procedure TTMSFNCTreeViewData.DoGetColumnHorizontalTextAlign(AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign);
begin

end;

procedure TTMSFNCTreeViewData.DoGetColumnTrimming(AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var ATrimming: TTMSFNCGraphicsTextTrimming);
begin

end;

procedure TTMSFNCTreeViewData.DoGetColumnVerticalTextAlign(AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin

end;

procedure TTMSFNCTreeViewData.DoGetColumnWordWrapping(AColumn: Integer; AKind: TTMSFNCTreeViewCacheItemKind; var AWordWrapping: Boolean);
begin

end;

procedure TTMSFNCTreeViewData.DoGetHTMLTemplate(ANodeValue: TTMSFNCTreeViewNodeValue; AColumnIndex: Integer; var AHTMLTemplate: string);
begin

end;

procedure TTMSFNCTreeViewData.DoGetHTMLTemplateValue(ANodeValue: TTMSFNCTreeViewNodeValue; AName: string; var AValue: string);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeCheckType(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ACheckType: TTMSFNCTreeViewNodeCheckType);
var
  it: TTMSFNCTreeViewNode;
begin
  it := ANode.Node;
  if Assigned(it) then
  begin
     if (AColumn >= 0) and (AColumn <= it.Values.Count - 1) then
       ACheckType := it.Values[AColumn].CheckType;
  end;
end;

procedure TTMSFNCTreeViewData.DoGetNodeColor(ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeData(ANode: TTMSFNCTreeViewVirtualNode);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeDisabledColor(
  ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeDisabledTextColor(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor: TTMSFNCGraphicsColor);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeDisabledTitleColor(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATitleColor: TTMSFNCGraphicsColor);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeExtraSize(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AExtraSize: Single);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeTitleExtraSize(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATitleExtraSize: Single);
begin

end;

procedure TTMSFNCTreeViewData.BeforeExport;
begin
  inherited;
  ClearNodeList;
end;

procedure TTMSFNCTreeViewData.BuildInternalNodeList(
  ANode: TTMSFNCTreeViewVirtualNode; AID: String; AParentNode: Integer; ANodeIndex: Integer; ALevel: Integer; AParentExpanded: Boolean; var ATotalChildren: Integer);
var
  I: Integer;
  nd: Integer;
  vi: Boolean;
  v: TTMSFNCTreeViewVirtualNode;
  exp: Boolean;
  tot: Integer;
  chk, ext: Boolean;
  chkst: TTMSFNCArrayBoolean;
  p: TTMSFNCTreeViewVirtualNode;
  l: TTMSFNCTreeViewVirtualNodeArray;
begin
  ANode.FRow := FNodeStructure.Count;
  ANode.FParentNode := AParentNode;
  ANode.FIndex := ANodeIndex;
  ANode.FLevel := ALevel;
  if AID <> 'ROOT' then
    GetNodeForNodeData(ANode);

  nd := 0;
  if (ALevel < MAXLEVELDEPTH) then
    DoGetNumberOfNodes(ANode, nd);

  ANode.FChildren := nd;

  ANode.FCalculated := False;
  ANode.FHeight := DefaultRowHeight;

  exp := False;
  if AID <> 'ROOT' then
    DoIsNodeExpanded(ANode, exp);
  ANode.FExpanded := exp;

  ext := False;
  DoIsNodeExtended(ANode, ext);
  UpdateNodeExtended(ANode, ext);

  SetLength(chkst, 0);
  if AID <> 'ROOT' then
  begin
    for I := 0 to ColumnCount - 1 do
    begin
      chk := False;
      DoIsNodeChecked(ANode, I, chk);
      SetLength(chkst, Length(chkst) + 1);
      chkst[Length(chkst) - 1] := chk;
    end;
    ANode.FCheckStates := chkst;
  end;

  if FFilterApplied then
    vi := MatchFilter(ANode)
  else
    vi := True;

  if AID <> 'ROOT' then
    DoIsNodeVisible(ANode, vi);

  tot := nd;

  if AID <> 'ROOT' then
  begin
    FNodeStructure.Add(ANode);
    if (AParentExpanded or (ANode.Level = 0)) and vi then
    begin
      if FFilterApplied then
      begin
        p := ANode.GetParent;
        l := nil;
        while Assigned(p) do
        begin
          if not p.FAddedToVisibleList then
          begin
            SetLength(l, Length(l) + 1);
            l[Length(l) - 1] := p;
          end;
          p := p.GetParent;
        end;

        for I := Length(l) - 1 downto 0 do
        begin
          FVisibleNodes.Add(l[I]);
          l[I].FAddedToVisibleList := True;
        end;
      end;

      FVisibleNodes.Add(ANode);
      ANode.FAddedToVisibleList := True;
    end;
  end;

  for I := 0 to nd - 1 do
  begin
    if FNodeStructure.Count = MAXNODECOUNT then
      Break;

    v := TTMSFNCTreeViewVirtualNode.Create(Self);
    if AID = 'ROOT' then
      BuildInternalNodeList(v, '', AParentNode, I, 0, exp, tot)
    else
      BuildInternalNodeList(v, '', ANode.Row, I, ANode.Level + 1, exp, tot);
  end;

  ATotalChildren := ATotalChildren + tot;

  if AID <> 'ROOT' then
    ANode.FTotalChildren := tot
  else
    ANode.Free;
end;

procedure TTMSFNCTreeViewData.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (AComponent = FBitmapContainer) and (Operation = opRemove) then
    FBitmapContainer := nil;
end;

procedure TTMSFNCTreeViewData.BuildNodeList;
var
  v: TTMSFNCTreeViewVirtualNode;
  c: Integer;
begin
  if NodeListBuild then
    Exit;

  FNodeStructure.Clear;
  FVisibleNodes.Clear;
  v := TTMSFNCTreeViewVirtualNode.Create(Self);
  TotalRowHeight := 1;
  RowCount := 0;
  c := 0;
  BuildInternalNodeList(v, 'ROOT', -1, -1, -1, True, c);
  RowCount := FVisibleNodes.Count;
  NodeListBuild := FNodeStructure.Count > 0;
end;

function TTMSFNCTreeViewData.GetNextChildNode(
  ANode: TTMSFNCTreeViewNode; AStartNode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode;
var
  v: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(ANode) and Assigned(AStartNode) then
  begin
    v := GetNextChildVirtualNode(ANode.VirtualNode, AStartNode.VirtualNode);
    if Assigned(v) then
      Result := v.Node;
  end;
end;

function TTMSFNCTreeViewData.GetNextChildVirtualNode(
  ANode: TTMSFNCTreeViewVirtualNode; AStartNode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode;
begin
  Result := GetNextSiblingVirtualNode(AStartNode);
end;

function TTMSFNCTreeViewData.GetNextNode(
  ANode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode;
var
  v: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(ANode) then
  begin
    v := GetNextVirtualNode(ANode.VirtualNode);
    if Assigned(v) then
      Result := v.Node;
  end;
end;

function TTMSFNCTreeViewData.GetNextSiblingNode(
  ANode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode;
var
  v: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(ANode) then
  begin
    v := GetNextSiblingVirtualNode(ANode.VirtualNode);
    if Assigned(v) then
      Result := v.Node;
  end;
end;

function TTMSFNCTreeViewData.GetNextSiblingVirtualNode(
  ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode;
var
  res: TTMSFNCTreeViewVirtualNode;
begin
  res := ANode;
  repeat
    res := GetNextVirtualNode(res);
    Result := res;
  until (Result = nil) or (Assigned(Result) and (Result.ParentNode = ANode.ParentNode));
end;

function TTMSFNCTreeViewData.GetNextVirtualNode(
  ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(ANode) then
    Result := GetNodeFromNodeStructure(ANode.Row + 1);
end;

function TTMSFNCTreeViewData.GetNodeChildCount(ANode: TTMSFNCTreeViewNode): Integer;
begin
  Result := 0;
  if Assigned(ANode) then
    Result := GetVirtualNodeChildCount(ANode.VirtualNode);
end;

function TTMSFNCTreeViewData.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

function TTMSFNCTreeViewData.GetFirstChildNode(
  ANode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode;
var
  v: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(ANode) then
  begin
    v := GetFirstChildVirtualNode(ANode.VirtualNode);
    if Assigned(v) then
      Result := v.Node;
  end;
end;

function TTMSFNCTreeViewData.GetFirstChildVirtualNode(
  ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(ANode) and (ANode.Children > 0) then
    Result := GetNodeFromNodeStructure(ANode.Row + 1);
end;

function TTMSFNCTreeViewData.GetFirstRootNode: TTMSFNCTreeViewNode;
var
  n: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  n := GetFirstRootVirtualNode;
  if Assigned(n) then
    Result := n.Node;
end;

function TTMSFNCTreeViewData.GetFirstRootVirtualNode: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if NodeStructure.Count > 0 then
    Result := NodeStructure[0];
end;

function TTMSFNCTreeViewData.GetHTMLTemplate(AColumnIndex: Integer): string;
begin
  Result := '';
end;

function TTMSFNCTreeViewData.GetInsertPosition(
  ANode: TTMSFNCTreeViewVirtualNode; ARoot: Boolean; AInsert: Boolean): Integer;
var
  prev: TTMSFNCTreeViewVirtualNode;
  I: Integer;
  r: Integer;
  n: TTMSFNCTreeViewVirtualNode;
begin
  Result := -1;
  if not Assigned(ANode) then
    Exit;

  if (ANode.Row = 0) then
  begin
    Result := 0;
    Exit;
  end;

  if AInsert then
    prev := GetNodeFromNodeStructure(ANode.Row - 1)
  else
    prev := GetNodeFromNodeStructure(ANode.Row);

  if ARoot then
  begin
    while Assigned(prev) and (prev.ParentNode >= 0) and (prev.ParentNode <= NodeStructure.Count - 1) do
    begin
      n := GetNodeFromNodeStructure(prev.ParentNode);
      if n <> prev then
        prev := n
      else
        Break;
    end;
  end;

  if Assigned(prev) then
  begin
    for I := 0 to VisibleNodes.Count - 1 do
    begin
      if AInsert then
        r := ANode.Row + 1
      else
        r := ANode.Row;

      if VisibleNodes[I].Row = r then
      begin
        Result := I;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCTreeViewData.GetLastChildNode(
  ANode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode;
var
  v: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(ANode) then
  begin
    v := GetLastChildVirtualNode(ANode.VirtualNode);
    if Assigned(v) then
      Result := v.Node;
  end;
end;

function TTMSFNCTreeViewData.GetLastChildVirtualNode(
  ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode;
var
  v: TTMSFNCTreeViewVirtualNode;
begin
  Result := GetFirstChildVirtualNode(ANode);
  if Assigned(Result) then
  begin
    v := Result;
    repeat
      Result := v;
      v := GetNextSiblingVirtualNode(v);
    until v = nil;
  end;
end;

function TTMSFNCTreeViewData.GetLastNode: TTMSFNCTreeViewNode;
var
  n: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  n := GetLastVirtualNode;
  if Assigned(n) then
    Result := n.Node;
end;

function TTMSFNCTreeViewData.GetLastRootNode: TTMSFNCTreeViewNode;
var
  n: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  n := GetLastRootVirtualNode;
  if Assigned(n) then
    Result := n.Node;
end;

function TTMSFNCTreeViewData.GetLastRootVirtualNode: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if NodeStructure.Count > 0 then
  begin
    Result := NodeStructure[NodeStructure.Count - 1];
    while Assigned(Result) and (Result.ParentNode > -1) do
      Result := NodeStructure[Result.Row - 1];
  end;
end;

function TTMSFNCTreeViewData.GetLastVirtualNode: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if NodeStructure.Count > 0 then
    Result := NodeStructure[NodeStructure.Count - 1];
end;

procedure TTMSFNCTreeViewData.GetNodeForNodeData(
  ANode: TTMSFNCTreeViewVirtualNode);
var
  col: TTMSFNCTreeViewNodes;
  p: TTMSFNCTreeViewVirtualNode;
  it: TTMSFNCTreeViewNode;
begin
  if Assigned(ANode) then
  begin
    p := GetNodeFromNodeStructure(ANode.ParentNode);
    if Assigned(p) and Assigned(p.Node) then
      col := TTMSFNCTreeViewNodes(p.Node.Nodes)
    else
      col := Nodes;
  end
  else
    Exit;

  if Assigned(col) then
  begin
    if (ANode.Index >= 0) and (ANode.Index <= col.Count - 1) then
    begin
      it := col[ANode.Index];
      ANode.FNode := it;
      it.FVirtualNode := ANode;
    end;
  end;
end;

procedure TTMSFNCTreeViewData.DoGetNodeHorizontalTextAlign(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeIcon(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; ALarge: Boolean; var AIcon: TTMSFNCBitmap);
var
  it: TTMSFNCTreeViewNode;
  v: TTMSFNCTreeViewNodeValue;
begin
  it := ANode.Node;
  if Assigned(it) then
  begin
     if (AColumn >= 0) and (AColumn <= it.Values.Count - 1) then
     begin
       v := it.Values[AColumn];
       if ANode.Expanded then
       begin
         if ALarge then
         begin
           if Assigned(BitmapContainer) and (v.ExpandedIconLargeName <> '') then
             AIcon := TTMSFNCBitmap(BitmapContainer.FindBitmap(v.ExpandedIconLargeName));

           if not Assigned(AIcon) then
             AIcon := v.ExpandedIconLarge;
         end
         else
         begin
           if Assigned(BitmapContainer) and (v.ExpandedIconName <> '') then
             AIcon := TTMSFNCBitmap(BitmapContainer.FindBitmap(v.ExpandedIconName));

           if not Assigned(AIcon) then
             AIcon := v.ExpandedIcon;
         end;
       end
       else
       begin
         if ALarge then
         begin
           if Assigned(BitmapContainer) and (v.CollapsedIconLargeName <> '') then
             AIcon := TTMSFNCBitmap(BitmapContainer.FindBitmap(v.CollapsedIconLargeName));

           if not Assigned(AIcon) then
             AIcon := v.CollapsedIconLarge;
         end
         else
         begin
           if Assigned(BitmapContainer) and (v.CollapsedIconName <> '') then
             AIcon := TTMSFNCBitmap(BitmapContainer.FindBitmap(v.CollapsedIconName));

           if not Assigned(AIcon) then
             AIcon := v.CollapsedIcon;
         end;
       end;
     end;
  end;
end;

procedure TTMSFNCTreeViewData.DoGetNodeIconSize(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ALarge: Boolean;
  AIcon: TTMSFNCBitmap; var AIconWidth: Double; var AIconHeight: Double);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeSelectedColor(
  ANode: TTMSFNCTreeViewVirtualNode; var AColor: TTMSFNCGraphicsColor);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeSelectedTextColor(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var ATextColor: TTMSFNCGraphicsColor);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeSelectedTitleColor(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATitleColor: TTMSFNCGraphicsColor);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeSides(ANode: TTMSFNCTreeViewVirtualNode;
  var ASides: TTMSFNCGraphicsSides);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeText(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; AMode: TTMSFNCTreeViewNodeTextMode; var AText: String);
var
  it: TTMSFNCTreeViewNode;
begin
  it := ANode.Node;
  if Assigned(it) then
  begin
     if (AColumn >= 0) and (AColumn <= it.Values.Count - 1) then
       AText := it.Values[AColumn].GetText;
  end;
end;

procedure TTMSFNCTreeViewData.DoGetNodeTitleExpanded(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var AExpanded: Boolean);
var
  it: TTMSFNCTreeViewNode;
begin
  it := ANode.Node;
  if Assigned(it) then
  begin
     if (AColumn >= 0) and (AColumn <= it.Values.Count - 1) then
       AExpanded := it.Values[AColumn].TitleExpanded;
  end;
end;

procedure TTMSFNCTreeViewData.DoGetNodeTitle(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; AMode: TTMSFNCTreeViewNodeTextMode; var ATitle: String);
var
  it: TTMSFNCTreeViewNode;
begin
  it := ANode.Node;
  if Assigned(it) then
  begin
     if (AColumn >= 0) and (AColumn <= it.Values.Count - 1) then
       ATitle := it.Values[AColumn].Title;
  end;
end;

procedure TTMSFNCTreeViewData.DoGetNodeTitleColor(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATitleColor: TTMSFNCGraphicsColor);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeTitleHorizontalTextAlign(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeTitleTrimming(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATrimming: TTMSFNCGraphicsTextTrimming);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeTitleVerticalTextAlign(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeTitleWordWrapping(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AWordWrapping: Boolean);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeTextColor(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var ATextColor: TTMSFNCGraphicsColor);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeTrimming(ANode: TTMSFNCTreeViewVirtualNode;
  AColumn: Integer; var ATrimming: TTMSFNCGraphicsTextTrimming);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeVerticalTextAlign(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNodeWordWrapping(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; var AWordWrapping: Boolean);
begin

end;

procedure TTMSFNCTreeViewData.DoGetNumberOfNodes(
  ANode: TTMSFNCTreeViewVirtualNode; var ANumberOfNodes: Integer);
begin
  if Assigned(ANode.Node) then
    ANumberOfNodes := ANode.Node.Nodes.Count
  else
    ANumberOfNodes := Nodes.Count;
end;

procedure TTMSFNCTreeViewData.DoIsNodeChecked(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer;
  var AChecked: Boolean);
var
  it: TTMSFNCTreeViewNode;
begin
  it := ANode.Node;
  if Assigned(it) then
  begin
     if (AColumn >= 0) and (AColumn <= it.Values.Count - 1) then
       AChecked := it.Values[AColumn].Checked;
  end;
end;

procedure TTMSFNCTreeViewData.CheckNodeInternal(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ARecurse: Boolean = False);
var
  chkt: TTMSFNCTreeViewNodeCheckType;
  pn, n: TTMSFNCTreeViewVirtualNode;
  st, stp: Integer;
  I: Integer;
  v: TTMSFNCTreeViewVirtualNode;
begin
  if not Assigned(ANode) then
    Exit;

  if (AColumn >= 0) and (AColumn <= Length(ANode.CheckStates) - 1) then
  begin
    ANode.CheckStates[AColumn] := True;
    UpdateCheckState(ANode, AColumn, ANode.CheckStates[AColumn]);
  end;

  if Assigned(ANode.Node) then
  begin
    if (AColumn >= 0) and (AColumn <= ANode.Node.Values.Count - 1) then
      ANode.Node.Values.UpdateChecked(AColumn, True);
  end;

  chkt := tvntNone;
  DoGetNodeCheckType(ANode, AColumn, chkt);
  if chkt = tvntRadioButton then
  begin
    pn := GetNodeFromNodeStructure(ANode.ParentNode);
    if Assigned(pn) then
    begin
      st := pn.Row + 1;
      stp := st + pn.TotalChildren;
    end
    else
    begin
      st := 0;
      stp := NodeStructure.Count;
    end;

    while st < stp do
    begin
      n := GetNodeFromNodeStructure(st);
      if Assigned(n) then
      begin
        if n <> ANode then
        begin
          chkt := tvntNone;
          DoGetNodeCheckType(n, AColumn, chkt);
          if chkt = tvntRadioButton then
          begin
            if (AColumn >= 0) and (AColumn <= Length(n.CheckStates) - 1) then
            begin
              n.CheckStates[AColumn] := False;
              UpdateCheckState(n, AColumn, n.CheckStates[AColumn]);
            end;

            if Assigned(n.Node) then
            begin
              if (AColumn >= 0) and (AColumn <= n.Node.Values.Count - 1) then
                n.Node.Values.UpdateChecked(AColumn, False);
            end;
          end;
        end;
        st := st + n.TotalChildren + 1;
      end
      else
        Break;
    end;
  end;

  if ARecurse then
  begin
    for I := ANode.Row + 1 to ANode.Row + ANode.TotalChildren do
    begin
      v := GetNodeFromNodeStructure(I);
      if Assigned(v) then
        CheckNodeInternal(v, AColumn, ARecurse);
    end;
  end;

  Invalidate;
end;

function TTMSFNCTreeViewData.InsertVirtualNode(AIndex: Integer; AParentNode: TTMSFNCTreeViewVirtualNode = nil): TTMSFNCTreeViewVirtualNode;
var
  v: TTMSFNCTreeViewVirtualNode;
  p, n, c: TTMSFNCTreeViewVirtualNode;
  I, idx: Integer;
begin
  Result := nil;
  if (csDestroying in ComponentState) or not NodeListBuild then
    Exit;

  v := TTMSFNCTreeViewVirtualNode.Create(Self);
  if Assigned(AParentNode) then
  begin
    idx := -1;
    I := AParentNode.Row + 1;
    while i <= AParentNode.Row + AParentNode.TotalChildren do
    begin
      n := GetNodeFromNodeStructure(I);
      if Assigned(n) then
      begin
        if (n.Index = AIndex) and (n.ParentNode = AParentNode.Row) then
        begin
          idx := n.Row;
          Break;
        end;
        I := I + n.Children + 1;
      end
      else
        Break;
    end;

    if idx = -1 then
      InsertNodeInternal(AParentNode, AIndex, AParentNode.Row + AParentNode.TotalChildren + 1, v)
    else
      InsertNodeInternal(AParentNode, AIndex, idx, v);
  end
  else
  begin
    p := nil;
    if NodeStructure.Count > 0 then
    begin
      p := GetNodeFromNodeStructure(NodeStructure.Count - 1);
      while Assigned(p) and (p.ParentNode >= 0) and (p.ParentNode <= NodeStructure.Count - 1) do
        p := GetNodeFromNodeStructure(p.ParentNode);
    end;

    if Assigned(p) then
    begin
      if AIndex > p.Index then
        InsertNodeInternal(nil, AIndex, p.Row + p.TotalChildren + 1, v)
      else
      begin
        n := nil;
        I := 0;
        while not Assigned(n) do
        begin
          c := GetNodeFromNodeStructure(I);
          if Assigned(c) then
          begin
            if c.Index = AIndex then
            begin
              n := c;
              Break;
            end
            else
              I := I + c.TotalChildren + 1;
          end
          else
            Break;
        end;

        if Assigned(n) then
          InsertNodeInternal(nil, AIndex, n.Row, v);
      end;
    end
    else
      InsertNodeInternal(nil, 0, 0, v);
  end;

  Result := v;
end;

function TTMSFNCTreeViewData.GetVirtualNodeChildCount(
  ANode: TTMSFNCTreeViewVirtualNode): Integer;
begin
  Result := 0;
  if Assigned(ANode) then
    Result := ANode.Children;
end;

function TTMSFNCTreeViewData.GetVirtualNodeTitleExpanded(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer): Boolean;
begin
  Result := True;
  if Assigned(ANode) then
  begin
    Result := True;
    DoGetNodeTitleExpanded(ANode, AColumn, Result);
  end;
end;

function TTMSFNCTreeViewData.GetVisibleNodeForIndex(
  AIndex: Integer): TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if (AIndex >= 0) and (AIndex <= VisibleNodes.Count - 1) then
    Result := VisibleNodes[AIndex];
end;

function TTMSFNCTreeViewData.HTMLReplace(AValue: string; ANodeValue: TTMSFNCTreeViewNodeValue): string;
var
  beforetag, aftertag, nm, vl: string;
  i, j: integer;
begin
  Result := '';
  if not Assigned(ANodeValue) then
    Exit;

  beforetag := '';

  while (Pos('<#', AValue) > 0) and (Pos('>', AValue) > 0) do
  begin
    i := pos('<#', AValue);
    beforetag := beforetag + copy(AValue, 1, i - 1); //part prior to the tag
    aftertag := copy(AValue, i, length(AValue)); //part after the tag
    j := pos('>', aftertag);
    nm := copy(aftertag, 1, j - 1);
    Delete(nm, 1, 2);
    Delete(AValue, 1, i + j - 1);
    vl := ANodeValue.HTMLTemplateItems.Values[nm];
    DoGetHTMLTemplateValue(ANodeValue, nm, vl);
    beforetag := beforetag + vl;
  end;

  Result := beforetag + AValue;
end;

function TTMSFNCTreeViewData.GetVirtualNodeText(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer): string;
begin
  Result := '';
  if Assigned(ANode) then
  begin
    Result := '';
    DoGetNodeText(ANode, AColumn, tntmDrawing, Result);
  end;
end;

function TTMSFNCTreeViewData.GetVirtualNodeTitle(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer): string;
begin
  Result := '';
  if Assigned(ANode) then
  begin
    Result := '';
    DoGetNodeTitle(ANode, AColumn, tntmDrawing, Result);
  end;
end;

function TTMSFNCTreeViewData.GetParentVirtualNode(ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(ANode) then
    Result := GetNodeFromNodeStructure(ANode.ParentNode);
end;

function TTMSFNCTreeViewData.AddVirtualNode(AParentNode: TTMSFNCTreeViewVirtualNode = nil): TTMSFNCTreeViewVirtualNode;
var
  p: TTMSFNCTreeViewVirtualNode;
  idx: Integer;
begin
  Result := nil;
  if (csDestroying in ComponentState) or not NodeListBuild then
    Exit;

  if Assigned(AParentNode) then
  begin
    idx := AParentNode.Children;
  end
  else
  begin
    p := nil;
    if NodeStructure.Count > 0 then
    begin
      p := GetNodeFromNodeStructure(NodeStructure.Count - 1);
      while Assigned(p) and (p.ParentNode >= 0) and (p.ParentNode <= NodeStructure.Count - 1) do
        p := GetNodeFromNodeStructure(p.ParentNode);
    end;

    idx := p.Index + 1;
  end;

  Result := InsertVirtualNode(idx, AParentNode);
end;

procedure TTMSFNCTreeViewData.AfterExport;
begin
  inherited;
  ClearNodeList;
end;

procedure TTMSFNCTreeViewData.ApplyFilter;
begin
  FFilterApplied := True;
  ClearNodeList;
end;

procedure TTMSFNCTreeViewData.SetNodeTextValues(ANode: TTMSFNCTreeViewNode; ATextValues: TTMSFNCTreeViewNodeTextValues);
begin
  if Assigned(ANode) then
    ANode.SetTextValues(ATextValues);
end;

procedure TTMSFNCTreeViewData.SetNodeExpandedIcons(ANode: TTMSFNCTreeViewNode; AIcons: TTMSFNCTreeViewNodeIcons; ALarge: Boolean = False);
begin
  if Assigned(ANode) then
    ANode.SetExpandedIcons(AIcons, ALarge);
end;

procedure TTMSFNCTreeViewData.SetNodeExpandedIconNames(ANode: TTMSFNCTreeViewNode; AIconNames: TTMSFNCTreeViewNodeIconNames; ALarge: Boolean = False);
begin
  if Assigned(ANode) then
    ANode.SetExpandedIconNames(AIconNames, ALarge);
end;

procedure TTMSFNCTreeViewData.SetNodeCollapsedIcons(ANode: TTMSFNCTreeViewNode; AIcons: TTMSFNCTreeViewNodeIcons; ALarge: Boolean = False);
begin
  if Assigned(ANode) then
    ANode.SetCollapsedIcons(AIcons, ALarge);
end;

procedure TTMSFNCTreeViewData.SetNodeCollapsedIconNames(ANode: TTMSFNCTreeViewNode; AIconNames: TTMSFNCTreeViewNodeIconNames; ALarge: Boolean = False);
begin
  if Assigned(ANode) then
    ANode.SetCollapsedIconNames(AIconNames, ALarge);
end;

procedure TTMSFNCTreeViewData.SetNodeCheckTypes(ANode: TTMSFNCTreeViewNode; ACheckTypes: TTMSFNCTreeViewNodeCheckTypes);
begin
  if Assigned(ANode) then
    ANode.SetCheckTypes(ACheckTypes);
end;

procedure TTMSFNCTreeViewData.SetNodeCheckStates(ANode: TTMSFNCTreeViewNode; ACheckStates: TTMSFNCTreeViewNodeCheckStates);
begin
  if Assigned(ANode) then
    ANode.SetCheckStates(ACheckStates);
end;

function TTMSFNCTreeViewData.AddNode(AParentNode: TTMSFNCTreeViewNode = nil): TTMSFNCTreeViewNode;
begin
  if Assigned(AParentNode) then
    Result := AParentNode.Nodes.Add
  else
    Result := Nodes.Add;
end;

function TTMSFNCTreeViewData.InsertNode(AIndex: Integer; AParentNode: TTMSFNCTreeViewNode = nil): TTMSFNCTreeViewNode;
begin
  if Assigned(AParentNode) then
    Result := AParentNode.Nodes.Insert(AIndex)
  else
    Result := Nodes.Insert(AIndex);
end;

procedure TTMSFNCTreeViewData.RemoveNode(ANode: TTMSFNCTreeViewNode);
begin
  if Assigned(ANode) then
  begin
    ANode.Free;
  end;
end;

function TTMSFNCTreeViewData.RemoveNodeInternal(ANode: TTMSFNCTreeViewVirtualNode; AUpdateNodes: Boolean = True): TTMSFNCTreeViewVirtualNodeRemoveData;
var
  I: Integer;
  R, pi: Integer;
  subv, pv, rs: TTMSFNCTreeViewVirtualNode;
  cnt: Integer;
  exp, ext: Boolean;
  chkst: TTMSFNCArrayBoolean;
  K: Integer;
  chk: Boolean;
begin
  if (csDestroying in ComponentState) or not NodeListBuild or not Assigned(ANode) then
    Exit;

  cnt := NodeStructure.Count;
  pv := GetNodeFromNodeStructure(ANode.ParentNode);
  while Assigned(pv) do
  begin
    UpdateNodeTotalChildren(pv, pv.TotalChildren - 1 - ANode.TotalChildren);
    if pv.Row = ANode.ParentNode then
      UpdateNodeChildren(pv, pv.Children - 1);

    pi := pv.ParentNode;
    pv := GetNodeFromNodeStructure(pi);
  end;

  for I := ANode.TotalChildren - 1 downto 0 do
  begin
    R := I + ANode.Row + 1;
    subv := GetNodeFromNodeStructure(R);
    if Assigned(subv) then
    begin
      if VisibleNodes.IndexOf(subv) > -1 then
      begin
        VisibleNodes.Remove(subv);
        TotalRowHeight := TotalRowHeight - subv.Height;
        RowCount := RowCount - 1;
      end;
      NodeStructure.Remove(subv);
      RemoveNodeFromSelection(subv);
    end;
  end;

  R := ANode.Row;
  pi := ANode.ParentNode;
  if VisibleNodes.IndexOf(ANode) > -1 then
  begin
    VisibleNodes.Remove(ANode);
    TotalRowHeight := TotalRowHeight - ANode.Height;
    RowCount := RowCount - 1;
  end;

  UpdateNodeChildren(ANode, ANode.Children - 1);
  UpdateNodeTotalChildren(ANode, ANode.TotalChildren - 1);
  ClearFocusedNode(ANode);
  NodeStructure.Remove(ANode);
  RemoveNodeFromSelection(ANode);

  cnt := cnt - NodeStructure.Count;
  Result.Count := cnt;
  Result.RowIndex := R;
  Result.ParentNode := pi;

  if AUpdateNodes then
  begin
    for I := R to NodeStructure.Count - 1 do
    begin
      rs := GetNodeFromNodeStructure(I);
      if Assigned(rs) then
      begin
        UpdateNodeRow(rs, rs.Row - cnt);

        if rs.ParentNode > pi then
          UpdateNodeParentNode(rs, rs.ParentNode - cnt);

        if rs.ParentNode = pi then
          UpdateNodeIndex(rs, rs.Index - 1);

        GetNodeForNodeData(rs);

        exp := False;
        DoIsNodeExpanded(rs, exp);
        UpdateNodeExpanded(rs, exp);

        ext := False;
        DoIsNodeExtended(rs, ext);
        UpdateNodeExtended(rs, ext);

        SetLength(chkst, 0);
        for K := 0 to ColumnCount - 1 do
        begin
          chk := False;
          DoIsNodeChecked(RS, K, chk);
          SetLength(chkst, Length(chkst) + 1);
          chkst[Length(chkst) - 1] := chk;
        end;

        UpdateNodeCheckStates(rs, chkst);
      end;
    end;

    if not FBlockUpdate then
    begin
      ResetNodes(False);
      UpdateTreeView;
      Invalidate;
    end;
  end;
end;

procedure TTMSFNCTreeViewData.UpdateVisibleNodes(
  ANode: TTMSFNCTreeViewVirtualNode);
var
  add: Boolean;
  R, I, C, idx: Integer;
  subv: TTMSFNCTreeViewVirtualNode;
  en, vi, visub: Boolean;
begin
  add := ANode.Expanded;
  if add then
  begin
    idx := GetInsertPosition(ANode, ANode.ParentNode = -1, False);
    en := True;
    DoIsNodeEnabled(ANode, en);

    vi := True;
    DoIsNodeVisible(ANode, vi);

    if (idx <> -1) and vi and en then
    begin
      I := 0;
      C := idx + 1;
      while I < ANode.TotalChildren do
      begin
        R := I + ANode.Row + 1;
        subv := GetNodeFromNodeStructure(R);
        if Assigned(subv) and (((MatchFilter(subv) or subv.FAddedToVisibleList) and FFilterApplied) or not FFilterApplied) then
        begin
          visub := True;
          DoIsNodeVisible(subv, visub);
          if visub then
          begin
            if (C >= 0) and (C < VisibleNodes.Count) then
              VisibleNodes.Insert(C, subv)
            else
              VisibleNodes.Add(subv);

            if subv.Children = 0 then
              subv.FExpanded := True;

            TotalRowHeight := TotalRowHeight + subv.Height;
            RowCount := RowCount + 1;
            Inc(C);
          end;

          if not subv.Expanded or not visub then
            I := I + subv.TotalChildren;
        end;
        Inc(I);
      end;
    end;
  end
  else
  begin
    for I := 0 to ANode.TotalChildren - 1 do
    begin
      R := I + ANode.Row + 1;
      subv := GetNodeFromNodeStructure(R);
      if Assigned(subv) then
      begin
        UpdateNodeCalculated(subv, False);
        if VisibleNodes.IndexOf(subv) > -1 then
        begin
          VisibleNodes.Remove(subv);

          if subv.Children = 0 then
            subv.FExpanded := False;

          TotalRowHeight := TotalRowHeight - subv.Height;
          RowCount := RowCount - 1;
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCTreeViewData.CollapseNodeInternal(ANode: TTMSFNCTreeViewVirtualNode; ARecurse: Boolean = False);
var
  I: Integer;
  v, n: TTMSFNCTreeViewVirtualNode;
begin
  if not Assigned(ANode) or not NodeListBuild or (csDestroying in ComponentState) then
    Exit;

  if not ANode.Expanded then
  begin
    if ARecurse then
    begin
      n := ANode.GetFirstChild;
      while Assigned(n) do
      begin
        CollapseNodeInternal(n, ARecurse);
        n := n.GetNextSibling;
      end;
    end;

    Exit;
  end;

  ANode.FExpanded := False;

  UpdateNodeExpanded(ANode, False);
  if ARecurse then
  begin
    for I := ANode.Row + 1 to ANode.Row + ANode.TotalChildren do
    begin
      v := GetNodeFromNodeStructure(I);
      if Assigned(v) then
        UpdateNodeExpanded(v, False);
    end;
  end;

  UpdateVisibleNodes(ANode);

  if not FBlockUpdate then
  begin
    ResetNodes(False);
    UpdateTreeView;
    Invalidate;
  end;
end;

procedure TTMSFNCTreeViewData.DoIsNodeEnabled(ANode: TTMSFNCTreeViewVirtualNode;
  var AEnabled: Boolean);
var
  it: TTMSFNCTreeViewNode;
begin
  it := ANode.Node;
  if Assigned(it) then
    AEnabled := it.Enabled;
end;

procedure TTMSFNCTreeViewData.DoIsNodeExpanded(ANode: TTMSFNCTreeViewVirtualNode;
  var AExpanded: Boolean);
var
  it: TTMSFNCTreeViewNode;
begin
  it := ANode.Node;
  if Assigned(it) then
    AExpanded := it.Expanded;
end;

procedure TTMSFNCTreeViewData.DoIsNodeExtended(ANode: TTMSFNCTreeViewVirtualNode;
  var AExtended: Boolean);
var
  it: TTMSFNCTreeViewNode;
begin
  it := ANode.Node;
  if Assigned(it) then
    AExtended := it.Extended;
end;

procedure TTMSFNCTreeViewData.DoIsNodeVisible(ANode: TTMSFNCTreeViewVirtualNode;
  var AVisible: Boolean);
begin

end;

procedure TTMSFNCTreeViewData.DoNodeCompare(ANode1, ANode2: TTMSFNCTreeViewNode; AColumn: Integer; var ACompareResult: Integer);
begin

end;

function TTMSFNCTreeViewData.FindGroupByName(AName: String): TTMSFNCTreeViewGroup;
var
  I: Integer;
  grp: TTMSFNCTreeViewGroup;
begin
  Result := nil;
  for I := 0 to Groups.Count - 1 do
  begin
    grp := Groups[I];
    if grp.Name = AName then
    begin
      Result := grp;
      Break;
    end;
  end;
end;

function TTMSFNCTreeViewData.FindGroupIndexByName(AName: String): Integer;
var
  grp: TTMSFNCTreeViewGroup;
begin
  Result := -1;
  grp := FindGroupByName(AName);
  if Assigned(grp) then
    Result := grp.Index;
end;

function TTMSFNCTreeViewData.FindNodeByDBKey(
  ADBKey: string): TTMSFNCTreeViewNode;
var
  v: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  v := FindVirtualNodeByDBKey(ADBKey);
  if Assigned(v) then
    Result := v.Node;
end;

function TTMSFNCTreeViewData.FindNodeByRow(ARow: Integer): TTMSFNCTreeViewNode;
var
  v: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  v := FindVirtualNodeByRow(ARow);
  if Assigned(v) then
    Result := v.Node;
end;

function TTMSFNCTreeViewData.FindNodeByTextAndColumn(AText: string;
  AColumn: Integer; ARecurse, ACaseSensitive: Boolean): TTMSFNCTreeViewNode;
var
  v: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  v := FindVirtualNodeByTextAndColumn(AText, AColumn, ARecurse, ACaseSensitive);
  if Assigned(v) then
    Result := v.Node;
end;

function TTMSFNCTreeViewData.FindVirtualNodeByDBKey(
  ADBKey: string): TTMSFNCTreeViewVirtualNode;
var
  n: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  n := GetFirstRootVirtualNode;
  while Assigned(n) do
  begin
    if Assigned(n.Node) and (n.Node.DBKey = ADBKey) then
    begin
      Result := n;
      Break;
    end;
    n := n.GetNext;
  end;
end;

function TTMSFNCTreeViewData.FindVirtualNodeByRow(
  ARow: Integer): TTMSFNCTreeViewVirtualNode;
var
  n: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  n := GetFirstRootVirtualNode;
  while Assigned(n) do
  begin
    if n.Row = ARow then
    begin
      Result := n;
      Break;
    end;
    n := n.GetNext;
  end;
end;

function TTMSFNCTreeViewData.FindVirtualNodeByTextAndColumn(AText: string;
  AColumn: Integer; ARecurse,
  ACaseSensitive: Boolean): TTMSFNCTreeViewVirtualNode;
var
  n: TTMSFNCTreeViewVirtualNode;
  t: String;
begin
  Result := nil;
  n := GetFirstRootVirtualNode;
  while Assigned(n) do
  begin
    t := '';
    DoGetNodeText(n, AColumn, tntmDrawing, t);
    if TTMSFNCUtils.MatchStrEx(AText, t, ACaseSensitive) then
    begin
      Result := n;
      Break;
    end;

    if ARecurse then
      n := n.GetNext
    else
      n := n.GetNextSibling;
  end;
end;

function TTMSFNCTreeViewData.IsColumnVisible(ACol: Integer): Boolean;
begin
  Result := inherited IsColumnVisible(ACol);
  if (ACol >= 0) and (ACol <= Columns.Count - 1) then
    Result := Columns[ACol].Visible;
end;

function TTMSFNCTreeViewData.MatchFilter(ANode: TTMSFNCTreeViewVirtualNode): Boolean;
var
  i: Integer;
  s:string;
  temp: Boolean;
begin
  Result := True;
  for i := 1 to FFilter.Count do
  begin
    with FFilter.Items[i - 1] do
    begin
      s := '';
      DoGetNodeText(ANode, Column, tntmDrawing, s);
      s := TTMSFNCUtils.HTMLStrip(s);
      s := TTMSFNCUtils.UnFixMarkup(s);
      s := Trim(s);

      if Prefix <> '' then
        if Pos(Prefix,s) = 1 then
          Delete(s,1,Length(FFilter.Items[i - 1].Prefix));

      if Suffix <> '' then
        if Pos(Suffix,s) = 1 + Length(s) - Length(Suffix) then
          Delete(s,1 + Length(s) - Length(Suffix),Length(s));

      try
        temp := TTMSFNCUtils.MatchStrEx(Condition,s,CaseSensitive);
      except
        temp := false;
      end;

      case FFilter.Items[i - 1].Operation of
        tfoSHORT:
        begin
          Result := temp;
          if not Result then
            Break;
        end;
        tfoNONE: Result := temp;
        tfoAND: Result := Result AND temp;
        tfoOR:  Result := Result OR temp;
        tfoXOR: Result := Result XOR temp;
      end;
    end;
  end;
end;

procedure TTMSFNCTreeViewData.MoveNode(ANode,
  ADestinationNode: TTMSFNCTreeViewNode; AIndex: Integer);
var
  n, np: TTMSFNCTreeViewNode;
begin
  if not Assigned(ANode) then
    Exit;

  if (ADestinationNode <> ANode) then
  begin
    if Assigned(ADestinationNode) then
    begin
      np := ADestinationNode.GetParent;

      while Assigned(np) do
      begin
        if np = ANode then
          Exit;

        np := np.GetParent;
      end;
    end;

    BeginUpdate;

    n := TTMSFNCTreeviewNode.Create(nil);

    if Assigned(ADestinationNode) then
      ANode.Collection := ADestinationNode.Nodes
    else
      ANode.Collection := Nodes;

    if Assigned(ADestinationNode) then
    begin
      if (AIndex >= 0) and (AIndex <= ADestinationNode.Nodes.Count - 1) then
        ANode.Index := AIndex
      else
        ANode.Index := ADestinationNode.Nodes.Count - 1;
    end
    else
    begin
      if (AIndex >= 0) and (AIndex <= Nodes.Count - 1) then
        ANode.Index := AIndex
      else
        ANode.Index := Nodes.Count - 1;
    end;

    n.Free;

    EndUpdate;
  end;
end;

procedure TTMSFNCTreeViewData.ToggleCheckNodeInternal(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ARecurse: Boolean = False);
var
  chk: Boolean;
begin
  if (csDestroying in ComponentState) or not Assigned(ANode) or not NodeListBuild then
    Exit;

  if (AColumn >= 0) and (AColumn <= Length(ANode.CheckStates) - 1) then
  begin
    chk := ANode.CheckStates[AColumn];
    if chk then
      UnCheckNodeInternal(ANode, AColumn, ARecurse)
    else
      CheckNodeInternal(ANode, AColumn, ARecurse);
  end;
end;

procedure TTMSFNCTreeViewData.ToggleNodeInternal(ANode: TTMSFNCTreeViewVirtualNode; ARecurse: Boolean = False);
begin
  if (csDestroying in ComponentState) or not Assigned(ANode) or not NodeListBuild then
    Exit;

  if ANode.Expanded then
    CollapseNodeInternal(ANode, ARecurse)
  else
    ExpandNodeInternal(ANode, ARecurse);
end;

procedure TTMSFNCTreeViewData.UnCheckNodeInternal(ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; ARecurse: Boolean = False);
var
  chkt: TTMSFNCTreeViewNodeCheckType;
  I: Integer;
  v: TTMSFNCTreeViewVirtualNode;
begin
  if (csDestroying in ComponentState) or not Assigned(ANode) or not NodeListBuild then
    Exit;

  chkt := tvntNone;
  DoGetNodeCheckType(ANode, AColumn, chkt);
  if chkt = tvntCheckBox then
  begin
    if (AColumn >= 0) and (AColumn <= Length(ANode.CheckStates) - 1) then
    begin
      ANode.CheckStates[AColumn] := False;
      UpdateCheckState(ANode, AColumn, ANode.CheckStates[AColumn]);
    end;

    if Assigned(ANode.Node) then
    begin
      if (AColumn >= 0) and (AColumn <= ANode.Node.Values.Count - 1) then
        ANode.Node.Values.UpdateChecked(AColumn, False);
    end;
  end;

  if ARecurse then
  begin
    for I := ANode.Row + 1 to ANode.Row + ANode.TotalChildren do
    begin
      v := GetNodeFromNodeStructure(I);
      if Assigned(v) then
        UnCheckNodeInternal(v, AColumn, ARecurse);
    end;
  end;

  Invalidate;
end;


procedure TTMSFNCTreeViewData.UpdateNodeCalculated(ANode: TTMSFNCTreeViewVirtualNode; ACalculated: Boolean);
begin
  ANode.FCalculated := ACalculated;
end;

function TTMSFNCTreeViewData.FindColumnByName(
  AName: String): TTMSFNCTreeViewColumn;
var
  I: Integer;
  res: TTMSFNCTreeViewColumn;
begin
  Result := nil;
  for I := 0 to ColumnCount - 1 do
  begin
    if (I >= 0) and (I <= Columns.Count - 1) then
    begin
      res := Columns[I];
      if res.Name = AName then
      begin
        Result := res;
        Break;
      end;
    end;
  end;
end;

function TTMSFNCTreeViewData.FindColumnIndexByName(AName: String): Integer;
var
  res: TTMSFNCTreeViewColumn;
begin
  Result := -1;
  res := FindColumnByName(AName);
  if Assigned(res) then
    Result := res.Index;
end;

procedure TTMSFNCTreeViewData.SetGroups(const Value: TTMSFNCTreeViewGroups);
begin
  FGroups.Assign(Value);
end;

procedure TTMSFNCTreeViewData.SetNodes(const Value: TTMSFNCTreeViewNodes);
begin
  FNodes.Assign(Value);
end;

procedure TTMSFNCTreeViewData.UpdateCheckState(
  ANode: TTMSFNCTreeViewVirtualNode; AColumn: Integer; AChecked: Boolean);
begin

end;

procedure TTMSFNCTreeViewData.UpdateColumns;
var
  I: Integer;
  c: TTMSFNCTreeViewColumn;
  g: TTMSFNCTreeViewGroup;
  grp: TTMSFNCTreeViewDisplayGroup;
begin
  if (UpdateCount > 0) or (csDestroying in ComponentState) then
    Exit;

  DisplayGroups.Clear;
  for I := 0 to Groups.Count - 1 do
  begin
    g := Groups[I];
    grp.StartColumn := Max(0, Min(g.StartColumn, Columns.Count - 1));
    grp.EndColumn := Max(0, Min(g.EndColumn, Columns.Count - 1));
    DisplayGroups.Add(grp);
  end;

  for I := 0 to Columns.Count - 1 do
  begin
    c := Columns[I];
    if c.Visible then
    begin
      ColumnWidths[I] := c.Width;
      ColumnMinWidths[I] := c.MinimumWidth;
    end
    else
    begin
      ColumnWidths[I] := 0;
      ColumnMinWidths[I] := 0;
    end;
  end;
end;

procedure TTMSFNCTreeViewData.UpdateNode(ANode: TTMSFNCTreeViewVirtualNode);
begin
  if not Assigned(ANode) or (csDestroying in ComponentState) or not NodeListBuild then
    Exit;

  if BlockUpdateNode then
    Exit;

  ANode.FCalculated := False;

  if UpdateCount > 0 then
    Exit;

  UpdateNodesCache;
  Invalidate;
end;

procedure TTMSFNCTreeViewData.UpdateNodeBitmapRects(
  ANode: TTMSFNCTreeViewVirtualNode; ABitmapRects: TTMSFNCArrayTRectF);
begin
  ANode.FBitmapRects := ABitmapRects;
end;

procedure TTMSFNCTreeViewData.UpdateNodeCacheReference(
  ANode: TTMSFNCTreeViewVirtualNode; ACache: TTMSFNCTreeViewCacheItem);
begin
  ANode.FCache := ACache;
end;

procedure TTMSFNCTreeViewData.UpdateNodeCheckRects(
  ANode: TTMSFNCTreeViewVirtualNode; ACheckRects: TTMSFNCArrayTRectF);
begin
  ANode.FCheckRects := ACheckRects;
end;

procedure TTMSFNCTreeViewData.UpdateNodeCheckStates(
  ANode: TTMSFNCTreeViewVirtualNode; ACheckStates: TTMSFNCArrayBoolean);
begin
  ANode.FCheckStates := ACheckStates;
end;

procedure TTMSFNCTreeViewData.UpdateNodeChildren(ANode: TTMSFNCTreeViewVirtualNode;
  AChildren: Integer);
begin
  ANode.FChildren := AChildren;
end;

procedure TTMSFNCTreeViewData.UpdateNodeExpanded(ANode: TTMSFNCTreeViewVirtualNode;
  AExpanded: Boolean);
begin
  ANode.FExpanded := AExpanded;
  if Assigned(ANode.Node) then
    ANode.Node.FExpanded := AExpanded;
end;

procedure TTMSFNCTreeViewData.UpdateNodeExpandRects(
  ANode: TTMSFNCTreeViewVirtualNode; AExpandRects: TTMSFNCArrayTRectF);
begin
  ANode.FExpandRects := AExpandRects;
end;

procedure TTMSFNCTreeViewData.UpdateNodeExtended(
  ANode: TTMSFNCTreeViewVirtualNode; AExtended: Boolean);
begin
  ANode.FExtended := AExtended;
end;

procedure TTMSFNCTreeViewData.UpdateNodeExtraRects(
  ANode: TTMSFNCTreeViewVirtualNode; AExtraRects: TTMSFNCArrayTRectF);
begin
  ANode.FExtraRects := AExtraRects;
end;

procedure TTMSFNCTreeViewData.UpdateNodeHeight(ANode: TTMSFNCTreeViewVirtualNode;
  AHeight: Double);
begin
  ANode.FHeight := AHeight;
end;

procedure TTMSFNCTreeViewData.ExpandNodeInternal(ANode: TTMSFNCTreeViewVirtualNode; ARecurse: Boolean = False);
var
  I: Integer;
  v, n: TTMSFNCTreeViewVirtualNode;
begin
  if (csDestroying in ComponentState) or not Assigned(ANode) then
    Exit;

  if ANode.Expanded then
  begin
    if ARecurse then
    begin
      n := ANode.GetFirstChild;
      while Assigned(n) do
      begin
        ExpandNodeInternal(n, ARecurse);
        n := n.GetNextSibling;
      end;
    end;

    Exit;
  end;

  ANode.FExpanded := True;

  UpdateNodeExpanded(ANode, True);
  if ARecurse then
  begin
    for I := ANode.Row + 1 to ANode.Row + ANode.TotalChildren do
    begin
      v := GetNodeFromNodeStructure(I);
      if Assigned(v) then
        UpdateNodeExpanded(v, True);
    end;
  end;
  UpdateVisibleNodes(ANode);
  if not FBlockUpdate then
  begin
    ResetNodes(False);
    UpdateTreeView;
    Invalidate;
  end;
end;

procedure TTMSFNCTreeViewData.UpdateNodeIndex(ANode: TTMSFNCTreeViewVirtualNode;
  AIndex: Integer);
begin
  ANode.FIndex := AIndex;
end;

procedure TTMSFNCTreeViewData.UpdateNodeLevel(ANode: TTMSFNCTreeViewVirtualNode;
  ALevel: Integer);
begin
  ANode.FLevel := ALevel;
end;

procedure TTMSFNCTreeViewData.UpdateNodeParentNode(
  ANode: TTMSFNCTreeViewVirtualNode; AParentNode: Integer);
begin
  ANode.FParentNode := AParentNode;
end;

procedure TTMSFNCTreeViewData.UpdateNodeRow(ANode: TTMSFNCTreeViewVirtualNode;
  ARow: Integer);
begin
  ANode.FRow := ARow;
end;

procedure TTMSFNCTreeViewData.UpdateNodesInternal(ANodeRemoveData: TTMSFNCTreeViewVirtualNodeRemoveData);
var
  cnt: Integer;
  I, pi, R: Integer;
  rs: TTMSFNCTreeViewVirtualNode;
  ext, exp: Boolean;
  chkst: TTMSFNCArrayBoolean;
  K: Integer;
  chk: Boolean;
begin
  cnt := ANodeRemoveData.Count;
  pi := ANodeRemoveData.ParentNode;
  R := ANodeRemoveData.RowIndex;

  for I := R to NodeStructure.Count - 1 do
  begin
    rs := GetNodeFromNodeStructure(I);
    if Assigned(rs) then
    begin
      UpdateNodeRow(rs, rs.Row - cnt);

      if rs.ParentNode > pi then
        UpdateNodeParentNode(rs, rs.ParentNode - cnt);

      if rs.ParentNode = pi then
        UpdateNodeIndex(rs, rs.Index - 1);

      GetNodeForNodeData(rs);

      exp := False;
      DoIsNodeExpanded(rs, exp);
      UpdateNodeExpanded(rs, exp);

      ext := False;
      DoIsNodeExtended(rs, ext);
      UpdateNodeExtended(rs, ext);

      SetLength(chkst, 0);
      for K := 0 to ColumnCount - 1 do
      begin
        chk := False;
        DoIsNodeChecked(RS, K, chk);
        SetLength(chkst, Length(chkst) + 1);
        chkst[Length(chkst) - 1] := chk;
      end;

      UpdateNodeCheckStates(rs, chkst);
    end;
  end;

  if not FBlockUpdate then
  begin
    ResetNodes(False);
    UpdateTreeView;
    Invalidate;
  end;
end;

procedure TTMSFNCTreeViewData.UpdateNodeTextRects(
  ANode: TTMSFNCTreeViewVirtualNode; ATextRects: TTMSFNCArrayTRectF);
begin
  ANode.FTextRects := ATextRects;
end;

procedure TTMSFNCTreeViewData.UpdateNodeTitleHeight(
  ANode: TTMSFNCTreeViewVirtualNode; AHeight: Double);
begin
  ANode.FTitleHeight := AHeight;
end;

procedure TTMSFNCTreeViewData.UpdateNodeTitleRects(
  ANode: TTMSFNCTreeViewVirtualNode; ATitleRects: TTMSFNCArrayTRectF);
begin
  ANode.FTitleRects := ATitleRects;
end;

procedure TTMSFNCTreeViewData.UpdateNodeTitleExtraRects(
  ANode: TTMSFNCTreeViewVirtualNode; ATitleExtraRects: TTMSFNCArrayTRectF);
begin
  ANode.FTitleExtraRects := ATitleExtraRects;
end;

procedure TTMSFNCTreeViewData.UpdateNodeTotalChildren(
  ANode: TTMSFNCTreeViewVirtualNode; ATotalChildren: Integer);
begin
  ANode.FTotalChildren := ATotalChildren;
end;

procedure TTMSFNCTreeViewData.ScrollToBottom;
begin
  {$IFDEF FMXLIB}
  VerticalScrollBar.Value := VerticalScrollBar.Max;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  VerticalScrollBar.Position := VerticalScrollBar.Max;
  {$ENDIF}
end;

procedure TTMSFNCTreeViewData.ScrollToNode(ANode: TTMSFNCTreeViewNode; AScrollIfNotVisible: Boolean = False; AScrollPosition: TTMSFNCTreeViewNodeScrollPosition = tvnspTop; AForceScroll: Boolean = False);
begin
  if Assigned(ANode) then
    ScrollToVirtualNode(ANode.VirtualNode, AScrollIfNotVisible, AScrollPosition, AForceScroll);
end;

procedure TTMSFNCTreeViewData.ScrollToTop;
begin
  {$IFDEF FMXLIB}
  VerticalScrollBar.Value := 0;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  VerticalScrollBar.Position := 0;
  {$ENDIF}
end;

procedure TTMSFNCTreeViewData.ScrollToVirtualNode(
  ANode: TTMSFNCTreeViewVirtualNode; AScrollIfNotVisible: Boolean = False; AScrollPosition: TTMSFNCTreeViewNodeScrollPosition = tvnspTop; AForceScroll: Boolean = False);
var
  v: Single;
begin
  if not Assigned(ANode) then
    Exit;

  if IsVariableNodeHeight then
  begin
//    BeginUpdate;
    v := -1;
    while not ANode.Calculated and (v <> GetVerticalScrollPosition) and (ANode.Expanded or (ANode.Children = 0)) do
    begin
      ScrollToVirtualNodeInternal(ANode, AScrollIfNotVisible, AScrollPosition, AForceScroll);
      v := GetVerticalScrollPosition;
    end;
//    EndUpdate;
  end;

  ScrollToVirtualNodeInternal(ANode, AScrollIfNotVisible, AScrollPosition, AForceScroll);
end;

procedure TTMSFNCTreeViewData.ScrollToVirtualNodeInternal(ANode: TTMSFNCTreeViewVirtualNode; AScrollIfNotVisible: Boolean = False;
  AScrollPosition: TTMSFNCTreeViewNodeScrollPosition = tvnspTop; AForceScroll: Boolean = False);
var
  I, C: Integer;
  yp, ps: Double;
  v: TTMSFNCTreeViewVirtualNode;
begin
  yp := 0;
  c := 0;
  if Assigned(ANode) then
  begin
    for I := 0 to VisibleNodes.Count - 1 do
    begin
      v := VisibleNodes[I];
      if Assigned(v) then
      begin
        if v.Row = ANode.Row then
          Break;

        if v.Calculated then
          yp := yp + v.Height
        else
          Inc(c);
      end;
    end;

    yp := yp + DefaultRowHeight * c;
    ps := yp;
    case AScrollPosition of
      tvnspBottom: yp := yp - GetVViewPortSize + ANode.Height;
      tvnspMiddle: yp := yp - GetVViewPortSize / 2 + ANode.Height / 2;
    end;

    CustomizeScrollPosition(ANode, yp, ps);

    if AScrollIfNotVisible then
    begin
      if ((ps < Abs(GetVScrollValue)) or (ps + ANode.Height > Abs(GetVScrollValue) + GetVViewPortSize)) or AForceScroll then
        Scroll(GetHScrollValue, yp);
    end
    else
    begin
      Scroll(GetHScrollValue, yp);
    end;
  end;
end;

function TTMSFNCTreeViewData.GetNodeForRow(
  ARow: Integer): TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if (ARow >= 0) and (ARow <= FNodeStructure.Count - 1) then
    Result := FNodeStructure[ARow];
end;

procedure TTMSFNCTreeViewData.ScrollToVirtualNodeRow(ARow: Integer;
  AScrollIfNotVisible: Boolean;
  AScrollPosition: TTMSFNCTreeViewNodeScrollPosition; AForceScroll: Boolean);
var
  n: TTMSFNCTreeViewVirtualNode;
begin
  n := GetNodeForRow(ARow);
  if Assigned(n) then
    ScrollToVirtualNode(n, AScrollIfNotVisible, AScrollPosition, AForceScroll);
end;

procedure TTMSFNCTreeViewData.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
  Invalidate;
end;

procedure TTMSFNCTreeViewData.SetColumns(const Value: TTMSFNCTreeViewColumns);
begin
  FColumns.Assign(Value);
end;

{ TTMSFNCTreeViewGroup }

procedure TTMSFNCTreeViewGroup.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCTreeViewGroup then
  begin
    FTag := (Source as TTMSFNCTreeViewGroup).Tag;
    FText := (Source as TTMSFNCTreeViewGroup).Text;
    FStartColumn := (Source as TTMSFNCTreeViewGroup).StartColumn;
    FEndColumn := (Source as TTMSFNCTreeViewGroup).EndColumn;
    FUseDefaultAppearance := (Source as TTMSFNCTreeViewGroup).UseDefaultAppearance;
    FBottomFill.Assign((Source as TTMSFNCTreeViewGroup).BottomFill);
    FBottomFont.Assign((Source as TTMSFNCTreeViewGroup).BottomFont);
    FBottomStroke.Assign((Source as TTMSFNCTreeViewGroup).BottomStroke);
    FTopFill.Assign((Source as TTMSFNCTreeViewGroup).TopFill);
    FTopStroke.Assign((Source as TTMSFNCTreeViewGroup).TopStroke);
    FTopFont.Assign((Source as TTMSFNCTreeViewGroup).TopFont);
  end;
end;

procedure TTMSFNCTreeViewGroup.Changed(Sender: TObject);
begin
  UpdateGroup;
end;

constructor TTMSFNCTreeViewGroup.Create(ACollection: TCollection);
var
  grp: TTMSFNCTreeViewGroup;
begin
  inherited;
  if Assigned(Collection) then
  begin
    FTreeView := (Collection as TTMSFNCTreeViewGroups).TreeView;
    FText := TranslateTextEx(sTMSFNCTreeViewGroup) + ' ' + inttostr(FTreeView.Groups.Count - 1);
    if FTreeView.Groups.Count < 2 then
    begin
      FStartColumn := 0;
      FEndColumn := 0
    end
    else
    begin
      grp := FTreeView.Groups[FTreeView.Groups.Count - 2];
      FStartColumn := grp.StartColumn + 1;
      FEndColumn := FStartColumn;
    end;
  end;

  FUseDefaultAppearance := True;

  FBottomFill := TTMSFNCGraphicsFill.Create(gfkNone, gcWhite);
  FBottomStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);
  FTopFill := TTMSFNCGraphicsFill.Create(gfkNone, gcWhite);
  FTopStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcGray);

  FTopFont := TTMSFNCGraphicsFont.Create;
  FBottomFont := TTMSFNCGraphicsFont.Create;

  FBottomFill.OnChanged := @Changed;
  FTopFill.OnChanged := @Changed;
  FBottomStroke.OnChanged := @Changed;
  FTopStroke.OnChanged := @Changed;

  {$IFDEF FMXLIB}
  FTopFont.OnChanged := @Changed;
  FBottomFont.OnChanged := @Changed;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  FTopFont.OnChange := @Changed;
  FBottomFont.OnChange := @Changed;
  {$ENDIF}

  UpdateGroup;
end;

destructor TTMSFNCTreeViewGroup.Destroy;
begin
  FTopFill.Free;
  FTopStroke.Free;
  FTopFont.Free;
  FBottomFill.Free;
  FBottomStroke.Free;
  FBottomFont.Free;
  inherited;
  UpdateGroup;
end;

function TTMSFNCTreeViewGroup.GetText: String;
begin
  Result  := Text;
  if Result = '' then
  begin
    Result := Name;
    if Result = '' then
      Result := TranslateTextEx(sTMSFNCTreeViewGroup) + ' ' + inttostr(Index);
  end;
end;

function TTMSFNCTreeViewGroup.TreeView: TTMSFNCTreeViewData;
begin
  Result := FTreeView;
end;

procedure TTMSFNCTreeViewGroup.SetStartColumn(const Value: Integer);
begin
  if FStartColumn <> Value then
  begin
    FStartColumn := Value;
    UpdateGroup;
  end;
end;

procedure TTMSFNCTreeViewGroup.SetEndColumn(const Value: Integer);
begin
  if FEndColumn <> Value then
  begin
    FEndColumn := Value;
    UpdateGroup;
  end;
end;

procedure TTMSFNCTreeViewGroup.SetBottomFont(const Value: TTMSFNCGraphicsFont);
begin
  if FBottomFont <> Value then
    FBottomFont.Assign(Value);
end;

procedure TTMSFNCTreeViewGroup.SetBottomStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FBottomStroke <> Value then
    FBottomStroke.Assign(Value);
end;

procedure TTMSFNCTreeViewGroup.SetBottomFill(const Value: TTMSFNCGraphicsFill);
begin
  if FBottomFill <> Value then
    FBottomFill.Assign(Value);
end;

procedure TTMSFNCTreeViewGroup.SetTopFont(const Value: TTMSFNCGraphicsFont);
begin
  if FTopFont <> Value then
    FTopFont.Assign(Value);
end;

procedure TTMSFNCTreeViewGroup.SetTopStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FTopStroke <> Value then
    FTopStroke.Assign(Value);
end;

procedure TTMSFNCTreeViewGroup.SetUseDefaultAppearance(const Value: Boolean);
begin
  if FUseDefaultAppearance <> Value then
  begin
    FUseDefaultAppearance := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCTreeViewGroup.SetTopFill(const Value: TTMSFNCGraphicsFill);
begin
  if FTopFill <> Value then
    FTopFill.Assign(Value);
end;

procedure TTMSFNCTreeViewGroup.SetName(const Value: String);
begin
  if FName <> Value then
  begin
    FName := Value;
  end;
end;

procedure TTMSFNCTreeViewGroup.SetText(const Value: String);
begin
  if FText <> Value then
  begin
    FText := Value;
    UpdateGroup;
  end;
end;

procedure TTMSFNCTreeViewGroup.UpdateGroup;
var
  c: TTMSFNCTreeViewData;
begin
  c := TreeView;
  if Assigned(c) then
  begin
    c.UpdateColumns;
    c.UpdateTreeViewCache;
  end;
end;

{ TTMSFNCTreeViewGroups }

function TTMSFNCTreeViewGroups.Add: TTMSFNCTreeViewGroup;
begin
  Result := TTMSFNCTreeViewGroup(inherited Add);
end;

constructor TTMSFNCTreeViewGroups.Create(ATreeView: TTMSFNCTreeViewData);
begin
  inherited Create(ATreeView, GetItemClass);
  FTreeView := ATreeView;
end;

function TTMSFNCTreeViewGroups.GetItem(Index: Integer): TTMSFNCTreeViewGroup;
begin
  Result := TTMSFNCTreeViewGroup(inherited Items[Index]);
end;

function TTMSFNCTreeViewGroups.GetItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCTreeViewGroup;
end;

function TTMSFNCTreeViewGroups.Insert(Index: Integer): TTMSFNCTreeViewGroup;
begin
  Result := TTMSFNCTreeViewGroup(inherited Insert(Index));
end;

function TTMSFNCTreeViewGroups.TreeView: TTMSFNCTreeViewData;
begin
  Result := FTreeView;
end;

procedure TTMSFNCTreeViewGroups.SetItem(Index: Integer;
  const Value: TTMSFNCTreeViewGroup);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCTreeViewNode }

function TTMSFNCTreeViewNode.SaveToString(ATextOnly: Boolean = True): String;
const
  TabChar = #9;
var
  vn: TTMSFNCTreeViewVirtualNode;
  I: Integer;
begin
  if not Assigned(FTreeView) then
    Exit;

  vn := VirtualNode;
  Result := '';
  for I := 0 to vn.Level - 1 do
    Result := Result + TabChar;

  for I := 0 to FTreeView.ColumnCount - 1 do
  begin
    if ATextOnly then
    begin
      if I = FTreeView.ColumnCount - 1 then
        Result := Result + GetStrippedHTMLText(I)
      else
        Result := Result + GetStrippedHTMLText(I)+'{1}'
    end
    else
    begin
      if I = FTreeView.ColumnCount - 1 then
        Result := Result + Text[I]
      else
        Result := Result + Text[I]+'{1}'
    end;
  end;

  if not ATextOnly then
    Result := Result + '{0}';

  for I := 0 to FTreeView.ColumnCount - 1 do
  begin
    if not ATextOnly then
    begin
      Result := Result + IntToStr(Integer(CheckTypes[I])) + ';';
      Result := Result + BoolToStr(Checked[I]) + ';';
      Result := Result + ExpandedIconNames[I, False] + ';';
      Result := Result + ExpandedIconNames[I, True] + ';';
      Result := Result + CollapsedIconNames[I, False] + ';';
      Result := Result + CollapsedIconNames[I, True] + ';';
      Result := Result + TTMSFNCUtils.SaveBitmapToHexStr(ExpandedIcons[I, False]) + ';';
      Result := Result + TTMSFNCUtils.SaveBitmapToHexStr(ExpandedIcons[I, True]) + ';';
      Result := Result + TTMSFNCUtils.SaveBitmapToHexStr(CollapsedIcons[I, False]) + ';';
      if I = Values.Count - 1 then
        Result := Result + TTMSFNCUtils.SaveBitmapToHexStr(CollapsedIcons[I, True])
      else
        Result := Result + TTMSFNCUtils.SaveBitmapToHexStr(CollapsedIcons[I, True]) + '|';
    end;
  end;

  if not ATextOnly then
  begin
    Result := Result + '{0}';
    Result := Result + BoolToStr(Extended) + '{0}';
    Result := Result + BoolToStr(Enabled);
  end;
end;

procedure TTMSFNCTreeViewNode.SetChecked(AColumn: integer;
  const Value: Boolean);
var
  v: TTMSFNCTreeViewNodeValue;
begin
  v := GetValueForColumn(AColumn);
  if Assigned(v) then
    v.Checked := Value;
end;

procedure TTMSFNCTreeViewNode.SetCheckStates(
  ACheckStates: TTMSFNCTreeViewNodeCheckStates);
var
  I: Integer;
begin
  for I := 0 to Length(ACheckStates) - 1 do
    Checked[I] := ACheckStates[I];
end;

procedure TTMSFNCTreeViewNode.SetCheckType(AColumn: Integer;
  const Value: TTMSFNCTreeViewNodeCheckType);
var
  v: TTMSFNCTreeViewNodeValue;
begin
  v := GetValueForColumn(AColumn);
  if Assigned(v) then
    v.CheckType := Value;
end;

procedure TTMSFNCTreeViewNode.SetCheckTypes(
  ACheckTypes: TTMSFNCTreeViewNodeCheckTypes);
var
  I: Integer;
begin
  for I := 0 to Length(ACheckTypes) - 1 do
    CheckTypes[I] := ACheckTypes[I];
end;

procedure TTMSFNCTreeViewNode.SetCollapsedIconNames(
  AIconNames: TTMSFNCTreeViewNodeIconNames; ALarge: Boolean);
var
  I: Integer;
begin
  for I := 0 to Length(AIconNames) - 1 do
    CollapsedIconNames[I, ALarge] := AIconNames[I];
end;

procedure TTMSFNCTreeViewNode.SetCollapsedIcons(AIcons: TTMSFNCTreeViewNodeIcons;
  ALarge: Boolean);
var
  I: Integer;
begin
  for I := 0 to Length(AIcons) - 1 do
    CollapsedIcons[I, ALarge] := AIcons[I];
end;

procedure TTMSFNCTreeViewNode.SetCollapsedIcon(AColumn: Integer;
  ALarge: Boolean; const Value: TTMSFNCBitmap);
var
  v: TTMSFNCTreeViewNodeValue;
begin
  v := GetValueForColumn(AColumn);
  if Assigned(v) then
  begin
    if ALarge then
      v.CollapsedIconLarge.Assign(Value)
    else
      v.CollapsedIcon.Assign(Value);
  end;
end;

procedure TTMSFNCTreeViewNode.SetCollapsedIconName(AColumn: Integer;
  ALarge: Boolean; const Value: String);
var
  v: TTMSFNCTreeViewNodeValue;
begin
  v := GetValueForColumn(AColumn);
  if Assigned(v) then
  begin
    if ALarge then
      v.CollapsedIconLargeName := Value
    else
      v.CollapsedIconName := Value;
  end;
end;

procedure TTMSFNCTreeViewNode.SetExpandedIconNames(
  AIconNames: TTMSFNCTreeViewNodeIconNames; ALarge: Boolean);
var
  I: Integer;
begin
  for I := 0 to Length(AIconNames) - 1 do
    ExpandedIconNames[I, ALarge] := AIconNames[I];
end;

procedure TTMSFNCTreeViewNode.SetExpandedIcons(AIcons: TTMSFNCTreeViewNodeIcons;
  ALarge: Boolean);
var
  I: Integer;
begin
  for I := 0 to Length(AIcons) - 1 do
    ExpandedIcons[I, ALarge] := AIcons[I];
end;

procedure TTMSFNCTreeViewNode.SetExpandedIcon(AColumn: Integer;
  ALarge: Boolean; const Value: TTMSFNCBitmap);
var
  v: TTMSFNCTreeViewNodeValue;
begin
  v := GetValueForColumn(AColumn);
  if Assigned(v) then
  begin
    if ALarge then
      v.ExpandedIconLarge.Assign(Value)
    else
      v.ExpandedIcon.Assign(Value);
  end;
end;

procedure TTMSFNCTreeViewNode.SetExpandedIconName(AColumn: Integer;
  ALarge: Boolean; const Value: String);
var
  v: TTMSFNCTreeViewNodeValue;
begin
  v := GetValueForColumn(AColumn);
  if Assigned(v) then
  begin
    if ALarge then
      v.ExpandedIconLargeName := Value
    else
      v.ExpandedIconName := Value;
  end;
end;

procedure TTMSFNCTreeViewNode.SetText(AColumn: Integer; const Value: String);
var
  v: TTMSFNCTreeViewNodeValue;
begin
  v := GetValueForColumn(AColumn);
  if Assigned(v) then
    v.Text := Value;
end;

procedure TTMSFNCTreeViewNode.SetTextValues(
  ATextValues: TTMSFNCTreeViewNodeTextValues);
var
  I: Integer;
begin
  for I := 0 to Length(ATextValues) - 1 do
    Text[I] := ATextValues[I];
end;

procedure TTMSFNCTreeViewNode.AssignData(Source: TPersistent);
begin
  if Source is TTMSFNCTreeViewNode then
  begin
    FDataString := (Source as TTMSFNCTreeViewNode).DataString;
    FDataBoolean := (Source as TTMSFNCTreeViewNode).DataBoolean;
    FDataObject := (Source as TTMSFNCTreeViewNode).DataObject;
    FDataInteger := (Source as TTMSFNCTreeViewNode).DataInteger;
    FDataPointer := (Source as TTMSFNCTreeViewNode).DataPointer;
    FDBKey := (Source as TTMSFNCTreeViewNode).DBKey;
  end;
end;

procedure TTMSFNCTreeViewNode.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCTreeViewNode then
  begin
    FTag := (Source as TTMSFNCTreeViewNode).Tag;
    FValues.Assign((Source as TTMSFNCTreeViewNode).Values);
    FExpanded := (Source as TTMSFNCTreeViewNode).Expanded;
    FExtended := (Source as TTMSFNCTreeViewNode).Extended;
    FEnabled := (Source as TTMSFNCTreeViewNode).Enabled;
    FNodes.Assign((Source as TTMSFNCTreeViewNode).Nodes);
    AssignData(Source);
    if Assigned(FTreeView) then
    begin
      if FExpanded then
        FTreeView.ExpandNode(Self)
      else
        FTreeView.CollapseNode(Self);
    end;
  end;
end;

procedure TTMSFNCTreeViewNode.Collapse(ARecurse: Boolean);
begin
  if Assigned(FTreeView) then
    FTreeView.CollapseNode(Self, ARecurse);
end;

constructor TTMSFNCTreeViewNode.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(Collection) then
    FTreeView := (Collection as TTMSFNCTreeViewNodes).TreeView;
  FValues := CreateNodeValues;

  FExpanded := False;
  FExtended := False;
  FEnabled := True;
  FNodes := CreateNodes;
  if Assigned(FTreeView) then
  begin
    if FTreeView.NodeListBuild then
      FTreeView.InsertItemInternal(Self)
    else
      FTreeView.UpdateTreeViewCache;
  end;
end;

function TTMSFNCTreeViewNode.CreateNodes: TTMSFNCTreeViewNodes;
begin
  Result := TTMSFNCTreeViewNodes.Create(FTreeView, Self);
end;

function TTMSFNCTreeViewNode.CreateNodeValues: TTMSFNCTreeViewNodeValues;
begin
  Result := TTMSFNCTreeViewNodeValues.Create(FTreeView, Self);
end;

destructor TTMSFNCTreeViewNode.Destroy;
var
  ru: TTMSFNCTreeViewVirtualNodeRemoveData;
begin
  ru.RowIndex := -1;
  ru.Count := 0;
  ru.ParentNode := -1;
  if Assigned(FTreeView) then
  begin
    if FTreeView.NodeListBuild and not (FTreeView.BlockRemoveNode > 0) then
      ru := FTreeView.RemoveItemInternal(Self, False);
  end;
  FVirtualNode := nil;

  if Assigned(FTreeView) then
    FTreeView.BlockRemoveNode := FTreeView.BlockRemoveNode + 1;
  FNodes.Free;
  if Assigned(FTreeView) then
    FTreeView.BlockRemoveNode := FTreeView.BlockRemoveNode - 1;
  FValues.Free;

  inherited;

  if (ru.RowIndex <> -1) then
    FTreeView.UpdateNodesInternal(ru);

  if Assigned(FTreeView) then
    FTreeView.UpdateTreeViewCache;
end;

procedure TTMSFNCTreeViewNode.Expand(ARecurse: Boolean);
begin
  if Assigned(FTreeView) then
    FTreeView.ExpandNode(Self, ARecurse);
end;

function TTMSFNCTreeViewNode.GetChecked(AColumn: integer): Boolean;
var
  v: TTMSFNCTreeViewNodeValue;
begin
  Result := False;
  v := GetValueForColumn(AColumn);
  if Assigned(v) then
    Result := v.Checked;
end;

function TTMSFNCTreeViewNode.GetCheckType(
  AColumn: Integer): TTMSFNCTreeViewNodeCheckType;
var
  v: TTMSFNCTreeViewNodeValue;
begin
  Result := tvntNone;
  v := GetValueForColumn(AColumn);
  if Assigned(v) then
    Result := v.CheckType;
end;

function TTMSFNCTreeViewNode.GetChildCount: Integer;
begin
  Result := 0;
  if Assigned(FTreeView) then
    Result := FTreeView.GetNodeChildCount(Self);
end;

function TTMSFNCTreeViewNode.GetCollapsedIcon(AColumn: Integer;
  ALarge: Boolean): TTMSFNCBitmap;
var
  v: TTMSFNCTreeViewNodeValue;
begin
  Result := nil;
  v := GetValueForColumn(AColumn);
  if Assigned(v) then
  begin
    if ALarge then
      Result := v.CollapsedIconLarge
    else
      Result := v.CollapsedIcon;
  end;
end;

function TTMSFNCTreeViewNode.GetCollapsedIconName(AColumn: Integer;
  ALarge: Boolean): String;
var
  v: TTMSFNCTreeViewNodeValue;
begin
  Result := '';
  v := GetValueForColumn(AColumn);
  if Assigned(v) then
  begin
    if ALarge then
      Result := v.CollapsedIconLargeName
    else
      Result := v.CollapsedIconName;
  end;
end;

function TTMSFNCTreeViewNode.GetExpandedIcon(AColumn: Integer;
  ALarge: Boolean): TTMSFNCBitmap;
var
  v: TTMSFNCTreeViewNodeValue;
begin
  Result := nil;
  v := GetValueForColumn(AColumn);
  if Assigned(v) then
  begin
    if ALarge then
      Result := v.ExpandedIconLarge
    else
      Result := v.ExpandedIcon;
  end;
end;

function TTMSFNCTreeViewNode.GetExpandedIconName(AColumn: Integer;
  ALarge: Boolean): string;
var
  v: TTMSFNCTreeViewNodeValue;
begin
  Result := '';
  v := GetValueForColumn(AColumn);
  if Assigned(v) then
  begin
    if ALarge then
      Result := v.ExpandedIconLargeName
    else
      Result := v.ExpandedIconName;
  end;
end;

function TTMSFNCTreeViewNode.GetFirstChild: TTMSFNCTreeViewNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.GetFirstChildNode(Self);
end;

function TTMSFNCTreeViewNode.GetLastChild: TTMSFNCTreeViewNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.GetLastChildNode(Self);
end;

function TTMSFNCTreeViewNode.GetNext: TTMSFNCTreeViewNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.GetNextNode(Self);
end;

function TTMSFNCTreeViewNode.GetNextChild(ANode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.GetNextChildNode(Self, ANode);
end;

function TTMSFNCTreeViewNode.GetNextSibling: TTMSFNCTreeViewNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.GetNextSiblingNode(Self);
end;

function TTMSFNCTreeViewNode.GetParent: TTMSFNCTreeViewNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.GetParentNode(Self);
end;

function TTMSFNCTreeViewNode.GetPrevious: TTMSFNCTreeViewNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.GetPreviousNode(Self);
end;

function TTMSFNCTreeViewNode.GetPreviousChild(ANode: TTMSFNCTreeViewNode): TTMSFNCTreeViewNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.GetPreviousChildNode(Self, ANode);
end;

function TTMSFNCTreeViewNode.GetPreviousSibling: TTMSFNCTreeViewNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.GetPreviousSiblingNode(Self);
end;

function TTMSFNCTreeViewNode.GetStrippedHTMLText(AColumn: Integer): String;
begin
  Result := TTMSFNCUtils.HTMLStrip(Text[AColumn]);
end;

function TTMSFNCTreeViewNode.GetText(AColumn: Integer): String;
var
  v: TTMSFNCTreeViewNodeValue;
begin
  Result := '';
  v := GetValueForColumn(AColumn);
  if Assigned(v) then
    Result := v.Text;
end;

function TTMSFNCTreeViewNode.GetValueForColumn(
  AColumn: Integer): TTMSFNCTreeViewNodeValue;
var
  I: Integer;
begin
  Result := nil;
  if (AColumn >= 0) and (AColumn <= Values.Count - 1) then
  begin
    Result := Values[AColumn];
    Exit;
  end;

  FTreeView.BlockUpdateNode := True;
  for I := 0 to AColumn do
  begin
    if I > Values.Count - 1 then
      Result := Values.Add;
  end;
  FTreeView.BlockUpdateNode := False;
end;

procedure TTMSFNCTreeViewNode.LoadFromString(AString: String);
var
  k, l: Integer;
  a: TStringList;
  c, s: TStringList;
  y: Integer;
  st: string;
  ps: Integer;
  subst: string;
begin
  a := TStringList.Create;
  try
    st := AString;
    while st <> '' do
    begin
      ps := Pos('{0}', st);
      if ps = 0 then
      begin
        a.Add(st);
        Break;
      end;
      subst := Copy(st, 1, ps - 1);
      a.Add(subst);
      Delete(st, 1, ps - 1 + Length('{0}'));
    end;

    for K := 0 to a.Count - 1 do
    begin
      if a[K] <> '' then
      begin
        if K = 0 then
        begin
          c := TStringList.Create;
          try
            st := a[K];
            while st <> '' do
            begin
              ps := Pos('{1}', st);
              if ps = 0 then
              begin
                c.Add(st);
                Break;
              end;
              subst := Copy(st, 1, ps - 1);
              c.Add(subst);
              Delete(st, 1, ps - 1 + Length('{0}'));
            end;

            for L := 0 to c.Count - 1 do
            begin
              if c[L] <> '' then
                Text[L] := c[L];
            end;
          finally
            c.free;
          end;
        end
        else if K = 1 then
        begin
          c := TStringList.Create;
          try
            TTMSFNCUtils.Split('|', a[K], c);
            for L := 0 to c.Count - 1 do
            begin
              s := TStringList.Create;
              try
                TTMSFNCUtils.Split(';', c[L], s);
                for y := 0 to s.Count - 1 do
                begin
                  if s[y] <> '' then
                  begin
                    if y = 0 then
                      CheckTypes[L] := TTMSFNCTreeViewNodeCheckType(StrToInt(s[y]))
                    else if y = 1 then
                      Checked[L] := StrToBool(s[y])
                    else if y = 2 then
                      ExpandedIconNames[L, False] := s[y]
                    else if y = 3 then
                      ExpandedIconNames[L, True] := s[y]
                    else if y = 4 then
                      CollapsedIconNames[L, False] := s[y]
                    else if y = 5 then
                      CollapsedIconNames[L, True] := s[y]
                    else if (y = 6) or (y = 7) or (y = 8) or (y = 9) then
                    begin
                      if y = 6 then
                        TTMSFNCUtils.LoadBitmapFromHexStr(s[y], ExpandedIcons[L, False])
                      else if y = 7 then
                        TTMSFNCUtils.LoadBitmapFromHexStr(s[y], ExpandedIcons[L, True])
                      else if y = 8 then
                        TTMSFNCUtils.LoadBitmapFromHexStr(s[y], CollapsedIcons[L, False])
                      else
                        TTMSFNCUtils.LoadBitmapFromHexStr(s[y], CollapsedIcons[L, True]);
                    end;
                  end;
                end;
              finally
                s.Free;
              end;
            end;
          finally
            c.Free;
          end;
        end
        else if K = 2 then
          Extended := StrToBool(a[K])
        else if K = 3 then
          Enabled := StrToBool(a[K]);
      end;
    end;
  finally
    a.Free;
  end;
end;

procedure TTMSFNCTreeViewNode.CopyTo(ADestination: TTMSFNCTreeViewNode; AIndex: Integer = -1);
begin
  if Assigned(FTreeView) then
    FTreeView.CopyNode(Self, ADestination, AIndex);
end;

procedure TTMSFNCTreeViewNode.MoveTo(ADestination: TTMSFNCTreeViewNode; AIndex: Integer = -1);
begin
  if Assigned(FTreeView) then
    FTreeView.MoveNode(Self, ADestination, AIndex);
end;

procedure TTMSFNCTreeViewNode.RemoveChildren;
begin
  Nodes.Clear;
end;

function TTMSFNCTreeViewNode.TreeView: TTMSFNCTreeViewData;
begin
  Result := FTreeView;
end;

procedure TTMSFNCTreeViewNode.SetEnabled(const Value: Boolean);
begin
  if FEnabled <> Value then
  begin
    FEnabled := Value;
    UpdateNode;
  end;
end;

procedure TTMSFNCTreeViewNode.SetExpanded(const Value: Boolean);
begin
  if FExpanded <> Value then
  begin
    FExpanded := Value;
    if Assigned(FTreeView) then
      FTreeView.ToggleNode(Self);
  end;
end;

procedure TTMSFNCTreeViewNode.SetExtended(const Value: Boolean);
begin
  if FExtended <> Value then
  begin
    FExtended := Value;
    UpdateNode;
  end;
end;

procedure TTMSFNCTreeViewNode.SetIndex(Value: Integer);

  procedure InsertSubNodes(ANodes: TTMSFNCTreeViewNodes);
  var
    I: Integer;
  begin
    for I := 0 to ANodes.Count - 1 do
    begin
      FTreeView.InsertItemInternal(ANodes[I]);
      InsertSubNodes(ANodes[I].Nodes);
    end;
  end;

begin
  inherited;
  if Assigned(FTreeView) then
  begin
    if FTreeView.NodeListBuild then
    begin
      FTreeView.RemoveItemInternal(Self);
      FTreeView.InsertItemInternal(Self);
      InsertSubNodes(Nodes);
    end
    else
      FTreeView.UpdateTreeViewCache;
  end;
end;

procedure TTMSFNCTreeViewNode.SetNodes(const Value: TTMSFNCTreeViewNodes);
begin
  if FNodes <> Value then
  begin
    FNodes.Assign(Value);
    UpdateNode;
  end;
end;

procedure TTMSFNCTreeViewNode.SetValues(const Value: TTMSFNCTreeViewNodeValues);
begin
  if FValues <> Value then
  begin
    FValues.Assign(Value);
    UpdateNode;
  end;
end;

procedure TTMSFNCTreeViewNode.UpdateNode;
var
  c: TTMSFNCTreeViewData;
begin
  c := TreeView;
  if Assigned(c) then
  begin
    if FTreeView.NodeListBuild then
      c.UpdateNode(VirtualNode)
    else
      c.UpdateTreeViewCache;
  end;
end;

procedure TTMSFNCTreeViewNode.ValuesChanged(Sender: TObject);
begin
  UpdateNode;
end;

{ TTMSFNCTreeViewNodes }

function TTMSFNCTreeViewNodes.Add: TTMSFNCTreeViewNode;
begin
  Result := TTMSFNCTreeViewNode(inherited Add);
end;

function TTMSFNCTreeViewNodes.Compare(ANode1, ANode2: TTMSFNCTreeViewNode; AColumn: Integer = 0; ACaseSensitive: Boolean = True;
  ASortingMode: TTMSFNCTreeViewNodesSortMode = nsmAscending): Integer;
var
  td: TTMSFNCTreeViewData;
begin
  Result := 0;

  td := TreeView;
  if not Assigned(td) then
    Exit;

  if not ACaseSensitive then
    Result := AnsiCompareStr(UpperCase(ANode1.StrippedHTMLText[AColumn]), UpperCase(ANode2.StrippedHTMLText[AColumn]))
  else
    Result := AnsiCompareStr(ANode1.StrippedHTMLText[AColumn], ANode2.StrippedHTMLText[AColumn]);

  case ASortingMode of
    nsmDescending: Result := Result * -1;
  end;

  td.DoNodeCompare(ANode1, ANode2, AColumn, Result);
end;

constructor TTMSFNCTreeViewNodes.Create(ATreeView: TTMSFNCTreeViewData; ANode: TTMSFNCTreeViewNode);
begin
  inherited Create(ATreeView, GetItemClass);
  FTreeView := ATreeView;
  FNode := ANode;
end;

destructor TTMSFNCTreeViewNodes.Destroy;
begin
  FNode := nil;
  inherited;
end;

function TTMSFNCTreeViewNodes.GetItem(Index: Integer): TTMSFNCTreeViewNode;
begin
  Result := TTMSFNCTreeViewNode(inherited Items[Index]);
end;

function TTMSFNCTreeViewNodes.GetItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCTreeViewNode;
end;

function TTMSFNCTreeViewNodes.Insert(Index: Integer): TTMSFNCTreeViewNode;
begin
  Result := TTMSFNCTreeViewNode(inherited Insert(Index));
end;

function TTMSFNCTreeViewNodes.Node: TTMSFNCTreeViewNode;
begin
  Result := FNode;
end;

procedure TTMSFNCTreeViewNodes.QuickSort(L, R: Integer; AColumn: Integer = 0; ACaseSensitive: Boolean = True; ASortingMode: TTMSFNCTreeViewNodesSortMode = nsmAscending);
var
  I, J, p: Integer;
  {$IFDEF LCLWEBLIB}
  SortList: TFPList;

  procedure ExchangeItems(Index1, Index2: Integer);
  var
    {$IFDEF WEBLIB}
    Save: JSValue;
    {$ENDIF}
    {$IFNDEF WEBLIB}
    Save: Pointer;
    {$ENDIF}
  begin
    Save := SortList.Items[Index1];
    SortList.Items[Index1] := SortList.Items[Index2];
    SortList.Items[Index2] := Save;
  end;
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  SortList: TList<TCollectionItem>;

  procedure ExchangeItems(Index1, Index2: Integer);
  var
    Save: TCollectionItem;
  begin
    Save := SortList[Index1];
    SortList[Index1] := SortList[Index2];
    SortList[Index2] := Save;
    Save.Index := Index2;
  end;
  {$ENDIF}
begin
  //This cast allows us to get at the private elements in the base class
  {$IFNDEF WEBLIB}
  SortList := {%H-}TShadowedCollection(Self).FItems;
  {$ENDIF}
  {$IFDEF WEBLIB}
  SortList := Self.FPList;
  {$ENDIF}

  repeat
    I := L;
    J := R;
    P := (L + R) shr 1;
    repeat
      while Compare(Items[I], Items[P], AColumn, ACaseSensitive, ASortingMode) < 0 do
        Inc(I);
      while Compare(Items[J], Items[P], AColumn, ACaseSensitive, ASortingMode) > 0 do
        Dec(J);
      if I <= J then
      begin
        ExchangeItems(I, J);
        if P = I then
          P := J
        else if P = J then
          P := I;
        Inc(I);
        Dec(J);
      end;
    until I > J;
    if L < J then
      QuickSort(L, J, AColumn, ACaseSensitive, ASortingMode);
    L := I;
  until I >= R;
end;

function TTMSFNCTreeViewNodes.TreeView: TTMSFNCTreeViewData;
begin
  Result := FTreeView;
end;

procedure TTMSFNCTreeViewNodes.SetItem(Index: Integer;
  const Value: TTMSFNCTreeViewNode);
begin
  inherited Items[Index] := Value;
end;

procedure TTMSFNCTreeViewNodes.Sort(AColumn: Integer = 0; ARecurse: Boolean = False; ACaseSensitive: Boolean = True;
  ASortingMode: TTMSFNCTreeViewNodesSortMode = nsmAscending; AClearNodeList: Boolean = True);
var
  td: TTMSFNCTreeViewData;
  I: Integer;
begin
  td := TreeView;
  if Assigned(td) then
  begin
    td.FSortMode := ASortingMode;
    td.BeginUpdate;
    if AClearNodeList then
      td.ClearNodeList;
    BeginUpdate;
    if Count > 0 then
    begin
      QuickSort(0, Pred(Count), AColumn, ACaseSensitive, ASortingMode);
      if ARecurse then
      begin
        for I := 0 to Count - 1 do
          Self[I].Nodes.Sort(AColumn, ARecurse, ACaseSensitive, ASortingMode, AClearNodeList);
      end;
    end;
    EndUpdate;
    td.EndUpdate;
  end;
end;

{ TTMSFNCTreeViewColumn }

procedure TTMSFNCTreeViewColumn.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCTreeViewColumn then
  begin
    FTag := (Source as TTMSFNCTreeViewColumn).Tag;
    FText := (Source as TTMSFNCTreeViewColumn).Text;
    FName := (Source as TTMSFNCTreeViewColumn).Name;
    FTrimming := (Source as TTMSFNCTreeViewColumn).Trimming;
    FWordWrapping := (Source as TTMSFNCTreeViewColumn).WordWrapping;
    FHorizontalTextAlign := (Source as TTMSFNCTreeViewColumn).HorizontalTextAlign;
    FVerticalTextAlign := (Source as TTMSFNCTreeViewColumn).VerticalTextAlign;
    FTitleTrimming := (Source as TTMSFNCTreeViewColumn).TitleTrimming;
    FTitleWordWrapping := (Source as TTMSFNCTreeViewColumn).TitleWordWrapping;
    FTitleHorizontalTextAlign := (Source as TTMSFNCTreeViewColumn).TitleHorizontalTextAlign;
    FTitleVerticalTextAlign := (Source as TTMSFNCTreeViewColumn).TitleVerticalTextAlign;
    FWidth := (Source as TTMSFNCTreeViewColumn).Width;
    FVisible := (Source as TTMSFNCTreeViewColumn).Visible;
    FUseDefaultAppearance := (Source as TTMSFNCTreeViewColumn).UseDefaultAppearance;
    FFill.Assign((Source as TTMSFNCTreeViewColumn).Fill);
    FDisabledFontColor := (Source as TTMSFNCTreeViewColumn).DisabledFontColor;
    FSelectedFontColor := (Source as TTMSFNCTreeViewColumn).SelectedFontColor;
    FDisabledTitleFontColor := (Source as TTMSFNCTreeViewColumn).DisabledTitleFontColor;
    FSelectedTitleFontColor := (Source as TTMSFNCTreeViewColumn).SelectedTitleFontColor;
    FTitleFont.Assign((Source as TTMSFNCTreeViewColumn).TitleFont);
    FFont.Assign((Source as TTMSFNCTreeViewColumn).Font);
    FStroke.Assign((Source as TTMSFNCTreeViewColumn).Stroke);
    FBottomFill.Assign((Source as TTMSFNCTreeViewColumn).BottomFill);
    FBottomFont.Assign((Source as TTMSFNCTreeViewColumn).BottomFont);
    FBottomStroke.Assign((Source as TTMSFNCTreeViewColumn).BottomStroke);
    FTopFill.Assign((Source as TTMSFNCTreeViewColumn).TopFill);
    FTopStroke.Assign((Source as TTMSFNCTreeViewColumn).TopStroke);
    FTopFont.Assign((Source as TTMSFNCTreeViewColumn).TopFont);
    FEditorType := (Source as TTMSFNCTreeViewColumn).EditorType;
    FEditorItems.Assign((Source as TTMSFNCTreeViewColumn).EditorItems);
    FCustomEditor := (Source as TTMSFNCTreeViewColumn).CustomEditor;
    FSorting := (Source as TTMSFNCTreeViewColumn).Sorting;
    FFiltering.Assign((Source as TTMSFNCTreeViewColumn).Filtering);
    FExpandingButtonSize := (Source as TTMSFNCTreeViewColumn).ExpandingButtonSize;
    FHTMLTemplate := (Source as TTMSFNCTreeViewColumn).HTMLTemplate;
  end;
end;

procedure TTMSFNCTreeViewColumn.Changed(Sender: TObject);
begin
  UpdateColumn;
end;

constructor TTMSFNCTreeViewColumn.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(Collection) then
    FTreeView := (Collection as TTMSFNCTreeViewColumns).TreeView;

  FSorting := tcsNone;
  FExpandingButtonSize := 15;
  FExpanded := True;
  FSortIndex := -1;
  FSortKind := nskNone;
  FFiltering := TTMSFNCTreeViewColumnFiltering.Create(Self);
  FEditorType := tcetNone;
  FWordWrapping := False;
  FTrimming := gttNone;
  FCustomEditor := False;
  FHorizontalTextAlign := gtaLeading;
  FVerticalTextAlign := gtaCenter;
  FTitleWordWrapping := False;
  FTitleTrimming := gttNone;
  FTitleHorizontalTextAlign := gtaLeading;
  FTitleVerticalTextAlign := gtaCenter;
  FEditorItems := TStringList.Create;
  if Assigned(Collection) then
    FText := TranslateTextEx(sTMSFNCTreeViewColumn) + ' ' + inttostr(Collection.Count - 1);

  FName :=  StringReplace(FText, ' ', '', [rfReplaceAll]);
  FWidth := 100;
  FVisible := True;
  FUseDefaultAppearance := True;

  FBottomFill := TTMSFNCGraphicsFill.Create(gfkNone, gcWhite);
  FBottomStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);
  FTopFill := TTMSFNCGraphicsFill.Create(gfkNone, gcWhite);
  FTopStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);

  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcNull);
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkGray);

  FSelectedFontColor := gcWhite;
  FDisabledFontColor := gcSilver;

  FSelectedTitleFontColor := gcWhite;
  FDisabledTitleFontColor := gcSilver;

  FFont := TTMSFNCGraphicsFont.Create;
  FTitleFont := TTMSFNCGraphicsFont.Create;
  FTopFont := TTMSFNCGraphicsFont.Create;
  FBottomFont := TTMSFNCGraphicsFont.Create;

  FBottomFill.OnChanged := @Changed;
  FTopFill.OnChanged := @Changed;
  FBottomStroke.OnChanged := @Changed;
  FTopStroke.OnChanged := @Changed;
  FStroke.OnChanged := @Changed;
  FFill.OnChanged := @Changed;

  FFont.OnChanged := @Changed;
  FTitleFont.OnChanged := @Changed;
  FTopFont.OnChanged := @Changed;
  FBottomFont.OnChanged := @Changed;

  UpdateColumn;
end;

destructor TTMSFNCTreeViewColumn.Destroy;
begin
  FFiltering.Free;
  FEditorItems.Free;
  FTopFill.Free;
  FTopStroke.Free;
  FTopFont.Free;
  FBottomFill.Free;
  FBottomStroke.Free;
  FBottomFont.Free;
  FFill.Free;
  FStroke.Free;
  FFont.Free;
  FTitleFont.Free;
  inherited;
  UpdateColumn;
end;

function TTMSFNCTreeViewColumn.GetColumnText: String;
begin
  Result := Text;
  if (Result = '') or (Pos('</', Result) > 0) or (Pos('/>', Result) > 0) or (Pos('<BR>', UpperCase(Result)) > 0) then
  begin
    Result := Name;
    if Result = '' then
      Result := TranslateTextEx(sTMSFNCTreeViewColumn) + ' ' + inttostr(Index);
  end;
end;

function TTMSFNCTreeViewColumn.GetDisplayName: string;
begin
  if FName <> '' then
    Result := FName
  {$IFDEF WEBLIB}
  else
    Result := '';
  {$ENDIF}
  {$IFNDEF WEBLIB}
  else
    Result := inherited;
  {$ENDIF}
end;

function TTMSFNCTreeViewColumn.GetText: String;
begin
  Result := Text;
  if Result = '' then
  begin
    Result := Name;
    if Result = '' then
      Result := TranslateTextEx(sTMSFNCTreeViewColumn) + ' ' + inttostr(Index);
  end;
end;

function TTMSFNCTreeViewColumn.IsExpandingButtonSizeStored: Boolean;
begin
  Result := ExpandingButtonSize <> 15;
end;

function TTMSFNCTreeViewColumn.TreeView: TTMSFNCTreeViewData;
begin
  Result := FTreeView;
end;

procedure TTMSFNCTreeViewColumn.SetHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FHorizontalTextAlign <> Value then
  begin
    FHorizontalTextAlign := Value;
    UpdateColumn;
  end;
end;

procedure TTMSFNCTreeViewColumn.SetHTMLTemplate(const Value: string);
begin
  if FHTMLTemplate <> Value then
  begin
    FHTMLTemplate := Value;
    if Assigned(FTreeView) then
      FTreeView.UpdateTreeView;
  end;
end;

procedure TTMSFNCTreeViewColumn.SetMinimumWidth(const Value: Double);
begin
  if FMinimumWidth <> Value then
  begin
    FMinimumWidth := Value;
    UpdateColumn;
  end;
end;

procedure TTMSFNCTreeViewColumn.SetBottomFont(const Value: TTMSFNCGraphicsFont);
begin
  if FBottomFont <> Value then
    FBottomFont.Assign(Value);
end;

procedure TTMSFNCTreeViewColumn.SetBottomStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FBottomStroke <> Value then
    FBottomStroke.Assign(Value);
end;

procedure TTMSFNCTreeViewColumn.SetDisabledFontColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FDisabledFontColor <> Value then
  begin
    FDisabledFontColor := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCTreeViewColumn.SetDisabledTitleFontColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FDisabledTitleFontColor <> Value then
  begin
    FDisabledTitleFontColor := Value;
    UpdateColumn;
  end;
end;

procedure TTMSFNCTreeViewColumn.SetEditorItems(const Value: TStringList);
begin
  FEditorItems.Assign(Value);
end;

procedure TTMSFNCTreeViewColumn.SetEditorType(
  const Value: TTMSFNCTreeViewColumnEditorType);
begin
  if FEditorType <> Value then
    FEditorType := Value;
end;

procedure TTMSFNCTreeViewColumn.SetExpandable(const Value: Boolean);
begin
  if FExpandable <> Value then
  begin
    FExpandable := Value;
    UpdateColumn;
  end;
end;

procedure TTMSFNCTreeViewColumn.SetExpanded(const Value: Boolean);
begin
  if FExpanded <> Value then
  begin
    FExpanded := Value;
    UpdateColumn;
  end;
end;

procedure TTMSFNCTreeViewColumn.SetExpandingButtonSize(const Value: Single);
begin
  FExpandingButtonSize := Value;
end;

procedure TTMSFNCTreeViewColumn.SetBottomFill(const Value: TTMSFNCGraphicsFill);
begin
  if FBottomFill <> Value then
    FBottomFill.Assign(Value);
end;

procedure TTMSFNCTreeViewColumn.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  if FFont <> Value then
    FFont.Assign(Value);
end;

procedure TTMSFNCTreeViewColumn.SetSelectedFontColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FSelectedFontColor <> Value then
  begin
    FSelectedFontColor := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCTreeViewColumn.SetSelectedTitleFontColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FSelectedTitleFontColor <> value then
  begin
    FSelectedTitleFontColor := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCTreeViewColumn.SetSortIndex(const Value: Integer);
begin
  if FSortIndex <> Value then
  begin
    FSortIndex := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCTreeViewColumn.SetSorting(const Value: TTMSFNCTreeViewColumnSorting);
begin
  if FSorting <> Value then
  begin
    FSorting := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCTreeViewColumn.SetSortKind(
  const Value: TTMSFNCTreeViewNodesSortKind);
begin
  if FSortKind <> Value then
  begin
    FSortKind := Value;
    Changed(Self);
  end;
end;

procedure TTMSFNCTreeViewColumn.SetStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FStroke <> Value then
    FStroke.Assign(Value);
end;

procedure TTMSFNCTreeViewColumn.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if FFill <> Value then
    FFill.Assign(Value);
end;

procedure TTMSFNCTreeViewColumn.SetFiltering(
  const Value: TTMSFNCTreeViewColumnFiltering);
begin
  if FFiltering <> Value then
    FFiltering.Assign(Value);
end;

procedure TTMSFNCTreeViewColumn.SetTopFont(const Value: TTMSFNCGraphicsFont);
begin
  if FTopFont <> Value then
    FTopFont.Assign(Value);
end;

procedure TTMSFNCTreeViewColumn.SetTopStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FTopStroke <> Value then
    FTopStroke.Assign(Value);
end;

procedure TTMSFNCTreeViewColumn.SetTopFill(const Value: TTMSFNCGraphicsFill);
begin
  if FTopFill <> Value then
    FTopFill.Assign(Value);
end;

procedure TTMSFNCTreeViewColumn.SetName(const Value: String);
begin
  if FName <> Value then
  begin
    FName := Value;
  end;
end;

procedure TTMSFNCTreeViewColumn.SetText(const Value: String);
begin
  if FText <> Value then
  begin
    FText := Value;
    UpdateColumn;
  end;
end;

procedure TTMSFNCTreeViewColumn.SetTitleFont(const Value: TTMSFNCGraphicsFont);
begin
  if FTitleFont <> Value then
    FTitleFont.Assign(Value);
end;

procedure TTMSFNCTreeViewColumn.SetTitleHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTitleHorizontalTextAlign <> Value then
  begin
    FTitleHorizontalTextAlign := Value;
    UpdateColumn;
  end;
end;

procedure TTMSFNCTreeViewColumn.SetTitleTrimming(
  const Value: TTMSFNCGraphicsTextTrimming);
begin
  if FTitleTrimming <> Value then
  begin
    FTitleTrimming := Value;
    UpdateColumn;
  end;
end;

procedure TTMSFNCTreeViewColumn.SetTitleVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FTitleVerticalTextAlign <> Value then
  begin
    FTitleVerticalTextAlign := Value;
    UpdateColumn;
  end;
end;

procedure TTMSFNCTreeViewColumn.SetTitleWordWrapping(const Value: Boolean);
begin
  if FTitleWordWrapping <> Value then
  begin
    FTitleWordWrapping := Value;
    UpdateColumn;
  end;
end;

procedure TTMSFNCTreeViewColumn.SetTrimming(const Value: TTMSFNCGraphicsTextTrimming);
begin
  if FTrimming <> Value then
  begin
    FTrimming := Value;
    UpdateColumn;
  end;
end;

procedure TTMSFNCTreeViewColumn.SetUseDefaultAppearance(const Value: Boolean);
begin
  if FUseDefaultAppearance <> Value then
  begin
    FUseDefaultAppearance := Value;
    UpdateColumn;
  end;
end;

procedure TTMSFNCTreeViewColumn.SetVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FVerticalTextAlign <> Value then
  begin
    FVerticalTextAlign := Value;
    UpdateColumn;
  end;
end;

procedure TTMSFNCTreeViewColumn.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    UpdateColumn;
  end;
end;

procedure TTMSFNCTreeViewColumn.SetWidth(const Value: Double);
begin
  if FWidth <> Value then
  begin
    FWidth := Value;
    UpdateColumn;
  end;
end;

procedure TTMSFNCTreeViewColumn.SetWordWrapping(const Value: Boolean);
begin
  if FWordWrapping <> Value then
  begin
    FWordWrapping := Value;
    UpdateColumn;
  end;
end;

procedure TTMSFNCTreeViewColumn.Sort(ARecurse, ACaseSensitive: Boolean; ASortingMode: TTMSFNCTreeViewNodesSortMode);
var
  t: TTMSFNCTreeViewData;
begin
  t := TreeView;
  if Assigned(t) then
    t.Nodes.Sort(Index, ARecurse, ACaseSensitive, ASortingMode);
end;

procedure TTMSFNCTreeViewColumn.UpdateColumn;
var
  t: TTMSFNCTreeViewData;
begin
  t := TreeView;
  if Assigned(t) then
  begin
    t.UpdateColumns;
    t.UpdateTreeViewCache;
  end;
end;

procedure TTMSFNCTreeViewColumn.UpdateWidth(AWidth: Double);
begin
  FWidth := AWidth;
end;

{ TTMSFNCTreeViewColumns }

function TTMSFNCTreeViewColumns.Add: TTMSFNCTreeViewColumn;
begin
  Result := TTMSFNCTreeViewColumn(inherited Add);
end;

constructor TTMSFNCTreeViewColumns.Create(ATreeView: TTMSFNCTreeViewData);
begin
  inherited Create(ATreeView, GetItemClass);
  FTreeView := ATreeView;
end;

function TTMSFNCTreeViewColumns.GetItem(Index: Integer): TTMSFNCTreeViewColumn;
begin
  Result := TTMSFNCTreeViewColumn(inherited Items[Index]);
end;

function TTMSFNCTreeViewColumns.GetItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCTreeViewColumn;
end;

function TTMSFNCTreeViewColumns.Insert(Index: Integer): TTMSFNCTreeViewColumn;
begin
  Result := TTMSFNCTreeViewColumn(inherited Insert(Index));
end;

function TTMSFNCTreeViewColumns.TreeView: TTMSFNCTreeViewData;
begin
  Result := FTreeView;
end;

procedure TTMSFNCTreeViewColumns.SetItem(Index: Integer;
  const Value: TTMSFNCTreeViewColumn);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCTreeViewNodeValue }

function TTMSFNCTreeViewNodeValue.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.BitmapContainer;
end;

function TTMSFNCTreeViewNodeValue.GetText: string;
var
  ht: string;
  s: string;
begin
  s := Text;
  if Assigned(FTreeView) then
  begin
    ht := FTreeView.GetHTMLTemplate(Index);
    FTreeView.DoGetHTMLTemplate(Self, Index, ht);
    if ht <> '' then
      s := FTreeView.HTMLReplace(ht, Self);
  end;

  Result := s;
end;

procedure TTMSFNCTreeViewNodeValue.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCTreeViewNodeValue then
  begin
    FTag := (Source as TTMSFNCTreeViewNodeValue).Tag;
    FText := (Source as TTMSFNCTreeViewNodeValue).Text;
    FTitleExpanded := (Source as TTMSFNCTreeViewNodeValue).TitleExpanded;
    FTitle := (Source as TTMSFNCTreeViewNodeValue).Title;
    FCheckType := (Source as TTMSFNCTreeViewNodeValue).CheckType;
    FChecked := (Source as TTMSFNCTreeViewNodeValue).Checked;
    FCollapsedIcon.Assign((Source as TTMSFNCTreeViewNodeValue).CollapsedIcon);
    FCollapsedIconLarge.Assign((Source as TTMSFNCTreeViewNodeValue).CollapsedIconLarge);
    FExpandedIcon.Assign((Source as TTMSFNCTreeViewNodeValue).ExpandedIcon);
    FExpandedIconLarge.Assign((Source as TTMSFNCTreeViewNodeValue).ExpandedIconLarge);
    FCollapsedIconName := (Source as TTMSFNCTreeViewNodeValue).CollapsedIconName;
    FCollapsedIconLargeName := (Source as TTMSFNCTreeViewNodeValue).CollapsedIconLargeName;
    FExpandedIconName := (Source as TTMSFNCTreeViewNodeValue).ExpandedIconName;
    FExpandedIconLargeName := (Source as TTMSFNCTreeViewNodeValue).ExpandedIconLargeName;
    FHTMLTemplateItems.Assign((Source as TTMSFNCTreeViewNodeValue).HTMLTemplateItems);
    AssignData(Source);
  end;
end;

procedure TTMSFNCTreeViewNodeValue.AssignData(Source: TPersistent);
begin
  if Source is TTMSFNCTreeViewNodeValue then
  begin
    FDataString := (Source as TTMSFNCTreeViewNodeValue).DataString;
    FDataPointer := (Source as TTMSFNCTreeViewNodeValue).DataPointer;
    FDataBoolean := (Source as TTMSFNCTreeViewNodeValue).DataBoolean;
    FDataObject := (Source as TTMSFNCTreeViewNodeValue).DataObject;
    FDataInteger := (Source as TTMSFNCTreeViewNodeValue).DataInteger;
    FDBKey := (Source as TTMSFNCTreeViewNodeValue).DBKey;
  end;
end;

procedure TTMSFNCTreeViewNodeValue.BitmapChanged(Sender: TObject);
begin
  UpdateNodeValue;
end;

constructor TTMSFNCTreeViewNodeValue.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(Collection) then
  begin
    FTreeView := (Collection as TTMSFNCTreeViewNodeValues).TreeView;
    FNode := (Collection as TTMSFNCTreeViewNodeValues).Node;
  end;
  FChecked := False;
  FTitleExpanded := True;
  FCheckType := tvntNone;
  FCollapsedIcon := TTMSFNCBitmap.Create;
  FCollapsedIcon.OnChange := @BitmapChanged;
  FExpandedIcon := TTMSFNCBitmap.Create;
  FExpandedIcon.OnChange := @BitmapChanged;

  FCollapsedIconLarge := TTMSFNCBitmap.Create;
  FCollapsedIconLarge.OnChange := @BitmapChanged;
  FExpandedIconLarge := TTMSFNCBitmap.Create;
  FExpandedIconLarge.OnChange := @BitmapChanged;

  FHTMLTemplateItems := TStringList.Create;
  FHTMLTemplateItems.OnChange := @TemplateItemsChanged;

  UpdateNodeValue;
end;

destructor TTMSFNCTreeViewNodeValue.Destroy;
begin
  FHTMLTemplateItems.Free;
  FCollapsedIcon.Free;
  FCollapsedIconLarge.Free;
  FExpandedIcon.Free;
  FExpandedIconLarge.Free;
  inherited;
  UpdateNodeValue;
end;

function TTMSFNCTreeViewNodeValue.Node: TTMSFNCTreeViewNode;
begin
  Result := FNode;
end;

procedure TTMSFNCTreeViewNodeValue.TemplateItemsChanged(Sender: TObject);
begin
  UpdateNodeValue;
end;

function TTMSFNCTreeViewNodeValue.TreeView: TTMSFNCTreeViewData;
begin
  Result := FTreeView;
end;

procedure TTMSFNCTreeViewNodeValue.SetChecked(const Value: Boolean);
var
  chkst: TTMSFNCArrayBoolean;
  I: Integer;
  chk: Boolean;
  t: TTMSFNCTreeViewData;
  n: TTMSFNCTreeViewNode;
begin
  if FChecked <> Value then
  begin
    FChecked := Value;
    t := TreeView;
    n := Node;
    if Assigned(t) and Assigned(n) and Assigned(n.VirtualNode) then
    begin
      SetLength(chkst, 0);
      for I := 0 to t.ColumnCount - 1 do
      begin
        chk := False;
        TreeView.DoIsNodeChecked(Node.VirtualNode, I, chk);
        SetLength(chkst, Length(chkst) + 1);
        chkst[Length(chkst) - 1] := chk;
      end;

      TreeView.UpdateNodeCheckStates(Self.Node.VirtualNode, chkst);
    end;

    UpdateNodeValue;
  end;
end;

procedure TTMSFNCTreeViewNodeValue.SetCheckType(
  const Value: TTMSFNCTreeViewNodeCheckType);
begin
  if FCheckType <> Value then
  begin
    FCheckType := Value;
    UpdateNodeValue;
  end;
end;

procedure TTMSFNCTreeViewNodeValue.SetCollapsedIconName(const Value: String);
begin
  if FCollapsedIconName <> Value then
  begin
    FCollapsedIconName := Value;
    UpdateNodeValue;
  end;
end;

procedure TTMSFNCTreeViewNodeValue.SetCollapsedIconLargeName(const Value: String);
begin
  if FCollapsedIconLargeName <> Value then
  begin
    FCollapsedIconLargeName := Value;
    UpdateNodeValue;
  end;
end;

procedure TTMSFNCTreeViewNodeValue.SetExpandedIconName(const Value: String);
begin
  if FExpandedIconName <> Value then
  begin
    FExpandedIconName := Value;
    UpdateNodeValue;
  end;
end;

procedure TTMSFNCTreeViewNodeValue.SetHTMLTemplateItems(const Value: TStringList);
begin
  if FHTMLTemplateItems <> Value then
  begin
    FHTMLTemplateItems.Assign(Value);
    UpdateNodeValue;
  end;
end;

procedure TTMSFNCTreeViewNodeValue.SetExpandedIconLargeName(const Value: String);
begin
  if FExpandedIconLargeName <> Value then
  begin
    FExpandedIconLargeName := Value;
    UpdateNodeValue;
  end;
end;

procedure TTMSFNCTreeViewNodeValue.SetTitleExpanded(const Value: Boolean);
begin
  if FTitleExpanded <> Value then
  begin
    FTitleExpanded := Value;
    UpdateNodeValue;
  end;
end;

procedure TTMSFNCTreeViewNodeValue.SetCollapsedIcon(const Value: TTMSFNCBitmap);
begin
  if FCollapsedIcon <> Value then
  begin
    FCollapsedIcon.Assign(Value);
    UpdateNodeValue;
  end;
end;

procedure TTMSFNCTreeViewNodeValue.SetCollapsedIconLarge(const Value: TTMSFNCBitmap);
begin
  if FCollapsedIconLarge <> Value then
  begin
    FCollapsedIconLarge.Assign(Value);
    UpdateNodeValue;
  end;
end;

procedure TTMSFNCTreeViewNodeValue.SetExpandedIcon(const Value: TTMSFNCBitmap);
begin
  if FExpandedIcon <> Value then
  begin
    FExpandedIcon.Assign(Value);
    UpdateNodeValue;
  end;
end;

procedure TTMSFNCTreeViewNodeValue.SetExpandedIconLarge(const Value: TTMSFNCBitmap);
begin
  if FExpandedIconLarge <> Value then
  begin
    FExpandedIconLarge.Assign(Value);
    UpdateNodeValue;
  end;
end;

procedure TTMSFNCTreeViewNodeValue.SetText(const Value: String);
begin
  if FText <> Value then
  begin
    FText := Value;
    UpdateNodeValue;
  end;
end;

procedure TTMSFNCTreeViewNodeValue.SetTitle(const Value: String);
begin
  if FTitle <> Value then
  begin
    FTitle := Value;
    UpdateNodeValue;
  end;
end;

procedure TTMSFNCTreeViewNodeValue.UpdateNodeValue;
var
  c: TTMSFNCTreeViewData;
  n: TTMSFNCTreeViewNode;
begin
  c := TreeView;
  n := Node;
  if Assigned(c) and Assigned(n) then
    c.UpdateNode(n.VirtualNode);
end;

{ TTMSFNCTreeViewNodeValues }

function TTMSFNCTreeViewNodeValues.Add: TTMSFNCTreeViewNodeValue;
begin
  Result := TTMSFNCTreeViewNodeValue(inherited Add);
end;

constructor TTMSFNCTreeViewNodeValues.Create(ATreeView: TTMSFNCTreeViewData; ANode: TTMSFNCTreeViewNode);
begin
  inherited Create(ATreeView, GetItemClass);
  FTreeView := ATreeView;
  FNode := ANode;
end;

function TTMSFNCTreeViewNodeValues.GetItem(Index: Integer): TTMSFNCTreeViewNodeValue;
begin
  Result := TTMSFNCTreeViewNodeValue(inherited Items[Index]);
end;

function TTMSFNCTreeViewNodeValues.GetItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCTreeViewNodeValue;
end;

function TTMSFNCTreeViewNodeValues.Insert(Index: Integer): TTMSFNCTreeViewNodeValue;
begin
  Result := TTMSFNCTreeViewNodeValue(inherited Insert(Index));
end;

function TTMSFNCTreeViewNodeValues.Node: TTMSFNCTreeViewNode;
begin
  Result := FNode;
end;

function TTMSFNCTreeViewNodeValues.TreeView: TTMSFNCTreeViewData;
begin
  Result := FTreeView;
end;

procedure TTMSFNCTreeViewNodeValues.UpdateChecked(AIndex: Integer;
  AValue: Boolean);
begin
  if Assigned(FTreeView) then
    FTreeView.UpdateCount := FTreeView.UpdateCount + 1;
  Items[AIndex].Checked := AValue;
  if Assigned(FTreeView) then
    FTreeView.UpdateCount := FTreeView.UpdateCount - 1;
end;

procedure TTMSFNCTreeViewNodeValues.SetItem(Index: Integer;
  const Value: TTMSFNCTreeViewNodeValue);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCTreeViewCacheItem }

class function TTMSFNCTreeViewCacheItem.CreateGroupBottom(ARect: TRectF; AGroup: Integer; AStartColumn, AEndColumn: Integer): TTMSFNCTreeViewCacheItem;
begin
  Result := TTMSFNCTreeViewCacheItem.Create;
  Result.Kind := ikGroupBottom;
  Result.Group := AGroup;
  Result.StartColumn := AStartColumn;
  Result.EndColumn := AEndColumn;
  Result.Rect := ARect;
end;

class function TTMSFNCTreeViewCacheItem.CreateGroupTop(
  ARect: TRectF; AGroup: Integer; AStartColumn, AEndColumn: Integer): TTMSFNCTreeViewCacheItem;
begin
  Result := TTMSFNCTreeViewCacheItem.Create;
  Result.Kind := ikGroupTop;
  Result.Group := AGroup;
  Result.StartColumn := AStartColumn;
  Result.EndColumn := AEndColumn;
  Result.Rect := ARect;
end;

class function TTMSFNCTreeViewCacheItem.CreateNode(ARect: TRectF; ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewCacheItem;
begin
  Result := TTMSFNCTreeViewCacheItem.Create;
  Result.Kind := ikNode;
  Result.Rect := ARect;
  Result.Node := ANode;
end;

class function TTMSFNCTreeViewCacheItem.CreateColumnTop(ARect: TRectF; AColumn: Integer): TTMSFNCTreeViewCacheItem;
begin
  Result := TTMSFNCTreeViewCacheItem.Create;
  Result.Kind := ikColumnTop;
  Result.Column := AColumn;
  Result.Rect := ARect;
end;

class function TTMSFNCTreeViewCacheItem.CreateColumnBottom(ARect: TRectF; AColumn: Integer): TTMSFNCTreeViewCacheItem;
begin
  Result := TTMSFNCTreeViewCacheItem.Create;
  Result.Kind := ikColumnBottom;
  Result.Column := AColumn;
  Result.Rect := ARect;
end;

destructor TTMSFNCTreeViewCacheItem.Destroy;
begin
  FNode := nil;
  inherited;
end;

{ TTMSFNCTreeViewVirtualNode }

procedure TTMSFNCTreeViewVirtualNode.Collapse(ARecurse: Boolean = False);
begin
  if Assigned(FTreeView) then
    FTreeView.CollapseVirtualNode(Self, ARecurse);
end;

procedure TTMSFNCTreeViewVirtualNode.CopyFrom(
  Source: TTMSFNCTreeViewVirtualNode);
begin
  if Assigned(Source) then
  begin
    FChildren := (Source as TTMSFNCTreeViewVirtualNode).Children;
    FTotalChildren := (Source as TTMSFNCTreeViewVirtualNode).TotalChildren;
    FExpanded := (Source as TTMSFNCTreeViewVirtualNode).Expanded;
    FExtended := (Source as TTMSFNCTreeViewVirtualNode).Extended;
    FLevel := (Source as TTMSFNCTreeViewVirtualNode).Level;
    FRow := (Source as TTMSFNCTreeViewVirtualNode).Row;
    FCalculated := (Source as TTMSFNCTreeViewVirtualNode).Calculated;
    FHeight := (Source as TTMSFNCTreeViewVirtualNode).Height;
    FParentNode := (Source as TTMSFNCTreeViewVirtualNode).ParentNode;
    FIndex := (Source as TTMSFNCTreeViewVirtualNode).Index;
    FTextRects := (Source as TTMSFNCTreeViewVirtualNode).TextRects;
    FExtraRects := (Source as TTMSFNCTreeViewVirtualNode).ExtraRects;
    FBitmapRects := (Source as TTMSFNCTreeViewVirtualNode).BitmapRects;
    FCheckRects := (Source as TTMSFNCTreeViewVirtualNode).CheckRects;
    FExpandRects := (Source as TTMSFNCTreeViewVirtualNode).ExpandRects;
    FCheckStates := (Source as TTMSFNCTreeViewVirtualNode).CheckStates;
    FNode := (Source as TTMSFNCTreeViewVirtualNode).Node;
  end;
end;

constructor TTMSFNCTreeViewVirtualNode.Create(ATreeView: TTMSFNCTreeViewData);
begin
  FTreeView := ATreeView;
end;

destructor TTMSFNCTreeViewVirtualNode.Destroy;
begin
  FCache := nil;
  FNode := nil;
  inherited;
end;

procedure TTMSFNCTreeViewVirtualNode.Expand(ARecurse: Boolean = False);
begin
  if Assigned(FTreeView) then
    FTreeView.ExpandVirtualNode(Self, ARecurse);
end;

function TTMSFNCTreeViewVirtualNode.GetChildCount: Integer;
begin
  Result := 0;
  if Assigned(FTreeView) then
    Result := FTreeView.GetVirtualNodeChildCount(Self);
end;

function TTMSFNCTreeViewVirtualNode.GetTitleExpanded(AColumn: Integer): Boolean;
begin
  Result := True;
  if Assigned(FTreeView) then
    Result := FTreeView.GetVirtualNodeTitleExpanded(Self, AColumn);
end;

function TTMSFNCTreeViewVirtualNode.GetFirstChild: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.GetFirstChildVirtualNode(Self);
end;

function TTMSFNCTreeViewVirtualNode.GetLastChild: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.GetLastChildVirtualNode(Self);
end;

function TTMSFNCTreeViewVirtualNode.GetNext: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.GetNextVirtualNode(Self);
end;

function TTMSFNCTreeViewVirtualNode.GetNextChild(
  ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.GetNextChildVirtualNode(Self, ANode);
end;

function TTMSFNCTreeViewVirtualNode.GetNextSibling: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.GetNextSiblingVirtualNode(Self);
end;

function TTMSFNCTreeViewVirtualNode.GetParent: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.GetParentVirtualNode(Self);
end;

function TTMSFNCTreeViewVirtualNode.GetPrevious: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.GetPreviousVirtualNode(Self);
end;

function TTMSFNCTreeViewVirtualNode.GetPreviousChild(
  ANode: TTMSFNCTreeViewVirtualNode): TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.GetPreviousChildVirtualNode(Self, ANode);
end;

function TTMSFNCTreeViewVirtualNode.GetPreviousSibling: TTMSFNCTreeViewVirtualNode;
begin
  Result := nil;
  if Assigned(FTreeView) then
    Result := FTreeView.GetPreviousSiblingVirtualNode(Self);
end;

function TTMSFNCTreeViewVirtualNode.GetText(AColumn: Integer): String;
begin
  Result := '';
  if Assigned(FTreeView) then
    Result := FTreeView.GetVirtualNodeText(Self, AColumn);
end;

function TTMSFNCTreeViewVirtualNode.GetTitle(AColumn: Integer): String;
begin
  Result := '';
  if Assigned(FTreeView) then
    Result := FTreeView.GetVirtualNodeTitle(Self, AColumn);
end;

procedure TTMSFNCTreeViewVirtualNode.RemoveChildren;
begin
  if Assigned(FTreeView) then
    FTreeView.RemoveVirtualNodeChildren(Self);
end;

{ TTMSFNCTreeViewColumnFiltering }

procedure TTMSFNCTreeViewColumnFiltering.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCTreeViewColumnFiltering then
  begin
    FEnabled := (Source as TTMSFNCTreeViewColumnFiltering).Enabled;
    FDropDownWidth := (Source as TTMSFNCTreeViewColumnFiltering).DropDownWidth;
    FDropDownHeight := (Source as TTMSFNCTreeViewColumnFiltering).DropDownHeight;
    FMultiColumn := (Source as TTMSFNCTreeViewColumnFiltering).MultiColumn;
    FButtonSize := (Source as TTMSFNCTreeViewColumnFiltering).ButtonSize;
  end;
end;

constructor TTMSFNCTreeViewColumnFiltering.Create(AColumn: TTMSFNCTreeViewColumn);
begin
  FColumn := AColumn;
  FEnabled := False;
  FDropDownWidth := 100;
  FDropDownHeight := 120;
  FMultiColumn := False;
  FButtonSize := 15;
end;

destructor TTMSFNCTreeViewColumnFiltering.Destroy;
begin
  inherited;
end;

function TTMSFNCTreeViewColumnFiltering.IsButtonSizeStored: Boolean;
begin
  Result := ButtonSize <> 15;
end;

procedure TTMSFNCTreeViewColumnFiltering.SetButtonSize(const Value: Single);
begin
  if FButtonSize <> Value then
  begin
    FButtonSize := Value;
    if Assigned(FColumn) then
      FColumn.UpdateColumn;
  end;
end;

procedure TTMSFNCTreeViewColumnFiltering.SetEnabled(const Value: Boolean);
begin
  if FEnabled <> Value then
  begin
    FEnabled := Value;
    if Assigned(FColumn) then
      FColumn.UpdateColumn;
  end;
end;

{ TTMSFNCTreeViewFilterData }

procedure TTMSFNCTreeViewFilterData.Assign(Source: TPersistent);
var
  ASrcFilterData: TTMSFNCTreeViewFilterData;
begin
  ASrcFilterData := Source as TTMSFNCTreeViewFilterData;
  if Assigned(ASrcFilterData) then
  begin
    FColumn  := ASrcFilterData.Column;
    FCondition := ASrcFilterData.Condition;
    FCaseSensitive := ASrcFilterData.CaseSensitive;
    FPrefix := ASrcFilterData.Prefix;
    FSuffix := ASrcFilterData.Suffix;
    FOperation := ASrcFilterData.Operation;
  end;
end;

constructor TTMSFNCTreeViewFilterData.Create(ACollection: TCollection);
begin
  inherited;
  FCaseSensitive := True;
end;

{ TTMSFNCTreeViewFilter }

function TTMSFNCTreeViewFilter.Add: TTMSFNCTreeViewFilterData;
begin
  Result := TTMSFNCTreeViewFilterData(inherited Add);
  if Count = 1 then
    Result.Operation := tfoNone
  else
    Result.Operation := tfoAND;
end;

constructor TTMSFNCTreeViewFilter.Create(AOwner: TTMSFNCTreeViewData);
begin
  inherited Create(AOwner, TTMSFNCTreeViewFilterData);
  FOwner := AOwner;
end;

function TTMSFNCTreeViewFilter.GetColFilter(Col: Integer): TTMSFNCTreeViewFilterData;
var
  i: Integer;
begin
  for i := 1 to Count do
  begin
    if Items[i - 1].Column = Col then
    begin
      Result := Items[i - 1];
      Exit;
    end;
  end;
  Result := Add;
  Result.Column := Col;
end;

function TTMSFNCTreeViewFilter.GetItem(Index: Integer): TTMSFNCTreeViewFilterData;
begin
  Result := TTMSFNCTreeViewFilterData(inherited GetItem(Index));
end;

procedure TTMSFNCTreeViewFilter.RemoveColumnFilter(Col: integer);
var
  i: integer;
begin
  i := Count;

  while i > 0 do
  begin
    dec(i);
    if Items[i].Column = Col then
      Delete(i);
  end;
end;

function TTMSFNCTreeViewFilter.HasFilter(Col: integer): Boolean;
var
  i: Integer;
begin
  Result := false;

  for i := 1 to Count do
  begin
    if Items[i - 1].Column = Col then
    begin
      Result := true;
      Break;
    end;
  end;
end;

function TTMSFNCTreeViewFilter.Insert(index: Integer): TTMSFNCTreeViewFilterData;
begin
  Result := TTMSFNCTreeViewFilterData(inherited Insert(Index));
end;

procedure TTMSFNCTreeViewFilter.SetItem(Index: Integer; const Value: TTMSFNCTreeViewFilterData);
begin
  inherited SetItem(Index, Value);
end;

{$IFDEF WEBLIB}
function TTMSFNCTreeViewCacheItemList.GetItem(Index: Integer): TTMSFNCTreeViewCacheItem;
begin
  Result := TTMSFNCTreeViewCacheItem(inherited Items[Index]);
end;

procedure TTMSFNCTreeViewCacheItemList.SetItem(Index: Integer; const Value: TTMSFNCTreeViewCacheItem);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCTreeViewIntegerList.GetItem(Index: Integer): Integer;
begin
  Result := Integer(inherited Items[Index]);
end;

procedure TTMSFNCTreeViewIntegerList.SetItem(Index: Integer; const Value: Integer);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCTreeViewNodeStructure.GetItem(Index: Integer): TTMSFNCTreeViewVirtualNode;
begin
  Result := TTMSFNCTreeViewVirtualNode(inherited Items[Index]);
end;

procedure TTMSFNCTreeViewNodeStructure.SetItem(Index: Integer; const Value: TTMSFNCTreeViewVirtualNode);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCTreeViewVisibleNodes.GetItem(Index: Integer): TTMSFNCTreeViewVirtualNode;
begin
  Result := TTMSFNCTreeViewVirtualNode(inherited Items[Index]);
end;

procedure TTMSFNCTreeViewVisibleNodes.SetItem(Index: Integer; const Value: TTMSFNCTreeViewVirtualNode);
begin
  inherited Items[Index] := Value;
end;

function TTMSFNCTreeViewDisplayGroups.GetItem(Index: Integer): TTMSFNCTreeViewDisplayGroup;
begin
  Result := TTMSFNCTreeViewDisplayGroup(inherited Items[Index]);
end;

procedure TTMSFNCTreeViewDisplayGroups.SetItem(Index: Integer; const Value: TTMSFNCTreeViewDisplayGroup);
begin
  inherited Items[Index] := Value;
end;
{$ENDIF}

end.
