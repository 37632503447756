unit _AppEditUnit;

interface

/// MMF GLOBAL INDEX/SUBNET IDEA :
/// Similar to segmented arrays, if the global index sub-divide into subnetted blocks.
/// Then a binary search is done on all blocks recursively like a tree.
/// Each block have a Immortal.SyncObjs.TRWLock for refCnt, Invalid, RW/lock capabilities to
/// enable multi threaded, incremental relocation/reallocation of even large complex blocks of memory
/// Each action will do it's own in-place JIT maintenance,
/// But cyclic actions to be performed:
///   autoTune : adjust parameters for load (e.g. MemMap cache, blockSize, pre-alloc, growSize)
///   replication  : Or backups to dump as packed/compressed/encrypted
///   defragmentation : re-arranging related data to be closer together on-disk/ram
///   prepartion : Changing allocation/grow sizes for predicted use/non-use
///   optimization : e.g. moving/splitting/combining/mirroring memblocks over multiple stores
///   maintenence : e.g. cycle logfiles, hour/day/month file optimization/packing/archiving

uses System.Classes, System.SysUtils, System.TypInfo, System.Rtti, System.Types;

implementation



end.
