unit AppEditFormU;

interface

uses
  System.SysUtils, System.Classes,
  JS, Web,
  WEBLib.Graphics, WEBLib.Controls, WEBLib.Utils,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.TreeNodes, Vcl.ComCtrls, WEBLib.ComCtrls,
  WEBLib.ExtCtrls, Vcl.Controls, WEBLib.SyntaxMemo, WebSocketWrapper,
  Types,   // Dynamic array types defined here (WebCore).
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Web.mORMot.Types,
  Web.mORMot.Rest,
  mORMotClient, WEBLib.REST, Data.DB, WEBLib.DB, WEBLib.CDS;

type
  TAppEditForm = class(TForm)
    WebSyntaxMemo1: TSyntaxMemo;
    WebPanel1: TPanel;
    WebPanel2: TPanel;
    WebTreeView1: TTreeView;
    TreeCombo: TComboBox;
    BtnSwagger: TButton;
    WebHttpRequest1: THttpRequest;
    WebClientConnection1: TClientConnection;
    WebClientDataSet1: TClientDataSet;
    WebDataSource1: TDataSource;
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormDestroy(Sender: TObject);
    procedure TreeComboChange(Sender: TObject);
    procedure BtnSwaggerClick(Sender: TObject);
    procedure WebClientConnection1DataReceived(Sender: TObject;
      ARequest: TJSXMLHttpRequestRecord; var AResponse: string);
  private
    { Private declarations }
    FWebSocketWrapper : TSocketWrapper;
    function GetCurRelationship: String;
    procedure SetCurRelationship(const Value: String);
  public
    { Public declarations }
    Client            : TRestClientURI;
    ProjService       : TServiceApp;
//    CurrRelationships : TTreeFile;
    Updating          : Boolean;
    procedure LoadForm;
  protected procedure LoadDFMValues; override; end;

var AppEditForm: TAppEditForm;

implementation

{$R *.dfm}

{ TAppEditForm }

procedure TAppEditForm.LoadForm;
begin
// if Client=nil
//    then Client:=GetCWebApp.GetORMClient;
// if Client=nil
//    then Assert(FalsE);
// ProjService:=TServiceApp.Create(Client);

// ProjService.

// WebApp.CallBackGetResult('P',[],nil,0);

//  FWebSocketWrapper.SendRequest('Hello, WebSocket!', 5000)
//    ._then(function(Response: JSValue): JSValue
//      begin
//        Log('Received response: ' + String(Response));
//        Result := Response;
//      end)
//    .catch(function(Error: JSValue): JSValue
//      begin
//        Log('Error: ' + String(Error));
//        Result := Error;
//      end);

  Log('Sent request');
end;

procedure TAppEditForm.WebClientConnection1DataReceived(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; var AResponse: string);
begin
 log(AResponse);
end;

procedure TAppEditForm.WebFormCreate(Sender: TObject);
var A : TArray<String>;
begin
// fWebSocketWrapper:=TWebSocketWrapper.Create('AppEdit','api.true.co.za',8887,'',True);
// Client:=WebApp.GetORMClient;
// if Client=nil
//    then begin
          // Show login form
//          Assert(False,'Login first');
//          exit;
//         end;
// ProjService := TServiceApp.Create(Client);
// ProjService.ListAllFiles(A);






end;

procedure TAppEditForm.WebFormDestroy(Sender: TObject);
begin
// FWebSocketWrapper.Free;
end;

procedure TAppEditForm.BtnSwaggerClick(Sender: TObject);
begin
 //
 WebClientConnection1.URI := 'https://api.true.co.za:8776/app/wrapper/Swagger/mORMotClient.json';
 WebClientConnection1.Active := true;
 WebClientConnection1.Active := true;


end;

function TAppEditForm.GetCurRelationship: String;
begin
// if CurrRelationships=nil
//    then Result:=''
//    else Result:=CurrRelationships.Name;
end;

procedure TAppEditForm.SetCurRelationship(const Value: String);
var I   : Integer;
    O   : TORMProjFile;
    Res : String;
//    OB : _Obj;
begin
 // Check relationships that need saving, ReSave
// if (CurrRelationships<>nil) and  (CurrRelationships.Modified)
//    then begin
//          CurrRelationships.ReSave;
//         end;

 if Value=''
    then begin
          // Clear relationships, now just a list
//          CurrRelationships:=nil;
          // ::: MARK NEED RELOAD
          exit;
         end;

// OB  := WebApp.QueryObj('HtmlFile','Text',function ( aSender : _Obj; const aRowNo : Integer ):Boolean
//                                          begin
//                                           Result:=aSender._Sn['name']=Value;
//                                          end);
//// OB._Sn['Tree']
//
// WebApp.CallBackGetResult('project/GetFile',['name',Value],nil,0);
// TJSON.

// ProjService.GetFile(Value,O, procedure (aClient: TRestClientURI)
//                              begin
//                               O:=
//                              end,
//                              procedure (aClient: TRestClientURI)
//                              begin
//
//                              end);
//
 if (O=nil)
    then Assert(False,Value+' parent not found.');

// if CurrRelationships=O
//    then exit;

// fCurrRelationships:=TTreeFile(O);
end;

procedure TAppEditForm.TreeComboChange(Sender: TObject);
begin
 if Updating
    then exit;
// WebApp.
end;

procedure TAppEditForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebSyntaxMemo1 := TSyntaxMemo.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  TreeCombo := TComboBox.Create(Self);
  BtnSwagger := TButton.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebTreeView1 := TTreeView.Create(Self);
  WebHttpRequest1 := THttpRequest.Create(Self);
  WebClientConnection1 := TClientConnection.Create(Self);
  WebClientDataSet1 := TClientDataSet.Create(Self);
  WebDataSource1 := TDataSource.Create(Self);

  WebSyntaxMemo1.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  TreeCombo.BeforeLoadDFMValues;
  BtnSwagger.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebTreeView1.BeforeLoadDFMValues;
  WebHttpRequest1.BeforeLoadDFMValues;
  WebClientConnection1.BeforeLoadDFMValues;
  WebClientDataSet1.BeforeLoadDFMValues;
  WebDataSource1.BeforeLoadDFMValues;
  try
    Name := 'AppEditForm';
    Width := 1296;
    Height := 673;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnDestroy', 'WebFormDestroy');
    WebSyntaxMemo1.SetParentComponent(Self);
    WebSyntaxMemo1.Name := 'WebSyntaxMemo1';
    WebSyntaxMemo1.Left := 425;
    WebSyntaxMemo1.Top := 60;
    WebSyntaxMemo1.Width := 871;
    WebSyntaxMemo1.Height := 613;
    WebSyntaxMemo1.Align := alClient;
    WebSyntaxMemo1.TabOrder := 0;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 1296;
    WebPanel1.Height := 60;
    WebPanel1.Align := alTop;
    WebPanel1.ChildOrder := 1;
    WebPanel1.TabOrder := 1;
    TreeCombo.SetParentComponent(WebPanel1);
    TreeCombo.Name := 'TreeCombo';
    TreeCombo.Left := 3;
    TreeCombo.Top := 3;
    TreeCombo.Width := 145;
    TreeCombo.Height := 23;
    TreeCombo.HeightPercent := 100.000000000000000000;
    TreeCombo.WidthPercent := 100.000000000000000000;
    SetEvent(TreeCombo, Self, 'OnChange', 'TreeComboChange');
    TreeCombo.ItemIndex := -1;
    BtnSwagger.SetParentComponent(WebPanel1);
    BtnSwagger.Name := 'BtnSwagger';
    BtnSwagger.Left := 184;
    BtnSwagger.Top := 3;
    BtnSwagger.Width := 96;
    BtnSwagger.Height := 51;
    BtnSwagger.Caption := 'Get Swagger';
    BtnSwagger.ChildOrder := 1;
    BtnSwagger.HeightPercent := 100.000000000000000000;
    BtnSwagger.WidthPercent := 100.000000000000000000;
    SetEvent(BtnSwagger, Self, 'OnClick', 'BtnSwaggerClick');
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 60;
    WebPanel2.Width := 425;
    WebPanel2.Height := 613;
    WebPanel2.Align := alLeft;
    WebPanel2.Caption := 'WebPanel2';
    WebPanel2.ChildOrder := 2;
    WebPanel2.TabOrder := 2;
    WebTreeView1.SetParentComponent(WebPanel2);
    WebTreeView1.Name := 'WebTreeView1';
    WebTreeView1.Left := 0;
    WebTreeView1.Top := 0;
    WebTreeView1.Width := 425;
    WebTreeView1.Height := 613;
    WebTreeView1.Align := alClient;
    WebTreeView1.HeightPercent := 100.000000000000000000;
    WebTreeView1.WidthPercent := 100.000000000000000000;
    WebTreeView1.TabOrder := 0;
    WebTreeView1.TabStop := True;
    WebHttpRequest1.SetParentComponent(Self);
    WebHttpRequest1.Name := 'WebHttpRequest1';
    WebHttpRequest1.Headers.BeginUpdate;
    try
      WebHttpRequest1.Headers.Clear;
      WebHttpRequest1.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      WebHttpRequest1.Headers.EndUpdate;
    end;
    WebHttpRequest1.Left := 312;
    WebHttpRequest1.Top := 16;
    WebClientConnection1.SetParentComponent(Self);
    WebClientConnection1.Name := 'WebClientConnection1';
    WebClientConnection1.Active := False;
    WebClientConnection1.Delimiter := ';';
    SetEvent(WebClientConnection1, Self, 'OnDataReceived', 'WebClientConnection1DataReceived');
    WebClientConnection1.Left := 384;
    WebClientConnection1.Top := 16;
    WebClientDataSet1.SetParentComponent(Self);
    WebClientDataSet1.Name := 'WebClientDataSet1';
    WebClientDataSet1.Connection := WebClientConnection1;
    WebClientDataSet1.Left := 528;
    WebClientDataSet1.Top := 16;
    WebDataSource1.SetParentComponent(Self);
    WebDataSource1.Name := 'WebDataSource1';
    WebDataSource1.DataSet := WebClientDataSet1;
    WebDataSource1.Left := 664;
    WebDataSource1.Top := 16;
  finally
    WebSyntaxMemo1.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    TreeCombo.AfterLoadDFMValues;
    BtnSwagger.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebTreeView1.AfterLoadDFMValues;
    WebHttpRequest1.AfterLoadDFMValues;
    WebClientConnection1.AfterLoadDFMValues;
    WebClientDataSet1.AfterLoadDFMValues;
    WebDataSource1.AfterLoadDFMValues;
  end;
end;

end.

