program TrueApp;

{$R *.dres}

uses
  Vcl.Forms,
  WEBLib.Forms,
  SignInU in 'FORMS\SignInU.pas'{*.html},
  LogInU in 'FORMS\LogInU.pas'{*.html},
  WebSocketWrapper in 'WebSocketWrapper.pas',
  AppEditFormU in 'AppEditFormU.pas'{*.html},
  _AppEditUnit in '_AppEditUnit.pas',
  mORMotClient in 'mORMotClient.pas',
  LoginOrmU in 'FORMS\LoginOrmU.pas'{*.html},
  emmethelper in '..\Emmet-Pascal\emmet\emmethelper.pas',
  DataExchangeU in 'DataExchangeU.pas',
  _AppConst in 'TrueAppService\_AppConst.pas',
  FormSwaggerU in 'FormSwaggerU.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
//  WebApp:=_WebApp.Create(nil);
  Application.CreateForm(TFormSwagger, FormSwagger);
  Application.CreateForm(TSignInForm, SignInForm);
  Application.Run;
end.
