unit _AppConst;

interface

/// _Col,_Row,_Cel UPDATE PROTOCOL
///  1) _Col.BeginWrite;  Before any writes
///     This will enable cell delta recording
///  2) _Col.EndWrite;
///     Will compile a ChangeLog to broadcast
///  SERVER:
///   RWLock will lock the collection
///   Broadcast to all clients
///   Keep log of all events in seqNos
///  CLIENT:
///   Update local data + send message
///

const vmt_Obj            =   1;
      vmtAppUser         =   2;
      vmtAuthGroup       =   3;
      vmtContact         =   4;
      vmtPersonalInfo    =   5;
      vmtCompanyInfo     =   6;
      vmtPhoneNumbers    =   7;
      vmtEmailAdresses   =   8;
      vmtAddresses       =   9;
      vmtAttachments     =  10;
      vmtEvent           =  11;
      vmtNetMsg          =  12;
      vmtNetMsgDB        =  13;

type _VmtManager         = class
                            function _Vmt2Obj     ( aVmtID : Integer ) : TObject;overload;
//                            function _Vmt2Obj<T>  ( aVmtID : Integer ) : T;overload;
                           end;

type _AppPage          = ( pgHome,
                           pgSignin,
                           pgToken,
                           pgRegister,
                           pgProfile,
                           pgContacts,
                           pgContact
                           );

implementation

{ _VmtManager }

function _VmtManager._Vmt2Obj(aVmtID: Integer): TObject;
begin
// case aVmtID of
//  vmt_Orm             : exit(_Orm  .Create);
//  vmtEvent            : exit(_Event.Create);
// end;
end;

end.
