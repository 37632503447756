unit WebSocketWrapper;

interface

uses System.SysUtils, System.Classes, System.Generics.Collections, JS, Web, WEBLib.WebSocketClient;

type TResponseCallback         = reference to procedure(const AResponse: string);
     TSocketWrapper         = class
                                 private
                                  fName   : String;
                                  fWebSocket: TSocketClient;
                                  fResponseQueue: TQueue<string>;
                                  fRequestMap: TJSObject;
                                  procedure OnWSMessage(Sender: TObject; AMessage: string);
                                  procedure OnConnect(Sender: TObject);
                                  procedure OnDisconnect(Sender: TObject);
                                  function  ExtractRequestId(const AMessage: string): string;
                                  function  GenerateUniqueId: string;
                                  function  AddRequestIdToMessage(const AMessage, ARequestId: string): string;
                                  procedure RemoveRequest(const RequestId: string);
                                 public
                                  constructor Create ( const aName, aHost : String; aPort : Integer; const aPathName : String ; aUseSSL:Boolean = True);
                                  destructor  Destroy; override;

                                  function  SendRequest(const ARequest: string; ATimeoutMS: Integer = 5000): TJSPromise;
                                  procedure SendMessage(const AMessage: string);
                                  procedure Connect;
                                  procedure Disconnect;

                                  property WebSocket: TSocketClient read FWebSocket;
                                 end;

implementation

uses
  WEBLib.JSON;

{ TWebSocketWrapper }

constructor TSocketWrapper.Create( const aNAme, aHost : String; aPort : Integer; const aPathName : String ; aUseSSL : Boolean = True);
begin
  inherited Create;
  fName                        := aName;
  FWebSocket                   := TSocketClient.Create(nil);
  FWebSocket.HostName          := aHost;
  FWebSocket.Port              := aPort;
  FWebSocket.PathName          := aPathName;
  FWebSocket.UseSSL            := aUseSSL;
  FWebSocket.OnMessageReceived := OnWSMessage;
  FWebSocket.OnConnect         := OnConnect;
  FWebSocket.OnDisconnect      := OnDisconnect;
  FResponseQueue               := TQueue<string>.Create;
  FRequestMap                  := TJSObject.new;
  Connect;
end;

destructor TSocketWrapper.Destroy;
begin
  Disconnect;
  FResponseQueue.Free;
  FWebSocket.Free;
  inherited;
end;

procedure TSocketWrapper.Connect;
begin
  FWebSocket.Connect;
end;

procedure TSocketWrapper.Disconnect;
begin
  FWebSocket.Disconnect;
end;

procedure TSocketWrapper.OnConnect(Sender: TObject);
begin
  console.log('WebSocket connected');
end;

procedure TSocketWrapper.OnDisconnect(Sender: TObject);
begin
  console.log('WebSocket disconnected');
end;

function TSocketWrapper.ExtractRequestId(const AMessage: string): string;
var
  JSONObj: TJSONObject;
begin
  JSONObj := TJSONObject.ParseJSONValue(AMessage) as TJSONObject;
  try
    if Assigned(JSONObj) then
      Result := JSONObj.GetValue('requestId').Value
    else
      Result := '';
  finally
    JSONObj.Free;
  end;
end;

function TSocketWrapper.GenerateUniqueId: string;
begin
  Result := TGUID.NewGuid.ToString;
end;

function TSocketWrapper.AddRequestIdToMessage(const AMessage, ARequestId: string): string;
var
  JSONObj: TJSONObject;
begin
  JSONObj := TJSONObject.Create;
  try
    JSONObj.AddPair('requestId', ARequestId);
    JSONObj.AddPair('message', AMessage);
    Result := JSONObj.ToString;
  finally
    JSONObj.Free;
  end;
end;

procedure TSocketWrapper.RemoveRequest(const RequestId: string);
begin
  asm
    delete this.FRequestMap[RequestId];
  end;
end;

procedure TSocketWrapper.OnWSMessage(Sender: TObject; AMessage: string);
var RequestId : string;
    Callback  : TResponseCallback;
begin
  RequestId := ExtractRequestId(AMessage);

  if not JS.isUndefined(FRequestMap[RequestId])
     then begin
           Callback := TResponseCallback(FRequestMap[RequestId]);
           Callback(AMessage);
           RemoveRequest(RequestId);
          end
     else FResponseQueue.Enqueue(AMessage);
end;

function TSocketWrapper.SendRequest(const ARequest: string; ATimeoutMS: Integer): TJSPromise;
var RequestId: string;
begin
  RequestId := GenerateUniqueId;

  Result := TJSPromise.New(
    procedure(Resolve, Reject: TJSPromiseResolver)
    var
      TimeoutHandle: NativeInt;
    begin
      FRequestMap[RequestId] := TResponseCallback(procedure(AResponse: string)
      begin
        window.clearTimeout(TimeoutHandle);
        RemoveRequest(RequestId);
        Resolve(AResponse);
      end);

      FWebSocket.Send(AddRequestIdToMessage(ARequest, RequestId));

      TimeoutHandle := window.setTimeout(procedure
      begin
        if not JS.isUndefined(FRequestMap[RequestId]) then
        begin
          RemoveRequest(RequestId);
          Reject('Request timed out');
        end;
      end, ATimeoutMS);
    end
  );
end;

procedure TSocketWrapper.SendMessage(const AMessage: string);
begin
  FWebSocket.Send(AMessage);
end;

end.





/// SAMPLE USAGE:

procedure TForm1.btnSendRequestClick(Sender: TObject);
begin
  FWebSocketWrapper.SendRequest('Hello, WebSocket!', 5000)
    .then(function(Response: JSValue): JSValue
      begin
        Log('Received response: ' + string(Response));
        Result := Response;
      end)
    .catch(function(Error: JSValue): JSValue
      begin
        Log('Error: ' + string(Error));
        Result := Error;
      end);

  Log('Sent request');
end;


