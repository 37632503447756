unit LoginOrmU;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  Types,   // Dynamic array types defined here (WebCore).
  Web.mORMot.Types,
  Web.mORMot.Rest,
  mORMotClient;

type
  TOrmLoginForm = class(TForm)
    signin: TButton;
    Username: TEdit;
    ebPassword: TEdit;
    Welcome: TLabel;
    login: TButton;
  private
    { Private declarations }
  public
    { Public declarations }
//   Client: TRestClientURI;
   procedure LoadDFMValues;override;
  published
   [JSExport]
   procedure ClickLogin(Sender: TObject);
   [JSExport]
   procedure ClickSignin(Sender: TObject);
  end;

var
  OrmLoginForm: TOrmLoginForm;

implementation

{$R *.dfm}

uses SignInU;

procedure TOrmLoginForm.ClickLogin(Sender: TObject);
begin
// WebApp.Login( UserName  .Text,
//               ebPassword.Text,
//                     procedure(aClient: TRestClientURI)
//                     begin
//                      // Success,
//                      // 1. SYNC DATA WITH SERVER
//                      // open user dashboard
//                      // Determnie user capabilities
//                      welcome.Caption := 'Connected';
//
//                     end,
//                     procedure(aClient: TRestClientURI)
//                     begin
//                      console.log('Unable to connect to server');
//                     end);
end;

procedure TOrmLoginForm.ClickSignin(Sender: TObject);
begin
 // Open signin screen to register email+phone
 // Send/validate token
 // Open new Contact/Profile screen for initial info
 Application.CreateForm(TSignInForm,SignInForm);
 SignInForm.Show;
end;

procedure TOrmLoginForm.LoadDFMValues;
begin
  inherited;
  Welcome := TLabel.Create('welcome');
  signin := TButton.Create('signIn');
  Username := TEdit.Create('username');
  ebPassword := TEdit.Create('password');
  login := TButton.Create('login');

  Welcome.BeforeLoadDFMValues;
  signin.BeforeLoadDFMValues;
  Username.BeforeLoadDFMValues;
  ebPassword.BeforeLoadDFMValues;
  login.BeforeLoadDFMValues;
  try
    Name := 'OrmLoginForm';
    Width := 640;
    Height := 480;
    Welcome.SetParentComponent(Self);
    Welcome.Name := 'Welcome';
    Welcome.Left := 296;
    Welcome.Top := 184;
    Welcome.Width := 50;
    Welcome.Height := 15;
    Welcome.Caption := 'Welcome';
    Welcome.ElementFont := efCSS;
    Welcome.ElementPosition := epRelative;
    Welcome.Font.Charset := DEFAULT_CHARSET;
    Welcome.Font.Color := clWindowText;
    Welcome.Font.Height := -12;
    Welcome.Font.Name := 'Segoe UI';
    Welcome.Font.Style := [];
    Welcome.HeightStyle := ssAuto;
    Welcome.HeightPercent := 100.000000000000000000;
    Welcome.ParentFont := False;
    Welcome.WidthStyle := ssAuto;
    Welcome.WidthPercent := 100.000000000000000000;
    signin.SetParentComponent(Self);
    signin.Name := 'signin';
    signin.Left := 192;
    signin.Top := 85;
    signin.Width := 96;
    signin.Height := 25;
    signin.Caption := 'Signin';
    signin.ElementFont := efCSS;
    signin.ElementPosition := epRelative;
    signin.Font.Charset := DEFAULT_CHARSET;
    signin.Font.Color := clWindowText;
    signin.Font.Height := -12;
    signin.Font.Name := 'Segoe UI';
    signin.Font.Style := [];
    signin.HeightStyle := ssAuto;
    signin.HeightPercent := 100.000000000000000000;
    signin.ParentFont := False;
    signin.WidthStyle := ssAuto;
    signin.WidthPercent := 100.000000000000000000;
    SetEvent(signin, Self, 'OnClick', 'ClickSignin');
    Username.SetParentComponent(Self);
    Username.Name := 'Username';
    Username.Left := 192;
    Username.Top := 22;
    Username.Width := 121;
    Username.Height := 22;
    Username.ChildOrder := 2;
    Username.ElementFont := efCSS;
    Username.ElementPosition := epRelative;
    Username.Font.Charset := DEFAULT_CHARSET;
    Username.Font.Color := clWindowText;
    Username.Font.Height := -12;
    Username.Font.Name := 'Segoe UI';
    Username.Font.Style := [];
    Username.HeightStyle := ssAuto;
    Username.HeightPercent := 100.000000000000000000;
    Username.ParentFont := False;
    Username.Text := 'User';
    Username.WidthStyle := ssAuto;
    Username.WidthPercent := 100.000000000000000000;
    ebPassword.SetParentComponent(Self);
    ebPassword.Name := 'ebPassword';
    ebPassword.Left := 192;
    ebPassword.Top := 57;
    ebPassword.Width := 121;
    ebPassword.Height := 22;
    ebPassword.ChildOrder := 4;
    ebPassword.ElementFont := efCSS;
    ebPassword.ElementPosition := epRelative;
    ebPassword.Font.Charset := DEFAULT_CHARSET;
    ebPassword.Font.Color := clWindowText;
    ebPassword.Font.Height := -12;
    ebPassword.Font.Name := 'Segoe UI';
    ebPassword.Font.Style := [];
    ebPassword.HeightStyle := ssAuto;
    ebPassword.HeightPercent := 100.000000000000000000;
    ebPassword.ParentFont := False;
    ebPassword.Text := 'synopse';
    ebPassword.WidthStyle := ssAuto;
    ebPassword.WidthPercent := 100.000000000000000000;
    login.SetParentComponent(Self);
    login.Name := 'login';
    login.Left := 294;
    login.Top := 85;
    login.Width := 96;
    login.Height := 25;
    login.Caption := 'Login';
    login.ChildOrder := 4;
    login.ElementFont := efCSS;
    login.ElementPosition := epRelative;
    login.Font.Charset := DEFAULT_CHARSET;
    login.Font.Color := clWindowText;
    login.Font.Height := -12;
    login.Font.Name := 'Segoe UI';
    login.Font.Style := [];
    login.HeightStyle := ssAuto;
    login.HeightPercent := 100.000000000000000000;
    login.ParentFont := False;
    login.WidthStyle := ssAuto;
    login.WidthPercent := 100.000000000000000000;
    SetEvent(login, Self, 'OnClick', 'ClickLogin');
  finally
    Welcome.AfterLoadDFMValues;
    signin.AfterLoadDFMValues;
    Username.AfterLoadDFMValues;
    ebPassword.AfterLoadDFMValues;
    login.AfterLoadDFMValues;
  end;

end;

end.

