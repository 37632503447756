{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2019 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.SyntaxMemo;

{$DEFINE NOPP}

interface

uses
  Classes, WEBLib.Controls, Web, JS, libace, SysUtils, Types;

type
  TSyntaxMemoMode = string;
  TSyntaxMemoTheme = string;

  TSyntaxMemoChangeCursorEvent = procedure(Sender: TObject; ARow: Integer; AColumn: Integer) of object;
  TSyntaxMemoChangeSelectionEvent = procedure(Sender: TObject; ASelectedText: string) of object;

  TSyntaxAutocomplete = (saNone, saBasic, saLive);
  TSyntaxWrapping = (swNone, swPrintMargin, swValue, swView);
  TSyntaxTextDirection = (stdLeftToRight, stdRightToLeft);

  TSyntaxCompletion = class(TCollectionItem)
  private
    FName: string;
    FMeta: string;
    FScore: Integer;
    FCaption: string;
    FValue: string;
  public
    constructor Create(ACollection: TCollection); override;
  published
    property Caption: string read FCaption write FCaption;
    property Meta: string read FMeta write FMeta;
    property Name: string read FName write FName;
    property Score: Integer read FScore write FScore default 0;
    property Value: string read FValue write FValue;
  end;

  TSyntaxCompleter = class(TCollection)
  private
    FOnChange: TNotifyEvent;
    procedure SetItem(Index: Integer; const Value: TSyntaxCompletion); reintroduce;
    function GetItem(Index: Integer): TSyntaxCompletion; reintroduce;
  protected
    procedure Changed; virtual; reintroduce;
    procedure Update(Item: TCollectionItem); override;
  public
    constructor Create;
    function Add: TSyntaxCompletion; reintroduce;
    function Insert(Index: Integer): TSyntaxCompletion; reintroduce;
    property Items[Index: integer]: TSyntaxCompletion read GetItem write SetItem;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TSyntaxMemo = class(TCustomControl)
  private
    FPascalCompleter: TAceCompleter;
    FCustomCompleter: TAceCompleter;
    FEditor: TAceEditor;
    FLines: TStringList;
    FTheme: string;
    FMode: TSyntaxMemoMode;
    FSelStart: Integer;
    FOnChange: TNotifyEvent;
    FOnChangeSelection: TSyntaxMemoChangeSelectionEvent;
    FOnChangeCursor: TSyntaxMemoChangeCursorEvent;
    FAutocompletion: TSyntaxAutocomplete;
    FWordWrapValue: Integer;
    FWordWrapIndent: Boolean;
    FWordWrap: TSyntaxWrapping;
    FSoftTabs: Boolean;
    FFixedGutterWidth: Boolean;
    FShowLineNumbers: Boolean;
    FTextDirection: TSyntaxTextDirection;
    FCustomAutocomplete: TSyntaxCompleter;
    procedure SetText(const Value: string);
    procedure SetTheme(const Value: string);
    procedure SetMode(const Value: TSyntaxMemoMode);
    procedure SetReadOnly(const Value: Boolean);
    procedure SetShowInvisibles(const Value: Boolean);
    procedure SetHighlightActiveLine(const Value: Boolean);
    procedure SetPrintMargin(const Value: Integer);
    procedure SetShowPrintMargin(const Value: Boolean);
    procedure SetLines(const Value: TStringList);
    procedure SetSelLength(const Value: Integer);
    procedure SetSelStart(const Value: Integer);
    procedure SetShowIndentGuides(const Value: Boolean);
    procedure SetCaretPosition(const Value: TPoint);
    procedure SetAutocompletion(const Value: TSyntaxAutocomplete);
    procedure SetWordWrap(const Value: TSyntaxWrapping);
    procedure SetWordWrapIndent(const Value: Boolean);
    procedure SetWordWrapValue(const Value: Integer);
    procedure SetSoftTabs(const Value: Boolean);
    procedure SetFixedGutterWidth(const Value: Boolean);
    procedure SetShowGutter(const Value: Boolean);
    procedure SetShowLineNumbers(const Value: Boolean);
    procedure SetFadeFoldWidgets(const Value: Boolean);
    procedure SetShowFoldWidgets(const Value: Boolean);
    procedure SetTextDirection(const Value: TSyntaxTextDirection);
    procedure SetFontSize(const Value: Integer);
    procedure SetCustomAutocomplete(const Value: TSyntaxCompleter);
    procedure SetPersistentHorizontalScrollbar(const Value: Boolean);
    procedure SetPersistentVerticalScrollbar(const Value: Boolean);
    function GetText: string;
    function GetReadOnly: Boolean;
    function GetShowInvisibles: Boolean;
    function GetHighlightActiveLine: Boolean;
    function GetPrintMargin: Integer;
    function GetShowPrintMargin: Boolean;
    function GetLines: TStringList;
    function GetSelLength: Integer;
    function GetSelStart: Integer;
    function GetShowIndentGuides: Boolean;
    function GetCaretPosition: TPoint;
    function GetShowGutter: Boolean;
    function GetFadeFoldWidgets: Boolean;
    function GetShowFoldWidgets: Boolean;
    function GetFontSize: Integer;
    function GetPersistentHorizontalScrollbar: Boolean;
    function GetPersistentVerticalScrollbar: Boolean;
    function GetFont: string;
    procedure SetFontName(const Value: string);
    function GetTabSize: Integer;
    procedure SetTabSize(const Value: Integer);
  protected
    procedure AutocompleteChanged(Sender: TObject);
    procedure BindEvents; override;
    procedure DoChange; virtual;
    procedure DoChangeCursorPosition(ARow: Integer; AColumn: Integer);
    procedure DoChangeSelection(ASelectedText: string);
    procedure DoLinesChange(Sender: TObject);
    procedure DragDrop(Source: TObject; X, Y: Integer); reintroduce;
    procedure DragOver(Source: TObject; X, Y: Integer; State: TDragState; var Accept: Boolean); reintroduce;
    procedure EditorResize(Sender: TObject);
    procedure RemoveCompleter(ACompleter: TAceCompleter);
    procedure UnbindEvents; override;
    procedure UpdateElement; override;
    function CreateElement: TJSElement; override;
    function GetElementBindHandle: TJSEventTarget; override;
    function GetInstanceName: string;
    function HandleDoChangeCursor(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoChangeSelection(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoDragOver(aEvent: TJSDragEvent): boolean; override;
    function HandleDoDrop(aEvent: TJSDragEvent): Boolean; virtual;
    procedure Loaded; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure BeginUpdate; override;
    procedure Clear;
    procedure DisableLocalKeywords;
    procedure EndUpdate; override;
    procedure Find(AText: string);
    procedure FindAll(AText: string);
    procedure FindNext;
    procedure FindPrevious;
    procedure Focus;
    procedure InitializeKeyWords(ACompleter: TSyntaxCompleter);
    procedure InsertText(AText: string); overload;
    procedure InsertText(APosition: TPoint; AText: string); overload;
    procedure PreloadPascalKeywords;
    procedure Redo;
    procedure RemoveSelectedText;
    procedure RemoveCustomAutocompleter;
    procedure RemovePascalKeywords;
    procedure Replace(AReplacement: string); overload; //replace the selected text
    procedure Replace(AText, AReplacement: string); overload;
    procedure ReplaceAll(AReplacement: string); overload; //replace the selected text
    procedure ReplaceAll(AText, AReplacement: string); overload;
    procedure SelectAll;
    procedure Undo;
    procedure Unselect;
    function OpenSearchBox: Boolean;
    property CaretPosition: TPoint read GetCaretPosition write SetCaretPosition;
    property FontName: string read GetFont write SetFontName;
    property Text: string read GetText write SetText;
  published
    property Align;
    property Anchors;
    property AlignWithMargins;
    property Autocompletion: TSyntaxAutocomplete read FAutocompletion write SetAutocompletion;
    property CustomAutocomplete: TSyntaxCompleter read FCustomAutocomplete write SetCustomAutocomplete;
    property FadeFoldWidgets: Boolean read GetFadeFoldWidgets write SetFadeFoldWidgets;
    property FixedGutterWidth: Boolean read FFixedGutterWidth write SetFixedGutterWidth;
    property FontSize: Integer read GetFontSize write SetFontSize;
    property Height;
    property HighlightActiveLine: Boolean read GetHighlightActiveLine write SetHighlightActiveLine;
    property Left;
    property Lines: TStringList read GetLines write SetLines;
    property Mode: TSyntaxMemoMode read FMode write SetMode;
    property PersistentHorizontalScrollbar: Boolean read GetPersistentHorizontalScrollbar write SetPersistentHorizontalScrollbar;
    property PersistentVerticalScrollbar: Boolean read GetPersistentVerticalScrollbar write SetPersistentVerticalScrollbar;
    property PrintMargin: Integer read GetPrintMargin write SetPrintMargin default 80;
    property ReadOnly: Boolean read GetReadOnly write SetReadOnly;
    property SelLength: Integer read GetSelLength write SetSelLength;
    property SelStart: Integer read GetSelStart write SetSelStart;
    property ShowFoldWidgets: Boolean read GetShowFoldWidgets write SetShowFoldWidgets;
    property ShowGutter: Boolean read GetShowGutter write SetShowGutter;
    property ShowIndentGuides: Boolean read GetShowIndentGuides write SetShowIndentGuides;
    property ShowInvisibles: Boolean read GetShowInvisibles write SetShowInvisibles;
    property ShowLineNumbers: Boolean read FShowLineNumbers write SetShowLineNumbers;
    property ShowPrintMargin: Boolean read GetShowPrintMargin write SetShowPrintMargin;
    property SoftTabs: Boolean read FSoftTabs write SetSoftTabs default True;
    property TextDirection: TSyntaxTextDirection read FTextDirection write SetTextDirection;
    property Theme: string read FTheme write SetTheme;
    property TabOrder;
    property TabSize: Integer read GetTabSize write SetTabSize;
    property TabStop;
    property Top;
    property Visible;
    property Width;
    property WordWrap: TSyntaxWrapping read FWordWrap write SetWordWrap;
    property WordWrapIndented: Boolean read FWordWrapIndent write SetWordWrapIndent;
    property WordWrapValue: Integer read FWordWrapValue write SetWordWrapValue;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnChangeCursor: TSyntaxMemoChangeCursorEvent read FOnChangeCursor write FOnChangeCursor;
    property OnChangeSelection: TSyntaxMemoChangeSelectionEvent read FOnChangeSelection write FOnChangeSelection;
    property OnClick;
    property OnDblClick;
    property OnDragDrop;
    property OnDragOver;
    property OnEnter;
    property OnExit;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseEnter;
    property OnMouseLeave;
    property OnMouseMove;
    property OnMouseUp;
  end;

  TWebSyntaxMemo = class(TSyntaxMemo);

implementation

{ TSyntaxMemo }

procedure TSyntaxMemo.AutocompleteChanged(Sender: TObject);
begin
  InitializeKeyWords(FCustomAutocomplete);
end;

procedure TSyntaxMemo.BeginUpdate;
begin
  inherited;
end;

procedure TSyntaxMemo.BindEvents;
begin
  inherited;
  if Assigned(FEditor) then
  begin
    TJSEventTarget(FEditor).addEventListener('change', @DoChange);
    TJSEventTarget(FEditor.selection).addEventListener('changeCursor', @HandleDoChangeCursor);
    TJSEventTarget(FEditor.selection).addEventListener('changeSelection', @HandleDoChangeSelection);

    TJSEventTarget(FEditor.container).addEventListener('keydown',@HandleDoKeyDown);
    TJSEventTarget(FEditor.container).addEventListener('keyup',@HandleDoKeyUp);
    TJSEventTarget(FEditor.container).addEventListener('keypress',@HandleDoKeyPress);
  end;

  if Assigned(ElementHandle) then
  begin
//    ElementHandle.ondrag := HandleDoDrag;
//    ElementHandle.ondragend := HandleDoDragEnd;
//    ElementHandle.ondragexit := HandleDoDragExit;
    ElementHandle.ondragover := HandleDoDragOver;
//    ElementHandle.ondragstart := HandleDoDragStart;
    ElementHandle.ondrop := HandleDoDrop;
  end;
end;

procedure TSyntaxMemo.Clear;
begin
  Text := '';
end;

function TSyntaxMemo.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');

  FEditor := ace.edit(Result);
  Autocompletion := saBasic;
  SoftTabs := True;
  TabSize := 2;
end;

procedure TSyntaxMemo.CreateInitialize;
begin
  inherited;
  TabStop := True;
  FCustomAutocomplete := TSyntaxCompleter.Create;
  FCustomAutocomplete.OnChange := AutocompleteChanged;
  FLines := TStringList.Create;
  FLines.SkipLastLinebreak := true;
  FLines.OnChange := DoLinesChange;
  EventStopPropagation := EventStopPropagation - [eeMouseMove];

  OnResize := EditorResize;

  if not Assigned(FEditor) and Assigned(ElementHandle) then
  begin
    UnbindEvents;
    FEditor := ace.edit(ElementHandle);
    Autocompletion := saBasic;
    SoftTabs := True;
    TabSize := 2;
    BindEvents;
  end;
end;

destructor TSyntaxMemo.Destroy;
begin
  FCustomAutocomplete.Free;
  FLines.Free;
  inherited;
end;

procedure TSyntaxMemo.DisableLocalKeywords;
begin
  asm
    let langTools = ace.require('ace/ext/language_tools');
    langTools.setCompleters([]);
  end;
end;

procedure TSyntaxMemo.DoChange;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TSyntaxMemo.DoChangeCursorPosition(ARow, AColumn: Integer);
begin
  if Assigned(OnChangeCursor) then
    OnChangeCursor(Self, ARow, AColumn);
end;

procedure TSyntaxMemo.DoChangeSelection(ASelectedText: string);
begin
  if Assigned(OnChangeSelection) then
    OnChangeSelection(Self, ASelectedText);
end;

procedure TSyntaxMemo.DoLinesChange(Sender: TObject);
var
  I: Integer;
  lns: TJSStringDynArray;
  p: TAcePoint;
begin
  if Assigned(FEditor) then
  begin
    SetLength(lns, FLines.Count);
    for I := 0 to FLines.Count - 1 do
      lns[I] := FLines[I];

    if Length(lns) = 0 then
    begin
      SetLength(lns, 1);
      lns[0] := '';
    end;

    FEditor.setValue('');
    p := TAcePoint.new;
    p.row := 0;
    p.column := 0;
    FEditor.session.getDocument.insertMergedLines(p, lns);
  end;
end;

procedure TSyntaxMemo.DragDrop(Source: TObject; X, Y: Integer);
begin
  if Assigned(OnDragDrop) then
    OnDragDrop(Self, Source, X, Y);
end;

procedure TSyntaxMemo.DragOver(Source: TObject; X, Y: Integer;
  State: TDragState; var Accept: Boolean);
begin
  if Assigned(OnDragOver) then
    OnDragOver(Self, Source, X, Y, State, Accept);
end;

procedure TSyntaxMemo.EditorResize(Sender: TObject);
begin
  if Assigned(FEditor) then
    FEditor.resize;
end;

procedure TSyntaxMemo.EndUpdate;
begin
  inherited;
  InitializeKeyWords(FCustomAutocomplete);
end;

procedure TSyntaxMemo.Find(AText: string);
begin
  if Assigned(FEditor) then
    FEditor.find(AText);
end;

procedure TSyntaxMemo.FindAll(AText: string);
begin
  if Assigned(FEditor) then
    FEditor.findAll(AText);
end;

procedure TSyntaxMemo.FindNext;
begin
  if Assigned(FEditor) then
    FEditor.findNext;
end;

procedure TSyntaxMemo.FindPrevious;
begin
  if Assigned(FEditor) then
    FEditor.findPrevious;
end;

procedure TSyntaxMemo.Focus;
begin
  if Assigned(FEditor) then
    FEditor.focus;
end;

function TSyntaxMemo.GetTabSize: Integer;
begin
  if Assigned(FEditor) then
    Result := FEditor.session.getTabSize
  else
    Result := 2;
end;

function TSyntaxMemo.GetText: string;
begin
  if Assigned(FEditor) then
    Result := FEditor.getValue
  else
    Result := '';
end;

function TSyntaxMemo.GetCaretPosition: TPoint;
var
  p: TPoint;
begin
  p.x := 0;
  p.y := 0;

  if Assigned(FEditor) then
  begin
    p.x := FEditor.getCursorPosition.column;
    p.y := FEditor.getCursorPosition.row;
  end;

  Result := p;
end;

function TSyntaxMemo.GetElementBindHandle: TJSEventTarget;
begin
  if Assigned(FEditor) then
    Result := TJSEventTarget(FEditor)
  else
    inherited;
end;

function TSyntaxMemo.GetFadeFoldWidgets: Boolean;
begin
  if Assigned(FEditor) then
    Result := FEditor.getFadeFoldWidgets
  else
    Result := False;
end;

function TSyntaxMemo.GetFont: string;
begin
  Result := ''; //TODO
end;

function TSyntaxMemo.GetFontSize: Integer;
var
  s: string;
begin
  if Assigned(FEditor) then
  begin
    s := FEditor.getFontSize + '';
    s := StringReplace(s, 'p', '', []);
    s := StringReplace(s, 'x', '', []);
    Result := StrToInt(s);
  end
  else
    Result := 12;
end;

function TSyntaxMemo.GetHighlightActiveLine: Boolean;
begin
  if Assigned(FEditor) then
    Result := FEditor.getHighlightActiveLine
  else
    Result := False;
end;

function TSyntaxMemo.GetInstanceName: string;
begin
  Result := Name + '_' + ClassName;
end;

function TSyntaxMemo.GetLines: TStringList;
var
  strl: TStringList;
  ds: TJSStringDynArray;
  I: Integer;
begin
  if not IsUpdating then
  begin
    strl := TStringList.Create;
    if Assigned(FEditor) then
    begin
      ds := FEditor.session.getDocument.getAllLines;
      strl := TStringList.Create;
      strl.BeginUpdate;
      for I := 0 to length(ds) - 1 do
        strl.Add(ds[I]);
      strl.EndUpdate;
    end;
    FLines.assign(strl);
    strl.Free;
  end;
  Result := FLines;
end;

function TSyntaxMemo.GetPersistentHorizontalScrollbar: Boolean;
begin
  if Assigned(FEditor) then
    Result := FEditor.getHScrollBarAlwaysVisible
  else
    Result := False;
end;

function TSyntaxMemo.GetPersistentVerticalScrollbar: Boolean;
begin
  if Assigned(FEditor) then
    Result := FEditor.getVScrollBarAlwaysVisible
  else
    Result := False;
end;

function TSyntaxMemo.GetPrintMargin: Integer;
begin
  if Assigned(FEditor) then
    Result := FEditor.getPrintMarginColumn
  else
    Result := 80;
end;

function TSyntaxMemo.GetReadOnly: Boolean;
begin
  if Assigned(FEditor) then
    Result := FEditor.getReadOnly
  else
    Result := False;
end;

function TSyntaxMemo.GetSelLength: Integer;
var
  d: TAceDocument;
begin
  if Assigned(FEditor) then
  begin  
    d := FEditor.session.getDocument;
    Result := d.positionToIndex(FEditor.selection.getRange.&end) - SelStart;
  end
  else
    Result := 0;
end;

function TSyntaxMemo.GetSelStart: Integer;
begin
  if Assigned(FEditor) then
    Result := FEditor.session.getDocument.positionToIndex(FEditor.selection.getRange.start)
  else
    Result := FSelStart;
end;

function TSyntaxMemo.GetShowFoldWidgets: Boolean;
begin
  if Assigned(FEditor) then
    FEditor.getShowFoldWidgets
  else
    Result := False;
end;

function TSyntaxMemo.GetShowGutter: Boolean;
begin
  if Assigned(FEditor) then
    Result := FEditor.renderer.getShowGutter
  else
    Result := False;
end;

function TSyntaxMemo.GetShowIndentGuides: Boolean;
begin
  if Assigned(FEditor) then
    Result := FEditor.getDisplayIndentGuides
  else
    Result := False;
end;

function TSyntaxMemo.GetShowInvisibles: Boolean;
begin
  if Assigned(FEditor) then
    Result := FEditor.getShowInvisibles
  else
    Result := False;
end;

function TSyntaxMemo.GetShowPrintMargin: Boolean;
begin
  if Assigned(FEditor) then
    Result := FEditor.getShowPrintMargin
  else
    Result := False;
end;

function TSyntaxMemo.HandleDoChangeCursor(Event: TJSMouseEvent): Boolean;
begin
  if Assigned(FEditor) then
    DoChangeCursorPosition(FEditor.getCursorPosition.row, FEditor.getCursorPosition.column);
    
  Result := True;
end;

function TSyntaxMemo.HandleDoChangeSelection(Event: TJSMouseEvent): Boolean;
begin
  if Assigned(FEditor) then
    DoChangeSelection(FEditor.getSelectedText);

  Result := True;
end;

function TSyntaxMemo.HandleDoDragOver(aEvent: TJSDragEvent): boolean;
var
  allow: boolean;
  obj: TObject;
begin
  AEvent.preventDefault;
  DragOver(obj, 0, 0, dsDragMove, allow);
  Result := True;
end;

function TSyntaxMemo.HandleDoDrop(aEvent: TJSDragEvent): Boolean;
var
  dragobj: TDragSourceObject;
begin
  AEvent.preventDefault;

  dragobj := TDragSourceObject.Create;
  dragobj.Event := aEvent;
  dragobj.&Object := Self; // figure out with which source component event is associated
  try
    DragDrop(dragobj, aEvent.clientX, aEvent.clientY);
  finally
    dragobj.Free;
  end;

  Result := True;
end;

procedure TSyntaxMemo.InitializeKeyWords(ACompleter: TSyntaxCompleter);
var
  I: Integer;
  ca: TAceCompletions;
  c: TAceCompletion;
begin
  if IsUpdating then
    Exit;

  RemoveCompleter(FCustomCompleter);

  SetLength(ca, ACompleter.Count);
  for I := 0 to ACompleter.Count - 1 do
  begin
    c := TAceCompletion.new;
    c.caption := ACompleter.Items[I].Caption;
    c.meta := ACompleter.Items[I].Meta;
    c.name := ACompleter.Items[I].Name;
    c.score := ACompleter.Items[I].Score;
    c.value := ACompleter.Items[I].Value;
    ca[I] := c;
  end;

  asm
    var completer = {
      getCompletions: function(editor, session, pos, prefix, callback) {
        callback(null, ca);
      }
    };

    this.FCustomCompleter = completer;

    let langTools = ace.require('ace/ext/language_tools');
    langTools.addCompleter(completer);
  end;
end;

procedure TSyntaxMemo.InsertText(AText: string);
begin
  if Assigned(FEditor) then
    FEditor.session.getDocument.insert(FEditor.getCursorPosition, AText);
end;

procedure TSyntaxMemo.InsertText(APosition: TPoint; AText: string);
var
  p: TAcePoint;
begin
  if Assigned(FEditor) then
  begin
    p := TAcePoint.new;
    p.row := APosition.y;
    p.column := APosition.x;
    FEditor.session.getDocument.insert(p, AText);
  end;
end;

procedure TSyntaxMemo.Loaded;
var
  fs: integer;
begin
  inherited;
  fs := FontSize;
  FontSize := fs+1;
  FontSize := fs-1;
end;

function TSyntaxMemo.OpenSearchBox: Boolean;
begin
  if Assigned(FEditor) then
    Result := FEditor.execCommand('find')
  else
    Result := False;
end;

procedure TSyntaxMemo.PreloadPascalKeywords;
var
  ca: TAceCompletions;
  c: TAceCompletion;
  I: Integer;
begin
  RemoveCompleter(FPascalCompleter);

  SetLength(ca, Length(PascalKeywords));
  for I := 0 to Length(PascalKeywords) do
  begin
    c := TAceCompletion.new;
    c.value := PascalKeywords[I];
    c.score := 1000;
    c.meta := 'keyword';
    ca[I] := c;
  end;

  asm
    var completer = {
      getCompletions: function(editor, session, pos, prefix, callback) {
        callback(null, ca);
      }
    };

    this.FPascalCompleter = completer;

    let langTools = ace.require('ace/ext/language_tools');
    langTools.addCompleter(completer);
  end;
end;

procedure TSyntaxMemo.Replace(AReplacement: string);
begin
  if Assigned(FEditor) then
    FEditor.replace(AReplacement);
end;

procedure TSyntaxMemo.Redo;
begin
  if Assigned(FEditor) then
    FEditor.redo;
end;

procedure TSyntaxMemo.RemoveCompleter(ACompleter: TAceCompleter);
begin
  if Assigned(FEditor) then
  begin
    asm
      let completers = this.FEditor.completers;
      if (completers) {
        if (completers.indexOf(ACompleter) !== -1) {
          completers.splice(completers.indexOf(ACompleter), 1);
        };
      };
    end;
  end;
end;

procedure TSyntaxMemo.RemoveCustomAutocompleter;
begin
  RemoveCompleter(FCustomCompleter);
end;

procedure TSyntaxMemo.RemovePascalKeywords;
begin
  RemoveCompleter(FPascalCompleter);
end;

procedure TSyntaxMemo.RemoveSelectedText;
begin
  if Assigned(FEditor) then
    FEditor.session.getDocument.remove(FEditor.selection.getRange);
end;

procedure TSyntaxMemo.Replace(AText, AReplacement: string);
var
  opt: TAceSearchOptions;
begin
  if Assigned(FEditor) then
  begin
    opt := TAceSearchOptions.new;
    opt.needle := AText;
    FEditor.replace(AReplacement, opt);
  end;
end;

procedure TSyntaxMemo.ReplaceAll(AText, AReplacement: string);
var
  opt: TAceSearchOptions;
begin
  if Assigned(FEditor) then
  begin
    opt := TAceSearchOptions.new;
    opt.needle := AText;
    FEditor.replaceAll(AReplacement, opt);
  end;
end;

procedure TSyntaxMemo.ReplaceAll(AReplacement: string);
begin
  if Assigned(FEditor) then
    FEditor.replaceAll(AReplacement);
end;

procedure TSyntaxMemo.SetTabSize(const Value: Integer);
begin
  if Assigned(FEditor) then
    FEditor.session.setTabSize(Value);
end;

procedure TSyntaxMemo.SetText(const Value: string);
begin
  if Assigned(FEditor) then
  begin
    FEditor.setValue(Value);
    FEditor.clearSelection;
  end;
end;

procedure TSyntaxMemo.SetTextDirection(const Value: TSyntaxTextDirection);
begin
  FTextDirection := Value;

  if Assigned(FEditor) then
  begin
    case Value of
      stdLeftToRight: FEditor.setOption('rtl', False);
      stdRightToLeft: FEditor.setOption('rtl', True);
    end;
  end;
end;

procedure TSyntaxMemo.SelectAll;
begin
  if Assigned(FEditor) then
    FEditor.selectAll;
end;

procedure TSyntaxMemo.SetAutocompletion(const Value: TSyntaxAutocomplete);
begin
  FAutocompletion := Value;

  if Assigned(FEditor) then
  begin
    case Value of
      saNone:
      begin
        FEditor.setOption('enableBasicAutocompletion', False);
        FEditor.setOption('enableLiveAutocompletion', False);
      end;
      saBasic:
      begin
        FEditor.setOption('enableBasicAutocompletion', True);
        FEditor.setOption('enableLiveAutocompletion', False);
      end;
      saLive:
      begin
        FEditor.setOption('enableBasicAutocompletion', True);
        FEditor.setOption('enableLiveAutocompletion', True);
      end;
    end;
  end;
end;

procedure TSyntaxMemo.SetCaretPosition(const Value: TPoint);
begin
  if Assigned(FEditor) then
    FEditor.selection.moveTo(Value.y, Value.x);
end;

procedure TSyntaxMemo.SetCustomAutocomplete(const Value: TSyntaxCompleter);
begin
  FCustomAutocomplete.Assign(Value);
  InitializeKeyWords(FCustomAutocomplete);
end;

procedure TSyntaxMemo.SetFadeFoldWidgets(const Value: Boolean);
begin
  if Assigned(FEditor) then
    FEditor.setFadeFoldWidgets(Value);
end;

procedure TSyntaxMemo.SetFixedGutterWidth(const Value: Boolean);
begin
  FFixedGutterWidth := Value;

  if Assigned(FEditor) then
  begin
    FEditor.renderer.setOption('fixedWidthGutter', Value);

    asm
      let iNum = 999;
      this.FEditor.session.gutterRenderer =  {
        getWidth: function(session, lastLineNumber, config) {
          if (lastLineNumber < (iNum + 1) & Value) {
            return iNum.toString().length * config.characterWidth;
          } else {
            return lastLineNumber.toString().length * config.characterWidth;
          }
        },
        getText: function(session, row) {
            return row + 1;
        }
      };
    end;
  end;
end;

procedure TSyntaxMemo.SetFontName(const Value: string);
begin
  if Assigned(FEditor) then
    FEditor.setOption('fontFamily', Value);
end;

procedure TSyntaxMemo.SetFontSize(const Value: Integer);
begin
  if Assigned(FEditor) then
  begin
    FEditor.setFontSize(IntToStr(Value) + 'px');
  end;
end;

procedure TSyntaxMemo.SetHighlightActiveLine(const Value: Boolean);
begin
  if Assigned(FEditor) then
    FEditor.setHighlightActiveLine(Value);
end;

procedure TSyntaxMemo.SetLines(const Value: TStringList);
begin
  FLines.Assign(Value);
end;

procedure TSyntaxMemo.SetMode(const Value: TSyntaxMemoMode);
begin
  FMode := Value;

  if Assigned(FEditor) then
    FEditor.session.setMode('ace/mode/' + Value);
end;

procedure TSyntaxMemo.SetPersistentHorizontalScrollbar(const Value: Boolean);
begin
  if Assigned(FEditor) then
    FEditor.setHScrollbarAlwaysVisible(Value);
end;

procedure TSyntaxMemo.SetPersistentVerticalScrollbar(const Value: Boolean);
begin
  if Assigned(FEditor) then
    FEditor.setVScrollbarAlwaysVisible(Value);
end;

procedure TSyntaxMemo.SetPrintMargin(const Value: Integer);
begin
  if Assigned(FEditor) then
    FEditor.setPrintMarginColumn(Value);
end;

procedure TSyntaxMemo.SetReadOnly(const Value: Boolean);
begin
  if Assigned(FEditor) then
    FEditor.setReadOnly(Value);
end;

procedure TSyntaxMemo.SetSelLength(const Value: Integer);
var
  r: TAceRange;
  ep, sp: TAcePoint;
  d: TAceDocument;
begin
  if Assigned(FEditor) then
  begin  
    d := FEditor.session.getDocument;
    ep := d.indexToPosition(SelStart + Value);
    sp := d.indexToPosition(SelStart);
    if (SelStart + Value) < SelStart then
    begin
      r := ace.Range.fromPoints(ep, sp);
      FEditor.selection.setSelectionRange(r, True);
    end
    else
    begin
      r := ace.Range.fromPoints(sp, ep);
      FEditor.selection.setSelectionRange(r, False);
    end;
  end;  
end;

procedure TSyntaxMemo.SetSelStart(const Value: Integer);
var
  r: TAceRange;
  p: TAcePoint;
  d: TAceDocument;
begin
  FSelStart := Value;

  if Assigned(FEditor) then
  begin
    d := FEditor.session.getDocument;
    p := d.indexToPosition(Value);
    r := ace.Range.fromPoints(p, p);
    FEditor.selection.setSelectionRange(r, False);
  end;
end;

procedure TSyntaxMemo.SetShowFoldWidgets(const Value: Boolean);
begin
  if Assigned(FEditor) then
    FEditor.setShowFoldWidgets(Value);
end;

procedure TSyntaxMemo.SetShowGutter(const Value: Boolean);
begin
  if Assigned(FEditor) then
    FEditor.renderer.setShowGutter(Value);
end;

procedure TSyntaxMemo.SetShowIndentGuides(const Value: Boolean);
begin
  if Assigned(FEditor) then
    FEditor.setDisplayIndentGuides(Value);
end;

procedure TSyntaxMemo.SetShowInvisibles(const Value: Boolean);
begin
  if Assigned(FEditor) then
    FEditor.setShowInvisibles(Value);
end;

procedure TSyntaxMemo.SetShowLineNumbers(const Value: Boolean);
begin
  FShowLineNumbers := Value;

  if Assigned(FEditor) then
    FEditor.renderer.setOption('showLineNumbers', Value);
end;

procedure TSyntaxMemo.SetShowPrintMargin(const Value: Boolean);
begin
  if Assigned(FEditor) then
    FEditor.setShowPrintMargin(Value);
end;

procedure TSyntaxMemo.SetSoftTabs(const Value: Boolean);
begin
  FSoftTabs := Value;

  if Assigned(FEditor) then
   FEditor.session.setOption('useSoftTabs', Value);
end;

procedure TSyntaxMemo.SetTheme(const Value: string);
begin
  FTheme := Value;

  if Assigned(FEditor) then
    FEditor.setTheme('ace/theme/' + Value);
end;

procedure TSyntaxMemo.SetWordWrap(const Value: TSyntaxWrapping);
begin
  FWordWrap := Value;

  if Assigned(FEditor) then
  begin
    if FWordWrapIndent then
      FEditor.session.setOption('indentedSoftWrap', True)
    else
      FEditor.session.setOption('indentedSoftWrap', False);

    case Value of
      swNone: FEditor.session.setUseWrapMode(False);
      swPrintMargin:
      begin
        FEditor.session.setUseWrapMode(True);
        FEditor.session.setWrapLimitRange(PrintMargin, PrintMargin);
      end;
      swValue:
      begin
        FEditor.session.setUseWrapMode(True);
        FEditor.session.setWrapLimitRange(FWordWrapValue, FWordWrapValue);
      end;
      swView:
      begin
        FEditor.session.setUseWrapMode(True);
        FEditor.session.setWrapLimitRange();
      end;
    end;
  end;
end;

procedure TSyntaxMemo.SetWordWrapIndent(const Value: Boolean);
begin
  FWordWrapIndent := Value;
  SetWordWrap(FWordWrap);
end;

procedure TSyntaxMemo.SetWordWrapValue(const Value: Integer);
begin
  FWordWrapValue := Value;
  if FWordWrap = swValue then
    SetWordWrap(FWordWrap);
end;

procedure TSyntaxMemo.UnbindEvents;
begin
  inherited;
  if Assigned(FEditor) then
  begin
    TJSEventTarget(FEditor).removeEventListener('change', @DoChange);
    TJSEventTarget(FEditor.selection).removeEventListener('changeCursor', @HandleDoChangeCursor);
    TJSEventTarget(FEditor.selection).removeEventListener('changeSelection', @HandleDoChangeSelection);
    
    TJSEventTarget(FEditor.container).removeEventListener('keydown',@HandleDoKeyDown);
    TJSEventTarget(FEditor.container).removeEventListener('keyup',@HandleDoKeyUp);
    TJSEventTarget(FEditor.container).removeEventListener('keypress',@HandleDoKeyPress);
  end;
end;

procedure TSyntaxMemo.Undo;
begin
  if Assigned(FEditor) then
    FEditor.undo;
end;

procedure TSyntaxMemo.Unselect;
begin
  if Assigned(FEditor) then
    FEditor.clearSelection;
end;

procedure TSyntaxMemo.UpdateElement;
begin
  inherited;

  if not IsUpdating and Assigned(ElementHandle) then
  begin
    ElementHandle.style.setProperty('font-family','');
    ElementHandle.style.setProperty('font-style','');
    ElementHandle.style.setProperty('font-size','');
    ElementHandle.style.setProperty('color','');
    ElementHandle.style.setProperty('user-select','');
    ElementHandle.style.setProperty('outline','');
  end;

end;

{ TSyntaxCompletion }

constructor TSyntaxCompletion.Create(ACollection: TCollection);
begin
  inherited;
  FScore := 0;
end;

{ TSyntaxCompleter }

function TSyntaxCompleter.Add: TSyntaxCompletion;
begin
  Result := TSyntaxCompletion(inherited Add);
end;

procedure TSyntaxCompleter.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TSyntaxCompleter.Create;
begin
  inherited Create(TSyntaxCompletion);
end;

function TSyntaxCompleter.GetItem(Index: Integer): TSyntaxCompletion;
begin
  Result := TSyntaxCompletion(inherited Items[Index]);
end;

function TSyntaxCompleter.Insert(Index: Integer): TSyntaxCompletion;
begin
  Result := TSyntaxCompletion(inherited Insert(Index));
end;

procedure TSyntaxCompleter.SetItem(Index: Integer;
  const Value: TSyntaxCompletion);
begin
  inherited Items[Index] := Value;
end;

procedure TSyntaxCompleter.Update(Item: TCollectionItem);
begin
  inherited;
  Changed;
end;

end.
