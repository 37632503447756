unit DataExchangeU;

interface

uses System.SysUtils,
     System.Classes,
     System.Generics.Collections,
     JS, Web,
     Data.DB,
     WEBLib.Modules,
     WEBLib.WebSocketClient,
     WEBLib.IndexedDb,
     Web.mORMot.Types,
     Web.mORMot.OrmTypes,
     mORMotClient;

type TDataModExch = class(TDataModule)
                     WebIndexedDbClientDataset1: TIndexedDbClientDataset;
                    private
                     { Private declarations }
                    public
                     { Public declarations }
                    protected procedure LoadDFMValues; override; end;

type _DataCol<T:TOrm>=class
                     public
                      Col     : TOrmObjDef;
                      DB      : TIndexedDbClientDataset;
                      Data    : TObjectDictionary<TID,T>;
                      Touched : TDateTime;
                      constructor Create ( aCol : TOrmObjDef );
                      destructor  Destroy;override;
                      procedure Touch;
                     end;

type TDataExchKind = ( deJson, de_Obj );
type TDataExch    = class
                    private
                     { Private declarations }
                    public
                     { Public declarations }
                     WSC  : TSocketClient;
//                     Cols : TObjectDictionary<String,_DataCol>;
                     constructor Create( const aPath : String );
                     destructor  Destroy;override;
                     procedure OnConnect    ( aSender : TObject);
                     procedure OnDisConnect ( aSender : TObject);
                     procedure WSCBinaryDataReceived(Sender: TObject; AData: TBytes);
//                     procedure OnEvent ( Event : _Obj );
                    end;

var DataModExch: TDataModExch;

procedure ObjDefCreateDatasetFields ( aObjDef : TOrmObjDef; aDataset: TDataset);
procedure ObjDefCheckDatasetFields  ( aObjDef : TOrmObjDef; aDataset: TDataset);

implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}

uses _AppConst;

{$R *.dfm}

procedure ObjDefCheckDatasetFields(aObjDef : TOrmObjDef; aDataset: TDataset);
//var I : Integer;
//    F : _Field;
//    N,
//    M : String;
begin
// Assert(Length(aObjDef.FieldNames)=Length(aObjDef.FieldMetaNames));
// for I:=0 to High(aObjDef.FieldNames) do
//  begin
//   N := aObjDef.FieldNames     [I];
//   M := aObjDef.FieldMetaNames [I];
//
//
//  end;
//
//
//  for I := 0 to fFields.Count-1 do
//    begin
//    F:=fFields[I];
//    if F.MetaType.FieldType=ftUnknown
//        then begin
//              case aDataset.FieldByName(F.Name).DataType of
//              ftString  : F.MetaType.FieldType:=ftString;
//              ftInteger : F.MetaType.FieldType:=ftInteger;
//              ftFloat   : F.MetaType.FieldType:=ftFloat;
//              ftDateTime: F.MetaType.FieldType:=ftDateTime;
//              ftBoolean : F.MetaType.FieldType:=ftBoolean;
//              else Assert(False,'Unknown field kind '+IntToStr(Ord(aDataset.FieldByName(F.Name).FieldKind)));
//              end;
//            end;
//    end;
end;

procedure ObjDefCreateDatasetFields ( aObjDef : TOrmObjDef; aDataset: TDataset);
//var I : Integer;
//    F : _Field;
begin
// for I := 0 to fFields.Count-1 do
//  begin
//   F:=fFields[I];
//   case F.MetaType.FieldType of
//    ftString  : aDataset.FieldDefs.Add(F.Name,ftString,F.MetaType.SortStrSize);
//    ftInteger : aDataset.FieldDefs.Add(F.Name,ftInteger);
//    ftFloat   : aDataset.FieldDefs.Add(F.Name,ftFloat);
//    ftDateTime: aDataset.FieldDefs.Add(F.Name,ftDateTime);
//    ftBoolean : aDataset.FieldDefs.Add(F.Name,ftBoolean);
//   else Assert(False,'Unknown field type '+IntToStr(Ord(F.MetaType.FieldType)));
//   end;
//  end;
end;


{ TDataExch }

constructor TDataExch.Create(const aPath : String);
begin
 inherited Create;
// Cols := TObjectDictionary<String,_DataCol>.Create([doOwnsValues]);

 WSC:=TSocketClient.Create(nil);
 WSC.HostName      := SERVER_HOST;
 WSC.Port          := SERVER_PORT;
 WSC.PathName      := aPath;
 WSC.UseSSL        := True;
 WSC.OnConnect     := OnConnect;
 WSC.OnDisconnect  := OnDisConnect;
 WSC.OnBinaryDataReceived := WSCBinaryDataReceived;
 WSC.Connect;
end;

destructor TDataExch.Destroy;
begin
 WSC.Free;
// Cols.Free;
 inherited Destroy;
end;

procedure TDataExch.OnConnect(aSender: TObject);
begin

end;

procedure TDataExch.OnDisConnect(aSender: TObject);
begin

end;

procedure TDataExch.WSCBinaryDataReceived(Sender: TObject; AData: TBytes);
var S : TStream;
//    O : _StrmObj;
//    E : _Event;
//    D : _NetMessageDB;
//    VID : _VmtID;
//    CLS : _Class;
//    TC : _DataCol;
begin
// S := TStream.Create;
// try
//  S.WriteData(aData[0],Length(aData));  // S.SetPointer(@AData[0], Length(AData));
//  S.Seek(0,soBeginning);
//  O:=App.StrmObjFromStream(S);
//  case O.GetVmtID of
//   vmtEvent  :  begin
//                 E := _Event(O);
////                 if D.Command=cmdColContent
//                 case E.Ops of
//                  eoNONE        : ;
//                  eoDbAdd       : begin
//                //                   App.Collection[aEvent.ID1].Add(aEvent.);
//                //                   aEvent.ID1
//                                  end;
//
//                  eoDBEdit      : ;
//                  eoDbEditSort  : ;
//                  eoDbDelete    : ;
//                  eoView        : ;
//                 end;
//
//                 App.OnEvent(E);
//                end;
//   vmtNetMsg :  begin
//
//                end;
//   vmtNetMsgDB: begin
//                 D:=_NetMessageDB(O);
//                 case D.Command of
//                  cmdValidatePreLogin: ;
//                  cmdLogin: ;
//                  cmdSubmit: ;
//                  cmdCelContent: ;
//                  cmdRowContent: ;
//                  cmdColContent : begin
//                                   VID := D.Event.Dst;
//                                   CLS := App.VmtIDToClass(VID);
//                                   Assert(CLS<>nil);
//                                   if not Cols.TryGetValue(CLS.Name,TC)
//                                      then begin
//                                            TC:=_DataCol.Create(App.Collection[CLS.Name]);
//                                            Cols.Add(CLS.Name,TC);
//                                           end;
//
//                                   // Update entire table
//
//                                  end;
//                 end;
//                end;
//  end;
//
// finally
//  S.Free;
// end;
end;

{ _DataCol }

constructor _DataCol<T>.Create( aCol: TOrmObjDef );
begin
 inherited Create;
 Col  := aCol;
 Data := TObjectDictionary<TID,T>.Create([doOwnsValues]);
 DB   := TIndexedDbClientDataset.Create(nil);
// Col.CreateDatasetFields(DB);
 DB.IDBDatabaseName    := 'TrueApp';
 DB.IDBObjectStoreName := Col.Name;
 DB.IDBKeyFieldName    := 'ID';
 DB.Active:=True;
end;

destructor _DataCol<T>.Destroy;
begin
 DB   .Free;
 Data .Free;
// Col  :=nil;
 inherited;
end;

procedure _DataCol<T>.Touch;
begin
 Touched:=Now;
end;

procedure TDataModExch.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebIndexedDbClientDataset1 := TIndexedDbClientDataset.Create(Self);

  WebIndexedDbClientDataset1.BeforeLoadDFMValues;
  try
    Name := 'DataModExch';
    Height := 505;
    Width := 933;
    WebIndexedDbClientDataset1.SetParentComponent(Self);
    WebIndexedDbClientDataset1.Name := 'WebIndexedDbClientDataset1';
    WebIndexedDbClientDataset1.IDBAutoIncrement := True;
    WebIndexedDbClientDataset1.Left := 88;
    WebIndexedDbClientDataset1.Top := 56;
  finally
    WebIndexedDbClientDataset1.AfterLoadDFMValues;
  end;
end;

end.

