{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit libindexeddb;

{$mode objfpc}
{$modeswitch externalclass}

interface

uses
  types, classes, JS, Web;

type
  TMIDBObjectStore = Class;
  TMIDBDatabase = class;
  TMIDBRequest = class;

  TMIDBTransactionMode = class
  const
    readonly = 'readonly';
    readwrite = 'readwrite';
    versionchange = 'versionchange';
  end;

 { TMIDBTransaction }

  TMIDBTransaction = class external name 'IDBTransaction'  (TJSEventTarget)
  private
    FDB : TMIDBDatabase; external name 'db';
    FError: JSValue; external name 'error';
    FMode: String; external name 'mode';
    FObjectStoreNames: TStringDynArray; external name 'objectStoreNames';
  public
    procedure abort;
    function objectStore(aName : String) : TMIDBObjectStore;
    property db : TMIDBDatabase read FDB;
    property mode : String read FMode;
    property objectStoreNames : TStringDynArray read FObjectStoreNames;
    property error : JSValue read FError;
  end;


  { TMIDBKeyRange }

  TMIDBKeyRange = class external name 'IDBKeyRange'
  private
    FLower: JSValue;
    FLowerOpen: Boolean;
    FUpper: JSValue;
    FUpperOpen: Boolean;
  Public
    Class Function bound(aLower,aUpper : JSValue) : TMIDBKeyRange; overload;
    Class Function bound(aLower,aUpper : JSValue; aLowerOpen : Boolean) : TMIDBKeyRange; overload;
    Class Function bound(aLower,aUpper : JSValue; aLowerOpen,aUpperOpen : Boolean) : TMIDBKeyRange; overload;
    Class Function lowerBound(aLower : JSValue) : TMIDBKeyRange; overload;
    Class Function lowerBound(aLower : JSValue; aOpen: Boolean) : TMIDBKeyRange; overload;
    Class Function only(aValue : JSValue) : TMIDBKeyRange;
    Class Function upperBound(aUpper : JSValue) : TMIDBKeyRange; overload;
    Class Function upperBound(aUpper : JSValue; aOpen: Boolean) : TMIDBKeyRange; overload;
    function includes (aValue : JSValue) : Boolean;
    property lower : JSValue read FLower;
    property lowerOpen : Boolean read FLowerOpen;
    property upper : JSValue read FUpper;
    property upperOpen : Boolean read FUpperOpen;
  end;

  TMIDBIndexParameters = record
    unique : boolean;
    multiEntry : boolean;
    locale : string;
  end;


  { TMIDBIndex }

  TMIDBIndex = class external name 'IDBIndex'
  private
    FKeyPath: JSValue; external name 'keyPath';
    FMultiEntry: Boolean; external name 'multiEntry';
    FObjectStore: TMIDBObjectStore; external name 'objectStore';
    FUnique: boolean; external name 'unique';
  public
    name : string;
    function count : TMIDBRequest;
    function get(aKey : jsValue) : TMIDBRequest; overload;
    function get(aKey : TMIDBKeyRange) : TMIDBRequest; overload;
    function getAll : TMIDBRequest; overload;
    function getAll(aKey : jsValue) : TMIDBRequest; overload;
    function getAll(aKey : TMIDBKeyRange) : TMIDBRequest; overload;
    function getAll(aKey : jsValue; ACount : NativeInt) : TMIDBRequest; overload;
    function getAll(aKey : TMIDBKeyRange; ACount : NativeInt) : TMIDBRequest; overload;
    function getAllKeys(aKey : jsValue) : TMIDBRequest; overload;
    function getAllKeys(aKey : TMIDBKeyRange) : TMIDBRequest; overload;
    function getAllKeys(aKey : jsValue; ACount : NativeInt) : TMIDBRequest; overload;
    function getAllKeys(aKey : TMIDBKeyRange; ACount : NativeInt) : TMIDBRequest; overload;
    function getKey(aKey : jsValue) : TMIDBRequest;
    function openCursor : TMIDBRequest; overload;
    function openCursor(aKeyRange : TMIDBKeyRange) : TMIDBRequest; overload;
    function openCursor(aKeyRange : TMIDBKeyRange; ADirection : String) : TMIDBRequest;overload;
    function openKeyCursor : TMIDBRequest;overload;
    function openKeyCursor(aKeyRange : TMIDBKeyRange) : TMIDBRequest;overload;
    function openKeyCursor(aKeyRange : TMIDBKeyRange; ADirection : String) : TMIDBRequest;overload;
    Property keyPath : JSValue Read FKeyPath;
    property multiEntry : Boolean read FMultiEntry;
    property objectStore : TMIDBObjectStore read FObjectStore;
    property unique : boolean read FUnique;
  end;

  TMIDBCursorDirection = class external name 'IDBCursorDirection'
  Const
    next = 'next';
    nextUnique = 'nextUnique';
    prev = 'prev';
    prevUnique = 'prevUnique';
  end;


  { TMIDBCursor }

  TMIDBCursor = class external name 'IDBCursor'
  private
    FDirection: string; external name 'direction';
    FKey: JSValue; external name 'key';
    FValue : JSValue; external name 'value';
    FPrimaryKey: JSValue; external name 'primaryKey';
    FSource: JSValue; external name 'source';
    FSourceAsIndex: TMIDBIndex; external name 'source';
    FSourceAsStore: TMIDBObjectStore; external name 'source';
  Public
    procedure advance(aCount : NativeInt); overload;
    procedure advance(aKey : JSValue); overload;
    procedure continue(aKey : JSValue); overload;
    procedure continue; overload;
    procedure continuePrimaryKey(aKey : JSValue); overload;
    procedure continuePrimaryKey(aKey,aPrimaryKey : JSValue); overload;
    procedure delete;
    procedure update(aValue : JSValue);
    property source : JSValue read FSource;
    property sourceAsStore : TMIDBObjectStore read FSourceAsStore;
    property sourceAsIndex : TMIDBIndex read FSourceAsIndex;
    property key : JSValue read FKey;
    Property Value : JSValue Read FValue;
    property primaryKey : JSValue read FPrimaryKey;
    property direction : string read FDirection;
  end;

  TMIDBObjectStore = class external name 'IDBObjectStore'  (TJSEventTarget)
  private
    FAutoIncrement : boolean; external name 'autoIncrement';
  public
    function add(aValue : JSValue; aKey : String) : TMIDBRequest;
    function add(aValue : JSValue) : TMIDBRequest;
    function clear : TMIDBRequest;
    function delete(aKey : string) : TMIDBRequest; overload;
    function delete(aKey : JSValue) : TMIDBRequest; overload;
    function delete(aKeyRange : TMIDBKeyRange) : TMIDBRequest;
    function get(aKey : string) : TMIDBRequest; overload;
    function get(aKey : JSValue) : TMIDBRequest; overload;
    function get(aKeyRange : TMIDBKeyRange) : TMIDBRequest; overload;
    function getKey(aKey : string) : TMIDBRequest; overload;
    function getKey(aKeyRange : TMIDBKeyRange) : TMIDBRequest; overload;
    function getAll : TMIDBRequest; overload;
    function getAll(aKey : String) : TMIDBRequest; overload;
    function getAll(aKeyRange : TMIDBKeyRange) : TMIDBRequest; overload;
    function getAll(aKey : String; aCount: NativeInt) : TMIDBRequest; overload;
    function getAll(aKeyRange : TMIDBKeyRange; aCount: NativeInt) : TMIDBRequest; overload;
    function getAllKeys(aKey : String) : TMIDBRequest; overload;
    function getAllKeys(aKeyRange : TMIDBKeyRange) : TMIDBRequest; overload;
    function getAllKeys(aKey : String; aCount: NativeInt) : TMIDBRequest; overload;
    function getAllKeys(aKeyRange : TMIDBKeyRange; aCount: NativeInt) : TMIDBRequest; overload;
    function createIndex (aIndexName : String; KeyPath : String)  : TMIDBIndex; overload;
    function createIndex (aIndexName : String; KeyPath : String; Options : TMIDBIndexParameters)  : TMIDBIndex; overload;
    function createIndex (aIndexName : String; KeyPath : Array of String)  : TMIDBIndex; overload;
    function createIndex (aIndexName : String; KeyPath : Array of String; Options : TMIDBIndexParameters)  : TMIDBIndex; overload;
    function createIndex (aIndexName : String; KeyPath : JSValue; Options : TMIDBIndexParameters)  : TMIDBIndex; overload;
    Procedure deleteIndex (aIndexName : String);
    function index (aIndexName : String)  : TMIDBIndex;
    function put(aValue : JSValue; aKey : String) : TMIDBRequest; overload;
    function put(aValue : JSValue; aKey : JSValue) : TMIDBRequest; overload;
    function put(aValue : JSValue) : TMIDBRequest; overload;
    function openCursor : TMIDBRequest; overload;
    function openCursor(aKey : String) : TMIDBRequest; overload;
    function openCursor(aKeyRange : TMIDBKeyRange) : TMIDBRequest; overload;
    function openCursor(aKey : String; aDirection : string) : TMIDBRequest; overload;
    function openCursor(aKeyRange : TMIDBKeyRange; aDirection : string) : TMIDBRequest; overload;
    function openKeyCursor : TMIDBRequest; overload;
    function openKeyCursor(aKey : String) : TMIDBRequest; overload;
    function openKeyCursor(aKeyRange : TMIDBKeyRange) : TMIDBRequest; overload;
    function openKeyCursor(aKey : String; aDirection : string) : TMIDBRequest; overload;
    function openKeyCursor(aKeyRange : TMIDBKeyRange; aDirection : string) : TMIDBRequest; overload;
    function count : TMIDBRequest; overload;
    function count(aKey : String) : TMIDBRequest; overload;
    function count(aKeyRange : TMIDBKeyRange) : TMIDBRequest; overload;
    property Indexes [aIndexName : String] : TMIDBIndex read index;
    Property autoIncrement : boolean read FAutoIncrement;
  end;

  { TMIDBRequest }

  TMIDBRequest = class external name 'IDBRequest'  (TJSEventTarget)
  private
    Ferror : JSValue; external name 'error'; // standards are not quite clear on this one
    FReadyState: string; external name 'readyState';
    FResult: JSValue; external name 'result';
    FResultDatabase: TMIDBDatabase; external name 'result';
    FResultIndex: TMIDBIndex; external name 'result';
    FResultObjectStore : TMIDBObjectStore; external name 'result';
    FResultCursor : TMIDBCursor; external name 'result';
    FSourceDatabase: TMIDBDatabase; external name 'source';
    FSourceIndex: TMIDBIndex; external name 'source';
    FSourceObjectStore : TMIDBObjectStore; external name 'source';
    FSourceCursor : TMIDBCursor; external name 'source';
    FSource: JSValue; external name 'source';
    FTransaction: TMIDBTransaction; external name 'transaction';
  Public
    onerror : TJSEventHandler;
    onsuccess : TJSEventHandler;
    Property error : JSValue read FError;
    property readyState : string read FReadyState;

    property result : JSValue read FResult;
    property resultAsObjectStore : TMIDBObjectStore read FResultObjectStore;
    property resultAsCursor : TMIDBCursor read FResultCursor;
    property resultAsIndex : TMIDBIndex read FResultIndex;
    property resultAsDatabase : TMIDBDatabase read FResultDatabase;

    property source : JSValue read FSource;
    property sourceAsObjectStore : TMIDBObjectStore read FSourceObjectStore;
    property sourceAsCursor : TMIDBCursor read FSourceCursor;
    property sourceAsIndex : TMIDBIndex read FSourceIndex;
    property sourceAsDatabase : TMIDBDatabase read FSourceDatabase;

    property transaction : TMIDBTransaction read FTransaction;
  end;

  TMIDBOpenDBRequest = class external name 'IDBOpenDBRequest' (TMIDBRequest)
  Public
    onblocked : TJSEventHandler;
    onupgradeneeded : TJSEventHandler;
  end;

  TJSCreateObjectStoreOptions = record
    keyPath : jsValue;
    autoIncrement : boolean;
  end;

  { TMIDBDatabase }

  TMIDBDatabase = class external name 'IDBDatabase' (TJSEventTarget)
  private
    FName: string; external name 'name';
    FobjectStoreNames: TStringDynArray; external name 'objectStoreNames';
    FVersion: integer; external name 'version';
    FObjectStore: TMIDBObjectStore; external name 'objectStore';
  public
    onversionchange : TJSEventHandler; 
    procedure close;
    function createObjectStore(aName : string) : TMIDBObjectStore; overload;
    function createObjectStore(aName : string; Options: TJSCreateObjectStoreOptions) : TMIDBObjectStore; overload;
    procedure deleteObjectStore(aName : string);
    function transaction(aStoreNames : array of string) : TMIDBTransaction; overload;
    function transaction(aStoreNames : array of string; aMode : string) : TMIDBTransaction; overload;
    property name : string read FName;
    property version : integer read FVersion;
    property objectStoreNames : TStringDynArray read FobjectStoreNames;
    property objectStore: TMIDBObjectStore read FObjectStore;
  end;

  TMIDBFactory = class external name 'IDBFactory' (TJSEventTarget)
  public
    function open(aName : string) : TMIDBOpenDBRequest;
    function open(aName : string; aVersion : Integer) : TMIDBOpenDBRequest;
    function deleteDatabase(aName : string) : TMIDBOpenDBRequest;
    function cmp (a,b : jsValue) : NativeInt;
  end;

  TIDBError = Class external name 'Error'
  private
    FMessage: String; external name 'message';
    FName: String; external name 'name';
  Public
    property message : String Read FMessage;
    property name : String Read FName;
  end;

implementation

end.
