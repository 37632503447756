// - retrieved from https://api.true.co.za:8776/app/wrapper/WebCore/mORMotClient.pas.txt
// at 2024-10-09 04:19:13 using "WebCore.pas.mustache" template
unit mORMotClient;

{
  WARNING:
    This unit has been generated by a mORMot 2.2.7815 server.
    Any manual modification of this file may be lost after regeneration.

  Synopse mORMot framework. Copyright (C) 2024 Arnaud Bouchez
    Synopse Informatique - http://synopse.info

  This unit is released under a MPL/GPL/LGPL tri-license,
  and therefore may be freely included in any application.

  This unit would work on Delphi 6 and later, under all supported platforms
  (including MacOSX, and NextGen iPhone/iPad), and the Free Pascal Compiler.

  Adapted for WebCore by Anton Ekermans 2024/09

}

interface

uses
  SysUtils,
  StrUtils,
  System.TypInfo,
  System.Rtti,

  //-- WebCore
  JS,
  Web,
  Types,
  WEBLib.JSON,
  WEBLib.Utils,
  //----------

  Web.mORMot.Types,
  Web.mORMot.OrmTypes,
  Web.mORMot.AuthTypes,
  Web.mORMot.RestTypes,
  Web.mORMot.Rest;


type RawBlob  = String;
     Variant  = JSValue;

  const
  /// the server host , corresponding to https://api.true.co.za:8776
  SERVER_HOST = 'api.true.co.za:8776';
  /// the server port, corresponding to https://api.true.co.za:8776
  SERVER_PORT = 8776;
  /// the server model root name, corresponding to https://api.true.co.za:8776
  SERVER_ROOT = 'app';


type // define some enumeration types, used below
  TAlignment = (taLeftJustify, taRightJustify, taCenter);
  TVerticalAlignment = (taAlignTop, taAlignBottom, taVerticalCenter);
  TTypeKind = (tkUnknown, tkInteger, tkChar, tkEnumeration, tkFloat, tkString, tkSet, tkClass, tkMethod, tkWChar, tkLString, tkWString, tkVariant, tkArray, tkRecord, tkInterface, tkInt64, tkDynArray, tkUString, tkClassRef, tkPointer, tkProcedure, tkMRecord);
  TFieldType = (ftUnknown, ftString, ftSmallint, ftInteger, ftWord, ftBoolean, ftFloat, ftCurrency, ftBCD, ftDate, ftTime, ftDateTime, ftBytes, ftVarBytes, ftAutoInc, ftBlob, ftMemo, ftGraphic, ftFmtMemo, ftParadoxOle, ftDBaseOle, ftTypedBinary, ftCursor, ftFixedChar, ftWideString, ftLargeint, ftADT, ftArray, ftReference, ftDataSet, ftOraBlob, ftOraClob, ftVariant, ftInterface, ftIDispatch, ftGuid, ftTimeStamp, ftFMTBcd, ftFixedWideChar, ftWideMemo, ftOraTimeStamp, ftOraInterval, ftLongWord, ftShortint, ftByte, ftExtended, ftConnection, ftParams, ftStream, ftTimeStampOffset, ftObject, ftSingle);
  _MetaMode = (mmNone, mmEnum, mmSet, mmArray, mmMap);
  _ColumnType = (ctNone, ctInteger, ctDouble, ctCurrency, ctTimeLog, ctDateTime, ctDate, ctTime, ctBoolean, ctEnums, ctSet, ctString, ctMultiline, ctName, ctPassword, ctPhone, ctEmail, ctAddress, ctCity, ctCountry, ctPostalCode, ctUrl, ctFileName, ctIpv4, ctIpv6, ctTID, ctFileExt, ctMimeType, ctBlobStr, ctFile, ctCel, ctFax, ctIDnum, ctRegNo, ctVatNo, ctGPS, ct_QUERY);
  _AutocompleteType = (ac_OFF, ac_ON, acName, acHonorificPrefix, acGivenName, acAdditionalName, acFamilyName, acHonorificSuffix, acNickname, acEmail, acUsername, acNewPassword, acCurrentPassword, acOrganizationTitle, acOrganization, acTel, acTelCountryCode, acTelNational, acTelAreaCode, acTelLocal, acTelExtension, acImpp, acStreetAddress, acAddressLine1, acAddressLine2, acAddressLine3, acAddressLevel1, acAddressLevel2, acAddressLevel3, acAddressLevel4, acCountry, acCountryName, acCountryCode, acPostalCode, acCcName, acCcGivenName, acCcFamilyName, acCcNumber, acCcExp, acCcExpMonth, acCcExpYear, acCcCsc, acCcType, acTransactionAmount, acTransactionCurrency, acLanguage, acBday, acBdayDay, acBdayMonth, acBdayYear, acSex, acUrl, acPhoto);
  _WidgetType = (wtNone, wtBreak, wtForm, wtPanel, wtListBox, wtEditText, wtMemo, wtComboBox, wtCheckBox, wtCheckGroup, wtRadioGroup, wtDateTimeEdit, wtButton, wtFile, wtImage, wtAvatar);
  _TextInputType = (ttNone, ttText, ttMultiline, ttSpinEdit, ttInteger, ttDouble, ttCurrency, ttPhone, ttDateTime, ttEmailAddress, ttURL, ttVisiblePassword, ttName, ttStreetAddress);
  _HTMLFieldType = (dtNone, dtText, dtPassword, dtEmail, dtTel, dtNumber, dtDate, dtTime, dtDateTime, dtDateTimeLocal, dtMonth, dtWeek, dtURL, dtSearch, dtColor, dtCheckbox, dtRadio, dtFile, dtRange, dthidden, dtbutton, dtimage, dtreset, dtsubmit);
  _TextInputAction = (taNone, taUnspecified, taDone, taGo, taSearch, taSend, taNext, taPrevious, taContinue, taJoin, taRoute, taEmergencyCall, taNewline);
  _BracketType = (btNone, btRound, btCurly, btSquare, btAngle);
  _MetaSIUnit = (siNone, siLength, siVolume, siMass, siTime, siSpeed, siPressure, siTemperature, siCharge, siCurrent, siVolts, siFrequency, siEnergy, siForce, siPower, siMole, siCandela);
  TMustKind = (fkUnknown, fkHTML, fkPart, fkCSS, fkJson, fkJS, fkPas, fkDfm, fkTxt, fkGif, fkJpg, fkPng, fkSvg, fkXml, fkBmp, fkTiff, fkPdf, fkTree);
  TConnectionKind = (ckSmtp, ckSMS, ckVoIP, ckApiHttp, ckApiWebSock);
  TEventOps = (eoNONE, eoDbAdd, eoDBEdit, eoDbEditSort, eoDbDelete, eoView);

type // define some set types, used below
  _FieldFlags = set of(ffRequired, ffPrimaryKey, ffIndexed, ffUnique, ffHidden, ffWriteOnce, ffReadOnly, ffLookup, ffCalculated, ffAggregate, ffDescending, ffCaseSen, ffJustifyRight, ffJustifyCenter, ffTrimLeft, ffAllOrNothing, ffNumerical, ffUnsigned, ffListMultiLine, ffSensitive, ffSortBy, ffEmptyIsDefault, ffSetValueToDefaults, ffNotInGetSize, ffNoDelta);
  _ViewFlags = set of(vfHoriz, vfVert, vfCompact, vfLabelTop, vfNoLabel, vfNoIcon, vfList, vfGrid, vfFormTemplate, vfForm);
  TEventFlags = set of(efBroadcast, efCommand, efMultiCast, efSystem, efApp, efCompany, efUser, efAccounts, efAccount, efDocuments, efDocument);

type // define some record types, used as properties below
  TUserToken = record
    Dest: String;
    Token: String;
    Expire: TDateTime;
  end;

  TGpsCoordInt = record
    Lat: Word;
    Long: Word;
  end;

type // define some dynamic array types, used as properties below
  TUserTokens = array of TUserToken;
  TStringDynArray = array of string;
  TIDDynArray = array of TID;
  TFileNameDynArray = array of TFileName;
  TRawUtf8DynArray = array of RawUtf8;

type
  /// map "AppAuthUser" table
  TAppAuthUser = class(TORM)
  protected
    fLogonName: String;
    fDisplayName: String;
    fPasswordHashHexa: String;
    fGroupRights: TID;
    fData: RawBlob;
    fName: String;
    fPhone: String;
    fEmail: String;
    fAvatar: String;
    fContactID: TID;
    fTokens: TUserTokens;
  published
    property LogonName: String index 20 read fLogonName write fLogonName;
    property DisplayName: String index 50 read fDisplayName write fDisplayName;
    property PasswordHashHexa: String index 64 read fPasswordHashHexa write fPasswordHashHexa;
    // defined as GroupRights: TAuthGroup on the server
    property GroupRights: TID read fGroupRights write fGroupRights;
    property Data: RawBlob read fData write fData;
    property Name: String read fName write fName;
    property Phone: String read fPhone write fPhone;
    property Email: String read fEmail write fEmail;
    property Avatar: String read fAvatar write fAvatar;
    property ContactID: TID read fContactID write fContactID;
    property Tokens: TUserTokens read fTokens write fTokens;
  end;

  /// map "Icon" table
  TOrmIcon = class(TORM)
  protected
    fName: String;
    fClasses: String;
    fSvg: String;
  published
    property Name: String read fName write fName;
    property Classes: String read fClasses write fClasses;
    property Svg: String read fSvg write fSvg;
  end;

  /// map "MetaType" table
  TOrmMetaType = class(TORM)
  protected
    fName: String;
    fDisplayName: String;
    fDisplayPlural: String;
    fDescription: String;
    fIconID: TID;
    fIconSelected: String;
    fDisplayWidth: Integer;
    fDisplayPerc: Integer;
    fSortStrSize: Integer;
    fSearchPrior: Byte;
    fSortNumber: Byte;
    fSortTextAlign: TAlignment;
    fDisplayHorizAlign: TAlignment;
    fDisplayVertAlign: TVerticalAlignment;
    fFlags: _FieldFlags;
    fTypeName: String;
    fTypeKind: TTypeKind;
    fFieldType: TFieldType;
    fMetaMode: _MetaMode;
    fColumnType: _ColumnType;
    fAutoComplete: _AutocompleteType;
    fItems: TStringDynArray;
    fWidgetType: _WidgetType;
    fViewFlags: _ViewFlags;
    fTextInput: _TextInputType;
    fHTMLField: _HTMLFieldType;
    fHTMLClasses: String;
    fTextAction: _TextInputAction;
    fBracketType: _BracketType;
    fSIUnit: _MetaSIUnit;
  published
    property Name: String read fName write fName;
    property DisplayName: String read fDisplayName write fDisplayName;
    property DisplayPlural: String read fDisplayPlural write fDisplayPlural;
    property Description: String read fDescription write fDescription;
    property IconID: TID read fIconID write fIconID;
    property IconSelected: String read fIconSelected write fIconSelected;
    property DisplayWidth: Integer read fDisplayWidth write fDisplayWidth;
    property DisplayPerc: Integer read fDisplayPerc write fDisplayPerc;
    property SortStrSize: Integer read fSortStrSize write fSortStrSize;
    property SearchPrior: Byte read fSearchPrior write fSearchPrior;
    property SortNumber: Byte read fSortNumber write fSortNumber;
    property SortTextAlign: TAlignment read fSortTextAlign write fSortTextAlign;
    property DisplayHorizAlign: TAlignment read fDisplayHorizAlign write fDisplayHorizAlign;
    property DisplayVertAlign: TVerticalAlignment read fDisplayVertAlign write fDisplayVertAlign;
    property Flags: _FieldFlags read fFlags write fFlags;
    property TypeName: String read fTypeName write fTypeName;
    property TypeKind: TTypeKind read fTypeKind write fTypeKind;
    property FieldType: TFieldType read fFieldType write fFieldType;
    property MetaMode: _MetaMode read fMetaMode write fMetaMode;
    property ColumnType: _ColumnType read fColumnType write fColumnType;
    property AutoComplete: _AutocompleteType read fAutoComplete write fAutoComplete;
    property Items: TStringDynArray read fItems write fItems;
    property WidgetType: _WidgetType read fWidgetType write fWidgetType;
    property ViewFlags: _ViewFlags read fViewFlags write fViewFlags;
    property TextInput: _TextInputType read fTextInput write fTextInput;
    property HTMLField: _HTMLFieldType read fHTMLField write fHTMLField;
    property HTMLClasses: String read fHTMLClasses write fHTMLClasses;
    property TextAction: _TextInputAction read fTextAction write fTextAction;
    property BracketType: _BracketType read fBracketType write fBracketType;
    property SIUnit: _MetaSIUnit read fSIUnit write fSIUnit;
  end;

  /// map "ObjDef" table
  TOrmObjDef = class(TORM)
  protected
    fName: String;
    fMetaID: TID;
    fMetaName: String;
    fFieldNames: TStringDynArray;
    fFieldMetaNames: TStringDynArray;
    fFieldMetaIDs: TIDDynArray;
    fFieldsList: TStringDynArray;
    fFieldsGrid: TStringDynArray;
    fFieldsForm: TStringDynArray;
    fFieldsDB: TStringDynArray;
    fMenuPath: String;
  published
    property Name: String read fName write fName;
    property MetaID: TID read fMetaID write fMetaID;
    property MetaName: String read fMetaName write fMetaName;
    property FieldNames: TStringDynArray read fFieldNames write fFieldNames;
    property FieldMetaNames: TStringDynArray read fFieldMetaNames write fFieldMetaNames;
    property FieldMetaIDs: TIDDynArray read fFieldMetaIDs write fFieldMetaIDs;
    property FieldsList: TStringDynArray read fFieldsList write fFieldsList;
    property FieldsGrid: TStringDynArray read fFieldsGrid write fFieldsGrid;
    property FieldsForm: TStringDynArray read fFieldsForm write fFieldsForm;
    property FieldsDB: TStringDynArray read fFieldsDB write fFieldsDB;
    property MenuPath: String read fMenuPath write fMenuPath;
  end;

  /// map "ProjFile" table
  TORMProjFile = class(TORM)
  protected
    fName: String;
    fCreateTime: TCreateTime;
    fUpdateTime: TModTime;
    fVersion: TRecordVersion;
    fFileName: String;
    fKind: TMustKind;
    fFileTime: Integer;
    fText: String;
    fData: String;
    fScript: String;
    fOutput: String;
  published
    property Name: String read fName write fName;
    property CreateTime: TCreateTime read fCreateTime write fCreateTime;
    property UpdateTime: TModTime read fUpdateTime write fUpdateTime;
    property Version: TRecordVersion read fVersion write fVersion;
    property FileName: String read fFileName write fFileName;
    property Kind: TMustKind read fKind write fKind;
    property FileTime: Integer read fFileTime write fFileTime;
    property Text: String read fText write fText;
    property Data: String read fData write fData;
    property Script: String read fScript write fScript;
    property Output: String read fOutput write fOutput;
  end;

  /// map "Project" table
  TOrmProject = class(TORM)
  protected
    fName: String;
    fCreateTime: TCreateTime;
    fUpdateTime: TModTime;
    fVersion: TRecordVersion;
    fPath: String;
    fProjType: String;
    fDescription: String;
    fAuthor: String;
    fHtml: String;
  published
    property Name: String read fName write fName;
    property CreateTime: TCreateTime read fCreateTime write fCreateTime;
    property UpdateTime: TModTime read fUpdateTime write fUpdateTime;
    property Version: TRecordVersion read fVersion write fVersion;
    property Path: String read fPath write fPath;
    property ProjType: String read fProjType write fProjType;
    property Description: String read fDescription write fDescription;
    property Author: String read fAuthor write fAuthor;
    property Html: String read fHtml write fHtml;
  end;

  /// map "HostConnection" table
  TOrmHostConnection = class(TORM)
  protected
    fName: String;
    fCreateTime: TCreateTime;
    fUpdateTime: TModTime;
    fVersion: TRecordVersion;
    fKind: TConnectionKind;
    fHost: String;
    fPort: Integer;
    fPath: String;
    fUN: String;
    fPW: String;
  published
    property Name: String read fName write fName;
    property CreateTime: TCreateTime read fCreateTime write fCreateTime;
    property UpdateTime: TModTime read fUpdateTime write fUpdateTime;
    property Version: TRecordVersion read fVersion write fVersion;
    property Kind: TConnectionKind read fKind write fKind;
    property Host: String read fHost write fHost;
    property Port: Integer read fPort write fPort;
    property Path: String read fPath write fPath;
    property UN: String read fUN write fUN;
    property PW: String read fPW write fPW;
  end;

  /// map "DomainInfo" table
  TORMDomainInfo = class(TORM)
  protected
    fName: String;
    fCreateTime: TCreateTime;
    fUpdateTime: TModTime;
    fVersion: TRecordVersion;
    fDomain: String;
    fAuthRoot: String;
    fCVersion: Integer;
    fWebSock: TID;
    fSmtp: TID;
    fSMS: TID;
    fVoIP: TID;
    fNtpHost: String;
    fField1: String;
    fField2: String;
    fField3: String;
    fField4: String;
    fField5: String;
    fDisclaimer: String;
    fEc: Integer;
    fStreetAddress: String;
  published
    property Name: String read fName write fName;
    property CreateTime: TCreateTime read fCreateTime write fCreateTime;
    property UpdateTime: TModTime read fUpdateTime write fUpdateTime;
    property Version: TRecordVersion read fVersion write fVersion;
    property Domain: String read fDomain write fDomain;
    property AuthRoot: String read fAuthRoot write fAuthRoot;
    property CVersion: Integer read fCVersion write fCVersion;
    // defined as WebSock: TOrmHostConnection on the server
    property WebSock: TID read fWebSock write fWebSock;
    // defined as Smtp: TOrmHostConnection on the server
    property Smtp: TID read fSmtp write fSmtp;
    // defined as SMS: TOrmHostConnection on the server
    property SMS: TID read fSMS write fSMS;
    // defined as VoIP: TOrmHostConnection on the server
    property VoIP: TID read fVoIP write fVoIP;
    property NtpHost: String read fNtpHost write fNtpHost;
    property Field1: String read fField1 write fField1;
    property Field2: String read fField2 write fField2;
    property Field3: String read fField3 write fField3;
    property Field4: String read fField4 write fField4;
    property Field5: String read fField5 write fField5;
    property Disclaimer: String read fDisclaimer write fDisclaimer;
    property Ec: Integer read fEc write fEc;
    property StreetAddress: String read fStreetAddress write fStreetAddress;
  end;

  /// map "DBEvent" table
  TOrmDBEvent = class(TORM)
  protected
    fOrigin: String;
    fOriginID: TID;
    fDB_ID: TID;
    fSub_ID: TID;
    fItm_ID: TID;
    fCreated: TCreateTime;
    fSent: TTimeLog;
    fReceived: TTimeLog;
    fSessionID: TID;
    fFlags: TEventFlags;
    fCmdOp: TEventOps;
    fPayload: Variant;
  published
    property Origin: String read fOrigin write fOrigin;
    property OriginID: TID read fOriginID write fOriginID;
    property DB_ID: TID read fDB_ID write fDB_ID;
    property Sub_ID: TID read fSub_ID write fSub_ID;
    property Itm_ID: TID read fItm_ID write fItm_ID;
    property Created: TCreateTime read fCreated write fCreated;
    property Sent: TTimeLog read fSent write fSent;
    property Received: TTimeLog read fReceived write fReceived;
    property SessionID: TID read fSessionID write fSessionID;
    property Flags: TEventFlags read fFlags write fFlags;
    property CmdOp: TEventOps read fCmdOp write fCmdOp;
    property Payload: Variant read fPayload write fPayload;
  end;

  /// map "PhoneNumber" table
  TORMPhoneNumber = class(TORM)
  protected
    fCreated: TCreateTime;
    fModified: TModTime;
    fOwner: TID;
    fAddress: String;
    fName: String;
    fKind: String;
    fPhoneFlags: String;
  published
    property Created: TCreateTime read fCreated write fCreated;
    property Modified: TModTime read fModified write fModified;
    // defined as Owner: TORMContact on the server
    property Owner: TID read fOwner write fOwner;
    property Address: String read fAddress write fAddress;
    property Name: String read fName write fName;
    property Kind: String read fKind write fKind;
    property PhoneFlags: String read fPhoneFlags write fPhoneFlags;
  end;

  /// map "EMailAddress" table
  TORMEMailAddress = class(TORM)
  protected
    fCreated: TCreateTime;
    fModified: TModTime;
    fOwner: TID;
    fAddress: String;
    fName: String;
    fKind: String;
    fEmailFlags: String;
  published
    property Created: TCreateTime read fCreated write fCreated;
    property Modified: TModTime read fModified write fModified;
    // defined as Owner: TORMContact on the server
    property Owner: TID read fOwner write fOwner;
    property Address: String read fAddress write fAddress;
    property Name: String read fName write fName;
    property Kind: String read fKind write fKind;
    property EmailFlags: String read fEmailFlags write fEmailFlags;
  end;

  /// map "StreetAddress" table
  TORMStreetAddress = class(TORM)
  protected
    fCreated: TCreateTime;
    fModified: TModTime;
    fOwner: TID;
    fStreet: String;
    fCity: String;
    fPostalCode: String;
    fAddressFlags: String;
    fName: String;
    fGPS: TGpsCoordInt;
    fGPSConfirmed: Boolean;
  public
    property GPS: TGpsCoordInt read fGPS write fGPS;
  published
    property Created: TCreateTime read fCreated write fCreated;
    property Modified: TModTime read fModified write fModified;
    // defined as Owner: TORMContact on the server
    property Owner: TID read fOwner write fOwner;
    property Street: String read fStreet write fStreet;
    property City: String read fCity write fCity;
    property PostalCode: String read fPostalCode write fPostalCode;
    property AddressFlags: String read fAddressFlags write fAddressFlags;
    property Name: String read fName write fName;
    property GPSConfirmed: Boolean read fGPSConfirmed write fGPSConfirmed;
  end;

  /// map "Contact" table
  TORMContact = class(TORM)
  protected
    fCreated: TCreateTime;
    fModified: TModTime;
    fAvatar: String;
    fFullName: String;
    fGivenName: String;
    fCapacity: String;
    fIdNumber: String;
    fRegNo: String;
    fVatNumber: String;
    fCompanyName: String;
    fTitle: String;
    fRole: String;
    fEmail: String;
    fPhone: String;
    fChat: String;
    fDebtor: String;
    fCreditor: String;
    fWisp: String;
    fContactFlags: String;
  published
    property Created: TCreateTime read fCreated write fCreated;
    property Modified: TModTime read fModified write fModified;
    property Avatar: String read fAvatar write fAvatar;
    property FullName: String read fFullName write fFullName;
    property GivenName: String read fGivenName write fGivenName;
    property Capacity: String read fCapacity write fCapacity;
    property IdNumber: String read fIdNumber write fIdNumber;
    property RegNo: String read fRegNo write fRegNo;
    property VatNumber: String read fVatNumber write fVatNumber;
    property CompanyName: String read fCompanyName write fCompanyName;
    property Title: String read fTitle write fTitle;
    property Role: String read fRole write fRole;
    property Email: String read fEmail write fEmail;
    property Phone: String read fPhone write fPhone;
    property Chat: String read fChat write fChat;
    property Debtor: String read fDebtor write fDebtor;
    property Creditor: String read fCreditor write fCreditor;
    property Wisp: String read fWisp write fWisp;
    property ContactFlags: String read fContactFlags write fContactFlags;
  end;

  /// map "LoginToken" table
  TORMLoginToken = class(TORM)
  protected
    fUserID: TID;
    fAddress: String;
    fCreateTime: TCreateTime;
    fExpire: TCreateTime;
    fToken: String;
    fSession: String;
  published
    property UserID: TID read fUserID write fUserID;
    property Address: String read fAddress write fAddress;
    property CreateTime: TCreateTime read fCreateTime write fCreateTime;
    property Expire: TCreateTime read fExpire write fExpire;
    property Token: String read fToken write fToken;
    property Session: String read fSession write fSession;
  end;

  /// map "Attachment" table
  TORMAttachment = class(TORM)
  protected
    fCreated: TCreateTime;
    fModified: TModTime;
    fOwner: TID;
    fSHA: String;
    fName: String;
    fFileName: String;
    fFileType: String;
    fMimeType: String;
    fRawData: RawBlob;
    fMetaData: Variant;
    fThumbnail: String;
    fAIDesc: String;
    fFlags: String;
    fRefCnt: Integer;
  published
    property Created: TCreateTime read fCreated write fCreated;
    property Modified: TModTime read fModified write fModified;
    // defined as Owner: TORMContact on the server
    property Owner: TID read fOwner write fOwner;
    property SHA: String read fSHA write fSHA;
    property Name: String read fName write fName;
    property FileName: String read fFileName write fFileName;
    property FileType: String read fFileType write fFileType;
    property MimeType: String read fMimeType write fMimeType;
    property RawData: RawBlob read fRawData write fRawData;
    property MetaData: Variant read fMetaData write fMetaData;
    property Thumbnail: String read fThumbnail write fThumbnail;
    property AIDesc: String read fAIDesc write fAIDesc;
    property Flags: String read fFlags write fFlags;
    property RefCnt: Integer read fRefCnt write fRefCnt;
  end;

  /// map "VoipPhones" table
  TORMVoipPhones = class(TORM)
  protected
    fExt: String;
    fName: String;
    fChannel: String;
  published
    property Ext: String read fExt write fExt;
    property Name: String read fName write fName;
    property Channel: String read fChannel write fChannel;
  end;

  /// map "SMS" table
  TOrmSMS = class(TORM)
  protected
    fOwner: TID;
    fContent: String;
    fRecipients: String;
    fCode: String;
    fGuid: String;
    fDateCreated: TDateTime;
    fDateToSend: TDateTime;
    fDateSent: TDateTime;
    fPaused: Boolean;
    fSender: String;
    fFromUser: String;
    fInteractionType: String;
    fCnt: Integer;
    fResults: String;
    fRetries: Integer;
  published
    // defined as Owner: TORMPhoneNumber on the server
    property Owner: TID read fOwner write fOwner;
    property Content: String read fContent write fContent;
    property Recipients: String read fRecipients write fRecipients;
    property Code: String read fCode write fCode;
    property Guid: String read fGuid write fGuid;
    property DateCreated: TDateTime read fDateCreated write fDateCreated;
    property DateToSend: TDateTime read fDateToSend write fDateToSend;
    property DateSent: TDateTime read fDateSent write fDateSent;
    property Paused: Boolean read fPaused write fPaused;
    property Sender: String read fSender write fSender;
    property FromUser: String read fFromUser write fFromUser;
    property InteractionType: String read fInteractionType write fInteractionType;
    property Cnt: Integer read fCnt write fCnt;
    property Results: String read fResults write fResults;
    property Retries: Integer read fRetries write fRetries;
  end;

  /// map "PhoneCall" table
  TORMPhoneCall = class(TORM)
  protected
    fOwner: TID;
    fIncoming: Boolean;
    fCID: String;
    fDateStart: TDateTime;
    fDateend: TDateTime;
    fLink: String;
  published
    // defined as Owner: TORMPhoneNumber on the server
    property Owner: TID read fOwner write fOwner;
    property Incoming: Boolean read fIncoming write fIncoming;
    property CID: String read fCID write fCID;
    property DateStart: TDateTime read fDateStart write fDateStart;
    property Dateend: TDateTime read fDateend write fDateend;
    property Link: String read fLink write fLink;
  end;

  /// map "EMail" table
  TOrmEMail = class(TORM)
  protected
    fOwner: TID;
    fCode: String;
    fGuid: String;
    fDateCreated: TDateTime;
    fDateToSend: TDateTime;
    fDateSent: TDateTime;
    fPaused: Integer;
    fSender: String;
    fRecipients: String;
    fSubject: String;
    fIDMessage: RawBlob;
    fFromUser: String;
    fInteractionType: String;
    fCntTo: Integer;
    fCntCc: Integer;
    fCntBcc: Integer;
    fResults: String;
    fRetries: Integer;
  published
    // defined as Owner: TORMEMailAddress on the server
    property Owner: TID read fOwner write fOwner;
    property Code: String read fCode write fCode;
    property Guid: String read fGuid write fGuid;
    property DateCreated: TDateTime read fDateCreated write fDateCreated;
    property DateToSend: TDateTime read fDateToSend write fDateToSend;
    property DateSent: TDateTime read fDateSent write fDateSent;
    property Paused: Integer read fPaused write fPaused;
    property Sender: String read fSender write fSender;
    property Recipients: String read fRecipients write fRecipients;
    property Subject: String read fSubject write fSubject;
    property IDMessage: RawBlob read fIDMessage write fIDMessage;
    property FromUser: String read fFromUser write fFromUser;
    property InteractionType: String read fInteractionType write fInteractionType;
    property CntTo: Integer read fCntTo write fCntTo;
    property CntCc: Integer read fCntCc write fCntCc;
    property CntBcc: Integer read fCntBcc write fCntBcc;
    property Results: String read fResults write fResults;
    property Retries: Integer read fRetries write fRetries;
  end;

  /// map "SmsTemplate" table
  TORMSmsTemplate = class(TORM)
  protected
    fName: String;
    fPurpose: String;
    fTemplate: String;
  published
    property Name: String read fName write fName;
    property Purpose: String read fPurpose write fPurpose;
    property Template: String read fTemplate write fTemplate;
  end;

  /// map "EmailTemplate" table
  TORMEmailTemplate = class(TORM)
  protected
    fName: String;
    fSubject: String;
    fPurpose: String;
    fTemplate: String;
  published
    property Name: String read fName write fName;
    property Subject: String read fSubject write fSubject;
    property Purpose: String read fPurpose write fPurpose;
    property Template: String read fTemplate write fTemplate;
  end;

  /// map "Appointment" table
  TORMAppointment = class(TORM)
  protected
    fOwner: TID;
    fApptID: String;
    fStartDate: Double;
    fStartTime: Double;
    fEndDate: Double;
    fEndTime: Double;
    fDescription: String;
    fAlarmEnabled: Integer;
    fAlarmAdvance: Integer;
    fClient: String;
    fStatus: String;
    fColour: Integer;
    fLocked: Integer;
    fFontColour: Integer;
    fFontName: String;
    fFontSize: Integer;
    fFontStyle: Integer;
    fSpecial: String;
    fAOrder: Integer;
  published
    // defined as Owner: TORMContact on the server
    property Owner: TID read fOwner write fOwner;
    property ApptID: String read fApptID write fApptID;
    property StartDate: Double read fStartDate write fStartDate;
    property StartTime: Double read fStartTime write fStartTime;
    property EndDate: Double read fEndDate write fEndDate;
    property EndTime: Double read fEndTime write fEndTime;
    property Description: String read fDescription write fDescription;
    property AlarmEnabled: Integer read fAlarmEnabled write fAlarmEnabled;
    property AlarmAdvance: Integer read fAlarmAdvance write fAlarmAdvance;
    property Client: String read fClient write fClient;
    property Status: String read fStatus write fStatus;
    property Colour: Integer read fColour write fColour;
    property Locked: Integer read fLocked write fLocked;
    property FontColour: Integer read fFontColour write fFontColour;
    property FontName: String read fFontName write fFontName;
    property FontSize: Integer read fFontSize write fFontSize;
    property FontStyle: Integer read fFontStyle write fFontStyle;
    property Special: String read fSpecial write fSpecial;
    property AOrder: Integer read fAOrder write fAOrder;
  end;

  /// map "CalendarLink" table
  TORMCalendarLink = class(TORM)
  protected
    fSchedName: String;
    fApptID: TID;
  published
    property SchedName: String read fSchedName write fSchedName;
    property ApptID: TID read fApptID write fApptID;
  end;

  /// map "ContactLink" table
  TORMContactLink = class(TORM)
  protected
    fBookName: String;
    fContactID: TID;
  published
    property BookName: String read fBookName write fBookName;
    property ContactID: TID read fContactID write fContactID;
  end;

  /// map "CalendarStatus" table
  TORMCalendarStatus = class(TORM)
  protected
    fName: String;
    fColour: Integer;
    fFontColour: Integer;
    fFontName: String;
    fFontSize: Integer;
    fFontStyle: Integer;
  published
    property Name: String read fName write fName;
    property Colour: Integer read fColour write fColour;
    property FontColour: Integer read fFontColour write fFontColour;
    property FontName: String read fFontName write fFontName;
    property FontSize: Integer read fFontSize write fFontSize;
    property FontStyle: Integer read fFontStyle write fFontStyle;
  end;

  /// map "Local" table
  TSQLLocal = class(TORM)
  protected
    fName: String;
    fVoipExt: String;
  published
    property Name: String read fName write fName;
    property VoipExt: String read fVoipExt write fVoipExt;
  end;

  /// map "TableDeleted" table
  TOrmTableDeleted = class(TORM)
  protected
    fDeleted: Int64;
  published
    property Deleted: Int64 read fDeleted write fDeleted;
  end;

  /// implements IApp from https://api.true.co.za:8776/app/App
  // - this service will run in sicPerSession mode
  TServiceApp = class(TServiceClientAbstract)
  public
    constructor Create(aClient: TRestClientURI); override;
    procedure GetUserID(out aID: TID; onSuccess: TProcedureString; onError: TRestEvent);
    procedure GetNotifcations(const aOnlyUnread: Boolean; out aIDs: TIDDynArray; onSuccess: TProcedureString; onError: TRestEvent);
  end;
  /// implements IDocument from https://api.true.co.za:8776/app/Document
  // - this service will run in sicPerUser mode
  TServiceDocument = class(TServiceClientAbstract)
  public
    constructor Create(aClient: TRestClientURI); override;
    procedure Load(const pmcName: String; out pmoData: String; onSuccess: TProcedureString; onError: TRestEvent);
    procedure Save(const pmcName: String; const pmcData: String; onSuccess: TProcedureString; onError: TRestEvent);
    procedure GetAllNames(out pmoFileNames: TFileNameDynArray; onSuccess: TProcedureString; onError: TRestEvent);
  end;
  /// implements IRender from https://api.true.co.za:8776/app/Render
  // - this service will run in sicPerThread mode
  TServiceRender = class(TServiceClientAbstract)
  public
    constructor Create(aClient: TRestClientURI); override;
    procedure AddFile(const aName: String; onSuccess: TProcedureString; onError: TRestEvent);
    procedure DelFile(const aName: String; onSuccess: TProcedureString; onError: TRestEvent);
    procedure GetFile(const aName: String; out aData: String; onSuccess: TProcedureString; onError: TRestEvent);
    procedure SetFile(const aName: String; const aData: String; onSuccess: TProcedureString; onError: TRestEvent);
    procedure GetParent(const aTree: String; const aName: String; out aData: String; onSuccess: TProcedureString; onError: TRestEvent);
    procedure SetParent(const aTree: String; const aName: String; const aParent: String; onSuccess: TProcedureString; onError: TRestEvent);
    procedure ListKinds(const aKind: TMustKind; out aData: TRawUtf8DynArray; onSuccess: TProcedureString; onError: TRestEvent);
    procedure ListAllFiles(out aData: TRawUtf8DynArray; onSuccess: TProcedureString; onError: TRestEvent);
  end;

/// return the database Model corresponding to this server
function GetModel(const aRoot: string=SERVER_ROOT): TORMModel;

/// create a TRestClientHTTP instance and connect to the server
// - it will use by default port 8776
// - secure connection will be established via TSQLRestServerAuthenticationDefault
// with the supplied credentials
// - request will be asynchronous, and trigger the onSuccess or onError event
procedure GetClient(const aServerAddress: string;
  onSuccess, onError: TRestEvent; aServerPort: integer = 8776);


// publish some low-level helpers for variant conversion
// - used internally: you should not need those functions in your end-user code
function Variant2TAlignment(const _variant: JSValue): TAlignment;
function Variant2TVerticalAlignment(const _variant: JSValue): TVerticalAlignment;
function Variant2TTypeKind(const _variant: JSValue): TTypeKind;
function Variant2TFieldType(const _variant: JSValue): TFieldType;
function Variant2_MetaMode(const _variant: JSValue): _MetaMode;
function Variant2_ColumnType(const _variant: JSValue): _ColumnType;
function Variant2_AutocompleteType(const _variant: JSValue): _AutocompleteType;
function Variant2_WidgetType(const _variant: JSValue): _WidgetType;
function Variant2_TextInputType(const _variant: JSValue): _TextInputType;
function Variant2_HTMLFieldType(const _variant: JSValue): _HTMLFieldType;
function Variant2_TextInputAction(const _variant: JSValue): _TextInputAction;
function Variant2_BracketType(const _variant: JSValue): _BracketType;
function Variant2_MetaSIUnit(const _variant: JSValue): _MetaSIUnit;
function Variant2TMustKind(const _variant: JSValue): TMustKind;
function Variant2TConnectionKind(const _variant: JSValue): TConnectionKind;
function Variant2TEventOps(const _variant: JSValue): TEventOps;

implementation

{$HINTS OFF} // for H2164 hints of unused variables


{ Some helpers for enumerates types }

function Variant2TAlignment(const _variant: JSValue ): TAlignment;
begin
  result := TAlignment(VariantToEnum(_variant,['taLeftJustify','taRightJustify','taCenter']));
end;

function Variant2TVerticalAlignment(const _variant: JSValue ): TVerticalAlignment;
begin
  result := TVerticalAlignment(VariantToEnum(_variant,['taAlignTop','taAlignBottom','taVerticalCenter']));
end;

function Variant2TTypeKind(const _variant: JSValue ): TTypeKind;
begin
  result := TTypeKind(VariantToEnum(_variant,['tkUnknown','tkInteger','tkChar','tkEnumeration','tkFloat','tkString','tkSet','tkClass','tkMethod','tkWChar','tkLString','tkWString','tkVariant','tkArray','tkRecord','tkInterface','tkInt64','tkDynArray','tkUString','tkClassRef','tkPointer','tkProcedure','tkMRecord']));
end;

function Variant2TFieldType(const _variant: JSValue ): TFieldType;
begin
  result := TFieldType(VariantToEnum(_variant,['ftUnknown','ftString','ftSmallint','ftInteger','ftWord','ftBoolean','ftFloat','ftCurrency','ftBCD','ftDate','ftTime','ftDateTime','ftBytes','ftVarBytes','ftAutoInc','ftBlob','ftMemo','ftGraphic','ftFmtMemo','ftParadoxOle','ftDBaseOle','ftTypedBinary','ftCursor','ftFixedChar','ftWideString','ftLargeint','ftADT','ftArray','ftReference','ftDataSet','ftOraBlob','ftOraClob','ftVariant','ftInterface','ftIDispatch','ftGuid','ftTimeStamp','ftFMTBcd','ftFixedWideChar','ftWideMemo','ftOraTimeStamp','ftOraInterval','ftLongWord','ftShortint','ftByte','ftExtended','ftConnection','ftParams','ftStream','ftTimeStampOffset','ftObject','ftSingle']));
end;

function Variant2_MetaMode(const _variant: JSValue ): _MetaMode;
begin
  result := _MetaMode(VariantToEnum(_variant,['mmNone','mmEnum','mmSet','mmArray','mmMap']));
end;

function Variant2_ColumnType(const _variant: JSValue ): _ColumnType;
begin
  result := _ColumnType(VariantToEnum(_variant,['ctNone','ctInteger','ctDouble','ctCurrency','ctTimeLog','ctDateTime','ctDate','ctTime','ctBoolean','ctEnums','ctSet','ctString','ctMultiline','ctName','ctPassword','ctPhone','ctEmail','ctAddress','ctCity','ctCountry','ctPostalCode','ctUrl','ctFileName','ctIpv4','ctIpv6','ctTID','ctFileExt','ctMimeType','ctBlobStr','ctFile','ctCel','ctFax','ctIDnum','ctRegNo','ctVatNo','ctGPS','ct_QUERY']));
end;

function Variant2_AutocompleteType(const _variant: JSValue ): _AutocompleteType;
begin
  result := _AutocompleteType(VariantToEnum(_variant,['ac_OFF','ac_ON','acName','acHonorificPrefix','acGivenName','acAdditionalName','acFamilyName','acHonorificSuffix','acNickname','acEmail','acUsername','acNewPassword','acCurrentPassword','acOrganizationTitle','acOrganization','acTel','acTelCountryCode','acTelNational','acTelAreaCode','acTelLocal','acTelExtension','acImpp','acStreetAddress','acAddressLine1','acAddressLine2','acAddressLine3','acAddressLevel1','acAddressLevel2','acAddressLevel3','acAddressLevel4','acCountry','acCountryName','acCountryCode','acPostalCode','acCcName','acCcGivenName','acCcFamilyName','acCcNumber','acCcExp','acCcExpMonth','acCcExpYear','acCcCsc','acCcType','acTransactionAmount','acTransactionCurrency','acLanguage','acBday','acBdayDay','acBdayMonth','acBdayYear','acSex','acUrl','acPhoto']));
end;

function Variant2_WidgetType(const _variant: JSValue ): _WidgetType;
begin
  result := _WidgetType(VariantToEnum(_variant,['wtNone','wtBreak','wtForm','wtPanel','wtListBox','wtEditText','wtMemo','wtComboBox','wtCheckBox','wtCheckGroup','wtRadioGroup','wtDateTimeEdit','wtButton','wtFile','wtImage','wtAvatar']));
end;

function Variant2_TextInputType(const _variant: JSValue ): _TextInputType;
begin
  result := _TextInputType(VariantToEnum(_variant,['ttNone','ttText','ttMultiline','ttSpinEdit','ttInteger','ttDouble','ttCurrency','ttPhone','ttDateTime','ttEmailAddress','ttURL','ttVisiblePassword','ttName','ttStreetAddress']));
end;

function Variant2_HTMLFieldType(const _variant: JSValue ): _HTMLFieldType;
begin
  result := _HTMLFieldType(VariantToEnum(_variant,['dtNone','dtText','dtPassword','dtEmail','dtTel','dtNumber','dtDate','dtTime','dtDateTime','dtDateTimeLocal','dtMonth','dtWeek','dtURL','dtSearch','dtColor','dtCheckbox','dtRadio','dtFile','dtRange','dthidden','dtbutton','dtimage','dtreset','dtsubmit']));
end;

function Variant2_TextInputAction(const _variant: JSValue ): _TextInputAction;
begin
  result := _TextInputAction(VariantToEnum(_variant,['taNone','taUnspecified','taDone','taGo','taSearch','taSend','taNext','taPrevious','taContinue','taJoin','taRoute','taEmergencyCall','taNewline']));
end;

function Variant2_BracketType(const _variant: JSValue ): _BracketType;
begin
  result := _BracketType(VariantToEnum(_variant,['btNone','btRound','btCurly','btSquare','btAngle']));
end;

function Variant2_MetaSIUnit(const _variant: JSValue ): _MetaSIUnit;
begin
  result := _MetaSIUnit(VariantToEnum(_variant,['siNone','siLength','siVolume','siMass','siTime','siSpeed','siPressure','siTemperature','siCharge','siCurrent','siVolts','siFrequency','siEnergy','siForce','siPower','siMole','siCandela']));
end;

function Variant2TMustKind(const _variant: JSValue ): TMustKind;
begin
  result := TMustKind(VariantToEnum(_variant,['fkUnknown','fkHTML','fkPart','fkCSS','fkJson','fkJS','fkPas','fkDfm','fkTxt','fkGif','fkJpg','fkPng','fkSvg','fkXml','fkBmp','fkTiff','fkPdf','fkTree']));
end;

function Variant2TConnectionKind(const _variant: JSValue ): TConnectionKind;
begin
  result := TConnectionKind(VariantToEnum(_variant,['ckSmtp','ckSMS','ckVoIP','ckApiHttp','ckApiWebSock']));
end;

function Variant2TEventOps(const _variant: JSValue ): TEventOps;
begin
  result := TEventOps(VariantToEnum(_variant,['eoNONE','eoDbAdd','eoDBEdit','eoDbEditSort','eoDbDelete','eoView']));
end;


{$HINTS ON} // for H2164 hints of unused variables

function GetModel(const aRoot: string): TORMModel;
begin
  result := TORMModel.Create([TAuthGroup,TAppAuthUser,TOrmIcon,TOrmMetaType,TOrmObjDef,TORMProjFile,TOrmProject,TOrmHostConnection,TORMDomainInfo,TOrmDBEvent,TORMPhoneNumber,TORMEMailAddress,TORMStreetAddress,TORMContact,TORMLoginToken,TORMAttachment,TORMVoipPhones,TOrmSMS,TORMPhoneCall,TOrmEMail,TORMSmsTemplate,TORMEmailTemplate,TORMAppointment,TORMCalendarLink,TORMContactLink,TORMCalendarStatus,TSQLLocal,TOrmTableDeleted],aRoot);
end;

procedure GetClient(const aServerAddress: string;  onSuccess, onError: TRestEvent; aServerPort: integer);
var client: TRestClientHTTP;
begin
 client := TRestClientHTTP.Create(aServerAddress, aServerPort, GetModel, true, true );

 client.Connect(
    procedure(Client: TRestClientURI)
    begin
      try
        if client.ServerTimeStamp = 0
			then begin
				  if Assigned(onError)
					then onError(client);
				  exit;
				 end;


        if Assigned(onSuccess)
			then onSuccess(client);
      except
        if Assigned(onError)
			then     onError(client);
      end;
    end,
    onError);
end;



{ TServiceApp }

constructor TServiceApp.Create(aClient: TRestClientURI);
begin
  fServiceName := 'App';
  fServiceURI := 'App';
  fInstanceImplementation := sicPerSession;
  fContractExpected := '84387B6FDE3C4881';
  inherited Create(aClient);
end;

procedure TServiceApp.GetUserID(out aID: TID; onSuccess: TProcedureString; onError: TRestEvent);
var res: TJSONArray;
begin
  fClient.CallRemoteServiceAsynch(self,'GetUserID',1, // raise EServiceException on error
    [],
	procedure (res:TJSValueDynArray)
    begin
     OnSuccess(JS.toString(res));
    end,
	onError
	);

end;
procedure TServiceApp.GetNotifcations(const aOnlyUnread: Boolean; out aIDs: TIDDynArray; onSuccess: TProcedureString; onError: TRestEvent);
var res: TJSONArray;
begin
  fClient.CallRemoteServiceAsynch(self,'GetNotifcations',1, // raise EServiceException on error
    [aOnlyUnread],
	procedure (res:TJSValueDynArray)
    begin
     OnSuccess(JS.toString(res));
    end,
	onError
	);

end;

{ TServiceDocument }

constructor TServiceDocument.Create(aClient: TRestClientURI);
begin
  fServiceName := 'Document';
  fServiceURI := 'Document';
  fInstanceImplementation := sicPerUser;
  fContractExpected := '1469966E6C571ADD';
  inherited Create(aClient);
end;

procedure TServiceDocument.Load(const pmcName: String; out pmoData: String; onSuccess: TProcedureString; onError: TRestEvent);
var res: TJSONArray;
begin
  fClient.CallRemoteServiceAsynch(self,'Load',1, // raise EServiceException on error
    [pmcName],
	procedure (res:TJSValueDynArray)
    begin
     OnSuccess(JS.toString(res));
    end,
	onError
	);

end;
procedure TServiceDocument.Save(const pmcName: String; const pmcData: String; onSuccess: TProcedureString; onError: TRestEvent);
var res: TJSONArray;
begin
  fClient.CallRemoteServiceAsynch(self,'Save',0, // raise EServiceException on error
    [pmcName,pmcData],
	procedure (res:TJSValueDynArray)
    begin
     OnSuccess(JS.toString(res));
    end,
	onError
	);
end;
procedure TServiceDocument.GetAllNames(out pmoFileNames: TFileNameDynArray; onSuccess: TProcedureString; onError: TRestEvent);
var res: TJSONArray;
begin
  fClient.CallRemoteServiceAsynch(self,'GetAllNames',1, // raise EServiceException on error
    [],
	procedure (res:TJSValueDynArray)
    begin
     OnSuccess(JS.toString(res));
    end,
	onError
	);

end;

{ TServiceRender }

constructor TServiceRender.Create(aClient: TRestClientURI);
begin
  fServiceName := 'Render';
  fServiceURI := 'Render';
  fInstanceImplementation := sicPerThread;
  fContractExpected := 'B739862C703F06F5';
  inherited Create(aClient);
end;

procedure TServiceRender.AddFile(const aName: String; onSuccess: TProcedureString; onError: TRestEvent);
var res: TJSONArray;
begin
  fClient.CallRemoteServiceAsynch(self,'AddFile',0, // raise EServiceException on error
    [aName],
	procedure (res:TJSValueDynArray)
    begin
     OnSuccess(JS.toString(res));
    end,
	onError
	);
end;
procedure TServiceRender.DelFile(const aName: String; onSuccess: TProcedureString; onError: TRestEvent);
var res: TJSONArray;
begin
  fClient.CallRemoteServiceAsynch(self,'DelFile',0, // raise EServiceException on error
    [aName],
	procedure (res:TJSValueDynArray)
    begin
     OnSuccess(JS.toString(res));
    end,
	onError
	);
end;
procedure TServiceRender.GetFile(const aName: String; out aData: String; onSuccess: TProcedureString; onError: TRestEvent);
var res: TJSONArray;
begin
  fClient.CallRemoteServiceAsynch(self,'GetFile',1, // raise EServiceException on error
    [aName],
	procedure (res:TJSValueDynArray)
    begin
     OnSuccess(JS.toString(res));
    end,
	onError
	);

end;
procedure TServiceRender.SetFile(const aName: String; const aData: String; onSuccess: TProcedureString; onError: TRestEvent);
var res: TJSONArray;
begin
  fClient.CallRemoteServiceAsynch(self,'SetFile',0, // raise EServiceException on error
    [aName,aData],
	procedure (res:TJSValueDynArray)
    begin
     OnSuccess(JS.toString(res));
    end,
	onError
	);
end;
procedure TServiceRender.GetParent(const aTree: String; const aName: String; out aData: String; onSuccess: TProcedureString; onError: TRestEvent);
var res: TJSONArray;
begin
  fClient.CallRemoteServiceAsynch(self,'GetParent',1, // raise EServiceException on error
    [aTree,aName],
	procedure (res:TJSValueDynArray)
    begin
     OnSuccess(JS.toString(res));
    end,
	onError
	);

end;
procedure TServiceRender.SetParent(const aTree: String; const aName: String; const aParent: String; onSuccess: TProcedureString; onError: TRestEvent);
var res: TJSONArray;
begin
  fClient.CallRemoteServiceAsynch(self,'SetParent',0, // raise EServiceException on error
    [aTree,aName,aParent],
	procedure (res:TJSValueDynArray)
    begin
     OnSuccess(JS.toString(res));
    end,
	onError
	);
end;
procedure TServiceRender.ListKinds(const aKind: TMustKind; out aData: TRawUtf8DynArray; onSuccess: TProcedureString; onError: TRestEvent);
var res: TJSONArray;
begin
  fClient.CallRemoteServiceAsynch(self,'ListKinds',1, // raise EServiceException on error
    [ord(aKind)],
	procedure (res:TJSValueDynArray)
    begin
     OnSuccess(JS.toString(res));
    end,
	onError
	);

end;
procedure TServiceRender.ListAllFiles(out aData: TRawUtf8DynArray; onSuccess: TProcedureString; onError: TRestEvent);
var res: TJSONArray;
begin
  fClient.CallRemoteServiceAsynch(self,'ListAllFiles',1, // raise EServiceException on error
    [],
	procedure (res:TJSValueDynArray)
    begin
     OnSuccess(JS.toString(res));
    end,
	onError
	);

end;

end.
