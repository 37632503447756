unit FormSwaggerU;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.REST, WEBLib.TreeNodes,
  WEBLib.SyntaxMemo, Vcl.ComCtrls, WEBLib.ComCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Controls, WEBLib.ExtCtrls, WEBLib.CDS, VCL.TMSFNCTypes,
  VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCTreeViewBase, VCL.TMSFNCTreeViewData,
  VCL.TMSFNCCustomTreeView, VCL.TMSFNCTreeView, VCL.TMSFNCCheckedTreeView;

type
  TFormSwagger = class(TForm)
    WebPanel1: TPanel;
    WebPanel2: TPanel;
    BtnSwagger: TButton;
    WebClientConnection1: TClientConnection;
    TreeV: TTMSFNCCheckedTreeView;
    WebPageControl1: TPageControl;
    Html: TTabSheet;
    Json: TTabSheet;
    LIST: TTabSheet;
    GRID: TTabSheet;
    FORM: TTabSheet;
    procedure BtnSwaggerClick(Sender: TObject);
    procedure WebClientConnection1DataReceived(Sender: TObject;
      ARequest: TJSXMLHttpRequestRecord; var AResponse: string);
    procedure WebClientConnection1AfterConnect(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
   procedure LoadDFMValues;override;
  end;

var
  FormSwagger: TFormSwagger;

implementation

{$R *.dfm}

procedure TFormSwagger.BtnSwaggerClick(Sender: TObject);
begin
 console.Log('trying');
// WebClientConnection1.URI := 'https://api.true.co.za:8776/app/wrapper/Swagger/mORMotClient.json';
 WebClientConnection1.URI := 'assets/swag.json';
 WebClientConnection1.Active := true;
end;

procedure TFormSwagger.LoadDFMValues;
begin
  inherited;
  WebPanel1 := TPanel.Create(Self);
  BtnSwagger := TButton.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  TreeV := TTMSFNCCheckedTreeView.Create(Self);
  WebPageControl1 := TPageControl.Create(Self);
  Html := TTabSheet.Create(Self);
  Json := TTabSheet.Create(Self);
  LIST := TTabSheet.Create(Self);
  GRID := TTabSheet.Create(Self);
  FORM := TTabSheet.Create(Self);
  WebClientConnection1 := TClientConnection.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  BtnSwagger.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  TreeV.BeforeLoadDFMValues;
  WebPageControl1.BeforeLoadDFMValues;
  Html.BeforeLoadDFMValues;
  Json.BeforeLoadDFMValues;
  LIST.BeforeLoadDFMValues;
  GRID.BeforeLoadDFMValues;
  FORM.BeforeLoadDFMValues;
  WebClientConnection1.BeforeLoadDFMValues;
  try
    Name := 'FormSwagger';
    Width := 1244;
    Height := 673;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 1244;
    WebPanel1.Height := 60;
    WebPanel1.Align := alTop;
    WebPanel1.ChildOrder := 1;
    WebPanel1.TabOrder := 0;
    BtnSwagger.SetParentComponent(WebPanel1);
    BtnSwagger.Name := 'BtnSwagger';
    BtnSwagger.Left := 3;
    BtnSwagger.Top := 3;
    BtnSwagger.Width := 96;
    BtnSwagger.Height := 25;
    BtnSwagger.Caption := 'Get Swagger';
    BtnSwagger.HeightPercent := 100.000000000000000000;
    BtnSwagger.WidthPercent := 100.000000000000000000;
    SetEvent(BtnSwagger, Self, 'OnClick', 'BtnSwaggerClick');
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 60;
    WebPanel2.Width := 425;
    WebPanel2.Height := 613;
    WebPanel2.Align := alLeft;
    WebPanel2.Caption := 'WebPanel2';
    WebPanel2.ChildOrder := 2;
    WebPanel2.TabOrder := 1;
    TreeV.SetParentComponent(WebPanel2);
    TreeV.Name := 'TreeV';
    TreeV.Left := 0;
    TreeV.Top := 0;
    TreeV.Width := 425;
    TreeV.Height := 613;
    TreeV.Align := alClient;
    TreeV.ParentDoubleBuffered := False;
    TreeV.DoubleBuffered := True;
    TreeV.TabOrder := 0;
    TreeV.Stroke.Color := clDarkgray;
    TreeV.GroupsAppearance.TopSize := 50.000000000000000000;
    TreeV.GroupsAppearance.BottomSize := 50.000000000000000000;
    TreeV.GroupsAppearance.TopFill.Kind := gfkNone;
    TreeV.GroupsAppearance.BottomFill.Kind := gfkNone;
    TreeV.GroupsAppearance.TopFont.Charset := DEFAULT_CHARSET;
    TreeV.GroupsAppearance.TopFont.Color := 4539717;
    TreeV.GroupsAppearance.TopFont.Height := -11;
    TreeV.GroupsAppearance.TopFont.Name := 'Segoe UI';
    TreeV.GroupsAppearance.TopFont.Style := [];
    TreeV.GroupsAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    TreeV.GroupsAppearance.BottomFont.Color := 4539717;
    TreeV.GroupsAppearance.BottomFont.Height := -11;
    TreeV.GroupsAppearance.BottomFont.Name := 'Segoe UI';
    TreeV.GroupsAppearance.BottomFont.Style := [];
    TreeV.Columns.Clear;
    with TreeV.Columns.Add do
    begin
      Name := 'Column0';
      Text := 'Name';
      Width := 100.000000000000000000;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -11;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [];
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -11;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
    end;
    with TreeV.Columns.Add do
    begin
      Name := 'Column1';
      Text := 'Unit';
      HorizontalTextAlign := gtaTrailing;
      Width := 90.000000000000000000;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -11;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [];
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -11;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
    end;
    with TreeV.Columns.Add do
    begin
      Name := 'Column2';
      Text := 'Status';
      HorizontalTextAlign := gtaCenter;
      Width := 80.000000000000000000;
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Segoe UI';
      Font.Style := [];
      TopFont.Charset := DEFAULT_CHARSET;
      TopFont.Color := clWindowText;
      TopFont.Height := -11;
      TopFont.Name := 'Segoe UI';
      TopFont.Style := [];
      BottomFont.Charset := DEFAULT_CHARSET;
      BottomFont.Color := clWindowText;
      BottomFont.Height := -11;
      BottomFont.Name := 'Segoe UI';
      BottomFont.Style := [];
    end;
    TreeV.Nodes.Clear;
    with TreeV.Nodes.Add do
    begin
      Values.Clear;
      with Values.Add do
      begin
        Text := 'Managers';
        CheckType := tvntCheckBox;
      end;
      Expanded := True;
      Tag := 0;
      Nodes.Clear;
      with Nodes.Add do
      begin
        Values.Clear;
        with Values.Add do
        begin
          Text := 'Isabella Peeters';
          CheckType := tvntCheckBox;
        end;
        with Values.Add do
        begin
          Text := 'HR';
          CheckType := tvntCheckBox;
        end;
        with Values.Add do
        begin
          Text := 'Flight';
          CheckType := tvntCheckBox;
        end;
        Tag := 0;
      end;
      with Nodes.Add do
      begin
        Values.Clear;
        with Values.Add do
        begin
          Text := 'Yusuf Andersson';
          CheckType := tvntCheckBox;
        end;
        with Values.Add do
        begin
          Text := 'Sales';
          CheckType := tvntCheckBox;
        end;
        with Values.Add do
        begin
          Text := 'Abroad';
          CheckType := tvntCheckBox;
        end;
        Tag := 0;
      end;
      with Nodes.Add do
      begin
        Values.Clear;
        with Values.Add do
        begin
          Text := 'Yusuf Peeters';
          CheckType := tvntCheckBox;
        end;
        with Values.Add do
        begin
          Text := 'Development';
          CheckType := tvntCheckBox;
        end;
        with Values.Add do
        begin
          Text := 'Office';
          CheckType := tvntCheckBox;
        end;
        Tag := 0;
      end;
    end;
    with TreeV.Nodes.Add do
    begin
      Values.Clear;
      with Values.Add do
      begin
        Text := 'Specialists';
        CheckType := tvntCheckBox;
      end;
      Expanded := True;
      Tag := 0;
      Nodes.Clear;
      with Nodes.Add do
      begin
        Values.Clear;
        with Values.Add do
        begin
          Text := 'Yusuf Wang';
          CheckType := tvntCheckBox;
        end;
        with Values.Add do
        begin
          Text := 'Research';
          CheckType := tvntCheckBox;
        end;
        with Values.Add do
        begin
          Text := 'Office';
          CheckType := tvntCheckBox;
        end;
        Tag := 0;
      end;
      with Nodes.Add do
      begin
        Values.Clear;
        with Values.Add do
        begin
          Text := 'Marie Gonzales';
          CheckType := tvntCheckBox;
        end;
        with Values.Add do
        begin
          Text := 'Marketing';
          CheckType := tvntCheckBox;
        end;
        with Values.Add do
        begin
          Text := 'On Leave';
          CheckType := tvntCheckBox;
        end;
        Tag := 0;
      end;
    end;
    with TreeV.Nodes.Add do
    begin
      Values.Clear;
      with Values.Add do
      begin
        Text := 'Assistants';
        CheckType := tvntCheckBox;
      end;
      Tag := 0;
      Nodes.Clear;
      with Nodes.Add do
      begin
        Values.Clear;
        with Values.Add do
        begin
          Text := 'Omar Gonzales';
          CheckType := tvntCheckBox;
        end;
        with Values.Add do
        begin
          Text := 'Development';
          CheckType := tvntCheckBox;
        end;
        with Values.Add do
        begin
          Text := 'On Leave';
          CheckType := tvntCheckBox;
        end;
        Tag := 0;
      end;
      with Nodes.Add do
      begin
        Values.Clear;
        with Values.Add do
        begin
          Text := 'Yusuf Wang';
          CheckType := tvntCheckBox;
        end;
        with Values.Add do
        begin
          Text := 'Finance';
          CheckType := tvntCheckBox;
        end;
        with Values.Add do
        begin
          Text := 'Abroad';
          CheckType := tvntCheckBox;
        end;
        Tag := 0;
      end;
      with Nodes.Add do
      begin
        Values.Clear;
        with Values.Add do
        begin
          Text := 'Marie Peeters';
          CheckType := tvntCheckBox;
        end;
        with Values.Add do
        begin
          Text := 'Research';
          CheckType := tvntCheckBox;
        end;
        with Values.Add do
        begin
          Text := 'Abroad';
          CheckType := tvntCheckBox;
        end;
        Tag := 0;
      end;
    end;
    TreeV.ColumnsAppearance.StretchColumn := 0;
    TreeV.ColumnsAppearance.StretchAll := False;
    TreeV.ColumnsAppearance.TopSize := 36.000000000000000000;
    TreeV.ColumnsAppearance.BottomSize := 25.000000000000000000;
    TreeV.ColumnsAppearance.TopFont.Charset := DEFAULT_CHARSET;
    TreeV.ColumnsAppearance.TopFont.Color := 4539717;
    TreeV.ColumnsAppearance.TopFont.Height := -13;
    TreeV.ColumnsAppearance.TopFont.Name := 'Segoe UI';
    TreeV.ColumnsAppearance.TopFont.Style := [fsBold];
    TreeV.ColumnsAppearance.BottomFont.Charset := DEFAULT_CHARSET;
    TreeV.ColumnsAppearance.BottomFont.Color := 4539717;
    TreeV.ColumnsAppearance.BottomFont.Height := -11;
    TreeV.ColumnsAppearance.BottomFont.Name := 'Segoe UI';
    TreeV.ColumnsAppearance.BottomFont.Style := [];
    TreeV.ColumnsAppearance.TopFill.Color := 16380654;
    TreeV.ColumnsAppearance.BottomFill.Kind := gfkNone;
    TreeV.ColumnsAppearance.BottomFill.Color := 16380654;
    TreeV.ColumnsAppearance.TopStroke.Kind := gskNone;
    TreeV.NodesAppearance.ShowFocus := False;
    TreeV.NodesAppearance.ExpandWidth := 18.000000000000000000;
    TreeV.NodesAppearance.ExpandHeight := 18.000000000000000000;
    TreeV.NodesAppearance.LevelIndent := 20.000000000000000000;
    TreeV.NodesAppearance.FixedHeight := 25.000000000000000000;
    TreeV.NodesAppearance.VariableMinimumHeight := 25.000000000000000000;
    TreeV.NodesAppearance.Font.Charset := DEFAULT_CHARSET;
    TreeV.NodesAppearance.Font.Color := 8026746;
    TreeV.NodesAppearance.Font.Height := -11;
    TreeV.NodesAppearance.Font.Name := 'Segoe UI';
    TreeV.NodesAppearance.Font.Style := [];
    TreeV.NodesAppearance.TitleFont.Charset := DEFAULT_CHARSET;
    TreeV.NodesAppearance.TitleFont.Color := clBlack;
    TreeV.NodesAppearance.TitleFont.Height := -11;
    TreeV.NodesAppearance.TitleFont.Name := 'Segoe UI';
    TreeV.NodesAppearance.TitleFont.Style := [];
    TreeV.NodesAppearance.SelectedFontColor := 4539717;
    TreeV.NodesAppearance.ExtendedFontColor := 4539717;
    TreeV.NodesAppearance.SelectedFill.Color := 16578806;
    TreeV.NodesAppearance.SelectedStroke.Color := 15702829;
    TreeV.NodesAppearance.SelectedStroke.Width := 2.000000000000000000;
    TreeV.NodesAppearance.ExtendedFont.Charset := DEFAULT_CHARSET;
    TreeV.NodesAppearance.ExtendedFont.Color := clWindowText;
    TreeV.NodesAppearance.ExtendedFont.Height := -11;
    TreeV.NodesAppearance.ExtendedFont.Name := 'Segoe UI';
    TreeV.NodesAppearance.ExtendedFont.Style := [];
    TreeV.NodesAppearance.ExpandNodeIcon.LoadFromFile('FormSwaggerU.WebPanel2.TreeV.NodesAppearance.ExpandNodeIcon.png');
    TreeV.NodesAppearance.CollapseNodeIcon.LoadFromFile('FormSwaggerU.WebPanel2.TreeV.NodesAppearance.CollapseNodeIcon.png');
    TreeV.NodesAppearance.ExpandNodeIconLarge.LoadFromFile('FormSwaggerU.WebPanel2.TreeV.NodesAppearance.ExpandNodeIconLarge.png');
    TreeV.NodesAppearance.CollapseNodeIconLarge.LoadFromFile('FormSwaggerU.WebPanel2.TreeV.NodesAppearance.CollapseNodeIconLarge.png');
    TreeV.GlobalFont.Name := 'Segoe UI';
    TreeV.DefaultViewJSONOptions.NameHTMLTemplate := '<#NAME>';
    TreeV.DefaultViewJSONOptions.ValueHTMLTemplate := '<#VALUE>';
    WebPageControl1.SetParentComponent(Self);
    WebPageControl1.Name := 'WebPageControl1';
    WebPageControl1.Left := 425;
    WebPageControl1.Top := 60;
    WebPageControl1.Width := 819;
    WebPageControl1.Height := 613;
    WebPageControl1.Align := alClient;
    WebPageControl1.ChildOrder := 2;
    WebPageControl1.ElementFont := efCSS;
    WebPageControl1.TabIndex := 0;
    WebPageControl1.TabOrder := 2;
    Html.SetParentComponent(WebPageControl1);
    Html.Name := 'Html';
    Html.Left := 0;
    Html.Top := 20;
    Html.Width := 819;
    Html.Height := 593;
    Html.Caption := 'HTML';
    Json.SetParentComponent(WebPageControl1);
    Json.Name := 'Json';
    Json.Left := 0;
    Json.Top := 20;
    Json.Width := 819;
    Json.Height := 593;
    Json.Caption := 'JSON';
    Json.ChildOrder := 1;
    LIST.SetParentComponent(WebPageControl1);
    LIST.Name := 'LIST';
    LIST.Left := 0;
    LIST.Top := 20;
    LIST.Width := 819;
    LIST.Height := 593;
    LIST.Caption := 'LIST';
    LIST.ChildOrder := 2;
    GRID.SetParentComponent(WebPageControl1);
    GRID.Name := 'GRID';
    GRID.Left := 0;
    GRID.Top := 20;
    GRID.Width := 819;
    GRID.Height := 593;
    GRID.Caption := 'GRID';
    GRID.ChildOrder := 3;
    FORM.SetParentComponent(WebPageControl1);
    FORM.Name := 'FORM';
    FORM.Left := 0;
    FORM.Top := 20;
    FORM.Width := 819;
    FORM.Height := 593;
    FORM.Caption := 'FORM';
    FORM.ChildOrder := 4;
    WebClientConnection1.SetParentComponent(Self);
    WebClientConnection1.Name := 'WebClientConnection1';
    WebClientConnection1.Active := False;
    WebClientConnection1.Delimiter := ';';
    WebClientConnection1.AfterConnect := WebClientConnection1AfterConnect;
    SetEvent(WebClientConnection1, Self, 'OnDataReceived', 'WebClientConnection1DataReceived');
    WebClientConnection1.Left := 608;
    WebClientConnection1.Top := 320;
  finally
    WebPanel1.AfterLoadDFMValues;
    BtnSwagger.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    TreeV.AfterLoadDFMValues;
    WebPageControl1.AfterLoadDFMValues;
    Html.AfterLoadDFMValues;
    Json.AfterLoadDFMValues;
    LIST.AfterLoadDFMValues;
    GRID.AfterLoadDFMValues;
    FORM.AfterLoadDFMValues;
    WebClientConnection1.AfterLoadDFMValues;
  end;


end;

procedure TFormSwagger.WebClientConnection1AfterConnect(Sender: TObject);
begin
 console.Log('Connect');
end;

procedure TFormSwagger.WebClientConnection1DataReceived(Sender: TObject;  ARequest: TJSXMLHttpRequestRecord; var AResponse: string);
var  S : TMemoryStream;
begin
// S := TMemoryStream.Create;
// S.Write(TBytes(AResponse),Length(aResponse));
// S.Seek(0,0);
// WebTreeView1.LoadFromStream(S);
// S.Free;

 TreeV.ViewJSONFromText(AResponse);
 console.Log(AResponse);
end;

end.

