{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2019 - 2020                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.IndexedDb;

//{$define idbdebug}

{
  For soft error testing: look at the
  comments for "soft error testing"

  Hard error testing: Any Delphi Exception will be
  hard error and should cause red alert.
  1) Try sending a usage error from the app, for example
     set the KeyFieldName to nonexistent field say 'idx'
  2) Look for comment with Hard error testing and simulate such
     an error from base dataset class
}


{$DEFINE NOPP}

interface

uses
   Classes, JS, Web, SysUtils, WEBLib.CDS, JSONDataSet,
   DB, libindexeddb;

type
  TIndexedDbOpCode = (opOpen, opAdd, opPut, opDelete, opGet, opGetAllKeys, opGetAllObjs,
                      opGetAllIndexKeys, opGetAllObjsByIndex);

  TIndexedDbResultEvent = procedure(success: Boolean; opCode: TIndexedDbOpCode; data: JSValue; sequenceId: JSValue; errorName, errorMsg: String) of object;

  TIndexedDb = Class(TComponent)
  private
     FDb: TMIDBDatabase;
     FDatabaseName: String;
     FObjectStoreName: String;
     FKeyFieldName: String;
     FAutoIncrement: Boolean;
     FIndexFields: TJSArray;
     FActiveIndex: String;
     FIndexDescending: Boolean;
     FIsOpen: Boolean;
     FCreatedIndexes: Boolean;
     FAllResult: TJSArray;
     FOnResult: TIndexedDbResultEvent;
  protected
    function ObjectStoreExists: Boolean;
    function GetObjectStore(theReq: TMIDBOpenDBRequest): TMIDBObjectStore;
    function DoUpgradeNeeded(Event: TEventListenerEvent): Boolean;
    function VersionChangeNeeded: Boolean;
    function DoSuccess(Event: TEventListenerEvent): Boolean;
    function DoFail(Event: TEventListenerEvent): Boolean;
    function DoVersionChange(Event: TEventListenerEvent): Boolean;
    function DoBlocked(Event: TEventListenerEvent): Boolean;
    function GetAutoIncrementDynamic: Boolean;
    procedure CorrectPrimaryKey(dataObj: TJSObject);
    procedure SetIndexFields(aFields: TJSArray);
  public
    constructor Create(AOwner: TComponent); overload; override;
    destructor Destroy; override;

    // Add Indexes before opening a "new" database. If database exists then
    // AddIndex will be ignored.
    procedure AddIndex(anIndexName: String; fields: String; isUnique: Boolean=False);

    { Explanation of parameters:
     Pass a DBName and ObjectStoreName. These will be created if absent.
     KeyFieldName: specify if you want to use inline-key (data contains key). In that case, don't pass key separately to PutData below.
                   The key field value will then be taken from that named property from the object passed to PutData.
     AutoIncrement: This will be effective only if db does not exist so that the db is created with autoincrement.
                    In case of autoincrement, you need not pass key to addData. The auto-created key is returned
                    in its success result.
    }
    procedure Open(aDbName: String; aObjectStoreName: String; KeyFieldName: String = ''; autoIncrement: Boolean = False; sequenceID: JSValue = 0);

    procedure PutData(akey: JSValue; data: JSValue; sequenceID: JSValue = 0); overload;
    procedure PutData(data: JSValue; sequenceID: JSValue = 0); overload;
    procedure AddData(data: JSValue; sequenceID: JSValue = 0);
    procedure DeleteData(AKey: JSValue; sequenceID: JSValue = 0);
    procedure GetData(akey: JSValue; sequenceID: JSValue = 0);
    procedure GetKeys(sequenceID: JSValue = 0);
    procedure GetAllObjs(sequenceID: JSValue = 0);
    procedure GetIndexKeys(indexPropertyName: String; sequenceID: JSValue = 0);
    // Gets objects by sequence set in properties ActiveIndex, IndexDescending
    // If ActiveIndex is empty then gets by primary index.
    procedure GetAllObjsByIndex(sequenceID: JSValue = 0);
    procedure GetIndexData(indexPropertyName: String; akey: JSValue; sequenceID: JSValue = 0);

    property DatabaseName: String read FDatabaseName;
    property ObjectStoreName: String read FObjectStoreName;
    property KeyFieldName: String read FKeyFieldName;
    property AutoIncrement: Boolean read FAutoIncrement;
    property ActiveIndex: String read FActiveIndex write FActiveIndex;
    property IndexDescending: Boolean read FIndexDescending write FIndexDescending;

    property OnResult: TIndexedDbResultEvent read FOnResult write FOnResult;
  end;

  TWebIndexedDB = Class(TIndexedDB);

  TIndexedDbClientDataProxy = Class(TDataProxy)
  private
    FIdb: TIndexedDb;
    FDataRequest: TDataRequest;
    FDataRequestEvent: TDataRequestEvent;
  protected
    function GetDataRequest(aOptions: TLoadOptions; aAfterRequest: TDataRequestEvent; aAfterLoad: TDatasetLoadEvent) : TDataRequest; override;
    function DoGetData(aRequest : TDataRequest) : Boolean; override;
    procedure DoOnError(opCode: TIndexedDbOpCode; errorName, errorMsg: String);
    procedure DoOnSuccess(success: Boolean; opCode: TIndexedDbOpCode; data: JSValue; sequenceID: JSValue; errorName, errorMsg: String);
    procedure ProcessUpdate(desc: TRecordUpdateDescriptor);
    procedure Close;
    // Dummy but necessary only as it is an Abstract method
    function ProcessUpdateBatch(aBatch: TRecordUpdateBatch): Boolean; override;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
  end;

  { TIndexedDbClientDataset }

  TIDBErrorEvent = procedure(DataSet: TDataSet; opCode: TIndexedDbOpCode; errorName, errorMsg: String) of object;
  TIDBInitSuccessEvent = procedure(Sender: TObject) of Object;
  TIDBProc = reference to procedure;

  TKeyId = record
    value: JSValue;
  end;
  TIDBAfterUpdateEvent = procedure(success: Boolean; opCode: TIndexedDbOpCode; keyId: TKeyId; errorName, errorMsg: String) of object;

  TIndexedDbClientDataset = Class(TClientDataSet)
  private
    FIDBProxy: TIndexedDbClientDataProxy;
    FIDBDatabaseName, FIDBObjectStoreName, FIDBKeyFieldName,
    FIDBActiveIndex: String;
    FIDBIndexDescending: Boolean;
    FIDBIndexFields: TJSArray;
    FIDBAutoIncrement: Boolean;

    FIDBDataLoaded: Boolean;
    FOpenAfterLoadDFM: Boolean;

    FRemKeyPos: JSValue;
    FOnIDBError: TIDBErrorEvent;
    FONIDBAfterUpdate: TIDBAfterUpdateEvent;

    //For Init
    FOnInitSuccess: TIDBInitSuccessEvent;
    FOnInitSuccessProc: TIDBProc;
    FIdbInit: TIndexedDb;

    // Suppresses a modify to Firestore when the insert
    // generated ID from FireStore is locally updated
    FInsertIdUpdated: Boolean;

    FUpdateCount: Integer;
    FRefreshPending: Boolean;
  protected
    procedure InternalClose; override;
    Function AddToChangeList(aChange : TUpdateStatus) : TRecordUpdateDescriptor ; override;
    procedure SetActive(Value: Boolean); override;
    procedure AfterLoadDFMValues; override;
    procedure DoAfterOpen; override;
    procedure DoOnError(opCode: TIndexedDbOpCode; errorName, errorMsg: String);
    procedure UpdateStarts;
    procedure UpdateEnds;
    procedure FixKeyFieldCase;
    function IsKeyFieldValid: Boolean;
    function IsKeyFieldInteger: Boolean;
    procedure DoInitSuccess(success: Boolean; opCode: TIndexedDbOpCode; data: JSValue; sequenceID: JSValue; errorName, errorMsg: String);
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Refresh; reintroduce;

    // Calling Init is necessary only if you have nultiple CDS
    // components using the same database.
    // In such a case, before making those CDS components Active
    // * call Init on the first CDS component.
    // * Pass an event handler to wait for success event before calling
    //   Init on the next CDS component and so on.
    // * Also, if you are using AddIDBIndex,
    //   please make sure that it is called before Init.
    function Init(ProcInitSuccess: TIDBProc=nil): Boolean;

    // Call AddIDBIndex before Init and Set Active calls.
    // Index will be added only for a new object store. If
    // the object store already exists then AddIDBIndex will be
    // ignored.
    procedure AddIDBIndex(anIndexName: String; fields: String; isUnique: Boolean=False);
    property IDBDataLoaded: Boolean read FIDBDataLoaded write FIDBDataLoaded;
    property IDBActiveIndex: String read FIDBActiveIndex write FIDBActiveIndex;
    property IDBIndexDescending: Boolean read FIDBIndexDescending write FIDBIndexDescending;
  published
    property Active;

    // Database and ObjectStore will be created if absent
    property IDBDatabaseName: String read FIDBDatabaseName write FIDBDatabaseName;
    property IDBObjectStoreName: String read FIDBObjectStoreName write FIDBObjectStoreName;
    // Specify primary key here
    property IDBKeyFieldName: String read FIDBKeyFieldName write FIDBKeyFieldName;

    // Specify AutoIncrement for above key.
    // Only effective when creating a new db. This can not be used
    // to change AutoIncrement property of an existing DB.
    property IDBAutoIncrement: Boolean read FIDBAutoIncrement write FIDBAutoIncrement;
    property OnIDBError: TIDBErrorEvent read FOnIDBError write FOnIDBError;
    property ONIDBAfterUpdate: TIDBAfterUpdateEvent read FONIDBAfterUpdate write FONIDBAfterUpdate;
    property OnInitSuccess: TIDBInitSuccessEvent read FOnInitSuccess write FOnInitSuccess;
  end;

  TWebIndexedDbClientDataset = class(TIndexedDBClientDataSet);

implementation

procedure SetOpCode(var aReq: TJSObject; anOpCode: TIndexedDbOpCode);
begin
  aReq['op'] := anOpCode;
end;

function GetOpCode(var aReq: TJSObject): TIndexedDbOpCode;
begin
  result := TIndexedDbOpCode(aReq['op']);
end;

procedure SetSeqId(var aReq: TJSObject; aSeqId: JSValue);
begin
  aReq['seqId'] := aSeqId;
end;

function GetSeqId(var aReq: TJSObject): JSValue;
begin
  result := aReq['seqId'];
end;

procedure SetKeyId(var aReq: TJSObject; anId: JSValue);
begin
  aReq['keyId'] := anId;
end;

function GetKeyId(var aReq: TJSObject): JSValue;
begin
  result := aReq['keyId'];
end;


{ TIndexedDb }

function TIndexedDb.ObjectStoreExists: Boolean;
var
  i: Integer;
begin
  result := False;
  for i := 0 to length(FDb.objectStoreNames) do
  begin
    if FDb.objectStoreNames[i] = FObjectStoreName then
    begin
      result := True;
      break;
    end;
  end;
end;

function TIndexedDb.GetObjectStore(theReq: TMIDBOpenDBRequest): TMIDBObjectStore;
var
  i: Integer;
begin
  result := nil;
  if theReq.transaction = nil then
    exit;
  for i := 0 to length(FDB.objectStoreNames) do
  begin
    if FDB.objectStoreNames[i] = FObjectStoreName then
    begin
      result := theReq.transaction.objectStore(FObjectStoreName);
      break;
    end;
  end;
end;

// This is entered only when a new database is created or when
// an Open with version causes a version change.
function TIndexedDb.DoUpgradeNeeded(Event: TEventListenerEvent): Boolean;
var
  i: Integer;
  exists: Boolean;
  options: TJSCreateObjectStoreOptions;
  theReq: TMIDBOpenDBRequest;
  objectStore: TMIDBObjectStore;
  indexOptions: TMIDBIndexParameters;
  aDefObject: TJSObject;
  idxKeyPath: String;
  idxKeyPathArray: TJSArray;
begin
{$ifdef idbdebug}
  console.log('Entered upgrade needed to Create Object Store');
{$endif}
  theReq := Event.target as TMIDBOpenDBRequest;
  FDb := theReq.resultAsDatabase;

  objectStore := GetObjectStore(theReq);
  exists := objectStore <> nil;

  if not exists then
  begin
    if FKeyFieldName <> '' then
    begin
      // in-line key -- See doc
      options.keyPath := FKeyFieldName;
      options.autoIncrement := FAutoIncrement;
      objectStore := FDb.createObjectStore(FObjectStoreName, options);
    end
    else
    begin
      // out-of-line key -- See doc
      objectStore := FDb.createObjectStore(FObjectStoreName);
    end;
    if FIndexFields <> nil then
    begin
      for I := 0 to FIndexFields.length-1 do
      begin
        // Each element of FIndexDefs is an IndexDef that we
        // process one by one.
        aDefObject := TJSObject(FIndexFields[I]);
        indexOptions.unique := Boolean(aDefObject['isUnique']);
        idxKeyPath := String(aDefObject['keypath']);
        idxKeyPathArray := TJSArray(TJSString(idxKeyPath).split(';'));
        if idxKeyPathArray.length = 1 then
          objectStore.createIndex(String(aDefObject['name']),
                                idxKeyPath,
                                indexOptions)
        else
          // index fields is passed in Delphi way with ; separator
          // It is converted to an array if multiple fields are
          // present. That's the way IndexedDB wants it.
          objectStore.createIndex(String(aDefObject['name']),
                                idxKeyPathArray,
                                indexOptions);
        FCreatedIndexes := True;
      end;
    end;
  end;

  Result := True;
end;

// Feature that allows multiple object stores to be created in the same database
function TIndexedDb.VersionChangeNeeded: Boolean;
begin
  result := False;
  if not ObjectStoreExists then
  begin
    result := True;
    exit;
  end;
  // In future, when we add capability to modify existing
  // indexes, we can add other conditions here that cause a
  // version change and entry into DoUpgradeNeeded
end;

// This kind of error processing is needed since FireStore API is JS based
// and causes JS exceptions.
function GetJSExceptionDetails(ExceptObject: TObject; out errorName: String; out errorMsg: String): Boolean;
var
  idbException: TIDBError;
begin
  result := False;
  if ExceptObject is Exception then
    exit;
  result := True;
  idbException := TIDBError(ExceptObject);
  if isUndefined(idbException.name) then
    errorName := 'UnknownError'
  else
    errorName := idbException.name;
  if isUndefined(idbException.message) then
    errorMsg := 'Unknown Error'
  else
    errorMsg := idbException.message;
end;

function FormatErrorMessage(theOpCode: TIndexedDbOpCode; errorName: String; errorMessage: String): String;
var
  oper: String;
begin
  case theOpCode of
    opOpen: oper := '(Open) ';
    opAdd: oper := '(Add) ';
    opPut: oper := '(Put) ';
    opDelete: oper := '(Delete) ';
    opGet: oper := '(Get) ';
    opGetAllKeys: oper := '(GetAllKeys) ';
    opGetAllObjs: oper := '(GetAllObjs) ';
    opGetAllIndexKeys: oper := '(GetAllIndexKeys) ';
    opGetAllObjsByIndex: oper := '(GetAllObjsByIndex) ';
  else
    oper := '(Unknown) ';
  end;

  Result := Format('IndexedDB Error %s: %s, %s',
                   [oper, errorName, errorMessage]);
end;

// The success response event for all operations on IndexedDB
function TIndexedDb.DoSuccess(Event: TEventListenerEvent): Boolean;
var
  theReq: TMIDBRequest;
  aCursor: TMIDBCursor;
  newVersionNeeded: Integer;
  newOpenRequest: TMIDBOpenDBRequest;
  theOpCode: TIndexedDbOpCode;
  exe: Exception;
  excName, excMsg: String;
begin
  theReq := Event.target as TMIDBRequest;

  theOpCode := GetOpCode(TJSObject(theReq));
  try
    case theOpCode of
      opOpen:
        begin
          FIsOpen := True;
          FDb := (theReq as TMIDBOpenDBRequest).resultAsDatabase;

          FDb.onversionchange := DoVersionChange;

          // Feature that allows multiple object stores to be created in the same database
          if VersionChangeNeeded then
          begin
  {$ifdef idbdebug}
            console.log('Object Store not found on Open Success. So forcing upgrade so that it is created.');
  {$endif}
            // Object store does not exist. So force a version change to
            // reenter DoUpgradeNeeded needed to create the object store
            newVersionNeeded := FDb.Version + 1;
            FDb.Close;

            newOpenRequest := TMIDBFactory(window.indexedDB).open(FDatabaseName, newVersionNeeded);
            SetOpCode(TJSObject(newOpenRequest), opOpen);
            SetSeqId(TJSObject(newOpenRequest), GetSeqId(TJSObject(theReq)));
            newOpenRequest.onupgradeneeded := DoUpgradeNeeded;
            newOpenRequest.onsuccess := DoSuccess;
            newOpenRequest.onerror := DoFail;
            newOpenRequest.onblocked := DoBlocked;
            exit;
          end;

          // if db exists get it from there. It can not be changed
          // so give a warning if this spec changed after creation.
          FAutoIncrement := GetAutoIncrementDynamic;

          if (not FCreatedIndexes) and (FIndexFields <> nil)
              and (FIndexFields.length > 0) then
             console.log('IndexedDB Warning: AddIndex commands ignored for existing database.');

          if Assigned(FOnResult) then
            FOnResult(True, theOpCode, 0, GetSeqId(TJSObject(theReq)), '', '');
        end;

      // seq id can be used to keep context by sending objects too
      // That's what we send back on result
      opPut, opDelete:
        begin
          if Assigned(FOnResult) then
            FOnResult(True, theOpCode, GetKeyId(TJSObject(theReq)), GetSeqId(TJSObject(theReq)), '', '');
        end;

      opGet, opAdd:
        begin
          if Assigned(FOnResult) then
            FOnResult(True, theOpCode, theReq.result, GetSeqId(TJSObject(theReq)), '', '');
        end;

      opGetAllKeys:
        begin
          if Assigned(FOnResult) then
            FOnResult(True, theOpCode, TJSArray(theReq.result), GetSeqId(TJSObject(theReq)), '', '');
        end;

      opGetAllObjs:
        begin
          if Assigned(FOnResult) then
            FOnResult(True, theOpCode, TJSArray(theReq.result), GetSeqId(TJSObject(theReq)), '', '');
        end;

      opGetAllIndexKeys:
        begin
          // This is entered for each value in the cursor and ends when
          // cursor becomes nil
          aCursor := theReq.resultAsCursor;
          if (aCursor <> nil) then
          begin
            FAllResult.push(aCursor.key);
            aCursor.continue;
          end
          else
          begin
            // no more results
           if Assigned(FOnResult) then
             FOnResult(True, theOpCode, FAllResult, GetSeqId(TJSObject(theReq)), '', '');
          end;
        end;

      opGetAllObjsByIndex:
        begin
          // This is entered for each value in the cursor and ends when
          // cursor becomes nil
          aCursor := theReq.resultAsCursor;
          if (aCursor <> nil) then
          begin
            FAllResult.push(aCursor.value);
            aCursor.continue;
          end
          else
          begin
            // no more results
           if Assigned(FOnResult) then
             FOnResult(True, theOpCode, FAllResult, GetSeqId(TJSObject(theReq)), '', '');
          end;
        end;
    end;
  except
    asm
      exe = $e;
    end;
    if not GetJSExceptionDetails(exe, excName, excMsg) then
      raise exe;
    excMsg := FormatErrorMessage(theOpCode, excName, excMsg);
    if Assigned(FOnResult) then
      FOnResult(False, theOpCode, 0, GetSeqId(TJSObject(theReq)), excName, excMsg);
  end;
  Result := True;
end;

function TIndexedDb.DoFail(Event: TEventListenerEvent): Boolean;
var
  errorMsg: String;
  theReq: TMIDBRequest;
  theOpCode: TIndexedDbOpCode;
  errObj: TJSObject;
begin
  theReq := Event.target as TMIDBRequest;

  theOpCode := GetOpCode(TJSObject(theReq));

  errObj := TJSObject(TJSObject(theReq)['error']);

  errorMsg := FormatErrorMessage(theOpCode,
                    String(errObj['name']),
                    String(errObj['message']));

  // Dump the error anyway in the log
  console.log(errorMsg);

  if Assigned(FOnResult) then
  begin
    FOnResult(False, theOpCode, 0, GetSeqId(TJSObject(theReq)), String(errObj['name']), errorMsg);
  end;
  Result := True;
end;

function TIndexedDb.DoVersionChange(Event: TEventListenerEvent): Boolean;
begin
  console.log('IndexedDB Warning: Version change detected for the CDS using objectstore '+ObjectStoreName +
  '. If you are using multiple CDS components on the same Database, before making them Active, please call Init, wait for OnInitSuccess then call Init on next CDS and so on.'
  );

  Result := True;
end;

function TIndexedDb.DoBlocked(Event: TEventListenerEvent): Boolean;
begin
  FOnResult(False, opOpen, 0, 0,
              'Error CDS Blocked',
              'IndexedDB Error: CDS has been blocked that uses objectstore '+ObjectStoreName +
              '. If you are using multiple CDS components on the same Database, before making them Active, please call Init, wait for OnInitSuccess then call Init on next CDS and so on.'
            );

  Result := True;
end;


// AutoIncrement is built-in at database creation time. So
// for an existing database, we take it from the db instead
// if relying on the spec which may have changed
function TIndexedDb.GetAutoIncrementDynamic: Boolean;
var
  aTransaction: TMIDBTransaction;
  objectStore: TMIDBObjectStore;
begin
  result := False;
  if FDb = nil then
    exit;
  aTransaction := FDb.transaction([FObjectStoreName], TMIDBTransactionMode.readwrite);
  objectStore := aTransaction.objectStore(FObjectStoreName);
  result := objectStore.autoIncrement;
end;

procedure TIndexedDb.CorrectPrimaryKey(dataObj: TJSObject);
begin
  // Correction is needed on AutoIncrement only where the primary
  // key should not be specified. when adding. See in-line key in Doc.
  if not FAutoIncrement then
    exit;
  JSDelete(dataObj, FKeyFieldName);
end;

procedure TIndexedDb.SetIndexFields(aFields: TJSArray);
begin
  FIndexFields := aFields;
end;

constructor TIndexedDb.Create(AOwner: TComponent);
begin
  inherited;
  FDb := nil;
  FIsOpen := False;
  FAllResult := TJSArray.new;
  FActiveIndex := '';
  FIndexDescending := False;
  FIndexFields := nil;
  FCreatedIndexes := false;
end;

destructor TIndexedDb.Destroy;
begin
  if FIsOpen then
    FDb.close;
  inherited;
end;

// Permanent index spec. Effective before a new db creation only.
procedure TIndexedDb.AddIndex(anIndexName: String; fields: String; isUnique: Boolean=False);
var
  anIndexDef: TJSObject;
  i: Integer;
begin
  if FIndexFields = nil then
    FIndexFields := TJSArray.New;
  for i := 0 to FIndexFields.length-1 do
  begin
    anIndexDef := TJSObject(FIndexFields[i]);
    if SameText(anIndexName, String(anIndexDef['name'])) then
    begin
      console.log(Format('IndexedDB AddIndex: Duplicate index name (%s). Ignored', [anIndexName]));
      exit;
    end;
  end;
  anIndexDef := TJSObject.New;
  anIndexDef['name'] := anIndexName;
  anIndexDef['keypath'] := fields;
  anIndexDef['isUnique'] := isUnique;
  FIndexFields.Push(anIndexDef);
end;

procedure TIndexedDb.Open(aDbName: String; aObjectStoreName: String; KeyFieldName: String = ''; autoIncrement: Boolean = False; sequenceID: JSValue = 0);
var
  aRequest: TMIDBOpenDBRequest;
begin
  FDatabaseName := aDbName;
  FObjectStoreName := aObjectStoreName;
  FKeyFieldName := KeyFieldName;
  FAutoIncrement := autoIncrement;
  aRequest := TMIDBFactory(window.indexedDB).open(aDbName);
  SetOpCode(TJSObject(aRequest), opOpen);
  SetSeqId(TJSObject(aRequest), sequenceID);
  aRequest.onupgradeneeded := DoUpgradeNeeded;
  aRequest.onsuccess := DoSuccess;
  aRequest.onerror := DoFail;
  aRequest.onblocked := DoBlocked;
end;

// put or modify for out-of-line keys - See doc.
procedure TIndexedDb.PutData(akey: JSValue; data: JSValue; sequenceID: JSValue = 0);
var
  aTransaction: TMIDBTransaction;
  objectStore: TMIDBObjectStore;
  aRequest: TMIDBRequest;
begin
  aTransaction := FDb.transaction([FObjectStoreName], TMIDBTransactionMode.readwrite);
  objectStore := aTransaction.objectStore(FObjectStoreName);
  aRequest := objectStore.put(data, akey);
  SetOpCode(TJSObject(aRequest), opPut);
  SetSeqId(TJSObject(aRequest), sequenceID);
  SetKeyId(TJSObject(aRequest), aKey);
  aRequest.onsuccess := DoSuccess;
  aRequest.onerror := DoFail;
end;

// Put or modify for in-line key--see doc
procedure TIndexedDb.PutData(data: JSValue; sequenceID: JSValue = 0);
var
  aTransaction: TMIDBTransaction;
  objectStore: TMIDBObjectStore;
  aRequest: TMIDBRequest;
begin
  aTransaction := FDb.transaction([FObjectStoreName], TMIDBTransactionMode.readwrite);
  objectStore := aTransaction.objectStore(FObjectStoreName);
  aRequest := objectStore.put(data);
  SetOpCode(TJSObject(aRequest), opPut);
  SetSeqId(TJSObject(aRequest), sequenceID);
  SetKeyId(TJSObject(aRequest), TJSObject(data)[FKeyFieldName]);
  aRequest.onsuccess := DoSuccess;
  aRequest.onerror := DoFail;
end;

// explicit add, use for in-line key only where key should be
// in data. For out-of-line key adds, use 1st putData above.
procedure TIndexedDb.AddData(data: JSValue; sequenceID: JSValue = 0);
var
  aTransaction: TMIDBTransaction;
  objectStore: TMIDBObjectStore;
  aRequest: TMIDBRequest;
begin
  aTransaction := FDb.transaction([FObjectStoreName], TMIDBTransactionMode.readwrite);
  objectStore := aTransaction.objectStore(FObjectStoreName);
  CorrectPrimaryKey(TJSObject(data));
  aRequest := objectStore.add(data);
  SetOpCode(TJSObject(aRequest), opAdd);
  SetSeqId(TJSObject(aRequest), sequenceID);
  aRequest.onsuccess := DoSuccess;
  aRequest.onerror := DoFail;
end;

procedure TIndexedDb.DeleteData(akey: JSValue; sequenceID: JSValue = 0);
var
  aTransaction: TMIDBTransaction;
  objectStore: TMIDBObjectStore;
  aRequest: TMIDBRequest;
begin
  aTransaction := FDb.transaction([FObjectStoreName], TMIDBTransactionMode.readwrite);
  objectStore := aTransaction.objectStore(FObjectStoreName);
  aRequest := objectStore.delete(akey);
  SetOpCode(TJSObject(aRequest), opDelete);
  SetSeqId(TJSObject(aRequest), sequenceID);
  SetKeyId(TJSObject(aRequest), aKey);
  aRequest.onsuccess := DoSuccess;
  aRequest.onerror := DoFail;
end;

procedure TIndexedDb.GetData(akey: JSValue; sequenceID: JSValue = 0);
var
  aTransaction: TMIDBTransaction;
  objectStore: TMIDBObjectStore;
  aRequest: TMIDBRequest;
begin
  aTransaction := FDb.transaction([FObjectStoreName], TMIDBTransactionMode.readwrite);
  objectStore := aTransaction.objectStore(FObjectStoreName);
  aRequest := objectStore.get(akey);
  SetOpCode(TJSObject(aRequest), opGet);
  SetSeqId(TJSObject(aRequest), sequenceID);
  aRequest.onsuccess := DoSuccess;
  aRequest.onerror := DoFail;
end;


// Gets keys in natural sequence only
procedure TIndexedDb.GetKeys(sequenceID: JSValue = 0);
var
  aTransaction: TMIDBTransaction;
  objectStore: TMIDBObjectStore;
  aRequest: TMIDBRequest;
begin
  aTransaction := FDb.transaction([FObjectStoreName], TMIDBTransactionMode.readwrite);
  objectStore := aTransaction.objectStore(FObjectStoreName);
  // This gets the keys straight. No cursor is used.
  aRequest := objectStore.getAllKeys(nil);
  SetOpCode(TJSObject(aRequest), opGetAllKeys);
  SetSeqId(TJSObject(aRequest), sequenceID);
  aRequest.OnSuccess := DoSuccess;
  aRequest.OnError := DoFail;
end;

// Gets objects in natural sequence only
procedure TIndexedDb.GetAllObjs(sequenceID: JSValue = 0);
var
  aTransaction: TMIDBTransaction;
  objectStore: TMIDBObjectStore;
  aRequest: TMIDBRequest;
begin
  aTransaction := FDb.transaction([FObjectStoreName], TMIDBTransactionMode.readwrite);
  objectStore := aTransaction.objectStore(FObjectStoreName);
  // This gets the objects straight. No cursor is used.
  aRequest := objectStore.getAll();
  SetOpCode(TJSObject(aRequest), opGetAllObjs);
  SetSeqId(TJSObject(aRequest), sequenceID);
  aRequest.OnSuccess := DoSuccess;
  aRequest.OnError := DoFail;
end;

// get keys in the order of an index
procedure TIndexedDb.GetIndexKeys(indexPropertyName: String; sequenceID: JSValue = 0);
var
  aTransaction: TMIDBTransaction;
  objectStore: TMIDBObjectStore;
  aRequest: TMIDBRequest;
  anIndex: TMIDbIndex;
begin
  aTransaction := FDb.transaction([FObjectStoreName], TMIDBTransactionMode.readwrite);
  objectStore := aTransaction.objectStore(FObjectStoreName);
  FAllResult.Length := 0; //clear the array
  anIndex := objectStore.index(indexPropertyName);
  aRequest := anIndex.openCursor;
  SetOpCode(TJSObject(aRequest), opGetAllIndexKeys);
  SetSeqId(TJSObject(aRequest), sequenceID);
  aRequest.OnSuccess := DoSuccess;
  aRequest.OnError := DoFail;
end;

// gets objects in the order of current index specified by
// FActiveIndex. If that is empty, natural order
procedure TIndexedDb.GetAllObjsByIndex(sequenceID: JSValue = 0);
var
  aTransaction: TMIDBTransaction;
  objectStore: TMIDBObjectStore;
  aRequest: TMIDBRequest;
  anIndex: TMIDbIndex;
  aDirection: String;
begin
  aTransaction := FDb.transaction([FObjectStoreName], TMIDBTransactionMode.readwrite);
  objectStore := aTransaction.objectStore(FObjectStoreName);
  FAllResult.Length := 0; //clear the array
  aDirection := 'next';
  if FIndexDescending then
    aDirection := 'prev';

  if FActiveIndex = '' then
    aRequest := objectStore.openCursor(nil, aDirection)
  else
  begin
    anIndex := objectStore.index(FActiveIndex);
    aRequest := anIndex.openCursor(nil, aDirection);
  end;
  SetOpCode(TJSObject(aRequest), opGetAllObjsByIndex);
  SetSeqId(TJSObject(aRequest), sequenceID);
  aRequest.OnSuccess := DoSuccess;
  aRequest.OnError := DoFail;
end;

// obtain data for a key faster using a particular index
procedure TIndexedDb.GetIndexData(indexPropertyName: String; akey: JSValue; sequenceID: JSValue = 0);
var
  aTransaction: TMIDBTransaction;
  objectStore: TMIDBObjectStore;
  anIndex: TMIDbIndex;
  aRequest: TMIDBRequest;
begin
  aTransaction := FDb.transaction([FObjectStoreName], TMIDBTransactionMode.readwrite);
  objectStore := aTransaction.objectStore(FObjectStoreName);
  anIndex := objectStore.index(indexPropertyName);
  aRequest := anIndex.get(akey);
  SetOpCode(TJSObject(aRequest), opGet);
  SetSeqId(TJSObject(aRequest), sequenceID);
  aRequest.OnSuccess := DoSuccess;
  aRequest.OnError := DoFail;
end;

{ TIndexedDbClientDataProxy }

// A data request object is created for initial loading sequence
// with this call first.
function TIndexedDbClientDataProxy.GetDataRequest(aOptions: TLoadOptions; aAfterRequest: TDataRequestEvent; aAfterLoad: TDatasetLoadEvent) : TDataRequest;
begin
  Result := GetDataRequestClass.Create(Self, aOptions, aAfterRequest, aAfterLoad);
  FDataRequest := Result;
  FDataRequestEvent := aAfterRequest;
end;

// The data loading is initiated by the ClientDataSet by this
// request coming from it.
function TIndexedDbClientDataProxy.DoGetData(aRequest: TDataRequest): Boolean;
begin
  // We had to code this flag and condition due to spurious multiple
  // requests coming from DBGrid even after the data is loaded.
  // In such cases, we return Empty array to say no more data
  if (Owner as TIndexedDbClientDataset).IDBDataLoaded then
  begin
{$ifdef idbdebug}
    // DBGrid sends too many such requests even after loading. Hence commenting to keep the log clean.
    // console.log('Response: Empty (no more) to additional records request after all objects already sent.');
{$endif}
    aRequest.success := rrEOF;
    aRequest.data := TJSArray.New;
    if Assigned(FDataRequestEvent) then
      FDataRequestEvent(FDataRequest);
    exit;
  end;
  // IndexedDB is opened if not already open
  if (FIdb = nil) then
  begin
    if ((Owner as TIndexedDbClientDataset).IDBDatabaseName <> '')
      and ((Owner as TIndexedDbClientDataset).IDBObjectStoreName <> '')
      and ((Owner as TIndexedDbClientDataset).IDBKeyFieldName <> '') then
    begin
      if not (Owner as TIndexedDbClientDataset).IsKeyFieldValid then
        // Usage error
        raise Exception.Create(
          Format('TIndexedDbClientData Usage Error: The IDBKeyFieldName "%s" not found in field definitions.', [(Owner as TIndexedDbClientDataset).IDBKeyFieldName])
          );
      if (not (Owner as TIndexedDbClientDataset).IsKeyFieldInteger)
         and (Owner as TIndexedDbClientDataset).IDBAutoIncrement
      then
      begin
        // Instead of giving exception, just give a warning and correct flag
        (Owner as TIndexedDbClientDataset).IDBAutoIncrement := False;
         console.log('IndexedDB Warning: IDBAutoIncrement switched OFF as IDBKeyFieldName is not of type Integer.');
      end;

      FIdb := TIndexedDb.Create(self);
      FIdb.OnResult := DoOnSuccess;
{$ifdef idbdebug}
      console.log('Opening indexed DB.');
{$endif}
      FIdb.setIndexFields((Owner as TIndexedDbClientDataset).FIDBIndexFields);
      FIdb.Open((Owner as TIndexedDbClientDataset).IDBDatabaseName,
                (Owner as TIndexedDbClientDataset).IDBObjectStoreName,
                (Owner as TIndexedDbClientDataset).IDBKeyFieldName,
                (Owner as TIndexedDbClientDataset).IDBAutoIncrement
                );
      FIdb.ActiveIndex := (Owner as TIndexedDbClientDataset).IDBActiveIndex;
      FIdb.IndexDescending := (Owner as TIndexedDbClientDataset).IDBIndexDescending;
    end
    else
    begin
      // Usage error
      raise Exception.Create('TIndexedDbClientData: DatabaseName, ObjectStoreName and KeyFieldName must be specified.');
    end;
  end
  else
  begin
    // The logic is such that either the data is loaded or the
    // database needs to be opened for loading. Third condition
    // is not possible and can signal an internal error to find
    // faults in logic.
    console.log('IndexedDB: Internal error, DoGetData entry with these conditions not possible.');
  end;
  // This Resul has no meaning in Async, caller does not check result
  Result := True;
end;


// On any error, whether for initial loading sequence or for
// later updates, this is called. It communicates the error
// accordingly to either the CDS for its loading sequence
// or to the App via CDS OnIDBError handler.
procedure TIndexedDbClientDataProxy.DoOnError(opCode: TIndexedDbOpCode; errorName, errorMsg: String);
var
  CDS: TIndexedDbClientDataset;
begin
  if ((opCode = opOpen) or (opCode = opGetAllObjsByIndex))
     and Assigned(FDataRequestEvent) then
  begin
    FDataRequest.success := rrFail;
    FDataRequest.ErrorMsg := FormatErrorMessage(opCode, errorName, errorMsg);
    FDataRequestEvent(FDataRequest);
  end;

  CDS := TIndexedDbClientDataset(Owner);
  CDS.DoOnError(opCode, errorName, errorMsg);
end;

// This is the response function used by Data Proxy for all
// IndexedDB operations whether for initial loading of data
// or for later updates.
procedure TIndexedDbClientDataProxy.DoOnSuccess(success: Boolean; opCode: TIndexedDbOpCode; data: JSValue; sequenceID: JSValue; errorName, errorMsg: String);
var
  cds: TIndexedDbClientDataset;
  aBookMark: TBookMark;
  exe: Exception;
  excName, excMsg: String;
  aKeyId: TKeyId = (value: 0);
begin
  cds := TIndexedDbClientDataset(Owner);

  try
    case opCode of
      opOpen:
        begin
          if not success then
          begin
            DoOnError(opCode, errorName, errorMsg);
            exit;
          end;
          // Immediately after opening:
          // Inform user AutoIncrement specified in ClientDataSet does
          // not match an existing dataset, a Spec Change error.
          if (Owner as TIndexedDbClientDataset).IDBAutoIncrement <> FIdb.AutoIncrement then
          begin
            (Owner as TIndexedDbClientDataset).IDBAutoIncrement := FIdb.AutoIncrement;
            if FIdb.AutoIncrement then
              console.log('IndexedDB Warning: AutoIncrement setting of ClientDataSet switched ON to match that of the existing database.')
            else
              console.log('IndexedDB Warning: AutoIncrement setting of ClientDataSet switched OFF to match that of the existing database.');
          end;
      {$ifdef idbdebug}
          console.log('Open Success. Next, getting all objects by index');
      {$endif}
          // Open finished is first part of initial data loading
          // sequence in the CDS. Next a request is sent to get
          // all the objects in the order of currently active index
          FIdb.OnResult := DoOnSuccess;
          FIdb.GetAllObjsByIndex;
        end;

      opGetAllObjsByIndex:
        begin
          if not success then
          begin
            DoOnError(opCode, errorName, errorMsg);
            exit;
          end;
          // determine if part of loading sequence data request
          if Assigned(FDataRequest) and Assigned(FDataRequest.DataSet) then
          begin
            // Get All finished is second part of initial data loading
            // sequence in the CDS. Now in order to set Rows, the dataset
            // must be closed. This is mandatory.
            FDataRequest.success := rrEOF;
            FDataRequest.Data := data;
            if cds.Active then
              cds.Close;
            if isArray(data) then
              // The following operation internally does an Open again
              cds.Rows := toArray(data);
            cds.IDBDataLoaded := true;
      {$ifdef idbdebug}
            console.log('Response: all objects');
      {$endif}
            // The loading sequence initiated by
            if Assigned(FDataRequestEvent) then
              FDataRequestEvent(FDataRequest);
          end;
        end;

      opPut, opDelete:
        begin
          cds.UpdateEnds;
          if (not success) then
          begin
            if not Assigned(cds.ONIDBAfterUpdate) then
              DoOnError(opCode, errorName, errorMsg)
            else
              cds.ONIDBAfterUpdate(False, opCode, aKeyId, errorName, errorMsg);
            exit;
          end
          else
            if Assigned(cds.ONIDBAfterUpdate) then
            begin
              aKeyId.value := data;
              cds.ONIDBAfterUpdate(True, opCode, aKeyId, '', '');
            end;
        end;

      opAdd:
        begin
          cds.UpdateEnds;
          if (not success) then
          begin
            if not Assigned(cds.ONIDBAfterUpdate) then
              DoOnError(opCode, errorName, errorMsg)
            else
              cds.ONIDBAfterUpdate(False, opCode, aKeyId, errorName, errorMsg);
            exit;
          end;
          // In case of Add with AutoIncrement, the primary
          // key is not known or sent by CDS when calling add.
          // But after the add finishes, we must go back and
          // update it in the original CDS record.
          if success and FIdb.AutoIncrement then
          begin
            cds := TIndexedDbClientDataset(Owner);
            aBookMark.Flag := bfCurrent;
            aBookMark.Data := SequenceId;
            cds.GotoBookmark(aBookMark);
            cds.Edit;

            // hard error testing: Add 'x' to key field. This should cause a Delphi exception (red alert)
            cds.FieldByName((Owner as TIndexedDbClientDataset).IDBKeyFieldName).AsInteger := Integer(data);
            // This may trigger an additional Put update but that is OK.
            Cds.FInsertIdUpdated := True;
            try
              // the following modify is for local use where generated auto id
              // is put in the record. We need to suppress a Modify to server
              // resulting from this, hence the flag FInsertIdUpdated used that
              // is processed in the next AddToChangeList call.
              cds.Post;
            finally
              Cds.FInsertIdUpdated := False;
            end;
          end;
          if Assigned(cds.ONIDBAfterUpdate) then
          begin
            aKeyId.value := data;
            cds.ONIDBAfterUpdate(True, opCode, aKeyId, '', '');
          end;
        end;
    end;
  except
    asm
      exe = $e;
    end;
    if not GetJSExceptionDetails(exe, excName, excMsg) then
      raise exe;
    excMsg := FormatErrorMessage(opCode, excName, excMsg);
    DoOnError(opCode, excName, excMsg);
  end;
end;

// Each change in CDS calls this to update the IndexedDB too.
procedure TIndexedDbClientDataProxy.ProcessUpdate(desc: TRecordUpdateDescriptor);
var
  dataObj: TJSObject;
  aBookMark: TBookMark;
  cds: TIndexedDbClientDataset;
  op: TIndexedDbOpCode;
  exe: Exception;
  excName, excMsg: String;
begin
  cds := TIndexedDbClientDataset(Owner);

  try
    // errors need to be caught on calls
    if (desc.Status = usModified) then
    begin
      op := opPut;
      FIdb.OnResult := DoOnSuccess;
      cds.UpdateStarts;
      FIdb.PutData(desc.data);
    end;
    if (desc.Status = usInserted) then
    begin
      op := opAdd;
      FIdb.OnResult := DoOnSuccess;
      dataObj := TJSObject(desc.data);
      // we send bookmark as sequence id so that the primary key
      // can be updated after auto increment creation
      aBookmark := cds.BookMark;
      if (aBookmark.Flag <> bfCurrent) then
        console.log('IndexedDB: Internal Warning, bookmark flag on insert not current as expected.');
      cds.UpdateStarts;
      FIdb.AddData(desc.data, aBookmark.data);
    end;
    if desc.Status = usDeleted then
    begin
      op := opDelete;
      dataObj := TJSObject(desc.data);
      FIdb.OnResult := DoOnSuccess;
      cds.UpdateStarts;
      // For soft error testing pass null to DeleteData
      FIdb.DeleteData(dataObj.properties[(Owner as TIndexedDbClientDataset).IDBKeyFieldName]);
    end;
  except
    asm
      exe = $e;
    end;
    if not GetJSExceptionDetails(exe, excName, excMsg) then
      raise exe;
    excMsg := FormatErrorMessage(op, excName, excMsg);
    DoOnError(op, excName, excMsg);
  end;
end;

procedure TIndexedDbClientDataProxy.Close;
begin
  if FIdb <> nil then
    FIdb.Free;
  FIdb := nil;
end;

// Dummy abstract method. Must be coded.
function TIndexedDbClientDataProxy.ProcessUpdateBatch(
  aBatch: TRecordUpdateBatch): Boolean;
begin
  Result := False;
end;

constructor TIndexedDbClientDataProxy.Create(AOwner: TComponent);
begin
  inherited;
  FDataRequest := nil;
  FDataRequestEvent := nil;
  FIdb := nil;
end;

destructor TIndexedDbClientDataProxy.Destroy;
begin
  if FIdb <> nil then
    FIdb.Free;
  inherited;
end;

{ TIndexedDbClientDataset }

procedure TIndexedDbClientDataset.InternalClose;
begin
  inherited;
  FIDBProxy.Close;
  FIDBDataLoaded := False;
  FOpenAfterLoadDFM := False;
end;

// This is called on each change. So we use it to trigger
// IndexedDB updates.
Function TIndexedDbClientDataset.AddToChangeList(aChange : TUpdateStatus) : TRecordUpdateDescriptor ;
var
  desc: TRecordUpdateDescriptor;
begin
{$ifdef idbdebug}
  console.log('Processing change instantly in Auto Update for change type: ', aChange);
{$endif}
  desc := FIDBProxy.GetUpdateDescriptor(Self, GetBookmark, ActiveBuffer.data, aChange);

  if (not FInsertIdUpdated) or (desc.Status <> usModified) then
      FIDBProxy.ProcessUpdate(desc);

  result := nil;
  exit;
end;


procedure TIndexedDbClientDataset.SetActive(Value: Boolean);
begin
  if (FieldDefs.Count = 0)
    and value and (State = dsInactive)
    and (not FIDBDataLoaded)
    and (not FOpenAfterLoadDFM)
  then
  begin
    FOpenAfterLoadDFM := True;
    exit;
  end;
  if value and (State = dsInactive)
    and (not FIDBDataLoaded)
  then
  begin
    FOpenAfterLoadDFM := False;
    // The following Load internally causes Active := True
    // in db.pas and then "inherited" path is taken
    Load([loNoEvents], nil);
    exit;
  end;
  inherited;
end;

procedure TIndexedDbClientDataset.AfterLoadDFMValues;
begin
  inherited;
  if FOpenAfterLoadDFM then
  begin
    // if field defs are still absent better raise error
    if FieldDefs.Count = 0 then
      raise Exception.Create(
          'TIndexedDbClientData Usage Error: Can not Set Active, Field definitions missing.');
    SetActive(True);
  end;
end;

procedure TIndexedDbClientDataset.DoAfterOpen;
begin
  inherited;

  // Reposition to same rec after a Refresh
  // Not sure if should be before or after inherited. The doubt
  // is related to CDS runtime indexes and a reposition should
  // be tested with and without indexes.
  if FRemKeyPos <> nil then
  begin
    Locate(FIDBKeyFieldName, FRemKeyPos, []);
    FRemKeyPos := nil;
  end;
end;

constructor TIndexedDbClientDataset.Create(AOwner: TComponent);
begin
  inherited;
  FIDBDatabaseName := '';
  FIDBObjectStoreName := '';
  FIDBKeyFieldName := '';
  FIDBActiveIndex := '';
  FIDBIndexDescending := False;
  FIDBAutoIncrement := False;
  FIDBDataLoaded := False;
  FOpenAfterLoadDFM := False;
  FRemKeyPos := nil;
  FIDbProxy := nil;
  FIDBIndexFields := nil;
  FIDbProxy := TIndexedDbClientDataProxy.Create(Self);
  DataProxy := FIDbProxy;

  FInsertIdUpdated := False;
  FUpdateCount := 0;
  FRefreshPending := False;
end;

destructor TIndexedDbClientDataset.Destroy;
begin
  if Active then
    Close;

  if FIDbProxy <> nil then
    FIDbProxy.Free;

  if FIdbInit <> nil then
    FIdbInit.Free;
  inherited;
end;

procedure TIndexedDbClientDataset.Refresh;
begin
  // Since a Refresh is going to close the db and reopen,
  // better wait for any updates in progress to finish.
  // Even that may not foolproof in certain conditions
  // and interleaving of operations. But we can try.
  if FUpdateCount > 0 then
  begin
    FRefreshPending := True;
    exit;
  end;
  FRefreshPending := False;

  if CurrentRecord > -1 then
    // remember the record to reposition after refresh
    FRemKeyPos := FieldByName(FIDBKeyFieldName).Value;

{$ifdef idbdebug}
  console.log('Closing the DB for a Refresh Load.');
{$endif}
  Close;
  Load([loNoEvents], nil);
end;

procedure TIndexedDbClientDataset.AddIDBIndex(anIndexName: String; fields: String; isUnique: Boolean=False);
var
  anIndexDef: TJSObject;
begin
  if FIDBIndexFields = nil then
    FIDBIndexFields := TJSArray.New;
  anIndexDef := TJSObject.New;
  anIndexDef['name'] := anIndexName;
  anIndexDef['keypath'] := fields;
  anIndexDef['isUnique'] := isUnique;
  FIDBIndexFields.Push(anIndexDef);
end;

// Communicate the error to the App. DataProxy uses it on error.
procedure TIndexedDbClientDataSet.DoOnError(opCode: TIndexedDbOpCode; errorName, errorMsg: String);
begin
  console.log(errorMsg); //log anyway
  if Assigned(FOnIDBError) then
    FOnIDBError(Self, opCode, errorName, errorMsg)
  else
    raise Exception.Create(errorMsg);
end;

procedure TIndexedDbClientDataSet.UpdateStarts;
begin
  // Update count is used to wait for updates to finish
  // when doing a Refresh.
  Inc(FUpdateCount);
end;

procedure TIndexedDbClientDataSet.UpdateEnds;
begin
  if FUpdateCount = 0 then
    console.log('IndexedDB Internal Warning: Update count is already zero in UpdateEnds')
  else
    Dec(FUpdateCount);
  if FRefreshPending and (FUpdateCount = 0) then
    Refresh;
end;

procedure TIndexedDbClientDataSet.FixKeyFieldCase;
var
  i: Integer;
begin
  for i := 0 to FieldDefs.Count - 1 do
  begin
    if SameText(TNamedItem(FieldDefs.Items[i]).Name, IDBKeyFieldName) then
    begin
      IDBKeyFieldName := TNamedItem(FieldDefs.Items[i]).Name;
      Break;
    end;
  end;
end;

function TIndexedDbClientDataSet.IsKeyFieldValid: Boolean;
var
  aFieldDef: TFieldDef;
begin
  FixKeyFieldCase;
  aFieldDef := TFieldDef(TDefCollection(FieldDefs).Find(IDBKeyFieldName));
  Result := (aFieldDef <> nil);
end;

function TIndexedDbClientDataSet.IsKeyFieldInteger: Boolean;
var
  aFieldDef: TFieldDef;
begin
  //we don't want any exception
  aFieldDef := TFieldDef(TDefCollection(FieldDefs).Find(IDBKeyFieldName));
  Result := (aFieldDef <> nil) and (aFieldDef.DataType = ftInteger);
end;

procedure TIndexedDbClientDataSet.DoInitSuccess(success: Boolean; opCode: TIndexedDbOpCode; data: JSValue; sequenceID: JSValue; errorName, errorMsg: String);
begin
{$ifdef idbdebug}
  console.log('Init completed for objectstore '+FIDBObjectStoreName);
{$endif}
  FIdbInit.Free;
  FIdbInit := nil;
  if not success then
     DoOnError(opOpen, 'Init: ' + errorName, errorMsg);
  if success then
  begin
    if assigned(FOnInitSuccessProc) then
      FOnInitSuccessProc
    else
    if assigned(FOnInitSuccess) then
      FOnInitSuccess(self);
  end;
end;

function TIndexedDbClientDataSet.Init(ProcInitSuccess: TIDBProc=nil): Boolean;
begin
  Result := False;
  if IDBDataLoaded then
  begin
    console.log('IndexedDB: Internal error, called Init inappropriately.');
    exit;
  end;
  if (IDBDatabaseName <> '')
      and (IDBObjectStoreName <> '')
      and (IDBKeyFieldName <> '') then
  begin
    if not IsKeyFieldValid then
      // Usage error
      raise Exception.Create(
        Format('TIndexedDbClientData Usage Error (Init): The IDBKeyFieldName "%s" not found in field definitions.', [(Owner as TIndexedDbClientDataset).IDBKeyFieldName])
        );
    if (not IsKeyFieldInteger)
       and IDBAutoIncrement
    then
    begin
      // Instead of giving exception, just give a warning and correct flag
      IDBAutoIncrement := False;
      console.log('IndexedDB Warning (Init): IDBAutoIncrement switched OFF as IDBKeyFieldName is not of type Integer.');
    end;

    if FIdbInit <> nil then
      FIdbInit.Free;

    FOnInitSuccessProc := ProcInitSuccess;

    FIdbInit := TIndexedDb.Create(self);
    FIdbInit.OnResult := DoInitSuccess;
{$ifdef idbdebug}
    console.log('Opening indexed DB for Init.');
{$endif}
    FIdbInit.setIndexFields(FIDBIndexFields);
    // Just opening makes sure that objectstore and
    // indexes are created.
    FIdbInit.Open(IDBDatabaseName,
                IDBObjectStoreName,
                IDBKeyFieldName,
                IDBAutoIncrement
                );
  end
  else
  begin
    // Usage error
    raise Exception.Create('TIndexedDbClientData (Init): DatabaseName, ObjectStoreName and KeyFieldName must be specified.');
  end;
  Result := True;
end;


end.
